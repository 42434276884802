import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../../mat/MatStyle';
import { useTranslation } from 'react-i18next';

const StaffParticipationSummary = (props) => {
  console.log(props?.staffParticipations);
  const { t } = useTranslation();
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          <h1 className='pres-title'>{'Management Standards'}</h1>
          <p className='pres-text'>
            {`The survey used is the Health and Safety (HSE) Indicator Tool. This is evidence built and DfE Staff Wellbeing Charter recognised and measures how staff view their working environment against the Health and Safety Executive's (HSE) Management Standards.`}
          </p>
          <p className='pres-text'>{`These Standards can be used to define the characteristics and culture of a school or organisation, where the risks from work related stress and poor staff wellbeing are being effectively managed and controlled. They cover six key areas of work design, that if not properly managed, are associated with poor health and wellbeing, lower productivity, increased sickness absence and lower staff retention.`}</p>
          <p className='pres-text'>{`These cover Demands, Control, Manager and Peer Support, Relationships, Role and Change. A definition for each of these is shown on the slide with individual Management Standard scores.`}</p>
          <h1 className='pres-title'>{props.title}</h1>
          <p className='pres-text'>
            {props.NoOfStaffParticipated} members of staff from across your
            across your school participated in the survey, which is{' '}
            {(
              (props.NoOfStaffParticipated / props.TotalSchoolStaffSurveySent) *
              100
            ).toFixed(2)}
            % of all staff. The breakdown of this across your schools and core
            filters is shown in the table below. Where no number is shown, this
            is because less than 5 participants took part for that filter shown.
          </p>
          <p className='table-title'>Table 1: Staff Participation</p>
          <div className='section'>
            <div className='stats-table'>
              <Scrollbars className='scrollbar-view'>
                <div className='job-role-container'>
                  <div className='stats-title-cell'>Organisation</div>
                  <div className='stats-role-cell'>
                    Support Staff (Classroom)
                  </div>
                  <div className='stats-role-cell'>
                    Support Staff (Non-Classroom)
                  </div>
                  <div className='stats-role-cell'>
                    Teaching Staff (Classroom)
                  </div>
                  <div className='stats-role-cell'>
                    Teaching Staff (Non-Classroom)
                  </div>
                  <div className='stats-role-cell'>{t('MiddleLeaders')}</div>
                  <div className='stats-role-cell'> {t('SeniorLeaders')}</div>
                  <div className='stats-role-cell'>Total</div>
                </div>
                <div className='job-role-container'>
                  <div className='job-role-title-cell'>
                    {props?.staffParticipations.ResponseCountByJob.Organisation}
                  </div>
                  <div className='job-role-cell'>
                    {
                      props?.staffParticipations.ResponseCountByJob
                        .SupportStaffWorkingInside
                    }
                  </div>
                  <div className='job-role-cell'>
                    {
                      props?.staffParticipations.ResponseCountByJob
                        .SupportStaffWorkingOutside
                    }
                  </div>
                  <div className='job-role-cell'>
                    {
                      props?.staffParticipations.ResponseCountByJob
                        .TeacherWorkingInside
                    }
                  </div>
                  <div className='job-role-cell'>
                    {
                      props?.staffParticipations.ResponseCountByJob
                        .TeacherWorkingOutside
                    }
                  </div>
                  <div className='job-role-cell'>
                    {props?.staffParticipations.ResponseCountByJob.MiddleLeader}
                  </div>
                  <div className='job-role-cell'>
                    {props?.staffParticipations.ResponseCountByJob.SeniorLeader}
                  </div>
                  <div className='job-role-cell'>
                    {props?.staffParticipations.ResponseCountByJob.Total}
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default StaffParticipationSummary;
