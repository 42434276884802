import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  ConvertStringToDateFormat,
  parseJwt,
} from '../../../utils/CommonFunctions';
import { welbeeslideslogo } from '../../../resources/images';
import {
  SurveyTypes,
  WebStorageNames,
  MenuItems,
  PptSlidesOrder,
} from '../../../utils/Constants';
import { storageService } from '../../../services';
import { loaderAction } from '../../../store/loaderStore';
import { navigationActions } from '../../../store/headerStore';
import PptxGenJS from 'pptxgenjs';
import { amplitudeLog } from '../../../utils/CommonFunctions';
import {
  PageOverlay,
  PresentationWrapperStyle,
  PresentationInnerWrapperStyle,
  PageFooterStyle,
} from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import StaffParticipSummaryPpt from './StaffParticipSummaryPpt';
import AverageScoresAllParticipantsPpt from './AverageScoresAllParticipantsPpt';
import ScoresbyJobRolePpt from './ScoresbyJobRolePpt';
import ScoresbySchoolPpt from './ScoresbySchoolPpt';
import HighestLowestScoringQuestionsPpt from './HighestLowestScoringQuestionsPpt';
import QuestionScoresbySchoolSitePpt from './QuestionScoresbySchoolSitePpt';
import QuestionScorebyJobRole from './QuestionScorebyJobRole';
import MathomePpt from './MathomePpt';
import GraphScoresbyJobRolePpt from './GraphScoresbyJobRolePpt';
import GraphScoresbySchoolsPpt from './GraphScoresbySchoolsPpt';
import { useTranslation, withTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import pptxgen from 'pptxgenjs';

let pptx;
let slideColors = {
  white: 'FFFFFF',
  yellow: 'FFCF05',
  purple: '45338C',
  green: '046458',
  orange: 'ff3000',
  lightred: 'F5ABCC',
};

class MatPPTNewVersion extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    this.printSurveyReportPresentation =
      this.printSurveyReportPresentation.bind(this);

    let groupType = storageService.getItem('groupType');

    this.state = {
      selectedSlideIndex: 0,
      presentationData: this.props.matPppData,
      isPreview: false,
      surveyResult: [],
      surveyData: [],
      selectedItem: 0,
      CategoryType: '',
      CategoryTitle: '',
      QuestionSurveyAverages: [],
      schoolInfo: '',
      surveyStartDate: new Date(),
      surveyEndDate: new Date(),
      SurveysCompletedPercentage: 0,
      message: '',
      latestSurveyOption: { value: '', label: 'Latest survey' },
      latestSurveyOptions: [{ value: SurveyTypes.any, label: 'Latest survey' }],
      isRole: userInfo.role,
      reportdata: {},
      matName: '',
      matType: '',
      loaded: false,
      matAvg: {},
      heatmapByRole: [],
      lowMstd: '',
      highMstd: '',
      heatmapBySchool: [],
      highestScoringSchools: [],
      lowestScoringSchools: [],
      highestScoringStandards: [],
      lowestScoringStandards: [],
      highScoreQuestions: [],
      lowScoreQuestions: [],
      JobRoleGraph: [],
      AllSchoolGraph: [],
      GroupType: groupType || this.props.t('MAT'),
      surveyCount: 0,
      usRegion: storageService.getItem(WebStorageNames.UsRegion),
    };
  }
  onKeyDown = (event) => {
    if (event.keyCode) {
      const { selectedItem } = this.state;
      this.setState({ selectedItem: selectedItem + 1 });
    }
  };

  GetPresentationData = (presentationData) => {
    this.setState({ surveyResult: [] });
    const { action } = this.props;
    action.dataLoading(true);
    var slide = this.props.NumberOfSlides;
    var isQuestion =
      presentationData?.staffPresentationData?.HasAdditionalQuestion;

    this.setState({
      reportdata: presentationData?.staffPresentationData,
      loaded: true,
      JobRoleGraph: this.props.JobRoleGraph,
      AllSchoolGraph: this.props.AllSchoolGraph,
      matType: presentationData?.staffPresentationData?.MatGroup?.GroupName,
      matName: presentationData?.staffPresentationData?.MatGroup?.Name,
      matAvg: presentationData?.staffPresentationData.AverageModel,
      heatmapByRole: presentationData?.staffPresentationData?.HeatmapByRole,
      heatmapBySchool: presentationData?.staffPresentationData?.HeatmapBySchool,
      lowMstd: presentationData?.staffPresentationData?.LowManagementStandards,
      highMstd: presentationData?.staffPresentationData?.TopManagementStandards,
      highestScoringSchools:
        presentationData?.staffPresentationData?.HighestScoringSchools,
      lowestScoringSchools:
        presentationData?.staffPresentationData?.LowestScoringSchools,
      lowestScoringStandards:
        presentationData?.staffPresentationData?.LowestScoringStandards,
      highestScoringStandards:
        presentationData?.staffPresentationData?.HighestScoringStandards,
      highScoreQuestions:
        presentationData?.staffPresentationData?.HighScoreQuestions,
      lowScoreQuestions:
        presentationData?.staffPresentationData?.LowScoreQuestions,
      schoolInfo: presentationData?.schoolInfo,
      surveyStartDate: presentationData?.schoolInfo?.SurveyStartDate,
      surveyEndDate: presentationData?.schoolInfo?.SurveyEndDate,
      SurveysCompletedPercentage:
        presentationData?.schoolInfo.SurveysCompletedPercentage,
      surveyCount: presentationData?.getAllSchoolGhraph[0]?.surveyCount,
    });
    if (!isQuestion) {
      slide = slide.filter((x) => x.id !== 9 && x.id !== 10);
    }

    if (!this.props.JobRoleGraph?.length) {
      slide = slide.filter((x) => x.id !== 5);
    }

    if (!this.props.AllSchoolGraph?.length) {
      slide = slide.filter((x) => x.id !== 7);
    }

    this.setState({ surveyResult: slide });
    action.dataLoading(false);
  };
  componentDidMount() {
    this.GetPresentationData(this.props.matPppData);
    this.props.navigationActions.activeItemAction({
      item: MenuItems.MatPresentation,
    });
    amplitudeLog('PageView', 'MAT Presentation', '');
    this.props.setClick(this.printSurveyReportPresentation);
  }

  renderSurveryReportPresentation = (item) => {
    if (item.id === PptSlidesOrder.MatNameAndLogo) {
      return (
        <MathomePpt
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          SchoolName={`${this.state.schoolInfo.SchoolName} \r\nSurvey Results`}
          matLogo={this.state.schoolInfo.SchoolLogo}
          surveydate={ConvertStringToDateFormat(
            this.state.schoolInfo.SurveyStartDate
          )}
        />
      );
    }

    if (item.id === PptSlidesOrder.ScorebyStaffParticipation) {
      return (
        <StaffParticipSummaryPpt
          id={item.id + item.label}
          title={item.label}
          welbeeslideslogo={welbeeslideslogo}
          CycleName={this.state.reportdata.CycleName}
          matLogo={this.state.schoolInfo.SchoolLogo}
          matType={this.state.matType}
          NoOfStaffParticipated={this.state.reportdata.NoOfStaffParticipated}
          TotalMatStaff={this.state.reportdata.TotalMatStaff}
          MatSchoolStaffCount={this.state.reportdata.MatSchoolStaffCount}
          SupportStaffInsideCount={
            this.state.reportdata.SupportStaffInsideCount
          }
          SupportStaffOutsideCount={
            this.state.reportdata.SupportStaffOutsideCount
          }
          TeachingStaffInsideCount={
            this.state.reportdata.TeachingStaffInsideCount
          }
          TeachingStaffOutsideCount={
            this.state.reportdata.TeachingStaffOutsideCount
          }
          MiddleLeaderCount={this.state.reportdata.MiddleLeaderCount}
          SeniorLearderCount={this.state.reportdata.SeniorLearderCount}
          reportData={this.state.reportdata}
        />
      );
    }

    if (item.id === PptSlidesOrder.AverageScoreAllParticiton) {
      return (
        <AverageScoresAllParticipantsPpt
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          title={item.label}
          SchoolName={this.state.reportdata.CycleName}
          matLogo={this.state.schoolInfo.SchoolLogo}
          matType={this.state.matType}
          matAvg={this.state.matAvg}
        />
      );
    }

    if (item.id === PptSlidesOrder.ScorebyJobRole) {
      return (
        <ScoresbyJobRolePpt
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          title={item.label}
          CycleName={this.state.reportdata.CycleName}
          matLogo={this.state.schoolInfo.SchoolLogo}
          heatmapByRole={this.state.heatmapByRole}
        />
      );
    }
    if (item.id === PptSlidesOrder.GraphScorebyJobRole) {
      return (
        <GraphScoresbyJobRolePpt
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          title={item.label}
          JoRoleGraph={this.state.JobRoleGraph}
          surveyCount={this.state.surveyCount}
          ppt={true}
          name={'ppt-chart-jobrole'}
        />
      );
    }
    if (item.id === PptSlidesOrder.ScorebySchools) {
      return (
        <ScoresbySchoolPpt
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          title={item.label}
          CycleName={this.state.reportdata.CycleName}
          matLogo={this.state.schoolInfo.SchoolLogo}
          heatmapBySchool={this.state.heatmapBySchool}
        />
      );
    }
    if (item.id === PptSlidesOrder.GraphScorebySchools) {
      return (
        <GraphScoresbySchoolsPpt
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          title={item.label}
          surveyCount={this.state.surveyCount}
          AllSchoolGraph={this.state.AllSchoolGraph[item?.subSlideIndex]}
          ppt={true}
          name={'ppt-chart-school' + item?.subSlideIndex}
        />
      );
    }
    if (item.id === PptSlidesOrder.HeighestandLowestScoring) {
      return (
        <HighestLowestScoringQuestionsPpt
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          title={item.label}
          CycleName={this.state.reportdata.CycleName}
          matLogo={this.state.schoolInfo.SchoolLogo}
          highScoreQuestions={this.state.highScoreQuestions}
          lowScoreQuestions={this.state.lowScoreQuestions}
        />
      );
    }

    if (item.id === PptSlidesOrder.QuestionsScorebySchool) {
      return (
        <QuestionScoresbySchoolSitePpt
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          title={item.label}
          CycleName={this.state.reportdata.CycleName}
          matLogo={this.state.schoolInfo.SchoolLogo}
          HasAdditionalQuestion={this.state.reportdata?.HasAdditionalQuestion}
          AdditionQuestionIds={this.state.reportdata?.AdditionQuestionIds}
          AdditionalQuestionsScoresforSchools={
            this.state.reportdata?.AdditionalQuestionsScoresforSchools
          }
          AdditionalQuestionsScores={
            this.state.reportdata?.AdditionalQuestionsScores
          }
        />
      );
    }
    if (item.id === PptSlidesOrder.QuestionsScorebyJobRole) {
      return (
        <QuestionScorebyJobRole
          welbeeslideslogo={welbeeslideslogo}
          id={item.id + item.label}
          title={item.label}
          CycleName={this.state.reportdata.CycleName}
          matLogo={this.state.schoolInfo.SchoolLogo}
          HasAdditionalQuestion={this.state.reportdata?.HasAdditionalQuestion}
          AdditionalQuestionsScores={
            this.state.reportdata?.AdditionalQuestionsScores
          }
          AdditionQuestionIds={this.state.reportdata?.AdditionQuestionIds}
        />
      );
    }
  };
  renderSurveryReportPresentationForPrint = (item) => {
    if (item.type === 'greetings') {
      return `<div>greeting screen </div>`;
    }

    if (item.type === 'overview') {
      return `<div>overview  screen </div>`;
    }

    if (item.type === 'result') {
      return `<div>result screen </div>`;
    }

    if (item.type === 'evaluation') {
      return `<div>evaluation screen </div>`;
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }
  StaffSummaryDataRows = (data) => {
    var column = [];
    data?.MatSchoolStaffCount?.map((row) => {
      column.push([
        {
          text:
            row.SchoolName.length >= 28
              ? row.SchoolName.substring(0, 28) + '...'
              : row.SchoolName,
          options: {
            fontFace: 'Avenir LT Pro',
            border: { pt: 1, color: '#e2e2e2' },
            fill: { color: '#FFFFFF' },
          },
          bold: true,
          breakLine: true,
        },
        {
          text:
            row.ResponseCountByJob.SupportStaffWorkingInside > 4
              ? row.ResponseCountByJob.SupportStaffWorkingInside +
                (' (' +
                  (
                    (row.ResponseCountByJob.SupportStaffWorkingInside /
                      row.ResponseCountByJob.totalSupportStaffClassRoom) *
                    100
                  ).toFixed(2) +
                  '%)')
              : '-',
          options: {
            fontFace: 'Avenir LT Pro',
            border: { pt: 1, color: '#e2e2e2' },
            fill: { color: '#FFFFFF' },
            breakLine: true,
          },
        },
        {
          text:
            row.ResponseCountByJob.SupportStaffWorkingOutside > 4
              ? row.ResponseCountByJob.SupportStaffWorkingOutside +
                (' (' +
                  (
                    (row.ResponseCountByJob.SupportStaffWorkingOutside /
                      row.ResponseCountByJob.totalSupportStaffNonClassRoom) *
                    100
                  ).toFixed(2) +
                  '%)')
              : '-',
          options: {
            fontFace: 'Avenir LT Pro',
            border: { pt: 1, color: '#e2e2e2' },
            fill: { color: '#FFFFFF' },
            breakLine: true,
          },
        },
        {
          text:
            row.ResponseCountByJob.TeacherWorkingInside > 4
              ? row.ResponseCountByJob.TeacherWorkingInside +
                (' (' +
                  (
                    (row.ResponseCountByJob.TeacherWorkingInside /
                      row.ResponseCountByJob.totalTeachingStaffClassRoom) *
                    100
                  ).toFixed(2) +
                  '%)')
              : '-',
          options: {
            fontFace: 'Avenir LT Pro',
            border: { pt: 1, color: '#e2e2e2' },
            fill: { color: '#FFFFFF' },
            breakLine: true,
          },
        },
        {
          text:
            row.ResponseCountByJob.TeacherWorkingOutside > 4
              ? row.ResponseCountByJob.TeacherWorkingOutside +
                (' (' +
                  (
                    (row.ResponseCountByJob.TeacherWorkingOutside /
                      row.ResponseCountByJob.totalTeachingStaffNonClassRoom) *
                    100
                  ).toFixed(2) +
                  '%)')
              : '-',
          options: {
            fontFace: 'Avenir LT Pro',
            border: { pt: 1, color: '#e2e2e2' },
            fill: { color: '#FFFFFF' },
            breakLine: true,
          },
        },
        {
          text:
            row.ResponseCountByJob.MiddleLeader > 4
              ? row.ResponseCountByJob.MiddleLeader +
                (' (' +
                  (
                    (row.ResponseCountByJob.MiddleLeader /
                      row.ResponseCountByJob.totalMiddlerLeader) *
                    100
                  ).toFixed(2) +
                  '%)')
              : '-',
          options: {
            fontFace: 'Avenir LT Pro',
            border: { pt: 1, color: '#e2e2e2' },
            fill: { color: '#FFFFFF' },
            breakLine: true,
          },
        },
        {
          text:
            row.ResponseCountByJob.SeniorLeader > 4
              ? row.ResponseCountByJob.SeniorLeader +
                (' (' +
                  (
                    (row.ResponseCountByJob.SeniorLeader /
                      row.ResponseCountByJob.totalLeader) *
                    100
                  ).toFixed(2) +
                  '%)')
              : '-',
          options: {
            fontFace: 'Avenir LT Pro',
            border: { pt: 1, color: '#e2e2e2' },
            fill: { color: '#FFFFFF' },
            breakLine: true,
          },
        },
        {
          text:
            row.NoOfStaffParticipated > 4
              ? row.NoOfStaffParticipated +
                (' (' +
                  (
                    (row.NoOfStaffParticipated /
                      row.TotalSchoolStaffSurveySent) *
                    100
                  ).toFixed(2) +
                  '%)')
              : '-',
          options: {
            fontFace: 'Avenir LT Pro',
            border: { pt: 1, color: '#e2e2e2' },
            fill: { color: '#FFFFFF' },
            breakLine: true,
          },
        },
      ]);
    });
    column.push([
      {
        text: 'Total',
        options: {
          fontFace: 'Avenir LT Pro',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text:
          data.SupportStaffInsideCount > 4
            ? data.SupportStaffInsideCount +
              ' (' +
              (
                (data.SupportStaffInsideCount / data.TotalSupportStaffInside) *
                100
              ).toFixed(2) +
              '%)'
            : '-',
        options: {
          fontFace: 'Avenir LT Pro',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text:
          data.SupportStaffOutsideCount > 4
            ? data.SupportStaffOutsideCount +
              ' (' +
              (
                (data.SupportStaffOutsideCount /
                  data.TotalSupportStaffOutside) *
                100
              ).toFixed(2) +
              '%)'
            : '-',
        options: {
          fontFace: 'Avenir LT Pro',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text:
          data.TeachingStaffInsideCount > 4
            ? data.TeachingStaffInsideCount +
              ' (' +
              (
                (data.TeachingStaffInsideCount /
                  data.TotalTeachingStaffInside) *
                100
              ).toFixed(2) +
              '%)'
            : '-',
        options: {
          fontFace: 'Avenir LT Pro',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text:
          data.TeachingStaffOutsideCount > 4
            ? data.TeachingStaffOutsideCount +
              ' (' +
              (
                (data.TeachingStaffOutsideCount /
                  data.TotalTeachingStaffOutside) *
                100
              ).toFixed(2) +
              '%)'
            : '-',
        options: {
          fontFace: 'Avenir LT Pro',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text:
          data.MiddleLeaderCount > 4
            ? data.MiddleLeaderCount +
              ' (' +
              ((data.MiddleLeaderCount / data.TotalMiddleLeader) * 100).toFixed(
                2
              ) +
              '%)'
            : '-',
        options: {
          fontFace: 'Avenir LT Pro',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text:
          data.SeniorLearderCount > 4
            ? data.SeniorLearderCount +
              ' (' +
              (
                (data.SeniorLearderCount / data.TotalSeniorLearder) *
                100
              ).toFixed(2) +
              '%)'
            : '-',
        options: {
          fontFace: 'Avenir LT Pro',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
      {
        text:
          data.NoOfStaffParticipated > 4
            ? data.NoOfStaffParticipated +
              ' (' +
              ((data.NoOfStaffParticipated / data.TotalMatStaff) * 100).toFixed(
                2
              ) +
              '%)'
            : '-',
        options: {
          fontFace: 'Avenir LT Pro',
          border: { pt: 1, color: '#e2e2e2' },
          fill: { color: '#FFFFFF' },
          breakLine: true,
        },
      },
    ]);
    return column;
  };
  StaffSummaryPresentation = async (pptx, reportdata) => {
    let slide = null;
    var dataRows = this.StaffSummaryDataRows(reportdata);
    let rows = [];
    slide = pptx.addSlide();
    slide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });
    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      fontFace: 'Avenir LT Pro',
      bold: true,
      fontSize: 20,
      color: slideColors.purple,
    };
    slide.addText('Staff Participation', titilCss);
    let staffPercent = (
      (this.state.reportdata.NoOfStaffParticipated /
        this.state.reportdata.TotalMatStaff) *
      100
    ).toFixed(2);

    let headerText = `${this.state.reportdata.NoOfStaffParticipated} members of staff from across your ${this.state.GroupType} participated in the survey, which is ${staffPercent}% of all staff. The breakdown of this across your schools and core filters is shown in the table below. Where no number is shown, this is because less than 5 participants took part for that job role. The participation numbers for each filter may not add to the total if people have multiple job titles and because participation below 5 is not shown.`;
    var Table1Obj1 = {
      x: 0.0,
      y: 0.6,
      margin: 20,
      h: 0.8,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir LT Pro',
      wrap: true,
      fontSize: 9,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0,
    };
    slide.addText(headerText, Table1Obj1);

    var Table1Obj = {
      x: 3,
      y: 1.574803,
      w: 4,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText('Table 1: Staff Participation', Table1Obj);

    var HeaderOptions = {
      breakLine: true,
      fontFace: 'Avenir LT Pro',
      align: 'center',
      valign: 'middle',
      fill: { color: '#C9E9F7' },
      color: '#000000',
      fontSize: 8,
      bold: true,
    };

    rows.push([
      { text: 'Organisation', options: HeaderOptions },
      { text: 'Support Staff (Classroom)', options: HeaderOptions },
      { text: 'Support Staff (Non-Classroom)', options: HeaderOptions },
      { text: 'Teaching Staff (Classroom)', options: HeaderOptions },
      { text: 'Teaching Staff (Non-Classroom)', options: HeaderOptions },
      // { text: 'Middle Leaders', options: HeaderOptions },
      {
        text:
          this.state.usRegion === 'us'
            ? ' Building Administrators'
            : 'Middle Leaders',
        options: HeaderOptions,
      },
      {
        text:
          this.state.usRegion === 'us'
            ? ' District Administrators'
            : 'Senior Leaders',
        options: HeaderOptions,
      },
      { text: 'Total', options: HeaderOptions },
    ]);

    dataRows.forEach((row, i) => {
      rows.push(row);
    });

    slide.addTable(rows, {
      x: 0.2,
      y: 2.01,
      h: 5.0,
      rowH: 0.35,
      colW: [2.0, 1.1, 1.2, 1.1, 1.2, 1.1, 1.0, 1.0],
      fontSize: 8,
      fontFace: 'Avenir LT Pro',
      border: { pt: 1, color: '#e2e2e2' },
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      valign: 'middle',
      align: 'center',
    });
  };

  getHeatmapCell = (level, value, isDecile, preVal, preDecile = null) => {
    var textValue = isDecile
      ? level +
        (level === 1 ? 'st' : level === 2 ? 'nd' : level === 3 ? 'rd' : 'th') +
        (preDecile
          ? ' (' +
            preDecile +
            (preDecile === 1
              ? 'st'
              : preDecile === 2
              ? 'nd'
              : preDecile === 3
              ? 'rd'
              : 'th') +
            ')'
          : '')
      : preVal
      ? value.toFixed(2) + (' (' + preVal.toFixed(2) + ')')
      : value.toFixed(2);
    var obj = {
      text: textValue,
      options: {
        fill: {
          color: level <= 3 ? '#84C1C3' : level >= 7 ? '#f7b2a5' : '#FFFFFF',
        },
      },
    };
    return obj;
  };

  getHeatmapManagmentStandardCell = (level, value, isDecile) => {
    var textValue =
      value >= 1
        ? value.toFixed(2) +
          ' (' +
          level +
          (level === 1
            ? 'st'
            : level === 2
            ? 'nd'
            : level === 3
            ? 'rd'
            : 'th') +
          ')'
        : '-';
    var obj = {
      text: textValue,
      options: {
        superscript: false,
        fill: {
          color: level <= 3 ? '#84C1C3' : level >= 7 ? '#f7b2a5' : '#FFFFFF',
        },
      },
    };
    return obj;
  };
  AvgAllParticipaintDataRows = (data) => {
    var column = [];
    data.map((row) => {
      column.push([
        {
          text: row.CategoryName,
          options: { fontFace: 'Avenir LT Pro', color: '#000000' },
          breakLine: true,
        },
        this.getHeatmapCell(
          row.SchoolDecileType,
          row.OverallCategoryAverage,
          false,
          row.PreviousAverage,
          null
        ),
        this.getHeatmapCell(
          row.SchoolDecileType,
          row.OverallCategoryAverage,
          true,
          null,
          row.PreviousSchoolDecileType
        ),
        this.getHeatmapCell(
          row.HseDecileType,
          row.HseDecileType,
          true,
          null,
          row.PresviousHseDecileType
        ),
      ]);
    });
    return column;
  };
  AvgAllParticipentPresentation = async (pptx, reportdata) => {
    let slide = null;
    var dataRows = this.AvgAllParticipaintDataRows(reportdata);
    let rows = [];
    slide = pptx.addSlide();
    slide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });
    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir LT Pro',
      color: slideColors.purple,
    };
    slide.addText('Average Scores for All Participants', titilCss);
    let headerText = `Heatmap 1 scores are out of 5, with higher scores showing that risks relating to workplace stress and poor wellbeing are more effectively managed. The school decile shows your performance against the school benchmark, with 1st Decile being in the top 10% of schools taking the survey with us, and 10th Decile meaning more than 90% of staff in other schools have scored more highly. Comparatively high scores (3rd school decile and higher) are shaded green and comparatively lower ones (7th school decile and lower) red. Those without shading are close to the average score for schools. The HSE Decile shows your comparative scores against the Health and Safety Executive's Benchmark of 136 cross sector ${this.props.t(
      'organisation'
    )}.`;
    var Table1Obj1 = {
      x: 0.0,
      y: 0.8,
      margin: 20,
      h: 1.0,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir LT Pro',
      wrap: true,
      fontSize: 9,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);
    var Table1Obj = {
      x: 2.5,
      y: 1.901574803,
      w: 5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText(
      'Heatmap 1: Average Score for All Survey Participants',
      Table1Obj
    );
    let headerOptions = {
      fontFace: 'Avenir LT Pro',
      align: 'center',
      valign: 'middle',
      fontSize: 8,
      fill: { color: '#C9E9F7' },
      color: '#000000',
      breakLine: true,
      bold: true,
    };
    rows.push([
      { text: 'Management Standards', options: headerOptions },
      { text: 'Score', options: headerOptions },
      { text: 'School Decile', options: headerOptions },
      { text: 'HSE Decile', options: headerOptions },
    ]);
    dataRows.forEach((row, i) => {
      rows.push(row);
    });
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    slide.addTable(rows, {
      x: 0.2007874016,
      y: 2.338582677,
      w: 9.5984251969,
      rowH: 0.37,
      fontSize: 8,
      fontFace: 'Avenir LT Pro',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      valign: 'middle',
      align: 'center',
    });
  };

  ScoreByJobRoleHeatmapPresentation = async (pptx, heatmapByRole) => {
    let slide = null;
    let rows = [];
    slide = pptx.addSlide();
    slide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });
    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir LT Pro',
      color: slideColors.purple,
    };
    slide.addText('Scores by Job Role', titilCss);
    let headerText = `The average scores for participants in each of the core job role filters are shown in heatmap 2 below. Again comparatively high scores are shaded green and comparatively lower ones red. Those without shading are close to average. Using heatmaps in your ${this.state.GroupType}  Dashboard you can apply further filters to identify where scores may be higher and lower.`;
    var Table1Obj1 = {
      x: 0.0,
      y: 0.6,
      margin: 20,
      h: 0.8,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir LT Pro',
      wrap: true,
      fontSize: 9,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    var Table1Obj = {
      x: 2.5,
      y: 1.401574803,
      w: 5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText('Heatmap 2: Scores by Job Role', Table1Obj);
    let rowOption = {
      fontFace: 'Avenir LT Pro',
      align: 'center',
      valign: 'middle',
      fill: { color: '#FFFFFF' },
      color: '#000000',
      breakLine: true,
      fontSize: 8,
      bold: true,
    };
    let arrBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    let bottomText = `* Heatmaps of all filtered scores can be viewed and downloaded from your ${this.state.GroupType} dashboard.`;
    let bottomTextOption = {
      x: 0.1456692913,
      y: 5.157480315,
      w: 9.44882,
      h: 0.3,
      fontFace: 'Avenir LT Pro',
      align: 'left',
      valign: 'bottom',
      color: '#000000',
      fontSize: 8,
      bold: false,
    };
    slide.addText(bottomText, bottomTextOption);
    rows.push([
      { text: '', options: rowOption },
      { text: 'Demands', options: rowOption },
      { text: 'Control', options: rowOption },
      { text: 'Manager Support', options: rowOption },
      { text: 'Peer Support', options: rowOption },
      { text: 'Relationships', options: rowOption },
      { text: 'Role', options: rowOption },
      { text: 'Change', options: rowOption },
    ]);

    heatmapByRole.map((row) => {
      rows.push([
        {
          text:
            this.state.usRegion === 'us' && row.Name === 'Senior Leader'
              ? 'District Administrator'
              : this.state.usRegion === 'us' && row.Name === 'Middle Leader'
              ? 'Building Administrator'
              : row.Name, //row.Name,
          options: { fontFace: 'Avenir LT Pro', color: '#000000' },
          breakLine: true,
          fontSize: 8,
          bold: true,
        },
        this.getHeatmapManagmentStandardCell(
          row.DemandDecile,
          row.Demand,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.ControlDecile,
          row.Control,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.ManagerSupportDecile,
          row.ManagerSupport,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.PeerSupportDecile,
          row.PeerSupport,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.RelationshipsDecile,
          row.Relationships,
          false
        ),
        this.getHeatmapManagmentStandardCell(row.RoleDecile, row.Role, false),
        this.getHeatmapManagmentStandardCell(
          row.ChangeDecile,
          row.Change,
          false
        ),
      ]);
    });
    slide.addTable(rows, {
      x: 0.2,
      y: 1.73228346,
      rowH: 0.3,
      colW: [2.35, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03],
      fontSize: 8,
      fontFace: 'Avenir LT Pro',
      border: arrBorder,
      autoPage: false,
      autoPageRepeatHeader: false,
      autoPageHeaderRows: 1,
      verbose: false,
      align: 'center',
      valign: 'middle',
    });
  };
  ScoreBySchoolHeatmapPresentation = async (pptx, heatmapBySchool) => {
    let slide = null;
    let rows = [];
    slide = pptx.addSlide();

    slide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });
    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 20,
      float: 'left',
      align: 'left',
      fontSize: 20,
      fontFace: 'Avenir LT Pro',
      bold: true,
      color: slideColors.purple,
    };
    slide.addText('Scores by Site', titilCss);
    let headerText = `Heatmap 3 below shows the average scores for all staff for each school within the ${this.state.GroupType}. Comparatively high scores (3rd school decile and higher) are shaded green and comparatively lower ones (7th school descile and lower) red. Those without shading are close to the average score for schools.`;
    var Table1Obj1 = {
      x: 0.0,
      y: 0.5,
      margin: 20,
      h: 0.8,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontWeight: 500,
      fontFace: 'Avenir LT Pro',
      wrap: true,
      fontSize: 9,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, Table1Obj1);

    var Table1Obj = {
      x: 2.251968504,
      y: 1.200787402,
      w: 5.5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText('Heatmap 3:  Scores by Site', Table1Obj);
    let rowOption = {
      fontFace: 'Avenir LT Pro',
      fill: { color: '#FFFFFF' },
      fontSize: 8,
      bold: true,
      align: 'center',
      vlign: 'middle',
      color: '#000000',
      breakLine: true,
    };
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    let bottomText = `* Heatmaps of all filtered scores by school can be viewed and downloaded from your ${this.state.GroupType} dashboard.`;
    let bottomTextOption = {
      x: 0.1456692913,
      y: 5.157480315,
      w: 9.44882,
      h: 0.3,
      fontFace: 'Avenir LT Pro',
      align: 'left',
      valign: 'bottom',
      color: '#000000',
      fontSize: 8,
      bold: false,
    };
    slide.addText(bottomText, bottomTextOption);

    rows.push([
      { text: '', options: rowOption },
      { text: 'Demands', options: rowOption },
      { text: 'Control', options: rowOption },
      { text: 'Manager Support', options: rowOption },
      { text: 'Peer Support', options: rowOption },
      { text: 'Relationship', options: rowOption },
      { text: 'Role', options: rowOption },
      { text: 'Change', options: rowOption },
    ]);

    heatmapBySchool.map((row) => {
      rows.push([
        {
          text: row.Name,
          options: { fontFace: 'Avenir LT Pro', color: '#000000' },
          fontSize: 8,
          align: 'left',
          breakLine: true,
        },
        this.getHeatmapManagmentStandardCell(
          row.DemandDecile,
          row.Demand,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.ControlDecile,
          row.Control,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.ManagerSupportDecile,
          row.ManagerSupport,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.PeerSupportDecile,
          row.PeerSupport,
          false
        ),
        this.getHeatmapManagmentStandardCell(
          row.RelationshipsDecile,
          row.Relationships,
          false
        ),
        this.getHeatmapManagmentStandardCell(row.RoleDecile, row.Role, false),
        this.getHeatmapManagmentStandardCell(
          row.ChangeDecile,
          row.Change,
          false
        ),
      ]);
    });

    slide.addTable(rows, {
      x: 0.2007874016,
      y: 1.527559055,
      h: 5.0,
      rowH: 0.3,
      colW: [2.35, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03, 1.03],
      fontSize: 8,
      fontFace: 'Avenir LT Pro',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      valign: 'middle',
      align: 'center',
    });
  };
  HighestLowestScoringPresentation = async (pptx, highScoreQuestions) => {
    let slide = null;
    let rows = [];
    slide = pptx.addSlide();

    slide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });
    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 18,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir LT Pro',
      color: slideColors.purple,
    };
    slide.addText('The Five Highest and Lowest Scoring Questions', titilCss);
    var Table1Obj = {
      x: 2.251968504,
      y: 0.8149606299,
      w: 5.5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText('Table 2: Highest and Lowest Question Scores', Table1Obj);
    let rowOption1 = {
      fontFace: 'Avenir LT Pro',
      valign: 'middle',
      fill: { color: '#C9E9F7' },
      align: 'center',
      color: '#000000',
      fontSize: 8,
      bold: true,
      breakLine: true,
      colspan: 2,
    };
    let rowOption2 = {
      fontFace: 'Avenir LT Pro',
      valign: 'middle',
      fill: { color: '#C9E9F7' },
      align: 'center',
      color: '#000000',
      bold: true,
      fontSize: 8,
      breakLine: true,
    };
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    rows.push([
      { text: 'THE FIVE HIGHEST SCORING QUESTIONS', options: rowOption1 },
      { text: 'SCHOOL DECILE', options: rowOption2 },
    ]);
    highScoreQuestions?.map((item) => {
      let qText = `Q${item.Order}: ${item.Text}`;
      let decileText = `${item.Decile}${
        item.Decile === 1
          ? 'st'
          : item.Decile === 2
          ? 'nd'
          : item.Decile === 3
          ? 'rd'
          : 'th'
      }`;
      rows.push([
        {
          text: item.Category,
          options: {
            fill: { color: '#f2f2f2' },
            bold: true,
            fontSize: 8,
            color: '#000000',
          },
        },
        {
          text: qText,
          options: {
            fill: { color: '#FFFFFF' },
            color: '#000000',
            fontSize: 8,
          },
        },
        {
          text: decileText,
          options: {
            superscript: false,
            fill: { color: '#FFFFFF' },
            fontSize: 8,
            align: 'center',
            color: '#000000',
          },
        },
      ]);
    });

    slide.addTable(rows, {
      x: 0.2007874,
      y: 1.192913,
      w: 9.7,
      rowH: 0.3,
      colW: [2.0, 6.2, 1.5],
      fontSize: 8,
      fontFace: 'Avenir LT Pro',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      valign: 'middle',
    });

    let lowestScore = [];
    if (this.state.lowScoreQuestions.length > 0) {
      lowestScore.push([
        { text: 'THE FIVE LOWEST SCORING QUESTIONS', options: rowOption1 },
        { text: 'SCHOOL DECILE', options: rowOption2 },
      ]);
      this.state.lowScoreQuestions?.map((item) => {
        let qText = `Q${item.Order}: ${item.Text}`;
        let decileText = `${item.Decile}${
          item.Decile === 1
            ? 'st'
            : item.Decile === 2
            ? 'nd'
            : item.Decile === 3
            ? 'rd'
            : 'th'
        }`;
        lowestScore.push([
          {
            text: item.Category,
            options: {
              fill: { color: '#f2f2f2' },
              bold: true,
              fontSize: 8,
              color: '#000000',
            },
          },
          {
            text: qText,
            options: { fill: { color: '#FFFFFF' }, color: '#000000' },
          },
          {
            text: decileText,
            options: {
              superscript: false,
              fill: { color: '#FFFFFF' },
              fontSize: 8,
              align: 'center',
              color: '#000000',
            },
          },
        ]);
      });

      slide.addTable(lowestScore, {
        x: 0.2007874,
        y: 3.0866142,
        w: 9.7,
        rowH: 0.3,
        colW: [2.0, 6.2, 1.5],
        fontSize: 8,
        fontFace: 'Avenir LT Pro',
        border: tableBorder,
        autoPage: true,
        autoPageRepeatHeader: true,
        autoPageHeaderRows: 1,
        verbose: false,
        valign: 'middle',
      });
    }
  };
  QuestionScorebySchoolSitePresentation = async (pptx, addtionalQuestion) => {
    let slide = null;
    let rows = [];
    slide = pptx.addSlide();
    var filterScoringQ = [];
    filterScoringQ = addtionalQuestion.filter((x) => x.Value > 0);
    slide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });
    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 18,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir LT Pro',
      color: slideColors.purple,
    };
    slide.addText('Your Additional Questions', titilCss);
    var Table1Obj = {
      x: 2.251968504,
      y: 0.8149606299,
      w: 5.5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText('Table 3: Your Questions', Table1Obj);
    let rowOption2 = {
      fontFace: 'Avenir LT Pro',
      fill: { color: '#C9E9F7' },
      color: '#000000',
      bold: true,
      breakLine: true,
      fontSize: 8,
      valign: 'middle',
      align: 'center',
    };
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];

    rows.push([
      { text: 'Question Number', options: rowOption2 },
      { text: 'Question Text', options: rowOption2 },
    ]);
    addtionalQuestion?.map((item) => {
      let order = `Q${item.Order}`;
      let qtext = `${item.Text}`;
      rows.push([
        {
          text: order,
          options: {
            fill: { color: '#FFFFFF' },
            color: '#000000',
            align: 'center',
            bold: true,
            fontSize: 8,
            valign: 'middle',
          },
        },
        {
          text: qtext,
          options: {
            fill: { color: '#FFFFFF' },
            color: '#000000',
            fontSize: 8,
            valign: 'middle',
          },
        },
      ]);
    });

    slide.addTable(rows, {
      x: 0.2007874,
      y: 1.192913,
      w: 9.7,
      rowH: 0.3,
      colW: [2.0, 7.7],
      fontSize: 8,
      fontFace: 'Avenir LT Pro',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
    });

    /************************Table 4 Your Question Scores by School or Site slide*********************************/
    slide = pptx.addSlide();
    let dataRows = [];
    slide.addText('Your Question Scores by Site', titilCss);
    slide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });

    var Table1Obj1 = {
      x: 2.251968504,
      y: 0.8307087,
      w: 5.5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText('Table 4: Your Question Scores by Site', Table1Obj1);
    let headerRowOption = {
      fontFace: 'Avenir LT Pro',
      fill: { color: '#C9E9F7' },
      color: '#000000',
      valign: 'middle',
      align: 'center',
      fontSize: 8,
      bold: true,
      breakLine: true,
    };
    let dataRowsOptions = {
      fontFace: 'Avenir LT Pro',
      fill: { color: '#FFFFFF' },
      color: '#000000',
      fontSize: 8,
      valign: 'middle',
      align: 'center',
      breakLine: true,
    };
    let bottomText = `* Heatmaps of all filtered scores by school for your question can be viewed and downloaded from your ${this.state.GroupType} dashboard.`;
    let bottomTextOption = {
      x: 0.1456692913,
      y: 5.157480315,
      w: 9.44882,
      h: 0.3,
      fontFace: 'Avenir LT Pro',
      align: 'left',
      valign: 'bottom',
      color: '#000000',
      fontSize: 8,
      bold: false,
    };
    slide.addText(bottomText, bottomTextOption);
    var data = Object.entries(
      this.state.reportdata?.AdditionalQuestionsScoresforSchools
        ? this.state.reportdata?.AdditionalQuestionsScoresforSchools
        : null
    );
    var allScoreRow = Object.entries(
      this.state.reportdata?.AdditionalQuestionsScores
    )[0];
    let schoolHeaderCell = [];
    let questionRow = filterScoringQ?.map((item) => ({
      text: 'Q: ' + item.Order,
      options: headerRowOption,
    }));
    schoolHeaderCell.push({ text: 'SCHOOLS', options: headerRowOption });
    questionRow.forEach((item, i) => {
      schoolHeaderCell.push(item);
    });
    dataRows.push(schoolHeaderCell);

    let allStaff = [];
    allStaff.push({ text: allScoreRow[0], options: dataRowsOptions });

    allScoreRow[1]?.map((score) => {
      allStaff.push({ text: score.toFixed(2), options: dataRowsOptions });
    });
    dataRows.push(allStaff);

    data?.map((item) => {
      let schoolRows = [];
      let scores = item[1];
      schoolRows.push({ text: item[0], options: dataRowsOptions });
      scores?.map((score) => {
        schoolRows.push({ text: score.toFixed(2), options: dataRowsOptions });
      });
      dataRows.push(schoolRows);
    });
    slide.addTable(dataRows, {
      x: 0.2007874,
      y: 1.192913,
      w: 9.7,
      rowH: 0.3,
      colW: [3.7, 2.0, 2.0, 2.0],
      fontSize: 8,
      fontFace: 'Avenir LT Pro',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      aling: 'center',
      valign: 'middle',
    });
  };
  QuestionScorebyJobRolePresentation = async (pptx) => {
    let slide = null;
    slide = pptx.addSlide();
    var filterScoringQ = [];
    filterScoringQ = this.state.reportdata?.AdditionQuestionIds.filter(
      (x) => x.Value > 0
    );

    var titilCss = {
      x: 0,
      y: 0.1299212598,
      h: 0.3937008,
      margin: 18,
      float: 'left',
      align: 'left',
      bold: true,
      fontSize: 20,
      fontFace: 'Avenir LT Pro',
      color: slideColors.purple,
    };
    var Table1Obj = {
      x: 2.464567,
      y: 1,
      w: 4,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText('Your Question Scores by Job Role', titilCss);
    let dataRows = [];
    slide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });
    let headerText = `Using heatmaps in your ${this.state.GroupType} Dashboard you can apply further filters to identify where scores may be higher and lower.`;
    var headerOptions = {
      x: 0.0,
      y: 0.4,
      margin: 20,
      h: 0.8,
      w: 9.645669,
      float: 'left',
      align: 'left',
      lineSpacing: 15,
      fontFace: 'Avenir LT Pro',
      wrap: true,
      fontSize: 9,
      color: '#000000',
      fit: 'resize',
      charSpacing: 0.0,
      breakLine: true,
    };
    slide.addText(headerText, headerOptions);
    var Table1Obj1 = {
      x: 2.5,
      y: 1,
      w: 5,
      h: 0.3,
      align: 'center',
      bold: true,
      fontFace: 'Avenir LT Pro',
      fontSize: 9,
      color: '#000000',
    };
    slide.addText('Table 5: Your Question Scores by Job Role', Table1Obj1);
    let headerRowOption = {
      fontFace: 'Avenir LT Pro',
      fill: { color: '#C9E9F7' },
      color: '#000000',
      fontSize: 8,
      align: 'center',
      valign: 'middle',
      bold: true,
      breakLine: true,
    };
    let dataRowsOptions = {
      fontFace: 'Avenir LT Pro',
      fill: { color: '#FFFFFF' },
      color: '#000000',
      breakLine: true,
      fontSize: 8,
      align: 'center',
      valign: 'middle',
    };
    let tableBorder = [
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
      { color: 'e2e2e2' },
    ];
    let bottomText = `* Heatmaps of all filtered scores for your question can be viewed and downloaded from your ${this.state.GroupType} dashboard.`;
    let bottomTextOption = {
      x: 0.1456692913,
      y: 5.157480315,
      w: 9.44882,
      h: 0.3,
      fontFace: 'Avenir LT Pro',
      align: 'left',
      valign: 'bottom',
      color: '#000000',
      fontSize: 8,
      bold: false,
    };
    slide.addText(bottomText, bottomTextOption);
    var data = Object.entries(
      this.state.reportdata?.AdditionalQuestionsScores
        ? this.state.reportdata?.AdditionalQuestionsScores
        : null
    );

    let jobRoleHeaderCell = [];

    let questionRow = filterScoringQ?.map((item) => ({
      text: 'Q: ' + item.Order,
      options: headerRowOption,
    }));
    jobRoleHeaderCell.push({
      text: 'JOB ROLE',
      options: headerRowOption,
    });
    questionRow.forEach((item, i) => {
      jobRoleHeaderCell.push(item);
    });
    dataRows.push(jobRoleHeaderCell);

    data?.map((item) => {
      let jobROle = [];
      let scores = item[1];
      jobROle.push({ text: item[0], options: dataRowsOptions });
      scores?.map((score) => {
        jobROle.push({ text: score.toFixed(2), options: dataRowsOptions });
      });
      dataRows.push(jobROle);
    });
    slide.addTable(dataRows, {
      x: 0.1496062992,
      y: 1.4,
      w: 9.7,
      rowH: 0.3,
      colW: [3.7, 2.0, 2.0, 2.0],
      fontSize: 8,
      fontFace: 'Avenir LT Pro',
      border: tableBorder,
      autoPage: true,
      autoPageRepeatHeader: true,
      autoPageHeaderRows: 1,
      verbose: false,
      align: 'center',
      valign: 'middle',
    });
  };

  JobRoleGraphPresentation = (pptx) => {
    return new Promise(async (resolve, reject) => {
      try {
        let chartId = 'ppt-chart-jobrole';
        const imageLink = document.createElement('a');
        let graphSlide = pptx.addSlide();
        // Introduce a delay of 1 second (adjust as needed)
        setTimeout(async () => {
          const devElement = document.getElementById(chartId);
          const canvas = await html2canvas(devElement);

          if (!(canvas instanceof HTMLCanvasElement)) {
            console.error(`Element with id '${chartId}' is not a canvas.`);
            reject(new Error(`Element with id '${chartId}' is not a canvas.`));
            return;
          }

          const padding = 6;
          const newCanvas = document.createElement('canvas');
          const context = newCanvas.getContext('2d');
          newCanvas.width = canvas.width + 2 * padding;
          newCanvas.height = canvas.height + 2 * padding;
          context.fillStyle = '#ffffff'; // Set the background color to white
          context.fillRect(0, 0, newCanvas.width, newCanvas.height);
          context.drawImage(canvas, padding, padding);

          imageLink.download = `${chartId}.jpeg`;
          imageLink.href = newCanvas.toDataURL('image/jpeg');

          let title =
            this.state.surveyCount > 1
              ? 'Changes in Scores by Job Role Since the Last Survey'
              : 'Scores by Job Role';

          var titleCss = {
            x: 0,
            y: 0.1299212598,
            h: 0.3937008,
            margin: 18,
            float: 'left',
            align: 'left',
            bold: true,
            fontFace: 'Avenir LT Pro',
            fontSize: 20,
            color: slideColors.purple,
          };

          graphSlide.addText(title, titleCss);
          graphSlide.addImage({
            path: welbeeslideslogo,
            x: 9.0,
            y: 0.2,
            w: 0.8,
            h: 0.3,
          });
          graphSlide.addImage({
            path: imageLink.href,
            x: 0.1417323,
            y: 0.7440945,
            w: '93%',
            h: '83%',
          });

          resolve();
        }, 2000); // 1-second delay (adjust as needed)
      } catch (error) {
        console.log('JobRoleGraphError', error);
        reject(error);
      }
    });
  };

  SchoolsGraphPresentation = (pptx, index, title) => {
    return new Promise(async (resolve, reject) => {
      try {
        let chartId = 'ppt-chart-school' + index;
        const imageLink = document.createElement('a');
        let graphSlide = pptx.addSlide();
        setTimeout(async () => {
          const devElement = document.getElementById(chartId);
          if (!devElement) {
            console.error(`Element with id '${chartId}' not found in the DOM.`);
            reject(
              new Error(`Element with id '${chartId}' not found in the DOM.`)
            );
            return;
          }
          const canvas = await html2canvas(devElement);

          if (!(canvas instanceof HTMLCanvasElement)) {
            console.error(`Element with id '${chartId}' is not a canvas.`);
            reject(new Error(`Element with id '${chartId}' is not a canvas.`));
            return;
          }

          const padding = 6;
          const newCanvas = document.createElement('canvas');
          const context = newCanvas.getContext('2d');
          newCanvas.width = canvas.width + 2 * padding;
          newCanvas.height = canvas.height + 2 * padding;
          context.fillStyle = '#ffffff'; // Set the background color to white
          context.fillRect(0, 0, newCanvas.width, newCanvas.height);
          context.drawImage(canvas, padding, padding);

          imageLink.download = `${chartId}.jpeg`;
          imageLink.href = newCanvas.toDataURL('image/jpeg');

          var titilCss = {
            x: 0,
            y: 0.1299212598,
            h: 0.3937008,
            margin: 18,
            float: 'left',
            align: 'left',
            bold: true,
            fontFace: 'Avenir LT Pro',
            fontSize: 20,
            color: slideColors.purple,
          };
          graphSlide.addText(title, titilCss);

          graphSlide.addImage({
            path: imageLink.href,
            x: 0.1417323,
            y: 0.7440945,
            w: '93%',
            h: '83%',
          });
          graphSlide.addImage({
            path: welbeeslideslogo,
            x: 9.0,
            y: 0.2,
            w: 0.8,
            h: 0.3,
          });

          resolve();
        }, 2000); // 2-second delay (adjust as needed)
      } catch (error) {
        console.log('schoolGraphError', error);
        reject(error);
      }
    });
  };

  printSurveyReportPresentation = async () => {
    const {
      schoolInfo,
      reportdata,
      matAvg,
      heatmapByRole,
      JobRoleGraph,
      heatmapBySchool,
      AllSchoolGraph,
      highScoreQuestions,
    } = this.state;
    const { action } = this.props;
    action.dataLoading(true);
    amplitudeLog('Download', 'Presentation', '');
    pptx = new PptxGenJS();

    var logoPath = schoolInfo?.SchoolLogo.replace('http://', 'https://');
    return new Promise(async (resolve, reject) => {
      try {
        let introSlide = pptx.addSlide();
        let headingFontSize = 36;
        introSlide.addText(
          [
            {
              text: schoolInfo.SchoolName,
              options: {
                fontSize: headingFontSize,
                color: slideColors.purple,
                breakLine: true,
                w: '60%',
                bold: true,
                fontFace: 'Avenir LT Pro',
              },
            },
            {
              text: 'Survey Results ' + schoolInfo.SurveyStartDate,
              options: {
                fontSize: headingFontSize,
                color: slideColors.purple,
                breakLine: true,
                w: '60%',
                bold: true,
                fontFace: 'Avenir LT Pro',
              },
            },
          ],
          { x: 0.5, w: '100%', h: '100%' }
        );

        introSlide.addImage({
          path: welbeeslideslogo,
          x: 9.0,
          y: 0.2,
          w: 0.8,
          h: 0.3,
        });

        var options = {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
        };

        var request = new Request(logoPath);
        const response = await fetch(request, options).catch((error) => {
          console.log('Error fetching SchoolLogo:', error);
        });

        if (response) {
          const blob = await response.blob().catch((error) => {
            console.log('Error getting blob from response:', error);
          });

          if (blob) {
            var reader = new FileReader();
            reader.onload = function () {
              introSlide.addImage({
                data: this.result,
                x: 9.0,
                y: 5.1,
                w: 0.8,
                h: 0.3,
              });
            };

            reader.readAsDataURL(blob);
          } else {
            console.log('SchoolLogo blob is null');
          }
        } else {
          console.log('Response is undefined');
        }

        await this.StaffSummaryPresentation(pptx, reportdata);
        await this.AvgAllParticipentPresentation(pptx, matAvg);
        await this.ScoreByJobRoleHeatmapPresentation(pptx, heatmapByRole);

        if (JobRoleGraph?.length) {
          await this.JobRoleGraphPresentation(pptx);
        }

        await this.ScoreBySchoolHeatmapPresentation(pptx, heatmapBySchool);

        if (AllSchoolGraph?.length) {
          for (let index = 0; index < AllSchoolGraph.length; index++) {
            let label = await this.state.surveyResult?.find(
              (x) => x.id === 7 && x.subSlideIndex === index
            )?.label;
            await this.SchoolsGraphPresentation(pptx, index, label);
          }
        }

        await this.HighestLowestScoringPresentation(pptx, highScoreQuestions);

        if (reportdata.HasAdditionalQuestion) {
          await this.QuestionScorebySchoolSitePresentation(
            pptx,
            reportdata?.AdditionQuestionIds
          );
          await this.QuestionScorebyJobRolePresentation(pptx);
        }

        pptx.writeFile({ fileName: 'Group-Presentation' });
        action.dataLoading(false);
        resolve();
      } catch (error) {
        action.dataLoading(false);
        console.log('PPT Dowonload Error', error);
        reject(error);
      }
    });
  };

  printSurveyReportPresentationOLD = async () => {
    const { schoolInfo } = this.state;
    amplitudeLog('Download', 'Presentation', '');
    pptx = new PptxGenJS();
    let introSlide = pptx.addSlide();
    let headingFontSize = 36;
    introSlide.addText(
      [
        {
          text: schoolInfo.SchoolName,
          options: {
            fontSize: headingFontSize,
            color: slideColors.purple,
            breakLine: true,
            w: '60%',
            bold: true,
            fontFace: 'Avenir LT Pro',
          },
        },
        {
          text: 'Survey Results ' + schoolInfo.SurveyStartDate,
          options: {
            fontSize: headingFontSize,
            color: slideColors.purple,
            breakLine: true,
            w: '60%',
            bold: true,
            fontFace: 'Avenir LT Pro',
          },
        },
      ],
      { x: 0.5, w: '100%', h: '100%' }
    );

    introSlide.addImage({
      path: welbeeslideslogo,
      x: 9.0,
      y: 0.2,
      w: 0.8,
      h: 0.3,
    });

    var options = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
    };

    var logoPath = schoolInfo?.SchoolLogo.replace('http://', 'https://');
    console.log('logoPath', logoPath);
    var request = new Request(logoPath);
    fetch(request, options)
      .then(async (response) => {
        if (response.ok) {
          const blob = await response.blob();
          var reader = new FileReader();
          reader.onload = function () {
            introSlide.addImage({
              data: this.result,
              x: 9.0,
              y: 5.1,
              w: 0.8,
              h: 0.3,
            });
            pptx.writeFile({ fileName: 'Group-Presentation' });
          };
          reader.readAsDataURL(blob);
        } else {
          console.error('Failed to download logo:', response.status);
          // Handle the error, and proceed with saving the file
          pptx.writeFile({ fileName: 'Group-Presentation' });
        }
      })
      .catch((error) => {
        console.error('Error while downloading logo:', error);
        // Handle the error, and proceed with saving the file
        pptx.writeFile({ fileName: 'Group-Presentation' });
      });

    await this.StaffSummaryPresentation(pptx, this.state.reportdata);
    await this.AvgAllParticipentPresentation(pptx, this.state.matAvg);
    await this.ScoreByJobRoleHeatmapPresentation(
      pptx,
      this.state.heatmapByRole
    );

    if (this.state.JobRoleGraph?.length) {
      await this.JobRoleGraphPresentation(pptx);
    }

    await this.ScoreBySchoolHeatmapPresentation(
      pptx,
      this.state.heatmapBySchool
    );

    if (this.state.AllSchoolGraph?.length) {
      for (let index = 0; index < this.state.AllSchoolGraph.length; index++) {
        await this.SchoolsGraphPresentation(pptx, index);
      }
    }

    await this.HighestLowestScoringPresentation(
      pptx,
      this.state.highScoreQuestions
    );
    if (this.state.reportdata.HasAdditionalQuestion) {
      await this.QuestionScorebySchoolSitePresentation(
        pptx,
        this.state.reportdata?.AdditionQuestionIds
      );
      await this.QuestionScorebyJobRolePresentation(pptx);
    }
  };

  handlePresentationPreview = () => {
    this.setState({ isPreview: true });
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  render() {
    return (
      <div>
        {this.state.isPreview && (
          <PageOverlay onClick={() => this.setState({ isPreview: false })} />
        )}
        <PresentationWrapperStyle>
          <div className='containerClass'>
            <Carousel
              autoPlay={false}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              useKeyboardArrows={true}
              selectedItem={this.state.selectedItem}
              onChange={(index) => {
                this.setState({ selectedItem: index });
              }}
            >
              {this.state.surveyResult.map((item, index) => (
                <PresentationInnerWrapperStyle key={index}>
                  <div ref={this.slideElem}>
                    {this.renderSurveryReportPresentation(item)}
                  </div>
                </PresentationInnerWrapperStyle>
              ))}
            </Carousel>
            <PageFooterStyle mb='30px'>
              <span>
                Page {this.state.selectedItem + 1} of{' '}
                {this.state.surveyResult?.length}
              </span>
            </PageFooterStyle>
          </div>
        </PresentationWrapperStyle>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(MatPPTNewVersion)));
