import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import schoolService from '../../services/api/schoolService';
import { AppButton } from '../../resources/styling';
import { AdminHeader, Paging, Dropdown } from '../../components';
import { colors } from '../../resources/theme/colors';
import { searchIconWhite } from '../../resources/images';
import { routesObj } from '../../routes';
import { storageService } from '../../services';
import { WelbeeTypes, schoolListFlags } from '../../utils/Constants';
import {
  CheckBox,
  SchoolListMainWrapper,
  TableStyle,
} from '../../resources/styling/appStyle';
import { WelbeeType, WebStorageNames } from '../../utils';
import { DateFormat } from '../../utils/CommonFunctions';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import superAdminService from '../../services/api/superAdminService';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import accountService from '../../services/api/accountService';
import RegistrationLink from './RegistrationLink';
import {
  SchoolSurveyWrapper,
  SchoolSurveyInnerMain,
  SchoolSurveyInner,
  PaginationWrapper,
} from './SchoolStyle';
import IdleTimerContainer from '../../components/common/IdleTimerContainer';

const { mandy, teal, pictonBlue } = colors;

const SortOrder = {
  ASC: 'Asc',
  DESC: 'Desc',
  NewestFirst: 'Newest',
};

const nameFilterOptions = [
  { label: SortOrder.ASC, value: 'Asc' },
  { label: SortOrder.DESC, value: 'Desc' },
  { label: SortOrder.NewestFirst, value: 'Newest' },
];

const CreateSchool = {
  CreateSchool: 'Create school',
  SurveyPremium: 'Survey Premium',
  SurveyPro: 'Survey Pro',
  SurveyFree: 'Survey Free',
  VoicePro: 'Voice Pro',
  VoiceFree: 'Voice Free',
  MAT: 'Group/MAT',
};

class Schools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectTestAccount: false,
      pageOfItems: [],
      schoolsList: [],
      nameSearchValue: '',
      isSearchDelete: true,
      isChanged: false,
      isArchive: false,
      archivedSchoolId: null,
      isShowRegistrationLinkPopup: false,
      inCompleteRegistrationLink: '',
      nameFilterSelectedOption: { label: SortOrder.ASC, value: 'Asc' },
      CreateSchoolSelectedOption: {
        label: CreateSchool.CreateSchool,
        value: 'Create school',
      },
      CreateSchoolOptions: [
        {
          label: CreateSchool.SurveyPremium,
          value: routesObj.SurveyPremiumUrl.path,
        },
        {
          label: CreateSchool.SurveyPro,
          value: routesObj.SurveyProUrl.path,
        },
        {
          label: CreateSchool.SurveyFree,
          value: routesObj.SurveyFreeUrl.path,
        },
        {
          label: CreateSchool.VoicePro,
          value: routesObj.VoiceProUrl.path,
        },
        {
          label: CreateSchool.VoiceFree,
          value: routesObj.VoiceFreeUrl.path,
        },

        { label: CreateSchool.MAT, value: routesObj.RegisterMat.path },
      ],
    };

    // bind function in constructor instead of render (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md)
    this.onChangePage = this.onChangePage.bind(this);
    this.handleSchoolSurvey = this.handleSchoolSurvey.bind(this);
    this.onCreateSchoolClick = this.onCreateSchoolClick.bind(this);
    this.handleArchiveSchool = this.handleArchiveSchool.bind(this);
    if (this.props.SetSchoolsListFlag != 0)
      storageService.setItem(
        WebStorageNames.SchoolListFlag,
        this.props.SetSchoolsListFlag
      );
  }

  componentDidMount() {
    this.getAllSchools();
  }

  getAllSchools() {
    schoolService
      .getSchoolList(storageService.getItem(WebStorageNames.SchoolListFlag))
      .then((response) => {
        this.setState({ schoolsList: response.data });
      });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }
  handleSchoolSurvey(schoolId) {
    storageService.setItem(WebStorageNames.SchoolId, schoolId);
    storageService.setItem(
      WebStorageNames.SelectedTab,
      WelbeeType.WelbeeSurveyWithVoice
    );
    storageService.setItem(
      WebStorageNames.WelbeeType,
      WelbeeType.WelbeeSurveyWithVoice
    );
    this.props.history.push(
      routesObj.SchoolSurveyList.path + '?schoolId=' + schoolId
    );
  }

  schoolDetail(schoolId) {
    this.props.history.push(
      routesObj.SchoolDetail.path + '?schoolId=' + schoolId
    );
  }

  handleNameSortFilterChange = (nameFilterSelectedOption) => {
    this.setState({
      nameFilterSelectedOption,
      isChanged: !this.state.isChanged,
    });
  };

  handleCreateSchoolFilterChange = (CreateSchoolSelectedOption) => {
    window.open(CreateSchoolSelectedOption.value, '_blank');
  };

  handleNameSearchChange = (e) => {
    this.setState({ nameSearchValue: e.target.value });
  };

  applyFilters() {
    const {
      nameFilterSelectedOption,
      nameSearchValue,
      schoolsList,
      selectTestAccount,
    } = this.state;

    let filteredSchoolsList = schoolsList;

    filteredSchoolsList = filteredSchoolsList.filter(
      (item) => !item.IsArchived
    );
    filteredSchoolsList = filteredSchoolsList.filter(
      (x) => x.IsTestAccount === selectTestAccount
    );
    if (nameSearchValue) {
      filteredSchoolsList = filteredSchoolsList.filter((item) =>
        item.Name.toLocaleLowerCase().includes(
          nameSearchValue.toLocaleLowerCase()
        )
      );
    }

    if (nameFilterSelectedOption.label === SortOrder.ASC) {
      filteredSchoolsList.sort((a, b) => {
        if (a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()) return -1;
        if (a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()) return 1;
        return 0;
      });
    } else if (nameFilterSelectedOption.label === SortOrder.DESC) {
      filteredSchoolsList.sort((a, b) => {
        if (b.Name.toLocaleLowerCase() < a.Name.toLocaleLowerCase()) return -1;
        if (b.Name.toLocaleLowerCase() > a.Name.toLocaleLowerCase()) return 1;
        return 0;
      });
    } else if (nameFilterSelectedOption.label === SortOrder.NewestFirst) {
      filteredSchoolsList.sort((a, b) => {
        const a_date = new Date(a.AddedDate);
        const b_date = new Date(b.AddedDate);
        if (b_date < a_date) return -1;
        if (b_date > a_date) return 1;
        return 0;
      });
    }
    return filteredSchoolsList;
  }

  onCreateSchoolClick() {
    this.props.history.push(routesObj.SurveyWithVoiceSchoolRegistration.path);
  }

  handleArchiveSchool() {
    schoolService
      .archiveSchool(this.state.archivedSchoolId)
      .then((response) => {
        this.setState({ isArchive: false });
        this.getAllSchools();
      })
      .catch((error) => error);
  }

  sendRegistrationCompletionEmail(schoolId) {
    superAdminService
      .sendRegistrationCompletionEmail(schoolId)
      .then((response) => toast.success('Registration completion email sent.'))
      .catch((error) => error);
  }

  renderHeader() {
    let SetSchoolsListFlag = storageService.getItem(
      WebStorageNames.SchoolListFlag
    );
    if (SetSchoolsListFlag === schoolListFlags.RegistrationNotComplete)
      return (
        <Fragment>
          <th className='list-heading'>Registration Status</th>
          <th className='list-heading'>Account Type</th>
          <th className='list-heading'>Registration Date</th>
          <th className='list-heading'>Email</th>
        </Fragment>
      );
    else if (SetSchoolsListFlag === schoolListFlags.CompletedAtLeastOneSurvey) {
      return (
        <>
          <th className='list-heading'>Registration Date</th>
          <th className='list-heading'>Account</th>
          <th className='list-heading'>Last Completed Survey date</th>
          <th className='list-heading'>Number of Completed Surveys</th>
        </>
      );
    } else if (SetSchoolsListFlag === schoolListFlags.NoSurveyAddedYet)
      return (
        <Fragment>
          <th className='list-heading'>Registration Date</th>
          <th className='list-heading'>Account</th>
        </Fragment>
      );
    else if (SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly)
      return (
        <Fragment>
          <th className='list-heading'>Registration Date</th>
          <th className='list-heading'>First Conversation Date</th>
          <th className='list-heading'>Account</th>
        </Fragment>
      );
    else if (
      SetSchoolsListFlag === schoolListFlags.NoSurveyAddedYet ||
      SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly ||
      SetSchoolsListFlag === schoolListFlags.WelbeeSurveyWithVoice
    )
      return (
        <Fragment>
          <th className='list-heading'>Registration Date</th>
          <th className='list-heading'>Account</th>
        </Fragment>
      );
    else if (SetSchoolsListFlag === schoolListFlags.SchoolWithLiveSurvey)
      return (
        <Fragment>
          <th className='list-heading'>First Submission</th>
          <th className='list-heading'>Registration Date</th>
          <th className='list-heading'>Account</th>
        </Fragment>
      );
    else if (SetSchoolsListFlag === schoolListFlags.NotLiveSurvey)
      return (
        <Fragment>
          <th className='list-heading'>Scheduled Start Date</th>
          <th className='list-heading'>Registration Date</th>
          <th className='list-heading'>Account</th>
        </Fragment>
      );
  }

  renderSchoolRecords(item) {
    let SetSchoolsListFlag = storageService.getItem(
      WebStorageNames.SchoolListFlag
    );

    if (SetSchoolsListFlag === schoolListFlags.RegistrationNotComplete)
      return (
        <Fragment>
          <td>
            {item.SignUpStatus === 1
              ? 'School Detail'
              : item.SignUpStatus === 2
              ? 'Invoice Detail'
              : item.SignUpStatus === 5
              ? 'Personal Detail'
              : item.SignUpStatus === 4
              ? 'Free Voice Detail'
              : item.SignUpStatus === 6
              ? 'Email Verification'
              : ''}
          </td>
          <td className='list-heading'>
            {item.WelbeeVoiceAccountType === 0 &&
            item.SchoolSubscription.SubscriptionId ===
              WelbeeTypes.WelbeeVoiceFree
              ? 'Voice Free'
              : item.SchoolSubscription.SubscriptionId ===
                WelbeeTypes.WelbeeVoicePro
              ? 'Voice Pro'
              : item.SchoolSubscription.SubscriptionId ===
                WelbeeTypes.WelbeeSurveyFree
              ? 'Survey Free'
              : item.SchoolSubscription.SubscriptionId ===
                WelbeeTypes.WelbeeSurveyPro
              ? 'Survey Pro'
              : item.SchoolSubscription.SubscriptionId ===
                WelbeeTypes.WelbeeSurveyPremium
              ? 'Survey Premium'
              : ''}
          </td>
          <td>{DateFormat(item.AddedDate)}</td>
          <td>
            <a
              href
              onClick={() => this.sendRegistrationCompletionEmail(item.Id)}
              className='email-link'
            >
              {item.Email == null ? 'N/A' : item.Email}
            </a>
          </td>
        </Fragment>
      );
    else if (SetSchoolsListFlag === schoolListFlags.CompletedAtLeastOneSurvey) {
      return (
        <>
          <td>{DateFormat(item.AddedDate)}</td>
          <td>
            <AppButton
              color={mandy}
              type='submit'
              onClick={() =>
                SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly
                  ? this.onConversationClick(item.Id)
                  : this.OnSchoolDashboard(item)
              }
            >
              Dashboard
            </AppButton>
          </td>
          <td>
            {item.lastSurveySubmissionDate == null
              ? 'N/A'
              : DateFormat(item.lastSurveySubmissionDate)}
          </td>
          <td>{item.TotalSurveys}</td>
        </>
      );
    } else if (SetSchoolsListFlag === schoolListFlags.NoSurveyAddedYet)
      return (
        <>
          <td>{DateFormat(item.AddedDate)}</td>
          <td>
            <AppButton
              color={mandy}
              type='submit'
              onClick={() =>
                SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly
                  ? this.onConversationClick(item.Id)
                  : this.OnSchoolDashboard(item)
              }
            >
              Dashboard
            </AppButton>
          </td>
        </>
      );
    else if (SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly)
      return (
        <>
          <td>{DateFormat(item.AddedDate)}</td>
          <td>
            {item.FirstSurveySubmissionDate == null
              ? 'N/A'
              : DateFormat(item.FirstSurveySubmissionDate)}
          </td>
          <td>
            <AppButton
              color={mandy}
              type='submit'
              onClick={() =>
                SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly
                  ? this.onConversationClick(item.Id)
                  : this.OnSchoolDashboard(item)
              }
            >
              Dashboard
            </AppButton>
          </td>
        </>
      );
    else if (SetSchoolsListFlag === schoolListFlags.WelbeeSurveyWithVoice)
      return (
        <>
          <td>{DateFormat(item.AddedDate)}</td>
          <td>
            <AppButton
              color={mandy}
              type='submit'
              onClick={() =>
                SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly
                  ? this.onConversationClick(item.Id)
                  : this.OnSchoolDashboard(item)
              }
            >
              Dashboard
            </AppButton>
          </td>
        </>
      );
    else if (SetSchoolsListFlag === schoolListFlags.SchoolWithLiveSurvey)
      return (
        <Fragment>
          <td>{DateFormat(item.FirstSurveySubmissionDate)}</td>
          <td>{DateFormat(item.AddedDate)}</td>
          <td>
            <AppButton
              color={mandy}
              type='submit'
              onClick={() =>
                SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly
                  ? this.onConversationClick(item.Id)
                  : this.OnSchoolDashboard(item)
              }
            >
              Dashboard
            </AppButton>
          </td>
        </Fragment>
      );
    else if (SetSchoolsListFlag === schoolListFlags.NotLiveSurvey)
      return (
        <Fragment>
          <td>{DateFormat(item.SurveyStartDate)}</td>
          <td>{DateFormat(item.AddedDate)}</td>
          <td>
            <AppButton
              color={mandy}
              type='submit'
              onClick={() =>
                SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly
                  ? this.onConversationClick(item.Id)
                  : this.OnSchoolDashboard(item)
              }
            >
              Dashboard
            </AppButton>
          </td>
        </Fragment>
      );
  }
  onConversationClick = (schoolId) => {
    storageService.setItem(WebStorageNames.SchoolId, schoolId);
    schoolService.getSchoolSubscriptions(schoolId).then((response) => {
      if (response.success) {
        storageService.setItem(WebStorageNames.SelectedTab, 1);
        storageService.setItem(
          WebStorageNames.SubscriptionId,
          response.data.SubscriptionId
        );
        window.open(routesObj.WelbeeVoice.path, '_blank');
      }
    });
  };
  OnSchoolDashboard = (school) => {
    storageService.setItem(WebStorageNames.SchoolId, school.Id);
    if (school.MatGroupId == null) {
      storageService.setItem(WebStorageNames.IsDisplayMatHeatMap, 'false');
    }
    schoolService.getSchoolSubscriptions(school.Id).then((response) => {
      if (response.success) {
        storageService.setItem(WebStorageNames.SelectedTab, 2);
        storageService.setItem(
          WebStorageNames.SubscriptionId,
          response.data.SubscriptionId
        );
        window.open(
          routesObj.Dashboard.path + '?schoolToken=' + school.SchoolToken,
          '_blank'
        );
      }
    });
  };

  getSchoolRegistraionLink = (schoolId) => {
    accountService.getInCompleteRegistrationLink(schoolId).then((response) => {
      if (response.success) {
        this.setState({ isShowRegistrationLinkPopup: true });
        this.setState({ inCompleteRegistrationLink: response.data });
        console.log(this.state.inCompleteRegistrationLink);
      } else {
        this.setState({ isShowRegistrationLinkPopup: true });
        this.setState({ inCompleteRegistrationLink: response.message });
        console.log(response.message);
      }
    });
  };

  render() {
    const {
      nameSearchValue,
      isSearchDelete,
      nameFilterSelectedOption,
      CreateSchoolSelectedOption,
      CreateSchoolOptions,
    } = this.state;

    let filteredSchoolsList = this.applyFilters();

    let { SetSchoolsListFlag } = storageService.getItem(
      WebStorageNames.SchoolListFlag
    );
    let additionalTitleText = '';

    if (SetSchoolsListFlag === schoolListFlags.SchoolWithLiveSurvey) {
      additionalTitleText = ' - Live Surveys';
    } else if (SetSchoolsListFlag === schoolListFlags.NotLiveSurvey) {
      additionalTitleText = ' - Not Live Surveys';
    } else if (
      SetSchoolsListFlag === schoolListFlags.CompletedAtLeastOneSurvey
    ) {
      additionalTitleText = ' - Completed at least 1 Survey';
    } else if (SetSchoolsListFlag === schoolListFlags.NoSurveyAddedYet) {
      additionalTitleText = ' - No Survey Added';
    } else if (SetSchoolsListFlag === schoolListFlags.RegistrationNotComplete) {
      additionalTitleText = ' - Registration Not Completed';
    } else if (SetSchoolsListFlag === schoolListFlags.WelbeeVoiceOnly) {
      additionalTitleText = ' - Welbee Voice Only';
    } else if (SetSchoolsListFlag === schoolListFlags.WelbeeSurveyWithVoice) {
      additionalTitleText = ' - Welbee Survey With Voice';
    }

    return (
      <div>
        <AdminHeader />
        <SchoolSurveyWrapper>
          <SchoolSurveyInnerMain>
            <SchoolSurveyInner>
              <SchoolListMainWrapper>
                <Grid container>
                  <Grid item xl={2} lg={2} md={12} sm={12}>
                    <h1 style={{ 'max-height': '60px', margin: '0px' }}>
                      School List {additionalTitleText}{' '}
                    </h1>
                  </Grid>
                  <Grid item xs={12} xl={2} lg={2} md={12} sm={12}>
                    <h1 style={{ 'max-height': '60px', margin: '0px' }}>
                      <div className='test-checkbox'>
                        <CheckBox>
                          <input
                            className='styled-checkbox'
                            type='checkbox'
                            id='selectTestAccountCheckbox'
                            value='value1'
                            checked={this.state.selectTestAccount}
                            onChange={(e) =>
                              this.setState(
                                { selectTestAccount: e.target.checked },
                                this.applyFilters
                              )
                            }
                          />
                          <label for='selectTestAccountCheckbox'>
                            <span
                              className='checkbox-wrapper'
                              style={{ color: 'white' }}
                            >
                              Test Accounts
                            </span>
                          </label>
                        </CheckBox>
                      </div>
                    </h1>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    xl={8}
                    lg={8}
                    md={12}
                    sm={12}
                    className='px-0'
                  >
                    <h1
                      style={{ 'min-height': '60px' }}
                      className='super-admin-header'
                    >
                      <div className='search-filters create-school-dropdown'>
                        <Dropdown
                          name='CreateSchool'
                          options={CreateSchoolOptions}
                          selectedOption={CreateSchoolSelectedOption}
                          onChange={this.handleCreateSchoolFilterChange}
                          value={CreateSchoolSelectedOption}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className='comments-search-filters'>
                        <div className='subcontainer-inner school-list-header'>
                          <div
                            className='search-filters'
                            style={{ padding: '0px 0px' }}
                          >
                            <Dropdown
                              name='NameSortFilter'
                              options={nameFilterOptions}
                              selectedOption={nameFilterSelectedOption}
                              onChange={this.handleNameSortFilterChange}
                              value={nameFilterSelectedOption}
                            />
                          </div>
                          <div className='search-filed'>
                            <img src={searchIconWhite} alt='search button' />
                            <input
                              id='searchInputField'
                              placeholder='Enter a keyword'
                              onChange={(e) => {
                                e.preventDefault();
                                this.handleNameSearchChange(e);
                              }}
                              icon='search'
                              className='searchField'
                              onFocus={this.handleInputFocus}
                              onBlur={this.handleInputBlur}
                              value={nameSearchValue}
                            />
                            {isSearchDelete ? (
                              <div
                                className='cross'
                                onClick={this.handleInputValue}
                              >
                                X
                              </div>
                            ) : (
                              <div />
                            )}
                          </div>
                        </div>
                      </div>
                    </h1>
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} px='0px'>
                    <TableStyle>
                      <table>
                        <thead>
                          <tr>
                            <th className='list-heading'>Name</th>
                            {this.renderHeader()}
                            <th className='list-heading list-action'>
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.pageOfItems.map((item, i) => (
                            <tr key={item.Id}>
                              <td>{item.Name ? item.Name : 'Not Provided'}</td>
                              {this.renderSchoolRecords(item)}
                              <td>
                                {storageService.getItem(
                                  WebStorageNames.SchoolListFlag
                                ) ===
                                schoolListFlags.RegistrationNotComplete ? (
                                  <AppButton
                                    color={teal}
                                    type='submit'
                                    onClick={() =>
                                      this.getSchoolRegistraionLink(item.Id)
                                    }
                                  >
                                    Link
                                  </AppButton>
                                ) : (
                                  <></>
                                )}
                                <AppButton
                                  color={teal}
                                  type='submit'
                                  onClick={() => this.schoolDetail(item.Id)}
                                >
                                  Detail
                                </AppButton>
                                {SetSchoolsListFlag ===
                                schoolListFlags.WelbeeVoiceOnly ? (
                                  <AppButton
                                    color={mandy}
                                    type='submit'
                                    onClick={() => {
                                      this.onConversationClick(item.Id);
                                    }}
                                  >
                                    Conversation
                                  </AppButton>
                                ) : (
                                  <AppButton
                                    color={mandy}
                                    type='submit'
                                    data-id-button={`survey${i}`}
                                    onClick={() =>
                                      SetSchoolsListFlag ===
                                      schoolListFlags.WelbeeVoiceOnly
                                        ? this.onConversationClick(item.Id)
                                        : this.handleSchoolSurvey(item.Id)
                                    }
                                  >
                                    Survey
                                  </AppButton>
                                )}
                                <AppButton
                                  color={mandy}
                                  type='submit'
                                  // data-id-button={`survey${i}`}
                                  onClick={() =>
                                    this.setState({
                                      isArchive: true,
                                      archivedSchoolId: item.Id,
                                    })
                                  }
                                >
                                  Delete
                                </AppButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableStyle>
                    <PaginationWrapper>
                      <Paging
                        items={filteredSchoolsList}
                        onChangePage={this.onChangePage}
                        isChanged={this.state.isChanged}
                      />
                    </PaginationWrapper>
                  </Grid>
                </Grid>
              </SchoolListMainWrapper>
            </SchoolSurveyInner>
            <ConfirmPopupForm
              isOpen={this.state.isArchive}
              onClose={() => this.setState({ isArchive: false })}
              onOk={this.handleArchiveSchool}
              successPopupMessage='Are you sure to archive this school?'
            />
            <RegistrationLink
              isOpen={this.state.isShowRegistrationLinkPopup}
              onClose={() =>
                this.setState({ isShowRegistrationLinkPopup: false })
              }
              title='Incomplete Registration Link'
              discription='Please use the registration link below for the account to complete the registration.'
              url={this.state.inCompleteRegistrationLink}
            />
          </SchoolSurveyInnerMain>
        </SchoolSurveyWrapper>
      </div>
    );
  }
}

const mapStateToProps = ({ superAdmin: { SetSchoolsListFlag } }) => ({
  SetSchoolsListFlag,
});

export default connect(mapStateToProps, null)(withRouter(Schools));
