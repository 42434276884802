import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../resources/theme/colors';
import { welbeeDashboardLogo } from '../../resources/images/index';
import { superAdminActions } from '../../store/superAdminStore';
import { storageService } from '../../services';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { AppButton } from '../../resources/styling';
import superAdminService from '../../services/api/superAdminService';
import { routesObj } from '../../routes';
import { bindActionCreators } from 'redux';
import { limitAverageValue } from '../../utils/roundScore';
import { schoolListFlags, WebStorageNames } from '../../utils/Constants';
import {
  ExtendedAssistanceWrapper,
  ExtendedDashboardMainWrapper,
} from './SuperAdminStyle';
import IdleTimerContainer from '../../components/common/IdleTimerContainer';

const { teal } = colors;

function SuperAdminDashboard(props) {
  const [adminDashboardInfo, setAdminDashboardInfo] = useState({});

  useEffect(() => {
    superAdminService
      .getAdminDashboardInfo()
      .then((response) => {
        if (response.success) setAdminDashboardInfo(response.data);
        storageService.removeItem(WebStorageNames.WelbeeType);
      })
      .catch((error) => {});
  }, []);

  return (
    <div>
      <ExtendedDashboardMainWrapper className='containerClass'>
        <ExtendedAssistanceWrapper>
          <span>
            {' '}
            <img src={welbeeDashboardLogo} alt='' />
          </span>
          <div className='dashboard-school-name'>Super admin dashboard</div>
        </ExtendedAssistanceWrapper>

        <div className='super-admin-dashboard-wrapper'>
          <Grid container>
            <Grid item lg={11} sm={12} xs={12} mx='auto' textAlign='end'>
              <div className='invoice'>
                <AppButton
                  color={teal}
                  onClick={() => {
                    props.history.push(routesObj.SentEmails.path);
                  }}
                >
                  Sent Emails
                </AppButton>
              </div>
              <div className='invoice'>
                <AppButton
                  color={teal}
                  onClick={() => {
                    props.history.push(routesObj.Invoices.path);
                  }}
                >
                  Process Invoices
                </AppButton>
              </div>
              <div className='invoice'>
                <AppButton
                  color={teal}
                  onClick={() => {
                    props.history.push(routesObj.SchoolSubscriptions.path);
                  }}
                >
                  Subscriptions
                </AppButton>
              </div>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <ul>
                <Stack direction='row' spacing={3} justifyContent='center'>
                  <Grid item xl={2} lg={2} sm={6} md={6} xs={12}>
                    <li
                      onClick={() => {
                        props.superAdminActions.setSchoolsListFlag(
                          schoolListFlags.RegistrationNotComplete
                        );
                        props.history.push(routesObj.Schools.path);
                      }}
                    >
                      Not complete{' '}
                      <span>{adminDashboardInfo.RegistrationNotComplete}</span>
                    </li>
                  </Grid>
                  <Grid item xl={2} lg={2} sm={6} md={6} xs={12}>
                    <li
                      onClick={() => {
                        props.superAdminActions.setSchoolsListFlag(
                          schoolListFlags.NoSurveyAddedYet
                        );
                        props.history.push(routesObj.Schools.path);
                      }}
                    >
                      No survey{' '}
                      <span>{adminDashboardInfo.NoSurveyAddedYet}</span>
                    </li>
                  </Grid>
                  <Grid item xl={2} lg={2} sm={6} md={6} xs={12}>
                    <li
                      onClick={() => {
                        props.superAdminActions.setSchoolsListFlag(
                          schoolListFlags.SchoolWithLiveSurvey
                        );
                        props.history.push(routesObj.Schools.path);
                      }}
                    >
                      Live{' '}
                      <span>
                        {adminDashboardInfo.SchoolsCountWithLiveSurvey}
                      </span>
                    </li>
                  </Grid>
                  <Grid item xl={2} lg={2} sm={6} md={6} xs={12}>
                    <li
                      onClick={() => {
                        props.superAdminActions.setSchoolsListFlag(
                          schoolListFlags.NotLiveSurvey
                        );
                        props.history.push(routesObj.Schools.path);
                      }}
                    >
                      Not Live
                      <span>
                        {adminDashboardInfo.SchoolsCountWithNotLiveSurvey}
                      </span>
                    </li>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    sm={{ size: 6, span: 12 }}
                    md={{ size: 6, span: 12 }}
                    xs={12}
                  >
                    <li
                      onClick={() => {
                        props.superAdminActions.setSchoolsListFlag(
                          schoolListFlags.CompletedAtLeastOneSurvey
                        );
                        props.history.push(routesObj.Schools.path);
                      }}
                    >
                      Complete{' '}
                      <span>
                        {adminDashboardInfo.CompletedAtLeastOneSurvey}
                      </span>
                    </li>
                  </Grid>
                </Stack>
                <Stack direction='row' spacing={3} justifyContent='center'>
                  <Grid item xl={2} lg={2} sm={6} md={6} xs={12}>
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        props.superAdminActions.setSchoolsListFlag(
                          schoolListFlags.WelbeeVoiceOnly
                        );
                        props.history.push(routesObj.Schools.path);
                      }}
                    >
                      Voice Only{' '}
                      <span>{adminDashboardInfo.WelbeeVoiceSchoolCount}</span>
                    </li>
                  </Grid>
                  <Grid item xl={2} lg={2} sm={6} md={6} xs={12}>
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        props.superAdminActions.setSchoolsListFlag(
                          schoolListFlags.WelbeeSurveyWithVoice
                        );
                        props.history.push(routesObj.Schools.path);
                      }}
                    >
                      Survey Only{' '}
                      <span>
                        {adminDashboardInfo.WelbeeSurveyWithVoiceSchoolCount}
                      </span>
                    </li>
                  </Grid>
                  <Grid item xl={2} lg={2} sm={6} md={6} xs={12}>
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        props.history.push(routesObj.AccountRegistrations.path)
                      }
                    >
                      Account Registrations{' '}
                      <span>{adminDashboardInfo.SchoolCount}</span>
                    </li>
                  </Grid>
                  <Grid item xl={2} lg={2} sm={6} md={6} xs={12}>
                    <li
                      onClick={() =>
                        props.history.push(routesObj.FilterLibrary.path)
                      }
                    >
                      Filter Library <span>Library</span>
                    </li>
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    sm={{ size: 6, span: 12 }}
                    md={{ size: 6, span: 12 }}
                    xs={12}
                  >
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        props.history.push(routesObj.MatAccounts.path)
                      }
                    >
                      Groupings <span>Groups</span>
                    </li>
                  </Grid>
                </Stack>
              </ul>
            </Grid>
          </Grid>
        </div>
      </ExtendedDashboardMainWrapper>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  superAdminActions: bindActionCreators(superAdminActions, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SuperAdminDashboard));
