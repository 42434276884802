import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../resources/theme';
import {
  LeftMenu,
  SurveyDropDown,
  MainHeader,
  Dropdown,
} from '../../components';
import { loaderAction } from '../../store/loaderStore';
import {
  parseJwt,
  getTransformedSurveyRecordList,
} from '../../utils/CommonFunctions';
import ManagementStandardHeatmap from './managementStandardHeatmap';
import {
  IndividualChart,
  SingleSurveyManagement,
  MultiSurveyManagement,
} from './index';
import {
  storageService,
  dashboardService,
  surveyService,
  additionalSurveyQuestionService,
  schoolSubscriptionService,
} from '../../services';
import { IsActionAllowed, RestrictedActions } from '../../utils';
import {
  WebStorageNames,
  SurveyTypes,
  CategoryList,
} from '../../utils/Constants';
import { navigationActions } from '../../store/headerStore';
import {
  DownloadButtonStyle,
  AssistanceWrapperHeading,
  AssistanceWrapper,
  SelectStyles,
} from '../../resources/styling/appStyle';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { QueryStringTokens } from '../../utils';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { getLikertOptions } from '../../utils/CommonFunctions';
import { find, isEmpty, isNull } from 'lodash';
import { iconDownload } from '../../resources/images/index';
import Tooltip from '@mui/material/Tooltip';
import { ExtendedSubContainer, CommentsMainWrapper } from './DashboardStyle';
import AnimatedModal from '../../components/common/animated-modal';
const { mineShaft, never, seldom, sometimes, often, always } = colors;

const Styles = {
  color: '#363636',
  fontweight: '500',
  fontsize: '12px',
  cursor: 'pointer',
  float: 'right',
  width: '150px',
};

class ManagementStandards extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    this.state = {
      isAllowed: false,
      individualResults: null,
      heatMapData: null,
      catogorySurveyAverage: null,
      harrastmentselectiondata: null,
      bullyselectiondata: null,
      IsMultiSurvey: false,
      progressGraphData: null,
      message: 'Loading...',
      searchValue: '',
      latestSurveyOption: [{ value: SurveyTypes.any, label: '' }],
      latestSurveyOptions: [{ value: SurveyTypes.any, label: '' }],
      isRole: userInfo.role,
      nextCategory: '',
      previousCategory: '',
      IsSurveyClosed: true,
      currentSurveyId: 0,
      isProgressGraphHidden: false,
      surveyQuestions: null,
      options: {
        chart: {
          stacked: true,
          stackType: '100%',
          toolbar: { show: false },
        },
        plotOptions: {
          bar: { horizontal: true },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },

        title: {
          text: '',
        },
        xaxis: {
          categories: ['Results'],
          labels: {
            formatter: function (val) {
              return val + '%';
            },
          },
        },
        colors: ['#FF5939', '#53B5E0', '#FFCF05', '#007A7A', '#F5ABCC'],
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: { show: false },
      },
      likertOptions: {},
    };
  }

  componentDidMount() {
    const queryString = window.location.search;
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    const categoryId = parseInt(queryString.split('categoryId=')[1], 10);
    const token = window.location.search.split('?token=')[1];
    this.setState({ currentSurveyId: categoryId });
    this.getSurveyReportData(queryString);

    IsActionAllowed(RestrictedActions.DashboardDecile)
      .then((response) => this.setState({ isAllowed: response.data }))
      .catch();

    dashboardService
      .getDashoboardCategoryProgressReport(categoryId)
      .then((response) => {
        const { success, data } = response;
        loaderActions.dataLoading(false);
        if (success) {
          this.setState({
            progressGraphData: data.ProgressGraphData,
            IsMultiSurvey: data.IsMultiSurvey,
          });
        }
      });

    surveyService
      .getSurveysNameAndToken(token)
      .then((response) => {
        const surveyRecordList = getTransformedSurveyRecordList(response.data);
        this.setState({ latestSurveyOptions: surveyRecordList });

        const token = window.location.search.split('&categoryId=')[0];
        var selectedRecord = surveyRecordList.filter(
          (item) => '?token=' + item.value === token
        );

        if (selectedRecord.length > 0) {
          this.setState({ latestSurveyOption: selectedRecord[0] });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => console.log(error));

    schoolSubscriptionService
      .getSchoolSubscriptionsByToken(token)
      .then((response) => {
        if (response.success)
          storageService.setItem(
            WebStorageNames.SubscriptionId,
            response.data.SubscriptionId
          );
      })
      .then(() => {
        this.getPreviousAndNextCategory(categoryId);
      });
  }

  getSurveyReportData(queryString) {
    const categoryId = parseInt(queryString.split('categoryId=')[1], 10);
    dashboardService
      .getDashoboardCategoryDetail(queryString)
      .then((response) => {
        const { success, data } = response;
        const { AdditionalFilters } = response.data.heatMapData;
        if (AdditionalFilters) {
          const region = storageService.getItem(WebStorageNames.UsRegion);
          const addFilters = AdditionalFilters.map((obj) => {
            if (obj.FilterName === 'Phase Or Year') {
              if (region === 'us') return { ...obj, FilterName: 'Grade' };
              else return { ...obj, FilterName: 'Year Or Key Stage' };
            }
            return obj;
          });
          data.heatMapData.AdditionalFilters = addFilters;
        }

        if (success) {
          this.setState({
            heatMapData: data.heatMapData,
            catogorySurveyAverage: data.catogorySurveyAverage,
            harrastmentselectiondata: data.harrastmentselectionList,
            bullyselectiondata: data.bullyselectionList,
            IsSurveyClosed: true,
          });
          if (data.catogorySurveyAverage.Category === 8)
            this.getAdditionalQuestions(data.individualResults);
          else this.setState({ individualResults: data.individualResults });
        } else {
          this.setState({ IsSurveyClosed: false });
        }
      });
  }

  getAdditionalQuestions(surveyResult) {
    const token = window.location.search.split('?token=')[1];
    additionalSurveyQuestionService
      .getSurveyQuestions(token)
      .then((response) => {
        const { success, data } = response;
        if (success) {
          surveyResult.QuestionSurveyAverages.map((question) => {
            var surveyQuestion = find(
              data,
              (surveyQuestion) => surveyQuestion.Order === question.QuestionId
            );
            let likertOptions;
            if (surveyQuestion.Likert) {
              const {
                LikertOption1,
                LikertOption2,
                LikertOption3,
                LikertOption4,
                LikertOption5,
              } = surveyQuestion.Likert;
              likertOptions = [
                { Id: 1, Answer: LikertOption1, Color: never, Selected: false },
                {
                  Id: 2,
                  Answer: LikertOption2,
                  Color: seldom,
                  Selected: false,
                },
                {
                  Id: 3,
                  Answer: LikertOption3,
                  Color: sometimes,
                  Selected: false,
                },
                { Id: 4, Answer: LikertOption4, Color: often, Selected: false },
                {
                  Id: 5,
                  Answer: LikertOption5,
                  Color: always,
                  Selected: false,
                },
              ];
            } else {
              likertOptions = getLikertOptions(surveyQuestion.LikertFormat);
            }

            question.LikertOptions = likertOptions.map(
              (option) => option.Answer
            );
            return question;
          });
          this.setState({ individualResults: surveyResult });
        }
      });
  }

  getPreviousAndNextCategory = (categoryId) => {
    let previousCategory = null;
    let nextCategory = null;
    const subscriptionId = storageService.getItem(
      WebStorageNames.SubscriptionId
    );
    var currentIndex = CategoryList.findIndex((x) => x.value === categoryId);

    var totalCategories = subscriptionId === 5 ? 8 : 7;

    if (categoryId !== 1)
      previousCategory = CategoryList.find((x, index) => {
        if (index === currentIndex - 1) return x;
      });
    if (categoryId !== totalCategories)
      nextCategory = CategoryList.find((x, index) => {
        if (index === currentIndex + 1) return x;
      });
    this.setState({
      previousCategory: previousCategory,
      nextCategory: nextCategory,
    });
  };

  handleSurveyValueChange = (latestSurveyOption) => {
    this.setState({ latestSurveyOption });
    const categoryId = parseInt(
      window.location.search.split('categoryId=')[1],
      10
    );
    window.location.search =
      '?token=' + latestSurveyOption.value + '&categoryId=' + categoryId;

    this.getSurveyReportData(window.location.search);
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  print = () => {
    const surveyToken = getQueryStringVal(QueryStringTokens.token);
    const categoryId = getQueryStringVal(QueryStringTokens.categoryId);

    dashboardService
      .GetPDFManagementStandardReport(surveyToken, categoryId)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'management-standards-' +
            (this.state.catogorySurveyAverage !== null
              ? this.state.catogorySurveyAverage.CategoryName
              : '') +
            '.pdf'
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  render() {
    const {
      latestSurveyOptions,
      latestSurveyOption,
      isRole,
      individualResults,
      heatMapData,
      catogorySurveyAverage,
      harrastmentselectiondata,
      bullyselectiondata,
      progressGraphData,
      IsMultiSurvey,
      nextCategory,
      previousCategory,
      IsSurveyClosed,
      currentSurveyId,
      isProgressGraphHidden,
      surveyQuestions,
    } = this.state;
    return (
      <CommentsMainWrapper>
        <div className='containerClass'>
          <div className='left-container'>
            <LeftMenu />
          </div>
          {!IsSurveyClosed ? (
            <div className='right-container'>
              <div className='report-not-available'>
                Report is not currently available because the current survey is
                still in progress{' '}
              </div>
            </div>
          ) : (
            <div className='right-container'>
              <MainHeader />
              <AssistanceWrapper>
                <AssistanceWrapperHeading>
                  <span className='comment-heading'>
                    {catogorySurveyAverage !== null
                      ? catogorySurveyAverage.CategoryName
                      : ''}
                  </span>
                  <span>
                    {catogorySurveyAverage !== null &&
                    catogorySurveyAverage.CategoryName == 'Additional Questions'
                      ? ''
                      : currentSurveyId + ' of 7 Management Standards'}
                  </span>
                </AssistanceWrapperHeading>
                <div className='header-bottom'>
                  <div className='btn-download'>
                    {this.state.isAllowed ? (
                      <Tooltip title='Download' followCursor>
                        <DownloadButtonStyle
                          data-id-div='reportDownload'
                          onClick={this.print}
                        >
                          <img src={iconDownload} alt='' />{' '}
                          <span>Download</span>
                        </DownloadButtonStyle>
                      </Tooltip>
                    ) : (
                      <DownloadButtonStyle
                        data-id-div='reportDownload'
                        className='tooltip'
                      >
                        <img src={iconDownload} alt='' /> <span>Download</span>
                        <span className='tooltiptext'>
                          You can download the heatmaps by upgrading your
                          account.
                        </span>
                      </DownloadButtonStyle>
                    )}
                  </div>
                  {IsMultiSurvey ? (
                    <div className='dd-survey'>
                      <SurveyDropDown
                        isRole={isRole}
                        latestSurveyOptions={latestSurveyOptions}
                        latestSurveyOption={latestSurveyOption}
                        handleSurveyValueChange={this.handleSurveyValueChange}
                      />
                    </div>
                  ) : null}
                </div>
              </AssistanceWrapper>
              <ExtendedSubContainer
                value={`${
                  catogorySurveyAverage != null
                    ? catogorySurveyAverage.CategoryColorName
                    : mineShaft
                }`}
              >
                <div className='inner-white-wrapper'>
                  {IsMultiSurvey ? (
                    <MultiSurveyManagement
                      individualResults={individualResults}
                      catogorySurveyAverage={catogorySurveyAverage}
                      ProgressGraphData={progressGraphData}
                      IsProgressGraphHidden={isProgressGraphHidden}
                      isAllowed={this.state.isAllowed}
                    />
                  ) : (
                    <SingleSurveyManagement
                      individualResults={individualResults}
                      catogorySurveyAverage={catogorySurveyAverage}
                      isAllowed={this.state.isAllowed}
                    />
                  )}
                  {individualResults != null && heatMapData ? (
                    <ManagementStandardHeatmap
                      questions={individualResults.QuestionSurveyAverages}
                      heatMapData={heatMapData}
                      categoryName={individualResults.CategoryType}
                    />
                  ) : (
                    <></>
                  )}

                  <div className='induidual-question-wrapper'>
                    Individual questions
                    {individualResults != null
                      ? individualResults.QuestionSurveyAverages.map(
                          (question, index) => {
                            return (
                              <div>
                                <span>
                                  {question.hasOwnProperty('LikertOptions') ? (
                                    <ul>
                                      {question.LikertOptions.map((scale) => {
                                        return <li>{scale}</li>;
                                      })}
                                    </ul>
                                  ) : question.Likert ? (
                                    <ul>
                                      <li>{question.Likert.LikertOption1}</li>
                                      <li>{question.Likert.LikertOption2}</li>
                                      <li>{question.Likert.LikertOption3}</li>
                                      <li>{question.Likert.LikertOption4}</li>
                                      <li>{question.Likert.LikertOption5}</li>
                                    </ul>
                                  ) : (
                                    <ul>
                                      <li>Never</li>
                                      <li>Seldom</li>
                                      <li>Sometimes</li>
                                      <li>Often</li>
                                      <li>Always</li>
                                    </ul>
                                  )}
                                </span>

                                <div className='graph-main-wrapper'>
                                  <div className='graph-wrapper'>
                                    <div
                                      className='question-wrapper'
                                      style={{ width: '95%' }}
                                    >
                                      <span>
                                        Q{question.QuestionId}.
                                        {question.QuestionId === 5 &&
                                        !isNull(
                                          this.state.harrastmentselectiondata
                                        ) &&
                                        !isEmpty(
                                          this.state.harrastmentselectiondata
                                        ) ? (
                                          <span
                                            style={{
                                              fontSize: '13px',
                                              color: 'rgb(54, 54, 54)',
                                              fontweight: '800',
                                              float: 'right',
                                              width: '35%',
                                              lineHeight: 'initial',
                                            }}
                                          >
                                            <AnimatedModal
                                              parrdata={
                                                this.state
                                                  .harrastmentselectiondata
                                              }
                                              type={'Harrasment'}
                                            />
                                          </span>
                                        ) : question.QuestionId === 21 &&
                                          !isNull(
                                            this.state.bullyselectiondata
                                          ) &&
                                          !isEmpty(
                                            this.state.bullyselectiondata
                                          ) ? (
                                          <span
                                            style={{
                                              fontSize: '13px',
                                              color: 'rgb(54, 54, 54)',
                                              fontweight: '800',
                                              float: 'right',
                                              width: '35%',
                                              lineHeight: 'initial',
                                            }}
                                          >
                                            <AnimatedModal
                                              parrdata={
                                                this.state.bullyselectiondata
                                              }
                                              type={'bulling'}
                                            />
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </span>

                                      {question.QuestionText}
                                    </div>
                                    <IndividualChart
                                      options={this.state.options}
                                      data={question}
                                      index={index + 1}
                                    />
                                  </div>
                                  <div className='results-wrapper'>
                                    <div className='results-wrapper-inner'>
                                      {question.QuestionAverage}
                                      <span>Your Score</span>
                                    </div>
                                    {catogorySurveyAverage.Category ===
                                    8 ? null : (
                                      <div className='results-wrapper-inner'>
                                        {this.state.isAllowed
                                          ? question.SchoolDecilesAverage
                                          : '---'}
                                        <span>School decile</span>
                                      </div>
                                    )}
                                    {catogorySurveyAverage.Category ===
                                    8 ? null : (
                                      <div className='results-wrapper-inner'>
                                        {this.state.isAllowed
                                          ? question.HseDecilesAverage
                                          : '---'}
                                        <span>HSE decile</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )
                      : null}
                  </div>
                  <div className='footer-navigation'>
                    <div
                      className='previous-btn'
                      onClick={() =>
                        (window.location.search =
                          '?token=' +
                          latestSurveyOption.value +
                          '&categoryId=' +
                          previousCategory.value)
                      }
                    >
                      {previousCategory !== null ? previousCategory.label : ''}
                    </div>
                    <div
                      className='next-btn'
                      onClick={() =>
                        (window.location.search =
                          '?token=' +
                          latestSurveyOption.value +
                          '&categoryId=' +
                          nextCategory.value)
                      }
                    >
                      {nextCategory !== null ? nextCategory.label : ''}
                    </div>
                  </div>
                </div>
              </ExtendedSubContainer>
            </div>
          )}
        </div>
      </CommentsMainWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(ManagementStandards));
