import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { ScrollContainer } from 'react-nice-scroll';
import Legends from './Legends';

const PresentationChart = ({ data, surveyCount, name }) => {
  const survey1Colors = {
    Demands: '#45338c',
    Control: '#e84f6b',
    Manager_Support: '#007a7a',
    Peer_Support: '#ff5939',
    Relationships: '#53b5e0',
    Role: '#fd92c2',
    Change: '#ffb205',
  };

  const survey2Colors = {
    Demands: '#978DE2',
    Control: '#F27F95',
    Manager_Support: '#18B5B2',
    Peer_Support: '#FF8D79',
    Relationships: '#82D4F4',
    Role: '#FDB0D6',
    Change: '#FFCD60',
  };

  const allCategories = Object.keys(survey2Colors);

  var barHeight = 30;
  var barGap = surveyCount > 1 ? 2.5 : 1.7;
  const totalHeight = data?.length * barHeight * barGap;

  return (
    <ScrollContainer>
      <div className='analytics-container responsive-analytics'>
        <div className='horizontal-analytics'>
          <div id={name}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: 'auto',
              }}
            >
              <Legends category={0} />
            </div>
            <ResponsiveContainer height={totalHeight}>
              <BarChart
                height={totalHeight}
                layout='vertical'
                data={data}
                barSize={30}
                fontSize={13}
              >
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                <XAxis type='number' hide={true} />
                <YAxis
                  dataKey='label'
                  type='category'
                  width={140}
                  fontWeight='600'
                  tick={{ fill: '#000', fontSize: 12, textAnchor: 'end' }}
                  tickLine={{ stroke: 'none' }}
                />
                {allCategories.map((category, index) => (
                  <React.Fragment key={index}>
                    <Bar
                      key={index}
                      dataKey={`${category.replace(/_/g, ' ')}_value1`}
                      stackId='a'
                      fill={survey1Colors[category]}
                      background={{ fill: '#333333', fillOpacity: 0.01 }}
                    >
                      <LabelList
                        dataKey={`${category.replace(/_/g, ' ')}_value1`}
                        position='insideLeft'
                        fill='#fff'
                        fontSize={14}
                        className='value-label'
                        offset={20}
                        formatter={(value) => value?.toFixed(2)}
                      />
                      <LabelList
                        dataKey={`${category.replace(/_/g, ' ')}_decileValue1`}
                        position='insideLeft'
                        fill='#fff'
                        fontSize={12}
                        className='decile-label'
                        offset={50}
                      />
                    </Bar>
                    {surveyCount > 1 && (
                      <Bar
                        key={`${index}_value2`}
                        dataKey={`${category.replace(/_/g, ' ')}_value2`}
                        stackId='b'
                        fill={survey2Colors[category]}
                        background={{ fill: '#333333', fillOpacity: 0.01 }}
                      >
                        <LabelList
                          dataKey={`${category.replace(/_/g, ' ')}_value2`}
                          position='insideLeft'
                          fill='#fff'
                          fontSize={14}
                          className='value-label'
                          offset={20}
                          formatter={(value) => value?.toFixed(2)}
                        />
                        <LabelList
                          dataKey={`${category.replace(
                            /_/g,
                            ' '
                          )}_decileValue2`}
                          position='insideLeft'
                          fill='#fff'
                          fontSize={12}
                          className='decile-label'
                          offset={50}
                        />
                      </Bar>
                    )}
                  </React.Fragment>
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </ScrollContainer>
  );
};

export default PresentationChart;
