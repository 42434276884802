import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PasswordReg } from '../../utils';
import { ExtendedWrapperMain } from '../../resources/styling/formStyle';
import { toast } from 'react-toastify';
import { ErrorWrapper, FieldWrapper, AppButton } from '../../resources/styling';
import { WelbeeTextField } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { schoolService, accountService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { PasswordViewIcon } from '../../components/common/loginStyle';
import { loginNormalEye, loginShowEye } from '../../resources/images/index';
import Grid from '@mui/material/Grid';

const { mandy } = colors;

class AdminInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SchoolRecord: null,
      errorMessage: '',
      isPasswordShow: true,
      confirmPasswordErrorMessage: '',
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ SchoolRecord: props.SchoolRecord });
  }

  render() {
    const { SchoolRecord } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: SchoolRecord ? SchoolRecord.school.Id : 0,
          Email:
            SchoolRecord && SchoolRecord.school
              ? SchoolRecord.school.Email != null
                ? SchoolRecord.school.Email
                : ''
              : '',
          FirstName:
            SchoolRecord && SchoolRecord.headTeacher
              ? SchoolRecord.headTeacher.FirstName != null
                ? SchoolRecord.headTeacher.FirstName
                : ''
              : '',
          LastName:
            SchoolRecord && SchoolRecord.headTeacher
              ? SchoolRecord.headTeacher.LastName != null
                ? SchoolRecord.headTeacher.LastName
                : ''
              : '',
          Password: '',
          IsUpdateEnabled: false,
        }}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          Email: Yup.string()
            .trim()
            .email('Provide email address like example@g.com.')
            .required('Admin email is required.'),
          FirstName: Yup.string().trim().required('First name is required.'),
          LastName: Yup.string().trim().required('Last name is required.'),
          Password: Yup.string()
            .trim()
            .min(12, 'Password must be at least 12 characters.')
            .max(50, 'Password less then 50 characters.')
            .matches(
              PasswordReg,
              'Your password must contain at least 1 uppercase, lowercase, number and special character from @£!#$%^&+="\',-.'
            ),
          ConfirmPassword: Yup.string().trim(),
        })}
        onSubmit={(values) => {
          console.log(values);
          if (
            (values.Password !== '' &&
              values.Password === values.ConfirmPassword) ||
            values.Password === ''
          ) {
            const { loaderActions } = this.props;
            loaderActions.dataLoading(true);
            schoolService
              .updateSchoolAdminInfoByHeadTeacher(values)
              .then((response) => {
                if (response.success) {
                  loaderActions.dataLoading(false);
                  toast.success(
                    'Account information has been updated successfully.'
                  );
                } else {
                  loaderActions.dataLoading(false);
                  this.duplicationErrorMeassageHandle(response.message);
                }
              })
              .catch((error) => {
                loaderActions.dataLoading(false);
              });
          } else {
            this.setState({
              confirmPasswordErrorMessage: 'Passwords must match.',
            });
          }
        }}
        render={({
          values,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
          handleBlur,
          handleChange,
        }) => (
          <div className='search-inner-sub-container'>
            <h2> Account Information</h2>

            <div className='form-body'>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='First Name'
                      name='First name'
                      value={values.FirstName}
                      onChange={(e) => {
                        setFieldValue('FirstName', e.target.value);
                        setFieldValue('IsUpdateEnabled', true);
                      }}
                    />
                    <ErrorWrapper>
                      {touched.FirstName && errors.FirstName}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Last Name'
                      name='Last name'
                      value={values.LastName}
                      onChange={(e) => {
                        setFieldValue('LastName', e.target.value);
                        setFieldValue('IsUpdateEnabled', true);
                      }}
                    />
                    <ErrorWrapper>
                      {touched.FirstName && errors.FirstName}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={12} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Email'
                      name='Email'
                      value={values.Email}
                      onChange={(e) => {
                        setFieldValue('Email', e.target.value);
                        setFieldValue('IsUpdateEnabled', true);
                      }}
                    />
                    <ErrorWrapper>{touched.Email && errors.Email}</ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Password'
                      name='Password'
                      type={this.state.isPasswordShow ? 'password' : 'text'}
                      autoComplete='new-password'
                      value={values.Password}
                      onChange={(e) => {
                        setFieldValue('Password', e.target.value);
                        setFieldValue('IsUpdateEnabled', true);
                        this.setState({
                          confirmPasswordErrorMessage: '',
                        });
                      }}
                      onBlur={handleBlur}
                    />
                    <ErrorWrapper>{errors.Password}</ErrorWrapper>
                    {/* <button className='change-button'>Change</button> */}
                    <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                      <img
                        src={
                          this.state.isPasswordShow
                            ? loginNormalEye
                            : loginShowEye
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            isPasswordShow: !this.state.isPasswordShow,
                          });
                        }}
                        alt=''
                      />
                    </PasswordViewIcon>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Confirm Password'
                      name='Confirm Password'
                      type={this.state.isPasswordShow ? 'password' : 'text'}
                      autoComplete='new-password'
                      value={values.ConfirmPassword}
                      onChange={(e) => {
                        setFieldValue('ConfirmPassword', e.target.value);
                        setFieldValue('IsUpdateEnabled', true);
                        this.setState({
                          confirmPasswordErrorMessage: '',
                        });
                      }}
                    />

                    {/* <button className='change-button'>Change</button> */}
                    <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                      <img
                        src={
                          this.state.isPasswordShow
                            ? loginNormalEye
                            : loginShowEye
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            isPasswordShow: !this.state.isPasswordShow,
                          });
                        }}
                        alt=''
                      />
                    </PasswordViewIcon>
                    <ErrorWrapper>
                      {this.state.confirmPasswordErrorMessage}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={12} xs={12} style={{ paddingTop: '0' }}>
                  <p className='password-instructions'>
                    To change your password please type into the box above and
                    click update. Passwords need to be between 8 and 50
                    characters and contain at least one uppercase, lowercase,
                    number and special character.
                  </p>
                </Grid>
                <Grid item md={12} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <FieldWrapper>
                      <AppButton
                        color={values.IsUpdateEnabled ? mandy : ''}
                        type='submit'
                        onClick={values.IsUpdateEnabled ? handleSubmit : {}}
                      >
                        Update
                      </AppButton>
                    </FieldWrapper>
                  </ExtendedWrapperMain>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      ></Formik>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(AdminInfoForm);
