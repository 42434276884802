import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { CalculationsStyle as ReportCalculationStyle } from '../../resources/styling/surveyReportStyle';
import {
  SubContainer,
  MediaQuery,
  ChartWrapper,
  ExtendedSubContainer as HeatmapExtendedContainer,
  DashboardMainWrapper,
  AssistanceWrapper,
  SearchFilterWrapper,
  VoiceDashboardWrapper,
  VoiceNextPreviousButtons,
  ReadActionPlan, UpcomingTasksWrapper
 } from '../../resources/styling/appStyle';
import {
  fillarrownext, fillarrowprev ,unfillarrownext, unfillarrowprev,
  filterIcon, flaginactivewithoutcircle, flagactivewithoutcircle,
  flagdisabled, replydisabled
} from '../../resources/images/index';

const {
  mineShaft, pictonBlue, whiteColor, lightBlue, lighterGrey, lightGray, never, seldom, sometimes, often, always,
  midSoftGrey, softGrey, barleyWhite, purple, teal, yellowColor, lightPink, mandy, cornflowerBlue,
  darkgrey, outrageousOrange, twilightBlueLight, blackColor, yourpink, bordergrey
} = colors;

/*** Group Management Standards ***/
/*** Management Standards ***/
export const CommentsMainWrapper = styled.div`
.report-not-available {
  padding: 50px;
  text-align: center;
  width: 70%;
  margin: 120px auto 0;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);

}
.mobile-wrapper{
  padding-bottom: 0;
}

.containerClass {
  padding-top: 0;
  width: 100%;
  max-width: 100%;
}
  float: left;
  width: 100%;
  ${media.xs`
    float: none;
  `}
  ${media.sm`
    float: none;
  `}
  .nodata{
    width: 185px;
    display: inline-block;
    margin-right: 40%;
    float: right;
    text-transform: initial;
    font-size: 12px;
    font-weight: initial;
  }
 
  input[type=text]{
    width:200px;
    padding:8px 10px;
  }
.tooltip{
            background-color: ${lightGray};
            color: ${midSoftGrey};
          .tooltiptext {
            display: none;
            width: 120px;
            background-color: ${pictonBlue};
            color: ${whiteColor};
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 120%;
            left: -20%;
            opacity: 0;
            transition: opacity 0.7s;
            :after {
              content: '';
              position: absolute;
              top: 100%;
              left: 20%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: ${pictonBlue} transparent transparent transparent;
            }
          }
          :hover .tooltiptext {
            display: inline-block;
            opacity: 1;
          }
        }
      }


      div.heatmap-sticky::-webkit-scrollbar {
        background: white;    
        width: 8px;
    }
    div.heatmap-sticky::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }
    div.heatmap-sticky::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
    div.heatmap-sticky {
        max-height: 350px;
        border: 0.1px solid transparent;
        overflow: overlay;
        scrollbar-width: thin;
        scrollbar-color: blue orange;
    }
    
    div.heatmap-sticky:hover::-webkit-scrollbar {
        background: lightgrey;
    }
    
    div.heatmap-sticky::-moz-scrollbar {
      background: white;    
      width: 8px;
    }
    div.heatmap-sticky::-moz-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
    }
    div.heatmap-sticky::-moz-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
    div.heatmap-sticky {
      max-height: 350px;      
      overflow: overlay;
    }
    div.heatmap-sticky:hover::-moz-scrollbar {
      background: lightgrey;
    }
`;
export const ExtendedSubContainer = styled(SubContainer)`

.footer-navigation{
  width: 100%;

  position: absolute;
  bottom: 0;
  font-size: 14px;
  color: ${pictonBlue};
  font-weight: 400;  
  margin: 2rem 0;
  .previous-btn{
    float: left;
    background-image: url(${fillarrowprev});
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 30px;
    background-size: 15px;
    cursor: pointer;
  }
  .next-btn{
    float: right;
    background-image: url(${fillarrownext});
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 30px;
    background-size: 15px;
    cursor: pointer;
  }
}

.graph-main-wrapper {
  background: ${whiteColor};
  color: ${mineShaft};
  margin: 15px 0 0;
  padding: 0 0 0 20px;
  box-sizing: border-box;
  border: 1px solid ${bordergrey};
  border-radius: 6px;
  width: 100%;
    float: left;
  .graph-wrapper {    
    overflow: hidden;   
    float: left;
    width: 90%;
  }
  .question-wrapper {
      float: left;
      font-size: 20px;      
      font-weight: 500;
      padding-top: 30px;
      line-height: 24px;
    span{
      font-size: 30px;      
      color: ${({ value }) => value};
      font-weight: 900;
      float: left;
      width: 100%;
      margin-bottom: 17px;
    }
  }
  .results-wrapper{
    width: 10%;
    text-align: center;
    color: ${blackColor};    
    float: right;    
    span {
      color: ${blackColor};
      font-weight: 500;
      font-size: 12px;    
      display: block;
      width: 100%;
   }
   .results-wrapper-inner{
     width: 100%;
    float: left;
    padding: 25.5px 0;
     border-left: 1px solid ${lighterGrey};
     border-bottom: 1px solid ${lighterGrey};
   }
   .results-wrapper-inner:last-child{
     border-bottom: 0;
   }
  }
}


.inner-white-wrapper{
  width: 85%;
  float: left;
  position: relative;    
  padding-bottom: 50px;  
}

@media only screen and (max-width: 1024px){
  .inner-white-wrapper {
    width: 100% !important;
    margin: 0 !important;
  }
}


.induidual-question-wrapper {
  font-size: 16px;
  font-weight: 900;  
  float: left;
  margin-bottom: 2rem;
  span{
    float: right;
    ul{
      list-style: none;
      padding-top: 15px;
      margin: 0;

      li{
        display: inline;
        font-size: 12px;
        font-weight: 500;        
        margin-left: 30px;
        :before{
          content: "";
          width: 15px;
          height: 15px;
          background: green;
          border-radius: 50%;
          display: table;
          display: inline-block;
          vertical-align: middle;
          line-height: normal;
          margin-top: -4px;
          margin-right: 10px;

        }
        :nth-child(1):before{
            background: ${never};
          }
          :nth-child(2):before{
            background: ${seldom};
          }
          :nth-child(3):before{
            background: ${sometimes};
          }
          :nth-child(4):before{
            background: ${often};
          }
          :nth-child(5):before{
            background: ${always};
          }
      }
    }
  }
}
.subcontainer-inner{
  width: 750px;
  margin: auto;

}
  float: left;
  padding: 50px 0px 0 0;
  color: ${mineShaft};
  min-height: 100vh;

  ${media.xs`
    padding: 15px 0 0 0;
  `}
  ${media.md`
    width: 100%;
    padding: 25px 0 0 0;
  `}
  ${media.lg`
    width: 100%;
  `}
  }
  thead {
    td {
      background: ${pictonBlue};
      padding: 15px 20px;
      color: ${whiteColor};
      border-right: 1px solid ${lightBlue};
      font-weight: 600;
      font-size: 16px;
      width: 11.1%;
    }
  }

  tbody {
    transition: all 0.5s ease;
    &:hover {
      background: ${lighterGrey};
    }
    tbody:nth-child(even) {
      background: ${lighterGrey};
    }
    td {

      word-break: break-word;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    word-break: break-all;
  }

.search-data-container{
  width: 100%;
  float: left;

}
.search-inner-container{
  width: 750px;
  margin: auto;  
  box-sizing: border-box;
  margin-bottom: 20px;
  font-size: 14px;
}
.search-inner-sub-container{
  padding: 40px;
  float: left;
  width: 100%;
  border-radius: 6px;
  background: ${whiteColor};
  box-shadow: 3px 3px 24px rgba(0, 122, 122, 0.12);
}
`;

/*** Individual Question Chart ***/
export const ChartBar = styled.div`
  width: ${({ width }) => width + '%'};

  display: inline-block;
  background: ${({ color }) => color};
  text-align: center;
  padding: 27px 0;
  position: relative;
`;
export const ExtendedChartWrapper = styled(ChartWrapper)`
  width: 100%;
  float: left;
  .main {
    margin-left: 30px;
    border-left: 1px solid;
    padding: 10px 0;
  }

  .main span {
    color: #636363;
    font-weight: 400;    
    font-size: 10px;
    position: absolute;
    top: -22px;
    width: 100%;
    text-align: center;

    left: 0;
  }

  .percent-value {
    /* padding-right: 15px; */
  }

  .percent-value span {
    /* width: 9%; */
    font-size: 12px;
    font-weight: 400;    
  }

  .percent-value span:nth-child(10) {
    padding: 3px 20px 0 0;
  }

  .percent-legend {
    margin-top: 0;
    margin-left: 30px;
    border-top: 1px solid;
  }

  .main-wrapper {
    width: 93%;
  }
  .main-wrapper > span {
    padding-left: 0px;

    left: -26px;
    font-size: 12px;
    font-weight: 400;
    padding-right: 0px;    
    height: 69%;
    top: 1px;
    -webkit-transform: rotate(145deg);
    -ms-transform: rotate(145deg);
    transform: rotate(-90deg);
  }
  .main-wrapper > span i {
    padding-top: 30px;
    font-style: normal;
    display: inherit;
  }
`;

/*** Management Standard Heatmap ***/
export const SuperExtendedSubContainer = styled(HeatmapExtendedContainer)`
  padding: 0 !important;
  overflow-x: initial !important;
  .subcontainer-inner {
    width: 100%;
  }
  min-height: 100%;
  padding: 80px 0px 0 0;

  .staff-result-innercontainer {
    width: 100%;
    padding: 0;
  }
  .staff-type-grid {
    position: relative;
    .heat-map-heading {
      font-size: 13px;
    }
    ul.questionNoWrapper {
      padding: 0;     
      li:hover {
        background: ${softGrey};
      }
      li:hover .questionDetail {
        display: block;
      }
    }
    .questionDetail {
      width: 181px;
      text-align: left;
      background: ${mineShaft};
      padding: 15px 20px;
      display: none;
      color: ${whiteColor};
      font-size: 12px;
      position: absolute;
      right: -197px;
      top: 35px;
      border-radius: 3px;
      span {
        display: block;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 10px;
      }
      span:nth-child(2) {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;        
      }
    }
    .questionDetail::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 10px solid ${mineShaft};
      margin-left: -28px;
      position: absolute;
    }
  }
  ul {
    padding: 9px 0 0 0;
  }
  .grid-data {
    transition: all 0.3s linear;
  }
  ul.upper-heading {
    li {
      font-size: 14px;
    }
  }
`;
/*** Group Management Standard Heatmap ***/
export const SuperExtendExtendedSubContainer = styled(ExtendedSubContainer)`
padding: 0 !important;
overflow-x: initial !important;
.subcontainer-inner {
  width: 100%;
}
min-height: 100%;
padding: 80px 0px 0 0;

.staff-result-innercontainer {
  width: 100%;
  padding: 0;
}
.staff-type-grid {
  position: relative;
  .heat-map-heading {
    font-size: 13px;
  }
  ul.questionNoWrapper {
    padding: 0;
    li:hover {
      background: ${softGrey};
    }
    li:hover .questionDetail {
      display: block;
    }
  }
  .questionDetail {
    width: 181px;
    text-align: left;
    background: ${mineShaft};
    padding: 15px 20px;
    display: none;
    color: ${whiteColor};
    font-size: 12px;
    position: absolute;
    right: -197px;
    top: 29px;
    border-radius: 3px;
    span {
      display: block;
      font-size: 14px;
      font-weight: 700;
    }
    span:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      margin-top: 12px;
    }
  }
  .questionDetail::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 10px solid ${mineShaft};
    margin-left: -28px;
    position: absolute;
  }
}
ul {
  padding: 9px 0 0 0;
}
.grid-data {
  transition: all 0.3s linear;
}
ul.upper-heading {
  li {
    font-size: 14px;
  }
}
`;
export const GroupCommentsMainWrapper = styled.div`
  .left-nav {
    max-width: 190px;
  }

  .comments-wrapper {
    max-width: 86%;
    margin-left: 190px;
    float: left;
    flex: auto;
    ${media.xl`
    max-width: 90%;
    `}
  }
  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }
  float: left;
  width: 100%;

div.foo::-webkit-scrollbar {
    background: white;    
    width: 8px;
}
div.foo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
div.foo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
div.foo {
    height: 500px;
    border: 0.1px solid white;
    overflow: overlay;
    scrollbar-width: thin;
    scrollbar-color: blue orange;
}

div.foo:hover::-webkit-scrollbar {
    background: lightgrey;
}

div.foo::-moz-scrollbar {
  background: white;    
  width: 8px;
}
div.foo::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
div.foo::-moz-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
div.foo {
  height: 500px;
  border: 0.1px solid white;
  overflow: overlay;
}
div.foo:hover::-moz-scrollbar {
  background: lightgrey;
}

.grid-role-heading ul li {
  text-align: left;
}
`;

/*** Multi Survey Dashboard ***/
export const ExtendedDashboardMainWrapper = styled(DashboardMainWrapper)`  
  .message-container {

    width: 86%;
     margin-left: 320px !important;
    ${media.md`
    `}

    ${media.xl`
    width: 88%;
    margin-bottom:15px;
    `}
    .tiles-wrapper {
      width: 100%;
      float: none;
    }    
  }
  
  .right-container {
    .tiles-wrapper {
      width: 100%;
      float: none;
    }    
  }
  .inner-right-container {
    margin-bottom: 30px;
    .new-dashboard-comments {
      background: ${yellowColor};
      color: ${blackColor};
      padding: 6px 15px;      
      font-size: 13px;
      font-weight: 600;
      width: 100%;
      z-index: 2;
    }
    .comments-wrapper{      
      text-align: left;
      h2{
        display: none;
        span{
          display: none;
        }
      }
      ${media.xs` min-height: 100vh; `}
      ${media.sm` min-height: 30vh; margin-bottom: 15px; `}
    }

    .carousel .carousel-status {
      bottom: -8px;
      top: auto;
      right: 24px;
      text-shadow: none;
      color: ${mineShaft};
      font-weight: 400;      
    }

    .carousel .slider-wrapper{
      .day {
        margin-top: 18px;
        color: ${teal};        
        font-size: 13px;
        font-weight: 500;
      }
      width: 100%;
      line-height: 23px;
      margin-bottom: 15px;           
    }
    overflow: hidden; 
  }


  .carousel .control-disabled.control-arrow{
    display: block !important;
    opacity: 1;
    pointer-events: none;
    background-size: 38%;
  }


  .carousel .control-next.control-arrow{
    background: url(${fillarrownext});
    background-repeat: no-repeat;
    opacity: 1;
    height: 15px;
    background-size: 38%;
    position: absolute;
    bottom: 13px;
    top: auto;
    right: -3px;
    :hover{
      background: url(${fillarrownext});
      background-repeat: no-repeat;
      background-size: 38%;
    }
  }
  .carousel .control-next.control-disabled {
    background: url(${unfillarrownext});
    background-repeat: no-repeat;
    background-size: 38%;
  }

  .carousel .control-prev.control-arrow {
    background: url(${fillarrowprev});
    background-repeat: no-repeat;
    opacity: 1;
    height: 15px;
    background-size: 38%;
    position: absolute;
    bottom: 13px;
    top: auto;
    right: 55px;
    left: auto;
    :hover{
      background: url(${fillarrowprev});
      background-repeat: no-repeat;
      background-size: 38%;
    }
  }
  .carousel .control-prev.control-disabled {
    background: url(${unfillarrowprev});
    background-repeat: no-repeat;
    background-size: 38%;
  }
  .carousel .control-prev.control-disabled {
    background: url(${unfillarrowprev});
    background-repeat: no-repeat;
    background-size: 38%;
  }

  .carousel .control-prev.control-arrow:before{
    border: 0;
    background: url(${unfillarrowprev});
    background-repeat: no-repeat;
  }

  .carousel .slide {
    background: ${whiteColor};
    font-size: 13px;
    text-align: left;
    line-height: 18px;        
    overflow-wrap: break-word;    
    height: 100vh;
    overflow-y: auto;        
    .comment-description{
      padding: 7px 0;    
      border-bottom: 1px solid ${bordergrey};
    }
  }

    .inner-left-container {   
      .scrollbar-view {
        height: 90% !important;
        ${media.md`
          height: 32vh !important;
        `}
        ${media.lg`
          height: 31vh !important;
        `}
        ${media.xl`
          height: 34.5vh !important;
        `}
      }
    }   
    
  }

  .weakest-tiles {    
    .tiles-box .header {
      font-weight: bold;
    }
  }
  .survey-wrapper {
    ${media.md`
      width: 15% !important;
      margin: 0 0 0 20px !important;
      padding: 19px 20px;
      min-height:34vh;
      margin-bottom: 15px;
    `}
    ${media.lg`
      padding: 19px 20px;
      width: 15%;
      min-height: 34vh;
      margin-bottom: 15px;
      margin: 0 20px !important;
    `}
    ${media.xl`
      padding: 28px 20px;
      width: 15.8% !important;
      min-height: 34vh;
      margin-bottom: 15px;
    `}
  }

  .active-survey-wrapper {
    min-height: 285px;
    h2 {
      font-size: 20px;            
    } 
    .large-graph-btn {
      position: absolute;
      top: 5px;
      right: 18px;
      font-size: 12px;
      color: ${pictonBlue};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .survey-wrapper{
    margin: 0 20px;
  }
  .upcoming-task-wrapper {    
    min-height: 285px;   
  }   

  @media only screen and (max-width: 600px){    
    .graph-wrapper,
    .active-survey-wrapper-inner {
      width: 100% !important;
    }

    .tiles-main{
      display: inline-block;
      padding-right: 0;
    }

    .active-survey-wrapper-inner{
      margin: 0 !important;
    }
  }

  
  .survey-completion {
    ${media.md`
    margin-top:  7px !important;
    `}
    ${media.lg`
    margin-top:  7px !important;
    `}
    ${media.xl`
    margin-top:  26px !important;
    `}
    span {
      ${media.md`
    margin-top:  1px !important;
    `}
      ${media.lg`
    margin-top:  1px !important;
    `}
      margin: 10px 0 0 0 !important;
    }
  }

  .progress-bar {
    ${media.md`
    margin-top:  7px !important;
    `}
    ${media.lg`
    margin-top:  7px !important;
    `}
    ${media.xl`
    margin-top:  23px !important;
    `}
  }  

  .multi-survey-outer{
    position: relative;
    ${media.md`
        min-height: 30vh;
    `}
    ${media.lg`
        min-height: 60vh;
    `}
    ${media.xl`
        min-height:100%;
    `}
    ${media.xs`
        min-height: 60vh;
        padding-bottom: 45px;
    `}
    h2 {      
      font-size: 20px;
    }

    }
    .multi-survey-see-all-btn{        
      position: absolute;      
      width: 95%;
      bottom: 0px;
      padding-top: 8px;
      padding-bottom: 8px;      
      border-top: 1px solid ${bordergrey};      
      background: ${whiteColor};      
      button{
        background: transparent;
        border: 0;        
        font-size: 12px;
        cursor: pointer;
        font-weight: 600;
        color: ${pictonBlue};
        padding: 0;        
        overflow: inherit;
      }
    }
    .see-all-comments {
      display: none;
    }

    .active-survey-wrapper-inner {            
      min-height: 285px;
      padding: 28px 20px;      
      border: 1px solid ${bordergrey};
      border-radius: 10px;
      background: #FFFFFF;      
      position: relative;
      height: 100%      
    }

    .header-text {        
        padding-bottom: 20px;
        paddin-top: 20px;
        text-align: center;
        margin-left: 190px;
        width: 83%;
        float: left;
        ${media.xs`
            margin-left: 0;
            margin-bottom: 20px;
            float: none;
        `}
      }
`;

/*** Group Dashboard ***/
export const GroupDashboardMainWrapper = styled(DashboardMainWrapper)`
  .error-message {
    background: ${whiteColor};
    padding: 30px;
    text-align: center;
    border: 1px solid ${bordergrey};
    border-radius: 8px;
    width: 100%;
    margin: 18px 0 0px 0;    
    font-size: 14px;
    font-weight: 600;
  }  
  .body-right-inner {
    margin-top: 20px;
  }
  .group-dashboard-listing {
    background: ${whiteColor};    
    margin: 15px 0 15px 0;
    border: 1px solid ${bordergrey};
    border-radius: 8px;
    position: relative;

    .survey-heading {
      padding: 20px;
    }
    .body-listing {
      padding: 0 0 20px 0;
    }
    .survey-heading {
      font-size: 16px;      
      font-weight: 900;
      padding: 14px 18px;
      margin: 0px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      div {
        display: inline-block;        
      }
    }
    .open-survey-btn {
      button {
        border: 2px solid ${teal};
        margin-top: 0;
        margin-bottom: 0;
        padding: 9px 20px;
        display: inline-block;
      }
      button:nth-child(1) {
        background: transparent;
        border: 2px solid ${mandy};
        color: ${mandy};
        margin-right: 15px;
        box-shadow: none;
        ${media.xs`
          margin-bottom: 10px;
        `}
      }
    }
    button {
      font-size: smaller;
    }
  }

  @media only screen and (max-width: 700px) {
    .group-dashboard-listing {
      margin: 0;
      width: 100%;
    }
    .error-message {
      width: 100%;
      margin: 15px 0 15px 0px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1000px){
    .group-dashboard-listing,
    .error-message{
      margin: 15px 0 15px 0px;
    }
  }

  .inner-heading {
    li:first-child {
      padding-left: 0 !important;
    }
  }

  .inner-heading,
  .inner-body {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    border-top: 1px solid ${lighterGrey};
    border-bottom: 1px solid ${lighterGrey};    
    font-size: 12px;
    font-weight: 900;
    color: ${lightGray};
    margin: 0;
    li {
      flex: 1;
      padding: 12px 0;
      text-align: center;
    }
    li:first-child {
      flex: 3;
      text-align: left;
      padding-left: 9px;
    }
  }
  .inner-body {    
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    border: 0;
    padding: 0 12px;
    .survey-open {
      color: ${teal};
      font-weight: 900;
    }
    :hover li {
      background: ${twilightBlueLight};
    }
  }
  .disabled {
    cursor: auto;
    background-color: ${lighterGrey} !important;
    color: ${whiteColor} !important;
    border: 2px solid ${lighterGrey} !important;
    box-shadow: none;
  }
  .tooltip {
    position: relative;
    .tooltiptext {
      display: none;
      width: 180px;
      background-color: ${pictonBlue};
      color: ${whiteColor};
      font-weight: 400;
      font-size: 13px;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 21;
      bottom: 110%;
      left: -17%;
      opacity: 0;
      transition: opacity 0.7s;
      :after {
        content: '';
        position: absolute;
        top: 100%;
        left: 20%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${pictonBlue} transparent transparent transparent;
      }
    }
    :hover .tooltiptext {
      display: inline-block;
      opacity: 1;
    }
  }

  @media only screen and (max-width: 1024px){
    .dashboard-school-name{
      //width: 100%;
    }
  }
`;

/*** Multi Survey Management Standard ***/
export const ExtendedCalculationsStyle = styled(ReportCalculationStyle)`
  border-top: 2px solid ${pictonBlue};
  border-bottom: 2px solid ${pictonBlue};

  width: 100%;
  margin: 40px 0 40px 0;

  ul {
    li {
      color: ${pictonBlue};
      font-size: 24px;

      span {
        font-weight: 600;
      }
    }
  }
`;

/*** Resend Survey Invitation ***/
export const InstructionWrapper = styled.div`
  .resend-survey-invitation-instructions {
    width: 100%;    
    font-size: 16px;    
    line-height: 24px;
    color: ${blackColor};    
  }
`;

/*** Single Survey Management Standard ***/
export const CalculationsStyle = styled.div`
  color: ${teal};
  border-top: 1px solid ${lighterGrey};  
  padding: 0;
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;

  ul {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
  display: flex;
  justify-content: center;
    li {
      color: ${mineShaft};
      font-size: 16px;
      width: 20%;
      font-weight: 900;
      float: left;
      text-align: center;
      padding: 16px 0;
      border-right: 1px solid ${lighterGrey};

      span {
            display: block;
        font-size: 10px;
        font-weight: 500;
        margin: auto;
        line-height: 14px;
        margin-top: 5px;
        padding: 0 5px 5px !important;
      }
    }
    li:last-child {
      border-right: 0;
    }
    li::before {
      content: '' !important;
      color: ${yellowColor};
      font-size: 15px;
      margin-right: 10px;
    }
  }
`;

/*** Strongest Tiles ***/
export const StrongestWrapper = styled.div`
  width: 100%;
  display: flex;
  float: left;

  .tiles-hover {
    display: none;
    position: absolute;
    background: ${darkgrey};
    color: ${whiteColor};
    font-weight: 400;
    text-align: left;
    font-size: 12px;
    border-radius: 5px;
    right: 1px;
    width: 55%;
    padding: 10px;
    top: 1px;
  }

  .tiles-box:hover .tiles-hover {
    display: flex;
    align-items: center;
  }
  .tiles-box {
    width: 24%;
    float: left;
    margin-right: 16px;    
    background: #fff;
    border-radius: 8px;    
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    ${media.xs`    
        width: 47.5%;
        overflow: visible;
        margin-bottom: 15px;
        :nth-child(2) {
            margin-right: 0px;
          }
    `}
  }
  .ms-tiles-box {
    cursor: pointer;
  }
  .tiles-box .header {
    background: ${teal};
    width: 100%;
    box-sizing: border-box;
    padding: 15px 8px;
    text-align: center;
    color: #fff;
    font-weight: 900;
    font-size: 12px;
    ${media.md`
    padding: 10px 8px;
    `}
    ${media.lg`
    padding: 10px 8px;
    `}
    ${media.xl`
    padding: 15px 8px;
    `}
  }
  .tiles-box:nth-child(1) .header {
    background: ${lightPink};
  }
  .tiles-box:nth-child(2) .header {
    background: ${purple};
  }
  .tiles-box:nth-child(3) .header {
    background: ${mandy};
  }

  .tiles-box:last-child {
    margin-right: 0;
  }
  .big-number,
  .survey-results {
    text-align: center;    
    font-size: 36px;
    display: block;
    color: #000;
    font-weight: 900;   
    margin-top: 30px;     
    ${media.md`
    font-size: 25px;
    `}
    ${media.lg`
    font-size: 25px;
    `}
    ${media.xl`
    font-size: 36px;
    `}
  }
  .previous-overall-score{
    font-size: medium;
  }

  .heat-map-container {
    position: absolute;
    top: 0;
    left: 0;
    background: ${always};
    height: 100%;
    width: 100%;
    border: 1px solid #E586B0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;    
    cursor: pointer;
    z-index: 2;
    margin-top: 0;    
    span {      
      color: ${blackColor};
      font-size: 18px;      
      font-weight: 600;
      padding: 0 22px;
    }
  }

  .survey-results {
    font-size: 14px;        
    ${media.xl`
      font-size: 18px;      
    `}
  }

  .comming-soon {
    padding: 10px 10px 0 10px;
    font-size: 25px;
    color: ${lightGray};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 57%;
    font-weight: 900;
    ${media.md`
    font-size: 17px;
    opacity: .3;
    `}
    ${media.lg`
    font-size: 17px;
    opacity: .3;
    `}
    ${media.xl`
    font-size: 25px;
    opacity: .3;
    `}
  }
  .percentage {
    visibility: hidden;
    font-size: 10px;
    color: ${cornflowerBlue};
    font-weight: 900;
    padding: 0 10px 10px 10px;
    span {
      display: inline-block;
    }
  }
  .red-number {
    color: ${mandy};
  }
  .school-decile,
  .hse-decile {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600;
    padding: 10px 0px 0px 0px;    
    span {
      color: ${blackColor};
      span{
        font-size: 11px;
      }
    }
  }
  .hse-decile {
    padding: 5px 0px 0px 0px;
  }
  .arrow-up {
    display: none !important;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin-right: 3px;
    margin-left: 2px;
    margin-bottom: 2px;
    border-bottom: 5px solid ${cornflowerBlue};
  }
  .arrow-down {
    display: none;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin-right: 3px;
    margin-left: 2px;
    margin-bottom: 0;
    border-top: 5px solid ${mandy};
  }
  .not-available {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${mandy};
    font-weight: 900;
  }

  ${media.xs`    
  display: inline-block;
  `}
`;
export const HeaderWrapper = styled.div`
  background: ${({ value }) => value};
  background: -moz-linear-gradient(top,  ${({ value }) => value} 0%, #ffffff 80%, #ffffff 100%);
  background: -webkit-linear-gradient(top,  ${({ value }) => value} 0%,#ffffff 80%,#ffffff 100%);
  background: linear-gradient(to bottom,  ${({ value }) => value} 0%,#ffffff 80%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${({ value }) => value}', endColorstr='#ffffff',GradientType=0 );
  border: 1px solid ${({ value }) => value};    
  width: 100%;  
  height: 100%;
  padding: 15px;  
  color: #fff;
  font-weight: 900;  
  font-size: 16px;  
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  ${media.xs`
    min-height: 210px;
  `}
`;

/*** Survey Link ***/
export const SurveyLinkWrapper = styled.div`
  .mainWrapper {
    border-style: solid;
    height: 47px;
    background-color: white;
    border-width: 1px;
    margin-bottom: 15px;
  }

  .survey-lnk {
    width: 85%;
    float: left;
    padding: 0% 0% 0% 1%;
  }
  .copy_link {
    cursor: pointer;
    float: right;
    color: black;
    padding: 13px 15px;    
    text-decoration: none;
    background: #45338C;
    color: #ffffff;
  }
`;

/*** Voice Dashboard ***/
export const ExtendedVoiceDashboardWrapper = styled(VoiceDashboardWrapper)`
  .content-body-inner-wrapper {  
    float:left;
    .disabled-replay-btn, .disabled-flag-btn{
          background: url(${replydisabled});
          background-color: ${lighterGrey};
          cursor: auto;
          position: relative;
          border-radius: 50%;
          :hover {
            background: url(${replydisabled});
            background-color: ${lighterGrey};
            background-repeat: no-repeat;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }
        }

        .disabled-flag-btn{
          background: url(${flagdisabled});
          background-color: ${lighterGrey};
          :hover {
            background: url(${flagdisabled});
            background-color: ${lighterGrey};
          }
        }
 .tooltip{
          position: relative;
          .tooltiptext {
            display: none;
            width: 120px;
            background-color: ${pictonBlue};
            color: ${whiteColor};
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 90%;
            left: -45%;
            opacity: 0;
            transition: opacity 0.7s;
            :after {
              content: '';
              position: absolute;
              top: 100%;
              left: 20%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: ${pictonBlue} transparent transparent transparent;
            }
          }
          :hover .tooltiptext {
            display: inline-block;
            opacity: 1;
          }
        }
  }
`;
export const ExtendedVoiceNextPreviousButtons = styled(VoiceNextPreviousButtons)`
.Buttons-wrapper {
  margin: 0px auto -24px auto;
.Previous-btn {
  top: -46px;
  left: 0;
  pedding-right: 5px;
  }
  .next-btn {
    top: -46px;
    left: 0;
    pedding-left: 5px;
  }
}
`;
export const ExtendedAssistanceWrapper = styled(AssistanceWrapper)`
  width: 100%;
  float: left;  
  .latest-survey{	
    .selected-option {
      border: 0;
      font-size: 14px;
      font-weight: 500;
      img {
        margin-left: 17px;
        width: 12px;
        margin-top: 6px;
      }
    }
    .options{
      border-radius: 5px;
      background: #45338C;
      color: ${whiteColor};
      padding: 10px 0;
      margin: -10px 0 0 0;
      //box-shadow: 3px 3px 10px rgba(0,122,122,0.12);
      z-index: 9999;
      max-height: inherit;
      position: absolute;
      width: 100%;
      span{
        color: ${whiteColor};
        font-size: 12px;	
        padding: 7px 11px 7px 26px;
      }
    }
    .active-option{
      background: #45338C;
      color: ${whiteColor};		
    }
    float: right;
    margin-right: 8px;
    > div{
      margin: 0;
      border-radius: 8px;
      min-height: 45px;
      min-width: 123px;
      background: #45338C;
      color: ${whiteColor} !important;      
      .selected-option {
        min-height: 45px;
        padding: 13px 25px;  
        label {
          color: ${whiteColor} !important;
        }
      }            
    }
    position: relative;    
  }
  .back-btn{
    float:right;
    background: #45338C;
    color: ${whiteColor};
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 10px 28px;
    text-decoration: none;
    text-transform: capitalize;
  }
`;
export const ExtendedSearchFilterWrapper = styled(SearchFilterWrapper)`
  .subcontainer-inner {    
    margin: auto;
    .search-filed {
      width: 75%;
    }
  }
  margin: 22px 0 20px 0;
  .search-sorting {
    .selected-option {
      background-image: url(${filterIcon});      
    }
  }
  .search-filters {
    background: url(${flaginactivewithoutcircle}) no-repeat center center;
    width: 5%;
    padding: 3px 0;
    height: 40px;
    background-size: contain;
    cursor: pointer;
    :hover {
      background-size: contain;
    }

    img {
      width: 38px;
      margin-top: 5px;
    }
  }
  .active {
    background: url(${flagactivewithoutcircle}) no-repeat center center;
    background-size: contain;
  }
`;

/*** Active Survey ***/
export const ExtendedReadActionPlan = styled(ReadActionPlan)`
  position: absolute;
  bottom: 8px;
  width: 93%;
  background: transparent;
  padding-top: 8px;
  border-top: 1px solid ${bordergrey};
  .surveyLink {
    .toolTipText {
      display: none;
      max-width: 200px;
      background-color: ${pictonBlue};
      color: ${whiteColor};
      font-weight: 400;
      font-size: 14px !important;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      top: 80%;
      left: 0%;
    }
    :hover .toolTipText {
      display: block;
    }    
  }
  .close-survey-button {
    position: relative;    
    float: right;
    font-size: 14px !important;    
    color: ${pictonBlue};
  }
  a {
    background: transparent;
    border: 0;    
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    color: ${pictonBlue};
    text-decoration: none;
    padding: 0;
    ${media.md`     
      display: inline-block;
    `}
  }  
`;
export const ActiveSurveyWrapper = styled.div`
  h2{
    font-size: 20px !important;
    margin-top: 0;
  }
  .manual-survey-btn {
    button {
      float: right;
      background: transparent;
      border: 1px solid ${pictonBlue};
      color: ${pictonBlue};
      font-size: 13px;
      font-weight: 700;
      border-radius: 20px;
      padding: 10px;      
      cursor: pointer;
    }
  }
  
  .week-heading {
    color: ${outrageousOrange};
    font-size: 24px;
    font-weight: 900;
    padding: 30px 0 0 0;    
    span.weeks {
      min-height: 20px;
    }
    span {
      display: block;
    }
    .deadline {
      color: ${lightGray};
      font-size: 10px;
      font-weight: 400;
      margin-top: 5px;      
    }
    ${media.md`
    padding: 1px 0 0 0;
    `}
    ${media.lg`
    padding: 1px 0 0 0;
    `}
    ${media.xl`
    padding: 30px 0 0 0;
    `}
  }

  .progress-with-startdate {
    margin: 24px 0 24px 0;
  }
  .progress-wrapper {
    .progress-bar {
      width: 100%;
      height: 4px;
      background: rgba(123, 123, 123, 0.3);
      margin-top: ${({ isSurveyClosed }) => isSurveyClosed};
      margin-bottom: 6px;
      ${media.md`
      margin-top: 0;
      `}
      /* ${media.lg`
      margin-top: 6px;
      `} */
      ${media.xl`
      margin-top: 20px;
      `}
      .progress-fill {
        width: ${({ value }) => value};
        display: block;
        background: ${outrageousOrange};
        height: 4px;
      }
    }
    .start {
      color: ${often};
      font-size: 12px;
      float: left; 
      font-weight: 500;     
    }
    .end {
      color: ${often};
      font-size: 12px;
      float: right;
      font-weight: 500;
    }
  }
  .survey-description {
    float: left;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    color: ${blackColor};
    font-weight: 600;    
    span {
      display: inline-block;
      font-weight: 900;
      color: ${blackColor};
      line-height: 18px;
      margin: 0 5px 0 5px;
    }
    span:nth-child(1) {
      margin-left: 0;
    }
  }
  .survey-completion {
    float: left;
    width: 100%;
    margin-top: 26px;
    font-size: 16px;
    font-weight: 900;    
    color: ${yourpink};
    ${media.md`
      margin-top: 0px;
    `}
    ${media.lg`
      margin-bottom: 10px;
      margin-top: 0;
    `}
    ${media.xl`
      margin-top: 26px;
    `}
    span {
      display: block;
      color: ${lightGray};
      line-height: 18px;
      font-weight: 400;
      font-size: 10px;      
      margin: 0 5px 0 5px;
      ${media.lg`
      line-height: 11px;
      `}
      ${media.xl`
      line-height: 18px;
      `}
    }
  }
  .not-available {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${mandy};
    font-weight: 900;
    padding-top: 18%;
    ${media.xl`
    padding-top: 18%;
    `}
  }
`;

/*** Associated Actions ***/
export const ExtendedUpcomingTasksWrapper = styled(UpcomingTasksWrapper)` 
  height: 170px;
  overflow-y: auto!important;
  ${media.lg`
    height: 150px;
  `}
  ${media.xl`
    height: 165px;
  `}
  .task-list-wrapper {
    margin-top: 0;
    padding: 1px 0 1px 0;
    font-size: 14px;
  }
  .task-list {  
    padding: 5px 0 !important;
  }
`;

/*** AutoComplete ***/
export const AutocompleteWrapper = styled.div`
  position: relative;
  input {
    border: 1px solid transparent;
    padding: 0.5rem;
    width: 300px;
  }

  .no-suggestions {
    color: ${mandy};
    position: absolute;
    top: 23px;
    font-size: 11px;
    font-weight: 900;    
    padding: 11px 19px;
  }

  .suggestions {
    background: ${whiteColor};
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    border-radius: 6px;
    min-width: 126px;
    position: absolute;
    right: 0;
    z-index: 1;
    padding: 12px 0 12px;
    top: 19px;
    height: 221px;
    overflow-y: auto;
  }

  .suggestions li {
    padding: 0.8rem 0.5rem;
    font-size: 12px;    
    font-weight: 400;
    text-align: left;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: ${teal};
    color: ${whiteColor};
    cursor: pointer;
    font-size: 12px;    
    font-weight: 400;
  }
`;

export const ResendSurveyLink = styled.div`
.surveyLink {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: ${teal};
  .toolTipText {
    display: none;
    max-width: 200px;
    background-color: ${pictonBlue};
    color: ${whiteColor};
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0%;
  }
  :hover .toolTipText {
    display: block;
  }    
}
`;

const DashboardStyle = {
  CommentsMainWrapper,
  ExtendedSubContainer,
  ChartBar,
  ExtendedChartWrapper,
  SuperExtendedSubContainer,
  ExtendedDashboardMainWrapper,
  GroupDashboardMainWrapper,
  ExtendedCalculationsStyle,  
  InstructionWrapper,
  CalculationsStyle,
  StrongestWrapper,
  HeaderWrapper,
  SurveyLinkWrapper,
  ExtendedVoiceDashboardWrapper,
  ExtendedVoiceNextPreviousButtons,
  ExtendedAssistanceWrapper,
  ExtendedSearchFilterWrapper,
  ExtendedReadActionPlan,
  ActiveSurveyWrapper,
  ExtendedUpcomingTasksWrapper,
  AutocompleteWrapper,
  SuperExtendExtendedSubContainer,
  GroupCommentsMainWrapper,
  ResendSurveyLink
}
export default DashboardStyle;