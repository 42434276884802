import React from 'react';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';
import { useTranslation } from 'react-i18next';
import { storageService } from '../../../services';
import { WebStorageNames } from '../../../utils';

const getHeatmapCellMd = (level, value, isDecile) => {
  return (
    <div
      className={
        level <= 3 ? 'width-20-green' : level >= 7 ? 'width-20-red' : 'width-10'
      }
    >
      {value >= 1 ? (
        <>
          {' '}
          {value.toFixed(2)}
          <span className='score-superscript'>
            &nbsp;({level}
            <sup>
              {level == 1 ? 'st' : level == 2 ? 'nd' : level == 3 ? 'rd' : 'th'}
            </sup>
            )
          </span>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};

const UsRegion = () => {
  const usRegion = storageService.getItem(WebStorageNames.UsRegion);
  return usRegion;
};

const ScoresbyJobRolePpt = (props) => {
  const { t } = useTranslation();
  const groupType = storageService.getItem('groupType');
  const GroupName = groupType || t('MAT');

  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          {/* {props.SchoolName} {'MAT Presentation'}
                    <AppLogo type="overview" color={colors.lightPink} width="100" /> 
                      */}
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          <h1 className='matpres-title'>{props.title}</h1>

          <div id='score-job'>
            <p className='section'>
              The average scores for participants in each of the core job role
              filters are shown in heatmap 2 below. Again comparatively high
              scores are shaded green and comparatively lower ones red. Those
              without shading are close to average. Using heatmaps in your{' '}
              {GroupName} Dashboard you can apply further filters to identify
              where scores may be higher and lower.
            </p>
            <p className='table-title'>Heatmap 2: Scores by Job Role</p>
            <div className='section'>
              <div className='job-role-container'>
                <div style={{ width: '25%', float: 'left' }}>&nbsp;</div>
                <div className='width-10'>Demands</div>
                <div className='width-10'>Control</div>
                <div className='width-10 title-txt'>Manager Support</div>
                <div className='width-10 title-txt'>Peer Support</div>
                <div className='width-10'>Relationships</div>
                <div className='width-10'>Role</div>
                <div className='width-10'>Change</div>
              </div>
              <div style={{ width: '100%' }}>
                {props.heatmapByRole.map((row) => {
                  return (
                    <div className='job-role-container'>
                      <div
                        style={{ width: '25%', marginLeft: '0px' }}
                        className='job-role-title-cell'
                      >
                        {UsRegion() === 'us' && row.Name === 'Senior Leader'
                          ? 'District Administrator'
                          : UsRegion() === 'us' && row.Name === 'Middle Leader'
                          ? 'Building Administrator'
                          : row.Name}
                      </div>
                      {getHeatmapCellMd(row.DemandDecile, row.Demand, false)}
                      {getHeatmapCellMd(row.ControlDecile, row.Control, false)}
                      {getHeatmapCellMd(
                        row.ManagerSupportDecile,
                        row.ManagerSupport,
                        false
                      )}
                      {getHeatmapCellMd(
                        row.PeerSupportDecile,
                        row.PeerSupport,
                        false
                      )}
                      {getHeatmapCellMd(
                        row.RelationshipsDecile,
                        row.Relationships,
                        false
                      )}
                      {getHeatmapCellMd(row.RoleDecile, row.Role, false)}
                      {getHeatmapCellMd(row.ChangeDecile, row.Change, false)}
                    </div>
                  );
                })}
              </div>
            </div>
            <p className='section-bottom'>
              * Heatmaps of all filtered scores can be viewed and downloaded
              from your {GroupName} dashboard.
            </p>
          </div>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default ScoresbyJobRolePpt;
