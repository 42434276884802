import {
  months,
  DateFormats,
  WelbeeTypes,
  ManagmentStandards,
} from './Constants';
import amplitude from 'amplitude-js';
import moment from 'moment';
import { storageService } from '../services';
import {
  WebStorageNames,
  WelbeeVoiceAccountType,
  FreePeriodEndingDate,
  BannerDisplayDate,
  StaffTypes,
} from '../utils';
import { colors } from '../resources/theme';
import { subtract } from 'lodash';
import { useTranslation } from 'react-i18next';
const { never, seldom, sometimes, often, always } = colors;

export const getQueryStringVal = (key) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let val = params.get(key);
  return val;
};

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const DateFormatWithMonth = (date) => {
  if (date === null) return;
  const SubmissionDate = new Date(date);
  let day = SubmissionDate.getDate();
  let month = months[SubmissionDate.getMonth()];
  if (day < 10) {
    return `0${day} ${month}, `;
  } else {
    return `${day} ${month}, `;
  }
};

export const DateFormat = (date, format = DateFormats.format_31_12_2019) => {
  if (date) {
    let parsedDate = moment(date);
    let formattedDate = parsedDate.format(format);
    return formattedDate;
  }
};

export const ConvertStringToDateFormat = (
  date,
  format = DateFormats.format_31_12_2019
) => {
  if (date) {
    var isDateContainTh = date.includes('th');
    if (isDateContainTh) {
      var dt = date.replace('th', '');
      var dateObj = new Date(dt);
      let parsedDate = moment(dateObj);
      let formattedDate = parsedDate.format(format);
      return formattedDate;
    }
    return date;
  }
};

export const DateFormatLocalTime = (date) => {
  if (date) {
    //commenting this code because are now not adding offset for survey Created Datetime
    // US survey are not creating properly.
    // let hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
    // let minutesDiff = (date.getHours() - date.getTimezoneOffset()) % 60;
    // date.setHours(hoursDiff);
    // date.setMinutes(minutesDiff);
    // var x = date.toJSON();
    var x = moment(date).format();
    return x;
  }
};

export const amplitudeLog = (groupName, eventName, data) => {
  let instance = amplitude.getInstance();
  instance.setGroup(groupName);

  let schoolId = storageService.getItem(WebStorageNames.SchoolId);
  let schoolName = storageService.getItem(WebStorageNames.SchoolName);

  if (schoolName && schoolId > 0) {
    instance.setUserProperties({
      SchoolId: schoolId,
      SchoolName: schoolName,
    });
  } else if (schoolName) {
    instance.setUserProperties({
      SchoolName: schoolName,
    });
  } else if (schoolId > 0) {
    instance.setUserProperties({
      SchoolId: schoolId,
    });
  }

  instance.logEvent(eventName, { data });
};

export const getJobRole = (jobRole) => {
  if (jobRole === 1 || jobRole === 4 || jobRole === 8) return 'Teaching staff';
  else if (jobRole === 2 || jobRole === 16 || jobRole === 32)
    return 'Support staff';
  else if (jobRole === 64) return 'Leader';
  else if (jobRole === 128) return 'Leader';

  return 'Teaching staff';
};

export const totalNoOfPage = (record) => {
  return Math.ceil(record / 10);
};

export const setTextLengthLimit = (text, length = 50) => {
  if (!text) return '';
  if (text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
};

export const getTransformedSurveyRecordList = (
  surveyRecordList,
  schoolToken = null
) => {
  const transformedRecordList = [];
  for (let i = 0; i < surveyRecordList.length; i++) {
    const item = surveyRecordList[i];
    if (item.label.length > 30) {
      const label =
        item.label.substring(0, 15) +
        '...' +
        item.label.substring(item.label.length - 12);
      transformedRecordList.push({
        label: label,
        value: item.value,
        schoolToken: schoolToken,
      });
    } else {
      item.schoolToken = schoolToken;
      transformedRecordList.push(item);
    }
  }
  return transformedRecordList;
};

export const getInvoicePrice = (numberOfPupil, subscriptionId) => {
  let price = 247;

  if (
    window.location.pathname === '/register-voice-pro' ||
    subscriptionId === WelbeeTypes.WelbeeVoicePro
  ) {
    if (numberOfPupil <= 200) price = 97;
    else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 147;
    else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 197;
    else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 247;
    else if (numberOfPupil > 1500) price = 297;
  }
  if (
    window.location.pathname === '/register-survey-pro' ||
    subscriptionId === WelbeeTypes.WelbeeSurveyPro
  ) {
    if (numberOfPupil <= 200) price = 247;
    else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 347;
    else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 447;
    else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 547;
    else if (numberOfPupil > 1500) price = 647;
  }
  if (
    window.location.pathname === '/register-survey-premium' ||
    subscriptionId === WelbeeTypes.WelbeeSurveyPremium
  ) {
    if (numberOfPupil <= 200) price = 397;
    else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 597;
    else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 797;
    else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 997;
    else if (numberOfPupil > 1500) price = 1197;
  }
  return price;
};

export const getInvoicePriceForUpgrade = (
  numberOfPupil,
  oldSubscriptionId,
  newSubscriptionId
) => {
  let IsUpgradeFromFree =
    oldSubscriptionId === WelbeeTypes.WelbeeVoiceFree ||
    oldSubscriptionId === WelbeeTypes.WelbeeSurveyFree;
  let price = 247;
  if (IsUpgradeFromFree) {
    if (newSubscriptionId === WelbeeTypes.WelbeeVoicePro) {
      if (numberOfPupil <= 200) price = 97;
      else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 147;
      else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 197;
      else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 247;
      else if (numberOfPupil > 1500) price = 297;
    }
    if (newSubscriptionId === WelbeeTypes.WelbeeSurveyPro) {
      if (numberOfPupil <= 200) price = 247;
      else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 347;
      else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 447;
      else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 547;
      else if (numberOfPupil > 1500) price = 647;
    }
    if (newSubscriptionId === WelbeeTypes.WelbeeSurveyPremium) {
      if (numberOfPupil <= 200) price = 397;
      else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 597;
      else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 797;
      else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 997;
      else if (numberOfPupil > 1500) price = 1197;
    }
  } else {
    if (
      newSubscriptionId === WelbeeTypes.WelbeeSurveyPro &&
      oldSubscriptionId === WelbeeTypes.WelbeeVoicePro
    ) {
      if (numberOfPupil <= 200) price = 150;
      else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 200;
      else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 250;
      else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 300;
      else if (numberOfPupil > 1500) price = 350;
    }
    if (
      newSubscriptionId === WelbeeTypes.WelbeeSurveyPremium &&
      oldSubscriptionId === WelbeeTypes.WelbeeVoicePro
    ) {
      if (numberOfPupil <= 200) price = 300;
      else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 450;
      else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 600;
      else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 750;
      else if (numberOfPupil > 1500) price = 900;
    }
    if (
      newSubscriptionId === WelbeeTypes.WelbeeSurveyPremium &&
      oldSubscriptionId === WelbeeTypes.WelbeeSurveyPro
    ) {
      if (numberOfPupil <= 200) price = 150;
      else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 250;
      else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 350;
      else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 450;
      else if (numberOfPupil > 1500) price = 550;
    }
  }
  return price;
};

export const getAdditionalSurveyPrice = (numberOfPupil) => {
  let price = 50;
  if (numberOfPupil >= 750) price = 100;
  return price;
};

export const getInvoicePriceBySubscription = (
  numberOfPupil,
  subscriptionId
) => {
  let price = 247;
  if (
    window.location.pathname === '/register-voice-pro' ||
    subscriptionId === WelbeeTypes.WelbeeVoicePro
  ) {
    if (numberOfPupil <= 200) price = 97;
    else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 147;
    else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 197;
    else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 247;
    else if (numberOfPupil > 1500) price = 297;
  }
  if (
    window.location.pathname === '/register-survey-pro' ||
    subscriptionId === WelbeeTypes.WelbeeSurveyPro
  ) {
    if (numberOfPupil <= 200) price = 247;
    else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 347;
    else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 447;
    else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 547;
    else if (numberOfPupil > 1500) price = 647;
  }
  if (
    window.location.pathname === '/register-survey-premium' ||
    subscriptionId === WelbeeTypes.WelbeeSurveyPremium
  ) {
    if (numberOfPupil <= 200) price = 397;
    else if (numberOfPupil >= 201 && numberOfPupil <= 500) price = 597;
    else if (numberOfPupil >= 501 && numberOfPupil <= 1000) price = 797;
    else if (numberOfPupil >= 1001 && numberOfPupil <= 1500) price = 997;
    else if (numberOfPupil > 1500) price = 1197;
  }
  return price;
};

export const isEnable = () => {
  const welbeeType = storageService.getItem(
    WebStorageNames.WelbeeVoiceAccountType
  );
  if (welbeeType === WelbeeVoiceAccountType.Paid) return true;
  else {
    const freePeriodEndingDate = new Date(FreePeriodEndingDate);
    const currentDate = new Date();
    if (currentDate < freePeriodEndingDate) return true;
    return false;
  }
};

export const isVoicePaid = (accountType, trialEndDate) => {
  if (accountType === WelbeeVoiceAccountType.Paid) {
    return true;
  } else {
    const freePeriodEndingDate = new Date(trialEndDate);
    const currentDate = new Date();
    if (currentDate < freePeriodEndingDate) return true;
    return false;
  }
};

export const isUpgradeVoideDisplay = (freeTrialEndDate) => {
  const welbeeAccountType = storageService.getItem(
    WebStorageNames.WelbeeVoiceAccountType
  );
  if (welbeeAccountType === WelbeeVoiceAccountType.Paid) return false;
  const isUpgradeDisplay = storageService.getItem(
    WebStorageNames.IsUpgradeDisplay
  );
  if (!isUpgradeDisplay) return false;
  const upgradeBannerDisplayDate = new Date(BannerDisplayDate);
  const bannerLifeEndingDate = new Date(freeTrialEndDate);
  const currentDate = new Date();
  return (
    currentDate >= upgradeBannerDisplayDate &&
    currentDate < bannerLifeEndingDate
  );
};

export const freePeriodNoOfDaysLeft = (freeTrailEndDate) => {
  const freePeriodEndingDate = new Date(freeTrailEndDate);
  const currentDate = new Date();
  const timeDifference = freePeriodEndingDate.getTime() - currentDate.getTime();
  return Math.floor(timeDifference / (1000 * 3600 * 24));
};

export const getLoggedUserId = () => {
  var userInfo = storageService.getItem(WebStorageNames.UserInfo);
  if (userInfo !== null) return userInfo.Id;
  return '';
};

export const getVoiceDate = (voiceDate = new Date()) => {
  const date = new Date(voiceDate);
  let month = date.getMonth() + 1;
  let year = date.getFullYear().toString();
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }

  return day + '-' + month + '-' + year.substr(2);
};

export const getTransformedSchoolRecordList = (surveyRecordList) => {
  const transformedRecordList = [];
  for (let i = 0; i < surveyRecordList.length; i++) {
    const item = surveyRecordList[i];
    if (item.Name.length > 30) {
      const name =
        item.Name.substring(0, 15) +
        '...' +
        item.Name.substring(item.Name.length - 12);
      transformedRecordList.push({
        label: name,
        value: item.Id,
        schoolToken: item.SchoolToken,
      });
    } else
      transformedRecordList.push({
        label: item.Name,
        value: item.Id,
        schoolToken: item.SchoolToken,
      });
  }
  return transformedRecordList;
};

export const getLikertOptions = (likertScale) => {
  switch (likertScale) {
    case 0:
      return [
        { Id: 1, Answer: 'Never', Color: never, Selected: false },
        { Id: 2, Answer: 'Seldom', Color: seldom, Selected: false },
        { Id: 3, Answer: 'Sometimes', Color: sometimes, Selected: false },
        { Id: 4, Answer: 'Often', Color: often, Selected: false },
        { Id: 5, Answer: 'Always', Color: always, Selected: false },
      ];
    case 1:
      return [
        { Id: 1, Answer: 'Strongly disagree', Color: never, Selected: false },
        { Id: 2, Answer: 'Disagree', Color: seldom, Selected: false },
        { Id: 3, Answer: 'Neutral', Color: sometimes, Selected: false },
        { Id: 4, Answer: 'Agree', Color: often, Selected: false },
        { Id: 5, Answer: 'Strongly agree', Color: always, Selected: false },
      ];
    case 2:
      return [
        { Id: 1, Answer: 'Very dissatisfied', Color: never, Selected: false },
        { Id: 2, Answer: 'Dissatisfied', Color: seldom, Selected: false },
        { Id: 3, Answer: 'Neutral', Color: sometimes, Selected: false },
        { Id: 4, Answer: 'Satisfied', Color: often, Selected: false },
        { Id: 5, Answer: 'Very satisfied', Color: always, Selected: false },
      ];
    case 3:
      return [
        { Id: 1, Answer: 'Not important', Color: never, Selected: false },
        { Id: 2, Answer: 'Slightly Important', Color: seldom, Selected: false },
        {
          Id: 3,
          Answer: 'Fairly Important',
          Color: sometimes,
          Selected: false,
        },
        { Id: 4, Answer: 'Important', Color: often, Selected: false },
        { Id: 5, Answer: 'Very important', Color: always, Selected: false },
      ];
    case 4:
      return [
        { Id: 1, Answer: 'For next term', Color: never, Selected: false },
        {
          Id: 2,
          Answer: 'For at least a year',
          Color: seldom,
          Selected: false,
        },
        {
          Id: 3,
          Answer: 'For at least 3 years',
          Color: sometimes,
          Selected: false,
        },
        {
          Id: 4,
          Answer: 'For at least 5 years',
          Color: often,
          Selected: false,
        },
        { Id: 5, Answer: 'I’ll retire here', Color: always, Selected: false },
      ];
    default:
      return [
        { Id: 1, Answer: 'Never', Color: never, Selected: false },
        { Id: 2, Answer: 'Seldom', Color: seldom, Selected: false },
        { Id: 3, Answer: 'Sometimes', Color: sometimes, Selected: false },
        { Id: 4, Answer: 'Often', Color: often, Selected: false },
        { Id: 5, Answer: 'Always', Color: always, Selected: false },
      ];
  }
};

export const SignUpStatus = (value) => {
  return value === 1
    ? 'School Detail'
    : value === 2
    ? 'Invoice Detail'
    : value === 3
    ? 'Completed'
    : value === 5
    ? 'Personal Detail'
    : value === 4
    ? 'Free Voice Detail'
    : value === 6
    ? 'Email Verification'
    : '';
};

export const chartColors = (value) => {
  return value === 0
    ? [
        '#45338c',
        '#e84f6b',
        '#007a7a',
        '#53b5e0',
        '#fd92c2',
        '#ffb205',
        '#ff5939',
        '#E3BDA3',
      ]
    : value === 1
    ? ['#45338c']
    : value === 2
    ? ['#e84f6b']
    : value === 3
    ? ['#007a7a']
    : value === 4
    ? ['#53b5e0']
    : value === 5
    ? ['#fd92c2']
    : value === 6
    ? ['#ffb205']
    : value === 7
    ? ['#ff5939']
    : value === 8
    ? ['#E3BDA3']
    : [
        '#45338c',
        '#e84f6b',
        '#007a7a',
        '#53b5e0',
        '#fd92c2',
        '#ffb205',
        '#ff5939',
        '#E3BDA3',
      ];
};

const UsRegion = () => {
  const usRegion = storageService.getItem(WebStorageNames.UsRegion);
  return usRegion;
};
export const getStaffJobRole = (params) => {
  let jobRoles = [];
  params.row.coreFilters.map((item) => {
    switch (item.JobRole) {
      case StaffTypes.TeacherWorkingInside:
        jobRoles.push('Teaching Staff (Classroom)');
        break;
      case StaffTypes.TeacherWorkingOutside:
        jobRoles.push('Teaching Staff (Non-Classroom)');
        break;
      case StaffTypes.SupportStaffWorkingInside:
        jobRoles.push('Support Staff (Classroom)');
        break;
      case StaffTypes.SupportStaffWorkingOutside:
        jobRoles.push('Support Staff (Non-Classroom)');
        break;
      case StaffTypes.MiddleLeader: {
        if (UsRegion()) {
          jobRoles.push('Building Administrator');
        } else {
          jobRoles.push('Middle Leader');
        }
        break;
      }
      case StaffTypes.SeniorLeader: {
        if (UsRegion()) {
          jobRoles.push('District Administrator');
        } else {
          jobRoles.push('Senior Leader');
        }
        break;
      }
      default:
        jobRoles.push('');
        break;
    }
  });
  return `${jobRoles.sort((a, b) => (a > b ? 1 : -1)).filter(onlyUnique)}`;
};
export const getStaffJobTitle = (params) => {
  let jobTitles = [];
  params.row.coreFilters.map((item) => {
    if (item.JobTitle !== null) {
      jobTitles.push(' ' + item.JobTitle);
    }
  });
  return `${jobTitles.sort((a, b) => (a > b ? 1 : -1)).filter(onlyUnique)}`;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const getSelectedRow = (params) => {
  const api = params.api;
  const thisRow = {};
  api
    .getAllColumns()
    .filter((c) => c.field !== '__check__' && !!c)
    .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
  return thisRow;
};

export const combineAllHeatMapModels = (data, selectedFilterName) => {
  const {
    AllSchools,
    AllStaff,
    JobRole,
    TimeAtSchool,
    EmploymentType,
    AdditionalFilters,
  } = data || {};

  const heatMapModels = [AllStaff];

  ///Loop through Core Filters' HeatMapModel
  [JobRole, TimeAtSchool, EmploymentType].forEach((filter) => {
    if (filter?.HeatMapsModel) {
      filter.HeatMapsModel.forEach((model) => {
        let staffType;
        if (filter === JobRole) {
          staffType = `Job Role: ${model.StaffType}`;
        } else if (filter === TimeAtSchool) {
          staffType = `Time At School: ${model.StaffType}`;
        } else if (filter === EmploymentType) {
          staffType = `Employment Type: ${model.StaffType}`;
        }

        const customizedModel = {
          ...model,
          StaffType: model.ClassRoom
            ? `${staffType} ${model.ClassRoom}`
            : staffType,
        };
        heatMapModels.push(customizedModel);
      });
    }
  });

  // Loop through AdditionalFilters' HeatMapModel
  AdditionalFilters?.forEach((filter) => {
    filter.HeatMapModel?.forEach((model) => {
      const staffType = `${filter.FilterName}: ${model.StaffType}`;
      const customizedModel = {
        ...model,
        StaffType: model.ClassRoom
          ? `${staffType} ${model.ClassRoom}`
          : staffType,
      };
      heatMapModels.push(customizedModel);
    });
  });
  // For MAT Schools Filters
  if (selectedFilterName === 'MAT') {
    AllSchools?.SchoolHeatMaps?.forEach((school) => {
      const schoolName = school.Name;

      const addCombinedHeatMap = (schoolHeatMap, staffType, subType) => {
        const combinedHeatMap = {
          ...schoolHeatMap,
          StaffType: `${staffType}: ${schoolName}`,
          ClassRoom: null,
          SubType: `${subType}`,
        };
        heatMapModels.push(combinedHeatMap);
      };
      addCombinedHeatMap(
        school.HeatMap || {},
        'All Staff Scores By Schools',
        '(All Staff)'
      );
      addCombinedHeatMap(
        school?.HeatMapSupportStafClasroom || {},
        'Support Staff Classroom Scores by School',
        '(Support Staff Classroom)'
      );
      addCombinedHeatMap(
        school?.HeatMapSupportStafNonClasroom || {},
        'Support Staff Non-Classroom Scores by School',
        '(Support Staff Non-Classroom)'
      );
      addCombinedHeatMap(
        school?.HeatMapTeachingStafClasroom || {},
        'Teaching Staff Classroom Scores by School',
        '(Teaching Staff Classroom)'
      );
      addCombinedHeatMap(
        school?.HeatMapTeachingStafNonClasroom || {},
        'Teaching Staff Non-Classroom Scores by School',
        '(Teaching Staff Non-Classroom)'
      );
      addCombinedHeatMap(
        school?.HeatMapMiddleLeader || {},
        'Middle Leader Scores by School',
        '(Middle Leaders)'
      );
      addCombinedHeatMap(
        school?.HeatMapSeniorLeader || {},
        'Senior Leader Scores by School',
        '(Senior Leaders)'
      );
      addCombinedHeatMap(
        school?.HeatMapLessThan1Year || {},
        'Staff With Less Than 1 Yr Service Scores By School',
        '(< 1 YR)'
      );
      addCombinedHeatMap(
        school?.HeatMapWithin1to3Years || {},
        'Staff With 1-3 Yrs Service Scores By School',
        '(1-3 YRS)'
      );
      addCombinedHeatMap(
        school?.HeatMapWithin4to5Years || {},
        'Staff With 4-5 Yrs Service Scores By School',
        '(4-5 YRS)'
      );
      addCombinedHeatMap(
        school?.HeatMapWithin6to10Years || {},
        'Staff With 6-10 Yrs Service Scores By School',
        '(6-10 YRS)'
      );
      addCombinedHeatMap(
        school?.HeatMapWithin11to15Years || {},
        'Staff With 11-15 Yrs Service Scores By School',
        '11-15'
      );
      addCombinedHeatMap(
        school?.HeatMapWithin16to20Years || {},
        'Staff With 16-20 Yrs Service Scores By School',
        '(16-20 YRS)'
      );
      addCombinedHeatMap(
        school?.HeatMapGreaterThan20Years || {},
        'Staff With Greater Than 20 Yrs Service Scores By School',
        '> 20 YRS'
      );
      addCombinedHeatMap(
        school?.HeatMapFullTimeStaff || {},
        'Full Time Staff Scores By School',
        '(Full-Time)'
      );
      addCombinedHeatMap(
        school?.HeatMapPartTimeStaff || {},
        'Part Time Staff Scores By School',
        '(Part-Time)'
      );
    });
  }
  console.log(heatMapModels);
  return heatMapModels;
};

export const getCoreFilterList = (data, selectedFilterName) => {
  const result = [];

  const addOptions = (filterName, filterData, labelCallback) => {
    if (!Array.isArray(filterData)) {
      throw new Error(`Invalid filter data for ${filterName}`);
    }

    const filterOptions = filterData
      .filter((item) => item?.IsBenchMarkAvailable === true && item.Change > 0)
      .map((item) => {
        const usRegion = storageService.getItem(WebStorageNames.UsRegion);
        var label = labelCallback(item)?.trim();
        let value = `${filterName}: ${label}`.trim();
        if (usRegion === 'us' && label === 'Middle Leader') {
          label = 'Building Administrator';
          value = `${filterName}: ${labelCallback(item)?.trim()}`.trim();
        } else if (usRegion === 'us' && label === 'Senior Leader') {
          label = 'District Administrator';
          value = `${filterName}: ${labelCallback(item)?.trim()}`.trim();
        }
        //const value = `${filterName}: ${label}`.trim();
        return {
          label,
          value,
        };
      });

    if (filterOptions.length > 0) {
      filterOptions.sort((a, b) => a.value.localeCompare(b.value));
      result.push({
        label: filterName,
        options: filterOptions,
      });
    }
  };

  const addAllSchoolOptions = (filterData) => {
    if (!Array.isArray(filterData)) {
      throw new Error('Invalid filter data for schools');
    }

    const filterOptions = filterData.map((item) => {
      const label = `${item?.Name} (All Staff)`;
      const value = `All Staff Scores By Schools: ${item?.Name}`.trim();
      return {
        label,
        value,
      };
    });

    if (filterOptions.length > 0) {
      filterOptions.sort((a, b) => a.value.localeCompare(b.value));
      result.push({
        label: 'All Staff Scores By Schools',
        options: filterOptions,
      });
    }
  };

  const addSchoolOptionsByFilter = (
    heatMapData,
    staffType,
    label,
    subLabel
  ) => {
    if (heatMapData?.IsBenchMarkAvailable) {
      const value = `${staffType}: ${label}`.trim();
      const labelText = `${label} ${subLabel}`;
      const existingOption = result.find((entry) => entry.label === staffType);

      if (existingOption) {
        existingOption.options.push({
          label: labelText,
          value,
        });
      } else {
        result.push({
          label: staffType,
          options: [
            {
              label: labelText,
              value,
            },
          ],
        });
      }
    }
  };

  const filters = data?.AdditionalFilters || [];
  const allSchools = data?.AllSchools?.SchoolHeatMaps || [];

  addOptions('Job Role', data?.JobRole?.HeatMapsModel || [], (item) => {
    const classRoom = item?.ClassRoom === null ? '' : `${item?.ClassRoom}`;
    return `${item?.StaffType} ${classRoom}`;
  });

  addOptions(
    'Employment Type',
    data?.EmploymentType?.HeatMapsModel || [],
    (item) => {
      const classRoom = item?.ClassRoom === null ? '' : `${item?.ClassRoom}`;
      return `${item?.StaffType} ${classRoom}`;
    }
  );

  addOptions(
    'Time At School',
    data?.TimeAtSchool?.HeatMapsModel || [],
    (item) => {
      const classRoom = item?.ClassRoom === null ? '' : `${item?.ClassRoom}`;
      return `${item?.StaffType} ${classRoom}`;
    }
  );

  filters.forEach((filter) => {
    addOptions(filter.FilterName, filter.HeatMapModel || [], (item) => {
      const classRoom = item?.ClassRoom === null ? '' : `${item?.ClassRoom}`;
      return `${item?.StaffType} ${classRoom}`;
    });
  });

  if (selectedFilterName === 'MAT') {
    addAllSchoolOptions(allSchools);

    allSchools.forEach((school) => {
      addSchoolOptionsByFilter(
        school?.HeatMapSupportStafClasroom || {},
        'Support Staff Classroom Scores by School',
        school.Name,
        ' (Support Staff Classroom)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapSupportStafNonClasroom || {},
        'Support Staff Non-Classroom Scores by School',
        school.Name,
        ' (Support Staff Non-Classroom)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapTeachingStafClasroom || {},
        'Teaching Staff Classroom Scores by School',
        school.Name,
        ' (Teaching Staff Classroom)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapTeachingStafNonClasroom || {},
        'Teaching Staff Non-Classroom Scores by School',
        school.Name,
        ' (Teaching Staff Non-Classroom)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapMiddleLeader || {},
        'Middle Leader Scores by School',
        school.Name,
        ' (Middle Leaders)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapSeniorLeader || {},
        'Senior Leader Scores by School',
        school.Name,
        ' (Senior Leaders)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapLessThan1Year || {},
        'Staff With Less Than 1 Yr Service Scores By School',
        school.Name,
        ' (< 1 YR)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapWithin1to3Years || {},
        'Staff With 1-3 Yrs Service Scores By School',
        school.Name,
        ' (1-3 YRS)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapWithin4to5Years || {},
        'Staff With 4-5 Yrs Service Scores By School',
        school.Name,
        ' (4-5 YRS)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapWithin6to10Years || {},
        'Staff With 6-10 Yrs Service Scores By School',
        school.Name,
        ' (6-10 YRS)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapWithin11to15Years || {},
        'Staff With 11-15 Yrs Service Scores By School',
        school.Name,
        ' (11-15 YRS)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapWithin16to20Years || {},
        'Staff With 16-20 Yrs Service Scores By School',
        school.Name,
        ' (16-20)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapGreaterThan20Years || {},
        'Staff With Greater Than 20 Yrs Service Scores By School',
        school.Name,
        ' (> 20 YRS)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapFullTimeStaff || {},
        'Full Time Staff Scores By School',
        school.Name,
        ' (Full-Time)'
      );
      addSchoolOptionsByFilter(
        school?.HeatMapPartTimeStaff || {},
        'Part Time Staff Scores By School',
        school.Name,
        ' (Part-Time)'
      );
    });
  }

  result.sort((a, b) => a.options[0]?.value.localeCompare(b.options[0]?.value));

  return result;
};

export const filterCustomHeatmap = (data, selectedOptions) => {
  const selectedValues = selectedOptions.map((option) => option.value.trim());
  console.log(selectedValues);
  const filteredData = data
    .filter((model) => selectedValues.includes(model.StaffType.trim()))
    .map((model) => {
      if (model.StaffType.includes(':')) {
        return {
          ...model,
          StaffType:
            UsRegion() === 'us' &&
            model.StaffType.split(':').pop().trim() === 'Middle Leader'
              ? 'Building Administrator'
              : UsRegion() === 'us' &&
                model.StaffType.split(':').pop().trim() === 'Senior Leader'
              ? 'District Administrator'
              : model.StaffType.split(':').pop().trim(),
        };
      }
      return model;
    });

  return filteredData;
};

export const coreFiltersGroupMappings = {
  'Phase Or Year': 'PhaseOrYears',
  Department: 'Department',
  'Job Title': 'JobTitle',
  'Job Role': 'JobRole',
  'Employment Type': 'EmploymentType',
  'Time At School': 'TimeAtSchool',
  'All Staff Scores By Schools': 'Schools',
  'Teaching Staff Classroom Scores by School': 'TechingStaffBySchools',
  'Teaching Staff Non-Classroom Scores by School':
    'NonClassTeachingStaffBySchools',
  'Support Staff Classroom Scores by School': 'SupportStaffBySchools',
  'Support Staff Non-Classroom Scores by School':
    'NonClassSupportStaffBySchools',
  'Middle Leader Scores by School': 'MiddleLeaderBySchools',
  'Senior Leader Scores by School': 'LeaderBySchools',
  'Full Time Staff Scores By School': 'FullTimeBySchools',
  'Part Time Staff Scores By School': 'PartTimeBySchools',
  'Staff With Less Than 1 Yr Service Scores By School': 'Less1YearsbySchools',
  'Staff With 1-3 Yrs Service Scores By School': 'OneTo3YearsBySchools',
  'Staff With 4-5 Yrs Service Scores By School': 'FourT5yearsBySchools',
  'Staff With 6-10 Yrs Service Scores By School': 'SixTo10YearsBySchools',
  'Staff With 11-15 Yrs Service Scores By School': 'ElevenTo15YearsBySchools',
  'Staff With 16-20 Yrs Service Scores By School': 'SixteenTo20yearsBySchools',
  'Staff With Greater Than 20 Yrs Service Scores By School':
    'Greater20YearsBySchools',
};

export const SchoolPPTText = (region, category) => {
  if (region === 'us' && category === ManagmentStandards.All) {
    return 'The evidence built and independently evaluated survey measures against the UK’s Health and Safety Executive’s Management Standards for employers. These cover the six key areas of work design, that if not properly managed, are associated with poor health and wellbeing, lower productivity, increased sickness absence and lower staff retention.';
  } else if (region === 'uk' && category === ManagmentStandards.All) {
    return 'The evidence built, independently evaluated and DfE Education Staff Wellbeing Charter recognised survey measures against the Health and Safety Executive’s Management Standards for employers. These cover the six key areas of work design, that if not properly managed, are associated with poor health and wellbeing, lower productivity, increased sickness absence and lower staff retention.';
  } else if (region === 'us' && category === ManagmentStandards.Demand) {
    return 'For example, staff workload, time given to complete tasks, deadlines and behavior and whether staff have the right knowledge and skills to deal with these.';
  } else if (region === 'uk' && category === ManagmentStandards.Demand) {
    return 'For example, staff workload, time given to complete tasks, deadlines and behaviour and whether staff have the right knowledge and skills to deal with these.';
  } else if (region === 'us' && category === ManagmentStandards.Control) {
    return 'For example, the extent to which staff feel they have a say in what  they do and how they do it.';
  } else if (region === 'uk' && category === ManagmentStandards.Control) {
    return 'For example, the extent to which staff feel they have a say in what  they do and how they do it.';
  } else if (
    region === 'us' &&
    category === ManagmentStandards.ManagerSupport
  ) {
    return 'For example, the encouragement, sponsorship and support staff feel they receive from leaders and peers.';
  } else if (
    region === 'uk' &&
    category === ManagmentStandards.ManagerSupport
  ) {
    return 'For example, the encouragement, sponsorship and support staff feel they receive from leaders, line managers and peers.';
  } else if (region === 'us' && category === ManagmentStandards.PeerSupport) {
    return 'For example, the encouragement, sponsorship and support staff feel they receive from leaders and peers.';
  } else if (region === 'uk' && category === ManagmentStandards.PeerSupport) {
    return 'For example, the encouragement, sponsorship and support staff feel they receive from leaders, line managers and peers.';
  } else if (region === 'us' && category === ManagmentStandards.Relationships) {
    return ' For example, the extent to which staff behavior is appropriate, positive working is promoted to avoid conflict and unacceptable behavior is effectively dealt with.';
  } else if (region === 'uk' && category === ManagmentStandards.Relationships) {
    return 'For example, the extent to which staff behaviour is appropriate, positive working is promoted to avoid conflict and unacceptable behaviour is effectively dealt with.';
  } else if (region === 'us' && category === ManagmentStandards.Role) {
    return 'For example, how well staff understand their role, what is expected of them, and how this fits with the role of their department and the overall aim of the school and District.';
  } else if (region === 'uk' && category === ManagmentStandards.Role) {
    return 'For example, how well staff understand their role, what is expected of them, and how this fits with the role of their department and the overall aim of the school, college or MAT.';
  } else if (region === 'us' && category === ManagmentStandards.Change) {
    return 'For example, how changes are communicated to staff, whether they are consulted and whether they have opportunities to question leaders.';
  } else if (region === 'uk' && category === ManagmentStandards.Change) {
    return 'For example, how changes are communicated to staff, whether they are consulted and whether they have opportunities to question leaders.';
  }
};
