import { storageService } from '../../services';
import useIdle from './useIdleTimeout';

// IdleTimerContainer component is used to consume useIdle hook
const IdleTimerContainer = (props) => {
  const logout = () => {
    storageService.clear();
    window.location.reload();
    console.log('User got logged out');
  };
  useIdle({
    onIdle: logout,
    idleTime: 30,
  });
  return <></>;
};
export default IdleTimerContainer;
