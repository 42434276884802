import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { colors } from '../../resources/theme';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  SurveyDropDown,
  DirectorLeftMenu,
  LeftMenu,
  Footer,
  HeatmapCell,
  MainMATHeader,
} from '../../components';
import { toast } from 'react-toastify';
import {
  SurveyTypes,
  MenuItems,
  WebStorageNames,
  QueryStringTokens,
  Roles,
} from '../../utils/Constants';
import {
  staffResultServices,
  storageService,
  matCustomHeatmapService,
} from '../../services';
import { limitAverageValue } from '../../utils/roundScore';
import {
  DownloadButtonStyle,
  AssistanceWrapper,
} from '../../resources/styling/appStyle';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import { routesObj } from '../../routes';
import { getQueryStringVal, parseJwt } from '../../utils/CommonFunctions';
import IndividualHeatmapSelectionForm from '../staffRoleHeatmap/IndividualHeatmapSelectionForm';
import MatCycleDropDown from '../../components/common/MatCycleDropDown';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { iconDownload, arrowDown } from '../../resources/images/index';
import {
  AssistanceWrapperHeadingExt,
  SuperExtendExtendedSubContainer,
  ExtendedAssistanceWrapper,
  CommentsMainWrapper,
} from './MatStyle';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import HeatMapListItem from '../common/atoms/HeatMapListItem';
import MatFiltersSearchBox from './matCustomHeatmap/MatFiltersSearchBox';
import MatCustomHeatmap from './matCustomHeatmap/matCustomHeatmap';
import { useTranslation } from 'react-i18next';

const { whiteColor, minttulip, yourpink, lightminttulip, lightyourpink } =
  colors;

const SortOrder = {
  Newest: 'Support staff',
  Oldest: 'Teaching staff',
};

class MatHeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schoolId: -1,
      sortingSelectedOption: {
        value: SortOrder.Newest,
        label: 'Filter by staff type',
      },
      isAnnonymised: false,
      selectedSchoolOption: { value: '', label: 'All schools' },
      schoolNameOptions: [{ value: SurveyTypes.any, label: 'All schools' }],
      allStaff: null,
      matScore: null,
      jobRole: [],
      timeAtSchool: [],
      employmentType: [],
      allSchools: [],
      additionalFilters: [],
      annonymisedSchoolScores: [],
      errorMessage: null,
      collapseJobRole: false,
      collapseTimeAtSchool: false,
      collapseEmploymentType: false,
      collapseAdditionalFilters: [],
      collapseSchoolName: true,
      schoolName: 'All schools',
      isMat: false,
      isSchoolNameShow: true,
      loggedRole: '',
      GroupName: '',
      matCycles: [],
      selectedMatCycle: '',
      matGroupId: storageService.getItem(WebStorageNames.MatGroupId),
      isOpen: false,
      isLoading: false,
      open: false,
      dropdownText: 'Heatmap Filters',
      downloadList: [
        { id: 1, value: true, label: 'All Staff scores by schools' },
        { id: 2, value: true, label: 'Job Role Scores across all schools' },
        { id: 3, value: true, label: 'Time at school across all schools' },
        { id: 4, value: true, label: 'Employment type across all schools ' },
        {
          id: 5,
          value: true,
          label: 'Support staff classroom scores by schools',
        },
        {
          id: 6,
          value: true,
          label: 'Support staff non-classroom scores by schools',
        },
        {
          id: 7,
          value: true,
          label: 'Teaching staff classroom scores by schools',
        },
        {
          id: 8,
          value: true,
          label: 'Teaching staff non-classroom scores by schools',
        },
        {
          id: 9,
          value: true,
          label: 'Middle leader scores by schools',
        },
        { id: 10, value: true, label: 'Senior leader scores by schools' },
        {
          id: 11,
          value: true,
          label: 'Staff with less than 1 yr service scores by school',
        },
        {
          id: 12,
          value: true,
          label: 'Staff with 1-3 yrs service scores by school',
        },
        {
          id: 13,
          value: true,
          label: 'Staff with 4-5 yrs service scores by school',
        },
        {
          id: 14,
          value: true,
          label: 'Staff with 6-10 yrs service scores by school',
        },
        {
          id: 15,
          value: true,
          label: 'Staff with 11-15 yrs service scores by school',
        },
        {
          id: 16,
          value: true,
          label: 'Staff with 16-20 yrs service scores by school',
        },
        {
          id: 17,
          value: true,
          label: 'Staff with greater than 20 yrs service scores by school',
        },
        { id: 18, value: true, label: 'Full time staff scores by school' },
        { id: 19, value: true, label: 'Part Time staff scores by school' },
      ],
      selectAll: true,
      selectedMatCycle: '',
      Customheatmap: [],
      isCustomHeatmap: false,
      isCustomHeatmapLoading: false,
      matCoreFilters: [],
      isCalledComeFromSchoolLeftMenu: false,
      customHeatmapError: '',
      mainHeatMapDownloadVisible: true,
      region: storageService.getItem(WebStorageNames.UsRegion),
    };
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onSelectionAll = this.onSelectionAll.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
  }
  downloadPdf() {
    loaderAction.dataLoading(true);
    const token = getQueryStringVal(QueryStringTokens.token);
    let heatmaps = '';
    this.state.downloadList.forEach((h) => {
      if (h.value) heatmaps += h.id + ',';
    });
    if (heatmaps === '') {
      toast.error('Please select atleast one heatmap to download.');
      return;
    }
    let data = {
      AllStaff: this.state.allStaff,
      OverAllMatScore: this.state.matScore,
      JobRole: this.state.jobRole,
      TimeAtSchool: this.state.timeAtSchool,
      EmploymentType: this.state.employmentType,
      AllSchools: this.state.allSchools,
      AdditionalFilters: this.state.additionalFilters,
    };
    staffResultServices
      .getMATHeatMapDownload(
        data,
        heatmaps,
        token,
        this.state.isAnnonymised,
        this.state.matGroupId,
        this.state.schoolId
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'welbee-mat-heatmap.pdf');
        document.body.appendChild(link);
        loaderAction.dataLoading(true);
        link.click();
        this.setState({ isOpen: false });
      })
      .catch(() => {
        loaderAction.dataLoading(true);
      });
  }
  onSelectionChange(obj, value) {
    let newList = this.state.downloadList;
    let item = newList.find((x) => x.id == obj.id);
    item.value = value;
    let selectAll =
      newList.filter((i) => {
        if (i.value === true) return true;
        else return false;
      }).length === newList.length;
    this.setState({ selectAll: selectAll });
    this.setState({ downloadList: newList });
  }
  onSelectionAll(value) {
    let newList = this.state.downloadList;
    newList.map((item) => {
      item.value = value;
    });
    let selectAll =
      newList.filter((i) => {
        if (i.value === true) return true;
        else return false;
      }).length === newList.length;
    this.setState({ selectAll: selectAll });
    this.setState({ downloadList: newList });
  }

  componentDidMount() {
    let matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    this.setState({
      schoolId: storageService.getItem(WebStorageNames.SchoolId),
    });
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let userToken = getQueryStringVal(QueryStringTokens.token);
    if (userToken == null) {
      userToken = storageService.getItem(WebStorageNames.MatUserId);
    }
    const { loaderAction } = this.props;
    if (schoolToken != null) {
      let newList = this.state.downloadList.filter((item) => {
        if (item.id < 5) return true;
        else return false;
      });
      this.setState({ downloadList: newList });
    }

    //i think this is not a right way to check isMat. in future we need to update
    const isMat =
      (storageService.getItem(WebStorageNames.IsMat) && schoolToken === null) ||
      userToken !== null;
    const loggedRole = storageService.getItem(WebStorageNames.Role);
    this.setState({ isMat: isMat, loggedRole: loggedRole });
    const selectedSchoolToken = window.location.search.split('schoolToken=')[1];
    //when call come from Mat school left menu it contain value schooltoken
    //so we are deciding on basic of url where the call come from
    const isCallfromSchoolHeatMapMAT = selectedSchoolToken === undefined;
    const role = storageService.getItem(WebStorageNames.Role);
    this.setState({
      isCalledComeFromSchoolLeftMenu:
        isCallfromSchoolHeatMapMAT && role != Roles.HeadTeacher,
    });
    const isMatSchoolHeatMap = window.location.search.split(
      'IsMatSchoolFromHeatmap='
    )[1];

    loaderAction.dataLoading(true);
    this.props.navigationActions.activeItemAction({
      item: MenuItems.MatHeatMap,
    });
    staffResultServices
      .getGroupName(selectedSchoolToken, userToken)
      .then((response) => {
        const { success, data } = response;
        if (success) {
          this.setState({
            GroupName: data.GroupName,
          });
        }
      })
      .catch(() => {
        loaderAction.dataLoading(false);
      });
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    // If=logged In as Individual School
    // Else =logged In as  Mat Group or super admin
    if (
      ((selectedSchoolToken != null || selectedSchoolToken !== undefined) &&
        userInfo.role !== Roles.HeadTeacher) ||
      userInfo.role === Roles.HeadTeacher
    ) {
      loaderAction.dataLoading(true);
      staffResultServices
        .getMatCycleHeatMap(
          selectedSchoolToken,
          this.state.matGroupId,
          null,
          isMatSchoolHeatMap
        )
        .then((response) => {
          const { success, data, message } = response;
          if (success) {
            loaderAction.dataLoading(false);
            this.setState({ matCoreFilters: data });
            this.setState({
              allStaff: data.AllStaff,
              matScore: data.OverAllMatScore,
              jobRole: data.JobRole,
              timeAtSchool: data.TimeAtSchool,
              employmentType: data.EmploymentType,
              allSchools: data.AllSchools,
              additionalFilters: data.AdditionalFilters,
              errorMessage: null,
            });
            this.setState({
              annonymisedSchoolScores: data.AnnonymisedSchoolScores,
              isAnnonymised: data.IsAnnonymised,
              /*&&
                (getQueryStringVal(QueryStringTokens.token) == null ||
                  getQueryStringVal(QueryStringTokens.token) === undefined)
                  ? true
                  : false*/
            });
            if (data.IsAnnonymised)
              this.state.downloadList[0].label = 'School Ranking by School';
            this.getSchoolsForDropDown(data.AllSchools.SchoolHeatMaps);
            loaderAction.dataLoading(false);
          } else {
            this.setState({ errorMessage: message });
            loaderAction.dataLoading(false);
          }
          loaderAction.dataLoading(false);
        })
        .catch();
    } else {
      loaderAction.dataLoading(true);
      staffResultServices
        .getGroupHeatMap(selectedSchoolToken, userToken)
        .then((response) => {
          const { success, data, message } = response;
          this.setState({ matCoreFilters: data });
          const region = storageService.getItem(WebStorageNames.UsRegion);
          if (data !== null) {
            const addFilters = data.AdditionalFilters.map((obj) => {
              if (obj.FilterName === 'Phase Or Year') {
                if (region === 'us') return { ...obj, FilterName: 'Grade' };
                else return { ...obj, FilterName: 'Year Or Key Stage' };
              }
              return obj;
            });
            data.AdditionalFilters = addFilters;
          }

          if (success) {
            loaderAction.dataLoading(false);
            this.setState({
              allStaff: data.AllStaff,
              matScore: data.OverAllMatScore,
              jobRole: data.JobRole,
              timeAtSchool: data.TimeAtSchool,
              employmentType: data.EmploymentType,
              allSchools: data.AllSchools,
              additionalFilters: data.AdditionalFilters,
            });
            this.getSchoolsForDropDown(data.AllSchools.SchoolHeatMaps);
          } else {
            this.setState({ errorMessage: message });
            loaderAction.dataLoading(false);
          }
          loaderAction.dataLoading(false);
        })
        .catch((error) => console.log(error));
    }
  }

  boxColor(colorValue, boxValue) {
    if (colorValue >= 1 && colorValue <= 3 && boxValue !== -1) {
      return minttulip;
    } else if (colorValue >= 7 && colorValue <= 10 && boxValue !== -1) {
      return yourpink;
    }
    return whiteColor;
  }

  staffBoxColor(colorValue, boxValue) {
    if (colorValue >= 1 && colorValue <= 3 && boxValue !== -1) {
      return lightminttulip;
    } else if (colorValue >= 7 && colorValue <= 10 && boxValue !== -1) {
      return lightyourpink;
    }
    return whiteColor;
  }
  checkStaffValue(value) {
    if (value === -1) {
      return <span className='blank-entry'>-</span>;
    }
    return limitAverageValue(value);
  }

  handleSortingValueChange = (sortingSelectedOption) => {
    this.setState({ sortingSelectedOption });
  };

  handleCycleChange = (selectedCycle) => {
    this.setState({
      additionalFilters: [],
    });
    const { loaderAction } = this.props;
    let userToken = getQueryStringVal(QueryStringTokens.token);
    const selectedSchoolToken = window.location.search.split('schoolToken=')[1];
    loaderAction.dataLoading(true);
    staffResultServices
      .getMatCycleHeatMap(userToken, this.state.matGroupId, selectedCycle.value)
      .then((response) => {
        const { success, data, message } = response;

        const region = storageService.getItem(WebStorageNames.UsRegion);
        if (data !== null) {
          const addFilters = data.AdditionalFilters.map((obj) => {
            if (obj.FilterName === 'Phase Or Year') {
              if (region === 'us') return { ...obj, FilterName: 'Grade' };
              else return { ...obj, FilterName: 'Year Or Key Stage' };
            }
            return obj;
          });
          data.AdditionalFilters = addFilters;
        }

        if (success) {
          this.setState({ matCoreFilters: data });
          this.setState({
            allStaff: data.AllStaff,
            matScore: data.OverAllMatScore,
            jobRole: data.JobRole,
            timeAtSchool: data.TimeAtSchool,
            employmentType: data.EmploymentType,
            allSchools: data.AllSchools,
            selectedMatCycle: selectedCycle.value,
            errorMessage: null,
            selectedMatCycle: selectedCycle,
            additionalFilters: data.AdditionalFilters,
          });
          this.getSchoolsForDropDown(data.AllSchools);
          loaderAction.dataLoading(false);
        } else {
          this.setState({ errorMessage: message });
          loaderAction.dataLoading(false);
        }
        loaderAction.dataLoading(false);
      })
      .catch((error) => {
        loaderAction.dataLoading(false);
      });
  };

  handleSchoolChange = (item, userToken, IsMatSchoolFromHeatmap) => {
    const { loaderAction } = this.props;
    let isIndividualSchool = item.label === 'All schools' ? false : true;
    if (item.label === 'All schools') {
      loaderAction.dataLoading(true);
      staffResultServices
        .getMatCycleHeatMap(
          userToken,
          this.state.matGroupId,
          this.state.selectedMatCycle
        )
        .then((response) => {
          const { success, data, message } = response;

          const region = storageService.getItem(WebStorageNames.UsRegion);
          if (data !== null) {
            const addFilters = data.AdditionalFilters.map((obj) => {
              if (obj.FilterName === 'Phase Or Year') {
                if (region === 'us') return { ...obj, FilterName: 'Grade' };
                else return { ...obj, FilterName: 'Year Or Key Stage' };
              }
              return obj;
            });
            data.AdditionalFilters = addFilters;
          }

          if (success) {
            loaderAction.dataLoading(false);
            this.setState({ matCoreFilters: data });
            this.setState({
              allStaff: data.AllStaff,
              matScore: data.OverAllMatScore,
              jobRole: data.JobRole,
              timeAtSchool: data.TimeAtSchool,
              employmentType: data.EmploymentType,
              allSchools: data.AllSchools,
              additionalFilters: data.AdditionalFilters,
              selectedSchoolOption: item,
              schoolName: item.label,
              isSchoolNameShow: item.label === 'All schools',
            });
            this.getSchoolsForDropDown(data.AllSchools);
          } else {
            this.setState({ errorMessage: message });
          }
          loaderAction.dataLoading(false);
        })
        .catch((error) => {
          loaderAction.dataLoading(false);
        });
    } else {
      loaderAction.dataLoading(true);
      staffResultServices
        .getGroupHeatMap(item.value, userToken, IsMatSchoolFromHeatmap)
        .then((response) => {
          const { success, data, message } = response;
          if (success) {
            loaderAction.dataLoading(true);
            this.setState({ matCoreFilters: data });
            this.setState({
              allStaff: data.AllStaff,
              matScore: data.OverAllMatScore,
              jobRole: data.JobRole,
              timeAtSchool: data.TimeAtSchool,
              employmentType: data.EmploymentType,
              allSchools: data.AllSchools,
              selectedSchoolOption: item,
              schoolName: item.label,
              isSchoolNameShow: item.label === 'All schools',
            });
            this.getSchoolsForDropDown(data.AllSchools);
          } else {
            this.setState({ errorMessage: message });
          }
          loaderAction.dataLoading(false);
        })
        .catch((error) => {
          loaderAction.dataLoading(false);
        });
    }
  };

  getSchoolsForDropDown = (allSchools) => {
    const transformedSchoolList = [{ value: '', label: 'All schools' }];
    allSchools.map((item) => {
      transformedSchoolList.push({ value: item.Token, label: item.Name });
    });
    this.setState({ schoolNameOptions: transformedSchoolList });
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClickAway = () => {
    this.setState({ open: false });
  };

  handleCustomHeatmap = (data, errorMsg) => {
    if (errorMsg !== '') {
      this.setState({ customHeatmapError: errorMsg });
      this.setState({ isCustomHeatmap: true });
    } else if (data?.length > 0) {
      this.setState({ mainHeatMapDownloadVisible: false });
      const sortedData = [...data].sort((a, b) =>
        a.StaffType.localeCompare(b.StaffType)
      );
      this.setState({ customHeatmapError: '' });
      this.setState({ Customheatmap: sortedData });
      this.setState({ isCustomHeatmap: true });
    } else {
      this.setState({ mainHeatMapDownloadVisible: true });
      this.setState({ isCustomHeatmap: false });
    }
  };

  handleSetLoading = (isLoading) => {
    loaderAction.dataLoading(isLoading);
    this.setState({ isCustomHeatmapLoading: isLoading });
  };
  handleMainHeatmapDownloadVisible = (isVisible) => {
    this.setState({ mainHeatMapDownloadVisible: isVisible });
  };
  render() {
    const {
      schoolNameOptions,
      selectedSchoolOption,
      matScore,
      allStaff,
      jobRole,
      timeAtSchool,
      employmentType,
      errorMessage,
      loggedRole,
      isMat,
      open,
      isCalledComeFromSchoolLeftMenu,
      matGroupId,
      isCustomHeatmap,
      Customheatmap,
      isCustomHeatmapLoading,
      matCoreFilters,
      selectedMatCycle,
      customHeatmapError,
      mainHeatMapDownloadVisible,
    } = this.state;
    let token = getQueryStringVal(QueryStringTokens.token);

    const styles = {
      position: 'absolute',
      top: 50,
      right: 0,
      left: 0,
      zIndex: 1,
      width: '20%',
      boxShadow: '0 0 8px rgba(0,0,0,0.35)',
    };

    const collapseItem = (param, activeCheck) => {
      var obj = {
        collapseSchoolName: param === 'collapseSchoolName',
        collapseJobRole: param === 'collapseJobRole',
        collapseTimeAtSchool: param === 'collapseTimeAtSchool',
        collapseEmploymentType: param === 'collapseEmploymentType',
        collapseSupportStaffClassroomBySchoolName:
          param === 'collapseSupportStaffClassroomBySchoolName',
        collapseSupportStaffNonClassroomBySchoolName:
          param === 'collapseSupportStaffNonClassroomBySchoolName',
        collapseTeachingStaffClassroomBySchoolName:
          param === 'collapseTeachingStaffClassroomBySchoolName',
        collapseTeachingStaffNonClassroomBySchoolName:
          param === 'collapseTeachingStaffNonClassroomBySchoolName',
        collapseMiddleLeaderScoreBySchoolName:
          param === 'collapseMiddleLeaderScoreBySchoolName',
        collapseSeniorLeaderScoresBySchoolName:
          param === 'collapseSeniorLeaderScoresBySchoolName',
        collapseLessThan1YearServiceBySchoolName:
          param === 'collapseLessThan1YearServiceBySchoolName',
        collapseBetween1to3YearServiceBySchoolName:
          param === 'collapseBetween1to3YearServiceBySchoolName',
        collapseBetween4to5YearServiceBySchoolName:
          param === 'collapseBetween4to5YearServiceBySchoolName',
        collapseBetween6to10YearServiceBySchoolName:
          param === 'collapseBetween6to10YearServiceBySchoolName',
        collapseBetween11to15YearServiceBySchoolName:
          param === 'collapseBetween11to15YearServiceBySchoolName',
        collapseBetween16to20YearServiceBySchoolName:
          param === 'collapseBetween16to20YearServiceBySchoolName',
        collapseGretterThan20YearServiceBySchoolName:
          param === 'collapseGretterThan20YearServiceBySchoolName',
        collapseFullTimeStaffScoresBySchoolName:
          param === 'collapseFullTimeStaffScoresBySchoolName',
        collapsePartlTimeStaffScoresBySchoolName:
          param === 'collapsePartlTimeStaffScoresBySchoolName',
        className: `job-role-heading ${
          !this.state.collapseSchoolName ? '' : 'active'
        }`,
      };
      return obj;
    };

    return (
      <CommentsMainWrapper>
        <div className='containerClass'>
          <div className='left-container'>
            {(isMat || loggedRole === Roles.GroupDirector) &&
            isCalledComeFromSchoolLeftMenu ? (
              <DirectorLeftMenu />
            ) : (
              <LeftMenu />
            )}
          </div>

          <div className='right-container'>
            <Grid container>
              <Grid item md={12} xs={12}>
                <MainMATHeader />
              </Grid>

              <Grid item xl={5} lg={5} sm={12} md={6} xs={12}>
                <ExtendedAssistanceWrapper>
                  <AssistanceWrapperHeadingExt>
                    <span
                      className='comment-heading'
                      style={{ marginBottom: '10px' }}
                    >
                      {this.state.schoolName === 'All schools'
                        ? `${this.state.GroupName} Summary Heatmaps`
                        : `${this.state.schoolName} Summary Heatmap`}
                    </span>
                    <span>
                      {/* {this.countComments()} */} Compare survey results
                      across all staff types, but note that scores are only
                      reported where there are 5 or more participants in that
                      category.
                    </span>
                  </AssistanceWrapperHeadingExt>
                </ExtendedAssistanceWrapper>
              </Grid>

              <Grid item xl={7} lg={7} sm={12} md={6} xs={12}>
                <ExtendedAssistanceWrapper>
                  <AssistanceWrapper>
                    <div className='mat-header-bottom'>
                      <div
                        className='btn-download'
                        style={{ marginLeft: '8px' }}
                      >
                        {mainHeatMapDownloadVisible === true ? (
                          <Tooltip title='Download' followCursor>
                            <DownloadButtonStyle
                              data-id-div='reportDownload'
                              onClick={() => {
                                this.setState({ isOpen: true });
                              }}
                            >
                              <img src={iconDownload} alt='' />{' '}
                              <span>Download</span>
                            </DownloadButtonStyle>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </div>

                      {this.state.isMat && (
                        <div className='dd-survey'>
                          <SurveyDropDown
                            // isRole={isRole}
                            latestSurveyOptions={schoolNameOptions}
                            latestSurveyOption={selectedSchoolOption}
                            handleSurveyValueChange={(item) => {
                              let userToken = getQueryStringVal(
                                QueryStringTokens.token
                              );
                              let IsMatSchoolFromHeatmap = 'false';
                              if (userToken !== null) {
                                if (item.value !== '') {
                                  IsMatSchoolFromHeatmap = 'true';
                                  this.props.history.push(
                                    routesObj.MatHeatMap.path +
                                      '?schoolToken=' +
                                      item.value +
                                      '&token=' +
                                      userToken +
                                      '&IsMatSchoolFromHeatmap=true'
                                  );
                                } else {
                                  IsMatSchoolFromHeatmap = 'false';
                                  this.props.history.push(
                                    routesObj.MatHeatMap.path +
                                      '?token=' +
                                      userToken +
                                      '&IsMatSchoolFromHeatmap=false'
                                  );
                                }
                              } else {
                                if (item.value !== '') {
                                  IsMatSchoolFromHeatmap = 'true';
                                  this.props.history.push(
                                    routesObj.MatHeatMap.path +
                                      '?schoolToken=' +
                                      item.value +
                                      '&IsMatSchoolFromHeatmap=true'
                                  );
                                } else {
                                  IsMatSchoolFromHeatmap = 'false';
                                  this.props.history.push(
                                    routesObj.MatHeatMap.path +
                                      '?IsMatSchoolFromHeatmap=false'
                                  );
                                }
                              }
                              this.handleSchoolChange(
                                item,
                                userToken,
                                IsMatSchoolFromHeatmap
                              );
                            }}
                          />
                        </div>
                      )}
                      {this.state.isMat && (
                        <div className='dd-survey'>
                          <MatCycleDropDown
                            handleOnChange={this.handleCycleChange}
                          />
                        </div>
                      )}
                    </div>
                  </AssistanceWrapper>
                </ExtendedAssistanceWrapper>
              </Grid>
            </Grid>

            {errorMessage !== null ? (
              <SuperExtendExtendedSubContainer>
                <div className='search-data-container'>
                  <div
                    className='staff-result-innercontainer'
                    style={{ width: '100%' }}
                  >
                    <div className='search-inner-sub-container'>
                      <div className='staff-type-grid'>
                        <div className='heatmap-error-msg'>{errorMessage}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SuperExtendExtendedSubContainer>
            ) : (
              allStaff !== null && (
                <Fragment>
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                      <MatFiltersSearchBox
                        SetMainDownloadVisisble={
                          this.handleMainHeatmapDownloadVisible
                        }
                        handleCustomHeatmap={this.handleCustomHeatmap}
                        handleSetLoading={this.handleSetLoading}
                        matCoreFilters={matCoreFilters}
                        matCycleId={selectedMatCycle}
                        selectedFilter={
                          this.state.dropdownText === 'Heatmap Filters'
                            ? 'All Staff Scores By Schools'
                            : this.state.dropdownText
                        }
                      />
                      <SuperExtendExtendedSubContainer
                        style={{ paddingTop: '0px' }}
                      >
                        <div className='search-data-container'>
                          <div
                            className='staff-result-innercontainer'
                            style={{ width: '100%' }}
                          >
                            {getQueryStringVal(
                              QueryStringTokens.schoolToken
                            ) === null ? (
                              <ClickAwayListener
                                mouseEvent='onMouseDown'
                                touchEvent='onTouchStart'
                                onClickAway={this.handleClickAway}
                              >
                                <Box sx={{ position: 'relative' }}>
                                  <button
                                    type='button'
                                    onClick={this.handleClick}
                                  >
                                    {this.state.dropdownText}{' '}
                                    <span>
                                      <img src={arrowDown} alt='' />
                                    </span>
                                  </button>
                                  {open ? (
                                    <Box
                                      sx={styles}
                                      onClick={this.handleClickAway}
                                    >
                                      <div className='mat-heatmap-filters'>
                                        <ul>
                                          {this.state.isSchoolNameShow && (
                                            // <HeatMapListItem
                                            //   collapseItem={collapseItem(
                                            //     'collapseSchoolName',
                                            //     this.state.collapseSchoolName
                                            //   )}
                                            //   dropdownText={
                                            //     'All Staff Scores By Schools'
                                            //   }
                                            // />

                                            <li
                                              className={`job-role-heading ${
                                                !this.state.collapseSchoolName
                                                  ? ''
                                                  : 'active'
                                              }`}
                                              onClick={() => {
                                                this.setState({
                                                  //collapseSchoolName: !this.state.collapseSchoolName,
                                                  collapseSchoolName: true,
                                                  collapseJobRole: false,
                                                  collapseTimeAtSchool: false,
                                                  collapseEmploymentType: false,
                                                  collapseSupportStaffClassroomBySchoolName: false,
                                                  collapseSupportStaffNonClassroomBySchoolName: false,
                                                  collapseTeachingStaffClassroomBySchoolName: false,
                                                  collapseTeachingStaffNonClassroomBySchoolName: false,
                                                  collapseMiddleLeaderScoreBySchoolName: false,
                                                  collapseSeniorLeaderScoresBySchoolName: false,
                                                  collapseLessThan1YearServiceBySchoolName: false,
                                                  collapseBetween1to3YearServiceBySchoolName: false,
                                                  collapseBetween4to5YearServiceBySchoolName: false,
                                                  collapseBetween6to10YearServiceBySchoolName: false,
                                                  collapseBetween11to15YearServiceBySchoolName: false,
                                                  collapseBetween16to20YearServiceBySchoolName: false,
                                                  collapseGretterThan20YearServiceBySchoolName: false,
                                                  collapseFullTimeStaffScoresBySchoolName: false,
                                                  collapsePartlTimeStaffScoresBySchoolName: false,
                                                  dropdownText:
                                                    'All Staff Scores By Schools',
                                                });
                                                this.state.additionalFilters.forEach(
                                                  (items) => {
                                                    debugger;
                                                    let collapseState =
                                                      this.state
                                                        .collapseAdditionalFilters;
                                                    collapseState[
                                                      items.FilterName
                                                    ] = false;
                                                    this.setState({
                                                      collapseAdditionalFilters:
                                                        collapseState,
                                                    });
                                                  }
                                                );
                                              }}
                                            >
                                              All Staff Scores By Schools
                                            </li>
                                          )}
                                          {getQueryStringVal(
                                            QueryStringTokens.IsMatSchoolFromHeatmap
                                          ) === 'true' ? (
                                            <li
                                              className={`job-role-heading ${
                                                !this.state.collapseJobRole
                                                  ? ''
                                                  : 'active'
                                              }`}
                                              onClick={() => {
                                                this.setState({
                                                  //collapseJobRole: !this.state.collapseJobRole,
                                                  collapseJobRole: true,
                                                  collapseSchoolName: false,
                                                  collapseTimeAtSchool: false,
                                                  collapseEmploymentType: false,
                                                  collapseSupportStaffClassroomBySchoolName: false,
                                                  collapseSupportStaffNonClassroomBySchoolName: false,
                                                  collapseTeachingStaffClassroomBySchoolName: false,
                                                  collapseTeachingStaffNonClassroomBySchoolName: false,
                                                  collapseMiddleLeaderScoreBySchoolName: false,
                                                  collapseSeniorLeaderScoresBySchoolName: false,
                                                  collapseLessThan1YearServiceBySchoolName: false,
                                                  collapseBetween1to3YearServiceBySchoolName: false,
                                                  collapseBetween4to5YearServiceBySchoolName: false,
                                                  collapseBetween6to10YearServiceBySchoolName: false,
                                                  collapseBetween11to15YearServiceBySchoolName: false,
                                                  collapseBetween16to20YearServiceBySchoolName: false,
                                                  collapseGretterThan20YearServiceBySchoolName: false,
                                                  collapseFullTimeStaffScoresBySchoolName: false,
                                                  collapsePartlTimeStaffScoresBySchoolName: false,
                                                  dropdownText: 'Job Role',
                                                });
                                                this.state.additionalFilters.forEach(
                                                  (items) => {
                                                    let collapseState =
                                                      this.state
                                                        .collapseAdditionalFilters;
                                                    collapseState[
                                                      items.FilterName
                                                    ] = false;
                                                    this.setState({
                                                      collapseAdditionalFilters:
                                                        collapseState,
                                                    });
                                                  }
                                                );
                                              }}
                                            >
                                              Job Role
                                              {/* {!jobRole.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                            </li>
                                          ) : (
                                            <li
                                              className={`job-role-heading ${
                                                !this.state.collapseJobRole
                                                  ? ''
                                                  : 'active'
                                              }`}
                                              onClick={() => {
                                                this.setState({
                                                  //collapseJobRole: !this.state.collapseJobRole,
                                                  collapseJobRole: true,
                                                  collapseSchoolName: false,
                                                  collapseTimeAtSchool: false,
                                                  collapseEmploymentType: false,
                                                  collapseSupportStaffClassroomBySchoolName: false,
                                                  collapseSupportStaffNonClassroomBySchoolName: false,
                                                  collapseTeachingStaffClassroomBySchoolName: false,
                                                  collapseTeachingStaffNonClassroomBySchoolName: false,
                                                  collapseMiddleLeaderScoreBySchoolName: false,
                                                  collapseSeniorLeaderScoresBySchoolName: false,
                                                  collapseLessThan1YearServiceBySchoolName: false,
                                                  collapseBetween1to3YearServiceBySchoolName: false,
                                                  collapseBetween4to5YearServiceBySchoolName: false,
                                                  collapseBetween6to10YearServiceBySchoolName: false,
                                                  collapseBetween11to15YearServiceBySchoolName: false,
                                                  collapseBetween16to20YearServiceBySchoolName: false,
                                                  collapseGretterThan20YearServiceBySchoolName: false,
                                                  collapseFullTimeStaffScoresBySchoolName: false,
                                                  collapsePartlTimeStaffScoresBySchoolName: false,
                                                  dropdownText:
                                                    'Job Role Scores Across all Schools',
                                                });
                                                this.state.additionalFilters.forEach(
                                                  (items) => {
                                                    let collapseState =
                                                      this.state
                                                        .collapseAdditionalFilters;
                                                    collapseState[
                                                      items.FilterName
                                                    ] = false;
                                                    this.setState({
                                                      collapseAdditionalFilters:
                                                        collapseState,
                                                    });
                                                  }
                                                );
                                              }}
                                            >
                                              Job Role Scores Across all Schools
                                              {/* {!jobRole.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                            </li>
                                          )}
                                          {getQueryStringVal(
                                            QueryStringTokens.IsMatSchoolFromHeatmap
                                          ) === 'true' ? (
                                            <li
                                              className={`job-role-heading ${
                                                !this.state.collapseTimeAtSchool
                                                  ? ''
                                                  : 'active'
                                              }`}
                                              onClick={() => {
                                                this.setState({
                                                  //collapseTimeAtSchool: !this.state.collapseTimeAtSchool,
                                                  collapseTimeAtSchool: true,
                                                  collapseSchoolName: false,
                                                  collapseJobRole: false,
                                                  collapseEmploymentType: false,
                                                  collapseSupportStaffClassroomBySchoolName: false,
                                                  collapseSupportStaffNonClassroomBySchoolName: false,
                                                  collapseTeachingStaffClassroomBySchoolName: false,
                                                  collapseTeachingStaffNonClassroomBySchoolName: false,
                                                  collapseMiddleLeaderScoreBySchoolName: false,
                                                  collapseSeniorLeaderScoresBySchoolName: false,
                                                  collapseLessThan1YearServiceBySchoolName: false,
                                                  collapseBetween1to3YearServiceBySchoolName: false,
                                                  collapseBetween4to5YearServiceBySchoolName: false,
                                                  collapseBetween6to10YearServiceBySchoolName: false,
                                                  collapseBetween11to15YearServiceBySchoolName: false,
                                                  collapseBetween16to20YearServiceBySchoolName: false,
                                                  collapseGretterThan20YearServiceBySchoolName: false,
                                                  collapseFullTimeStaffScoresBySchoolName: false,
                                                  collapsePartlTimeStaffScoresBySchoolName: false,
                                                  dropdownText:
                                                    'Time at school',
                                                });
                                                this.state.additionalFilters.forEach(
                                                  (items) => {
                                                    let collapseState =
                                                      this.state
                                                        .collapseAdditionalFilters;
                                                    collapseState[
                                                      items.FilterName
                                                    ] = false;
                                                    this.setState({
                                                      collapseAdditionalFilters:
                                                        collapseState,
                                                    });
                                                  }
                                                );
                                              }}
                                            >
                                              Time at school
                                              {/* {!timeAtSchool.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                            </li>
                                          ) : (
                                            <li
                                              className={`job-role-heading ${
                                                !this.state.collapseTimeAtSchool
                                                  ? ''
                                                  : 'active'
                                              }`}
                                              onClick={() => {
                                                this.setState({
                                                  //collapseTimeAtSchool: !this.state.collapseTimeAtSchool,
                                                  collapseTimeAtSchool: true,
                                                  collapseSchoolName: false,
                                                  collapseJobRole: false,
                                                  collapseEmploymentType: false,
                                                  collapseSupportStaffClassroomBySchoolName: false,
                                                  collapseSupportStaffNonClassroomBySchoolName: false,
                                                  collapseTeachingStaffClassroomBySchoolName: false,
                                                  collapseTeachingStaffNonClassroomBySchoolName: false,
                                                  collapseMiddleLeaderScoreBySchoolName: false,
                                                  collapseSeniorLeaderScoresBySchoolName: false,
                                                  collapseLessThan1YearServiceBySchoolName: false,
                                                  collapseBetween1to3YearServiceBySchoolName: false,
                                                  collapseBetween4to5YearServiceBySchoolName: false,
                                                  collapseBetween6to10YearServiceBySchoolName: false,
                                                  collapseBetween11to15YearServiceBySchoolName: false,
                                                  collapseBetween16to20YearServiceBySchoolName: false,
                                                  collapseGretterThan20YearServiceBySchoolName: false,
                                                  collapseFullTimeStaffScoresBySchoolName: false,
                                                  collapsePartlTimeStaffScoresBySchoolName: false,
                                                  dropdownText:
                                                    'Time at school Across all Schools',
                                                });
                                                this.state.additionalFilters.forEach(
                                                  (items) => {
                                                    let collapseState =
                                                      this.state
                                                        .collapseAdditionalFilters;
                                                    collapseState[
                                                      items.FilterName
                                                    ] = false;
                                                    this.setState({
                                                      collapseAdditionalFilters:
                                                        collapseState,
                                                    });
                                                  }
                                                );
                                              }}
                                            >
                                              Time at school Across all Schools
                                              {/* {!timeAtSchool.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                            </li>
                                          )}
                                          {getQueryStringVal(
                                            QueryStringTokens.IsMatSchoolFromHeatmap
                                          ) === 'true' ? (
                                            <li
                                              className={`job-role-heading ${
                                                !this.state
                                                  .collapseEmploymentType
                                                  ? ''
                                                  : 'active'
                                              }`}
                                              onClick={() => {
                                                this.setState({
                                                  //collapseEmploymentType: !this.state.collapseEmploymentType,
                                                  collapseEmploymentType: true,
                                                  collapseSchoolName: false,
                                                  collapseJobRole: false,
                                                  collapseTimeAtSchool: false,
                                                  collapseSupportStaffClassroomBySchoolName: false,
                                                  collapseSupportStaffNonClassroomBySchoolName: false,
                                                  collapseTeachingStaffClassroomBySchoolName: false,
                                                  collapseTeachingStaffNonClassroomBySchoolName: false,
                                                  collapseMiddleLeaderScoreBySchoolName: false,
                                                  collapseSeniorLeaderScoresBySchoolName: false,
                                                  collapseLessThan1YearServiceBySchoolName: false,
                                                  collapseBetween1to3YearServiceBySchoolName: false,
                                                  collapseBetween4to5YearServiceBySchoolName: false,
                                                  collapseBetween6to10YearServiceBySchoolName: false,
                                                  collapseBetween11to15YearServiceBySchoolName: false,
                                                  collapseBetween16to20YearServiceBySchoolName: false,
                                                  collapseGretterThan20YearServiceBySchoolName: false,
                                                  collapseFullTimeStaffScoresBySchoolName: false,
                                                  collapsePartlTimeStaffScoresBySchoolName: false,
                                                  dropdownText:
                                                    'Employment Type',
                                                });
                                                this.state.additionalFilters.forEach(
                                                  (items) => {
                                                    let collapseState =
                                                      this.state
                                                        .collapseAdditionalFilters;
                                                    collapseState[
                                                      items.FilterName
                                                    ] = false;
                                                    this.setState({
                                                      collapseAdditionalFilters:
                                                        collapseState,
                                                    });
                                                  }
                                                );
                                              }}
                                            >
                                              Employment type
                                              {/* {!employmentType.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                            </li>
                                          ) : (
                                            <li
                                              className={`job-role-heading ${
                                                !this.state
                                                  .collapseEmploymentType
                                                  ? ''
                                                  : 'active'
                                              }`}
                                              onClick={() => {
                                                this.setState({
                                                  //collapseEmploymentType: !this.state.collapseEmploymentType,
                                                  collapseEmploymentType: true,
                                                  collapseSchoolName: false,
                                                  collapseJobRole: false,
                                                  collapseTimeAtSchool: false,
                                                  collapseSupportStaffClassroomBySchoolName: false,
                                                  collapseSupportStaffNonClassroomBySchoolName: false,
                                                  collapseTeachingStaffClassroomBySchoolName: false,
                                                  collapseTeachingStaffNonClassroomBySchoolName: false,
                                                  collapseMiddleLeaderScoreBySchoolName: false,
                                                  collapseSeniorLeaderScoresBySchoolName: false,
                                                  collapseLessThan1YearServiceBySchoolName: false,
                                                  collapseBetween1to3YearServiceBySchoolName: false,
                                                  collapseBetween4to5YearServiceBySchoolName: false,
                                                  collapseBetween6to10YearServiceBySchoolName: false,
                                                  collapseBetween11to15YearServiceBySchoolName: false,
                                                  collapseBetween16to20YearServiceBySchoolName: false,
                                                  collapseGretterThan20YearServiceBySchoolName: false,
                                                  collapseFullTimeStaffScoresBySchoolName: false,
                                                  collapsePartlTimeStaffScoresBySchoolName: false,
                                                  dropdownText:
                                                    'Employment type Across all Schools',
                                                });
                                                this.state.additionalFilters.forEach(
                                                  (items) => {
                                                    let collapseState =
                                                      this.state
                                                        .collapseAdditionalFilters;
                                                    collapseState[
                                                      items.FilterName
                                                    ] = false;
                                                    this.setState({
                                                      collapseAdditionalFilters:
                                                        collapseState,
                                                    });
                                                  }
                                                );
                                              }}
                                            >
                                              Employment type Across all Schools
                                              {/* {!employmentType.IsFilterBenchMarksAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                            </li>
                                          )}
                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseSupportStaffClassroomBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseSupportStaffClassroomBySchoolName: !this.state.collapseSupportStaffClassroomBySchoolName,
                                                    collapseSupportStaffClassroomBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Support Staff Classroom Scores by School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Support Staff Classroom Scores
                                                by School
                                                {/* {!this.state.allSchools.IsSupportStaffClassRoomAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}
                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseSupportStaffNonClassroomBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseSupportStaffNonClassroomBySchoolName: !this.state.collapseSupportStaffNonClassroomBySchoolName,
                                                    collapseSupportStaffNonClassroomBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Support Staff Non-Classroom Scores by School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Support Staff Non-Classroom
                                                Scores by School
                                                {/* {!this.state.allSchools.IsSupportStaffNonClassRoomAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}
                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseTeachingStaffClassroomBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseTeachingStaffClassroomBySchoolName: !this.state.collapseTeachingStaffClassroomBySchoolName,
                                                    collapseTeachingStaffClassroomBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Teaching Staff Classroom Scores by School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Teaching Staff Classroom Scores
                                                by School
                                                {/* {!this.state.allSchools.IsTeachingStaffClassRoomAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}
                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseTeachingStaffNonClassroomBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseTeachingStaffNonClassroomBySchoolName: !this.state.collapseTeachingStaffNonClassroomBySchoolName,
                                                    collapseTeachingStaffNonClassroomBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Teaching Staff Non-Classroom Scores by School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Teaching Staff Non-Classroom
                                                Scores by School
                                                {/* {!this.state.allSchools.IsTeachingStaffNonClassRoomAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}
                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseMiddleLeaderScoreBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseMiddleLeaderScoreBySchoolName: !this.state.collapseMiddleLeaderScoreBySchoolName,
                                                    collapseMiddleLeaderScoreBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      this.state.region === 'us'
                                                        ? 'Building Administrator Scores by School'
                                                        : 'Middle Leader Scores by School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                {this.state.region === 'us'
                                                  ? ' Building Administrator Scores by School'
                                                  : 'Middle Leader Scores by School'}
                                                {/* {!this.state.allSchools.IsMiddleLeaderScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseSeniorLeaderScoresBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseSeniorLeaderScoresBySchoolName: !this.state.collapseSeniorLeaderScoresBySchoolName,
                                                    collapseSeniorLeaderScoresBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      this.state.region === 'us'
                                                        ? 'District Administrator Scores by School'
                                                        : 'Senior Leader Scores by School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                {this.state.region === 'us'
                                                  ? ' District Administrator Scores by School'
                                                  : 'Senior Leader Scores by School'}
                                                {/* {!this.state.allSchools.IsSeniorLeaderScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseLessThan1YearServiceBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseLessThan1YearServiceBySchoolName: !this.state.collapseLessThan1YearServiceBySchoolName,
                                                    collapseLessThan1YearServiceBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Staff With Less Than 1 Yr Service Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Staff With Less Than 1 Yr
                                                Service Scores By School
                                                {/* {!this.state.allSchools.IsStaffWithLessThan1YearServiceScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseBetween1to3YearServiceBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseBetween1to3YearServiceBySchoolName: !this.state.collapseBetween1to3YearServiceBySchoolName,
                                                    collapseBetween1to3YearServiceBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Staff With 1-3 Yrs Service Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Staff With 1-3 Yrs Service
                                                Scores By School
                                                {/* {!this.state.allSchools.IsStaffWith1To3YearServiceScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseBetween4to5YearServiceBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseBetween4to5YearServiceBySchoolName: !this.state.collapseBetween4to5YearServiceBySchoolName,
                                                    collapseBetween4to5YearServiceBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Staff With 4-5 Yrs Service Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Staff With 4-5 Yrs Service
                                                Scores By School
                                                {/* {!this.state.allSchools.IsStaffWith4To5YearServiceScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseBetween6to10YearServiceBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseBetween6to10YearServiceBySchoolName: !this.state.collapseBetween6to10YearServiceBySchoolName,
                                                    collapseBetween6to10YearServiceBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Staff With 6-10 Yrs Service Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Staff With 6-10 Yrs Service
                                                Scores By School
                                                {/* {!this.state.allSchools.IsStaffWith6To10YearServiceScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseBetween11to15YearServiceBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseBetween11to15YearServiceBySchoolName: !this.state.collapseBetween11to15YearServiceBySchoolName,
                                                    collapseBetween11to15YearServiceBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Staff With 11-15 Yrs Service Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Staff With 11-15 Yrs Service
                                                Scores By School
                                                {/* {!this.state.allSchools.IsStaffWith11To15YearServiceScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseBetween16to20YearServiceBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseBetween16to20YearServiceBySchoolName: !this.state.collapseBetween16to20YearServiceBySchoolName,
                                                    collapseBetween16to20YearServiceBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Staff With 16-20 Yrs Service Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Staff With 16-20 Yrs Service
                                                Scores By School
                                                {/* {!this.state.allSchools.IsStaffWith16To20YearServiceScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseGretterThan20YearServiceBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseGretterThan20YearServiceBySchoolName: !this.state.collapseGretterThan20YearServiceBySchoolName,
                                                    collapseGretterThan20YearServiceBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Staff With Greater Than 20 Yrs Service Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Staff With Greater Than 20 Yrs
                                                Service Scores By School
                                                {/* {!this.state.allSchools.IsStaffWithMoreThan20YearServiceScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapseFullTimeStaffScoresBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapseFullTimeStaffScoresBySchoolName: !this.state.collapseFullTimeStaffScoresBySchoolName,
                                                    collapseFullTimeStaffScoresBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapsePartlTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Full Time Staff Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Full Time Staff Scores By School
                                                {/* {!this.state.allSchools.IsFullTimeStaffScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}

                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null && (
                                              <li
                                                className={`job-role-heading ${
                                                  !this.state
                                                    .collapsePartlTimeStaffScoresBySchoolName
                                                    ? ''
                                                    : 'active'
                                                }`}
                                                onClick={() => {
                                                  this.setState({
                                                    //collapsePartlTimeStaffScoresBySchoolName: !this.state.collapsePartlTimeStaffScoresBySchoolName,
                                                    collapsePartlTimeStaffScoresBySchoolName: true,
                                                    collapseSchoolName: false,
                                                    collapseJobRole: false,
                                                    collapseTimeAtSchool: false,
                                                    collapseEmploymentType: false,
                                                    collapseSupportStaffClassroomBySchoolName: false,
                                                    collapseSupportStaffNonClassroomBySchoolName: false,
                                                    collapseTeachingStaffClassroomBySchoolName: false,
                                                    collapseTeachingStaffNonClassroomBySchoolName: false,
                                                    collapseMiddleLeaderScoreBySchoolName: false,
                                                    collapseSeniorLeaderScoresBySchoolName: false,
                                                    collapseLessThan1YearServiceBySchoolName: false,
                                                    collapseBetween1to3YearServiceBySchoolName: false,
                                                    collapseBetween4to5YearServiceBySchoolName: false,
                                                    collapseBetween6to10YearServiceBySchoolName: false,
                                                    collapseBetween11to15YearServiceBySchoolName: false,
                                                    collapseBetween16to20YearServiceBySchoolName: false,
                                                    collapseGretterThan20YearServiceBySchoolName: false,
                                                    collapseFullTimeStaffScoresBySchoolName: false,
                                                    dropdownText:
                                                      'Part Time Staff Scores By School',
                                                  });
                                                  this.state.additionalFilters.forEach(
                                                    (items) => {
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] = false;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }
                                                  );
                                                }}
                                              >
                                                Part Time Staff Scores By School
                                                {/* {!this.state.allSchools.IsPartTimeStaffScoreAvailable ? (<em className='nodata'>No data is available to display</em>) : <></>} */}
                                              </li>
                                            )}
                                          {/* Additional FIlters Dropdown Items Added starts here */}
                                          {this.state.isSchoolNameShow &&
                                            loggedRole !== Roles.HeadTeacher &&
                                            getQueryStringVal(
                                              QueryStringTokens.schoolToken
                                            ) === null &&
                                            this.state.additionalFilters.map(
                                              (item) => {
                                                return item.IsFilterBenchMarksAvailable ? (
                                                  <>
                                                    <li
                                                      className={`job-role-heading ${
                                                        !this.state
                                                          .collapseAdditionalFilters[
                                                          item.FilterName
                                                        ]
                                                          ? ''
                                                          : 'active'
                                                      }`}
                                                      onClick={() => {
                                                        this.setState({
                                                          collapseSchoolName: false,
                                                          collapseJobRole: false,
                                                          collapseTimeAtSchool: false,
                                                          collapseEmploymentType: false,
                                                          collapseSupportStaffClassroomBySchoolName: false,
                                                          collapseSupportStaffNonClassroomBySchoolName: false,
                                                          collapseTeachingStaffClassroomBySchoolName: false,
                                                          collapseTeachingStaffNonClassroomBySchoolName: false,
                                                          collapseMiddleLeaderScoreBySchoolName: false,
                                                          collapseSeniorLeaderScoresBySchoolName: false,
                                                          collapseLessThan1YearServiceBySchoolName: false,
                                                          collapseBetween1to3YearServiceBySchoolName: false,
                                                          collapseBetween4to5YearServiceBySchoolName: false,
                                                          collapseBetween6to10YearServiceBySchoolName: false,
                                                          collapseBetween11to15YearServiceBySchoolName: false,
                                                          collapseBetween16to20YearServiceBySchoolName: false,
                                                          collapseGretterThan20YearServiceBySchoolName: false,
                                                          collapseFullTimeStaffScoresBySchoolName: false,
                                                          collapsePartlTimeStaffScoresBySchoolName: false,
                                                          dropdownText:
                                                            item.FilterName,
                                                        });

                                                        this.state.additionalFilters.forEach(
                                                          (items) => {
                                                            let collapseState =
                                                              this.state
                                                                .collapseAdditionalFilters;
                                                            collapseState[
                                                              items.FilterName
                                                            ] = false;
                                                            this.setState({
                                                              collapseAdditionalFilters:
                                                                collapseState,
                                                            });
                                                          }
                                                        );

                                                        let collapseState =
                                                          this.state
                                                            .collapseAdditionalFilters;
                                                        collapseState[
                                                          item.FilterName
                                                        ] =
                                                          !collapseState[
                                                            item.FilterName
                                                          ] &&
                                                          item.IsFilterBenchMarksAvailable;
                                                        this.setState({
                                                          collapseAdditionalFilters:
                                                            collapseState,
                                                        });
                                                      }}
                                                    >
                                                      {item.FilterName}
                                                    </li>
                                                  </>
                                                ) : (
                                                  <></>
                                                );
                                              }
                                            )}
                                          {/* Additional FIlters Dropdown Items Added starts here */}
                                        </ul>
                                      </div>
                                    </Box>
                                  ) : null}
                                </Box>
                              </ClickAwayListener>
                            ) : (
                              <></>
                            )}
                            <div className='search-inner-sub-container'>
                              <div className='staff-type-grid'>
                                <span className='heat-map-heading'>
                                  Filters
                                </span>

                                <span className='heat-map-grid-heading'>
                                  <ul className='upper-heading'>
                                    <li>Management Standards</li>
                                  </ul>
                                  <ul>
                                    <li>Demands</li>
                                    <li>Control</li>
                                    <li>Manager support</li>
                                    <li>Peer support</li>
                                    <li>Relationships</li>
                                    <li>Role</li>
                                    <li>Change</li>
                                  </ul>
                                </span>
                              </div>
                              <div className='staff-type-grid-data staff-type-grid'>
                                {getQueryStringVal(
                                  QueryStringTokens.IsMatSchoolFromHeatmap
                                ) === 'true' && (
                                  <span className='grid-role-heading'>
                                    <ul>
                                      <li>{this.state.GroupName} Score</li>
                                    </ul>
                                  </span>
                                )}
                                {getQueryStringVal(
                                  QueryStringTokens.IsMatSchoolFromHeatmap
                                ) === 'true' && (
                                  <span className='grid-data grid-data-heading '>
                                    {matScore && (
                                      <ul>
                                        <HeatmapCell
                                          Decile={matScore.DemandColor}
                                          Value={matScore.Demand}
                                          Allow={true}
                                          AllStaff={true}
                                        />
                                        <HeatmapCell
                                          Decile={matScore.ControlColor}
                                          Value={matScore.Control}
                                          Allow={true}
                                          AllStaff={true}
                                        />
                                        <HeatmapCell
                                          Decile={matScore.ManagerSupportColor}
                                          Value={matScore.ManagerSupport}
                                          Allow={true}
                                          AllStaff={true}
                                        />
                                        <HeatmapCell
                                          Decile={matScore.PeerSupportColor}
                                          Value={matScore.PeerSupport}
                                          Allow={true}
                                          AllStaff={true}
                                        />
                                        <HeatmapCell
                                          Decile={matScore.RelationshipsColor}
                                          Value={matScore.Relationships}
                                          Allow={true}
                                          AllStaff={true}
                                        />
                                        <HeatmapCell
                                          Decile={matScore.RoleColor}
                                          Value={matScore.Role}
                                          Allow={true}
                                          AllStaff={true}
                                        />
                                        <HeatmapCell
                                          Decile={matScore.ChangeColor}
                                          Value={matScore.Change}
                                          Allow={true}
                                          AllStaff={true}
                                        />
                                      </ul>
                                    )}
                                  </span>
                                )}
                                {getQueryStringVal(
                                  QueryStringTokens.IsMatSchoolFromHeatmap
                                ) === 'true' ? (
                                  <span className='grid-role-heading'>
                                    <ul>
                                      <li>School Score</li>
                                    </ul>
                                  </span>
                                ) : (
                                  <span className='grid-role-heading'>
                                    <ul>
                                      <li>{this.state.GroupName} Score </li>
                                    </ul>
                                  </span>
                                )}
                                <span className='grid-data grid-data-heading '>
                                  {allStaff && (
                                    <ul>
                                      <HeatmapCell
                                        Decile={allStaff.DemandColor}
                                        Value={allStaff.Demand}
                                        Allow={true}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.ControlColor}
                                        Value={allStaff.Control}
                                        Allow={true}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.ManagerSupportColor}
                                        Value={allStaff.ManagerSupport}
                                        Allow={true}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.PeerSupportColor}
                                        Value={allStaff.PeerSupport}
                                        Allow={true}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.RelationshipsColor}
                                        Value={allStaff.Relationships}
                                        Allow={true}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.RoleColor}
                                        Value={allStaff.Role}
                                        Allow={true}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.ChangeColor}
                                        Value={allStaff.Change}
                                        Allow={true}
                                        AllStaff={true}
                                      />
                                    </ul>
                                  )}
                                </span>
                                {isCustomHeatmap ? (
                                  <MatCustomHeatmap
                                    staffHeatmap={Customheatmap}
                                    isCustomHeatmap={isCustomHeatmap}
                                    isLoading={isCustomHeatmapLoading}
                                    customHeatmapError={customHeatmapError}
                                  />
                                ) : (
                                  <Scrollbars
                                    style={{ height: 400, width: '100%' }}
                                  >
                                    {this.state.isSchoolNameShow &&
                                      this.state.collapseSchoolName && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state.collapseSchoolName
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                        >
                                          {this.state.isAnnonymised
                                            ? 'SCHOOL RANKING'
                                            : 'ALL STAFF SCORES'}{' '}
                                          BY SCHOOL
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      this.state.collapseSchoolName &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <div className='staff-type-grid-data staff-type-grid'>
                                              <span className='grid-role-heading'>
                                                <ul>
                                                  <li>{item.Name}</li>
                                                </ul>
                                              </span>
                                              <span className='grid-data'>
                                                <ul>
                                                  <HeatmapCell
                                                    Decile={
                                                      item.HeatMap.DemandColor
                                                    }
                                                    Value={item.HeatMap.Demand}
                                                    Allow={true}
                                                    AllStaff={true}
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.HeatMap.ControlColor
                                                    }
                                                    Value={item.HeatMap.Control}
                                                    Allow={true}
                                                    AllStaff={true}
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.HeatMap
                                                        .ManagerSupportColor
                                                    }
                                                    Value={
                                                      item.HeatMap
                                                        .ManagerSupport
                                                    }
                                                    Allow={true}
                                                    AllStaff={true}
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.HeatMap
                                                        .PeerSupportColor
                                                    }
                                                    Value={
                                                      item.HeatMap.PeerSupport
                                                    }
                                                    Allow={true}
                                                    AllStaff={true}
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.HeatMap
                                                        .RelationshipsColor
                                                    }
                                                    Value={
                                                      item.HeatMap.Relationships
                                                    }
                                                    Allow={true}
                                                    AllStaff={true}
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.HeatMap.RoleColor
                                                    }
                                                    Value={item.HeatMap.Role}
                                                    Allow={true}
                                                    AllStaff={true}
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.HeatMap.ChangeColor
                                                    }
                                                    Value={item.HeatMap.Change}
                                                    Allow={true}
                                                    AllStaff={true}
                                                  />
                                                </ul>
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      this.state.collapseSchoolName &&
                                      this.state.annonymisedSchoolScores.HeatMapAllSchools.map(
                                        (item, key) => {
                                          return (
                                            <div className='staff-type-grid-data staff-type-grid'>
                                              <span className='grid-role-heading'>
                                                <ul>
                                                  <li
                                                    style={{
                                                      paddingLeft: '85%',
                                                    }}
                                                  >
                                                    {key + 1}
                                                    {key + 1 === 1
                                                      ? 'st'
                                                      : key + 1 === 2
                                                      ? 'nd'
                                                      : key + 1 === 3
                                                      ? 'rd'
                                                      : 'th'}
                                                  </li>
                                                </ul>
                                              </span>
                                              <span className='grid-data'>
                                                <ul>
                                                  <HeatmapCell
                                                    Decile={item.Demand.Color}
                                                    Value={item.Demand.Value}
                                                    Allow={true}
                                                    AllStaff={false}
                                                    IsAnonymised={
                                                      this.state.schoolId ===
                                                      item.Demand.Id
                                                    }
                                                  />
                                                  <HeatmapCell
                                                    Decile={item.Control.Color}
                                                    Value={item.Control.Value}
                                                    Allow={true}
                                                    AllStaff={false}
                                                    IsAnonymised={
                                                      this.state.schoolId ===
                                                      item.Control.Id
                                                    }
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.ManagerSupport.Color
                                                    }
                                                    Value={
                                                      item.ManagerSupport.Value
                                                    }
                                                    Allow={true}
                                                    AllStaff={false}
                                                    IsAnonymised={
                                                      this.state.schoolId ===
                                                      item.ManagerSupport.Id
                                                    }
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.PeerSupport.Color
                                                    }
                                                    Value={
                                                      item.PeerSupport.Value
                                                    }
                                                    Allow={true}
                                                    AllStaff={false}
                                                    IsAnonymised={
                                                      this.state.schoolId ===
                                                      item.PeerSupport.Id
                                                    }
                                                  />
                                                  <HeatmapCell
                                                    Decile={
                                                      item.Relationship.Color
                                                    }
                                                    Value={
                                                      item.Relationship.Value
                                                    }
                                                    Allow={true}
                                                    AllStaff={false}
                                                    IsAnonymised={
                                                      this.state.schoolId ===
                                                      item.Relationship.Id
                                                    }
                                                  />
                                                  <HeatmapCell
                                                    Decile={item.Role.Color}
                                                    Value={item.Role.Value}
                                                    Allow={true}
                                                    AllStaff={false}
                                                    IsAnonymised={
                                                      this.state.schoolId ===
                                                      item.Role.Id
                                                    }
                                                  />
                                                  <HeatmapCell
                                                    Decile={item.Change.Color}
                                                    Value={item.Change.Value}
                                                    Allow={true}
                                                    AllStaff={false}
                                                    IsAnonymised={
                                                      this.state.schoolId ===
                                                      item.Change.Id
                                                    }
                                                  />
                                                </ul>
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    {this.state.collapseJobRole &&
                                    getQueryStringVal(
                                      QueryStringTokens.IsMatSchoolFromHeatmap
                                    ) === 'true' ? (
                                      <span
                                        className={`job-role-heading ${
                                          !this.state.collapseJobRole &&
                                          jobRole.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                        }`}
                                        //onClick={() => { this.setState({ collapseJobRole: !this.state.collapseJobRole && jobRole.IsFilterBenchMarksAvailable, }); }}
                                      >
                                        Job Role
                                        {!jobRole.IsFilterBenchMarksAvailable ? (
                                          <em className='nodata'>
                                            No data is available to display
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    ) : this.state.collapseJobRole ? (
                                      <span
                                        className={`job-role-heading ${
                                          !this.state.collapseJobRole &&
                                          jobRole.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                        }`}
                                        //onClick={() => { this.setState({ collapseJobRole: !this.state.collapseJobRole && jobRole.IsFilterBenchMarksAvailable, }); }}
                                      >
                                        Job Role Scores Across all Schools
                                        {!jobRole.IsFilterBenchMarksAvailable ? (
                                          <em className='nodata'>
                                            No data is available to display
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    ) : (
                                      <span></span>
                                    )}
                                    {this.state.collapseJobRole &&
                                    jobRole.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span className='grid-role-heading'>
                                          <ul>
                                            {jobRole.HeatMapsModel.map(
                                              (item) => {
                                                return (
                                                  <>
                                                    {item.IsBenchMarkAvailable ? (
                                                      <li>
                                                        {this.state.region ===
                                                          'us' &&
                                                        item.StaffType ===
                                                          'Middle Leader'
                                                          ? 'Building Administrator'
                                                          : this.state
                                                              .region ===
                                                              'us' &&
                                                            item.StaffType ===
                                                              'Senior Leader'
                                                          ? 'District Administrator'
                                                          : item.StaffType}{' '}
                                                        <span>
                                                          {item.ClassRoom}
                                                        </span>
                                                      </li>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </span>
                                        <span className='grid-data'>
                                          {jobRole.HeatMapsModel.map(
                                            (items, index) => {
                                              return (
                                                <>
                                                  {items.IsBenchMarkAvailable ? (
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          items.DemandColor
                                                        }
                                                        Value={items.Demand}
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ControlColor
                                                        }
                                                        Value={items.Control}
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ManagerSupportColor
                                                        }
                                                        Value={
                                                          items.ManagerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.PeerSupportColor
                                                        }
                                                        Value={
                                                          items.PeerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.RelationshipsColor
                                                        }
                                                        Value={
                                                          items.Relationships
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.RoleColor}
                                                        Value={items.Role}
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ChangeColor
                                                        }
                                                        Value={items.Change}
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {this.state.collapseTimeAtSchool &&
                                    getQueryStringVal(
                                      QueryStringTokens.IsMatSchoolFromHeatmap
                                    ) === 'true' ? (
                                      <span
                                        className={`job-role-heading ${
                                          !this.state.collapseTimeAtSchool &&
                                          timeAtSchool.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                        }`}
                                        //onClick={() => { this.setState({ collapseTimeAtSchool: !this.state.collapseTimeAtSchool && timeAtSchool.IsFilterBenchMarksAvailable, }); }}
                                      >
                                        Time at school
                                        {!timeAtSchool.IsFilterBenchMarksAvailable ? (
                                          <em className='nodata'>
                                            No data is available to display
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    ) : this.state.collapseTimeAtSchool ? (
                                      <span
                                        className={`job-role-heading ${
                                          !this.state.collapseTimeAtSchool &&
                                          timeAtSchool.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                        }`}
                                        //onClick={() => { this.setState({ collapseTimeAtSchool: !this.state.collapseTimeAtSchool && timeAtSchool.IsFilterBenchMarksAvailable, }); }}
                                      >
                                        Time at school Across all Schools
                                        {!timeAtSchool.IsFilterBenchMarksAvailable ? (
                                          <em className='nodata'>
                                            No data is available to display
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    ) : (
                                      <span></span>
                                    )}
                                    {this.state.collapseTimeAtSchool &&
                                    timeAtSchool.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span className='grid-role-heading'>
                                          <ul>
                                            {timeAtSchool.HeatMapsModel.map(
                                              (item) => {
                                                return (
                                                  <>
                                                    {item.IsBenchMarkAvailable ? (
                                                      <li>{item.StaffType}</li>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </span>
                                        <span className='grid-data'>
                                          {timeAtSchool.HeatMapsModel.map(
                                            (items, index) => {
                                              return (
                                                <>
                                                  {items.IsBenchMarkAvailable ? (
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          items.DemandColor
                                                        }
                                                        Value={items.Demand}
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ControlColor
                                                        }
                                                        Value={items.Control}
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ManagerSupportColor
                                                        }
                                                        Value={
                                                          items.ManagerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.PeerSupportColor
                                                        }
                                                        Value={
                                                          items.PeerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.RelationshipsColor
                                                        }
                                                        Value={
                                                          items.Relationships
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.RoleColor}
                                                        Value={items.Role}
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ChangeColor
                                                        }
                                                        Value={items.Change}
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {this.state.collapseEmploymentType &&
                                    getQueryStringVal(
                                      QueryStringTokens.IsMatSchoolFromHeatmap
                                    ) === 'true' ? (
                                      <span
                                        className={`job-role-heading ${
                                          !this.state.collapseEmploymentType &&
                                          employmentType.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                        }`}
                                        //onClick={() => { this.setState({ collapseEmploymentType: !this.state.collapseEmploymentType && employmentType.IsFilterBenchMarksAvailable, }); }}
                                      >
                                        Employment type
                                        {!employmentType.IsFilterBenchMarksAvailable ? (
                                          <em className='nodata'>
                                            No data is available to display
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    ) : this.state.collapseEmploymentType ? (
                                      <span
                                        className={`job-role-heading ${
                                          !this.state.collapseEmploymentType &&
                                          jobRole.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                        }`}
                                        //onClick={() => { this.setState({ collapseEmploymentType: !this.state.collapseEmploymentType && jobRole.IsFilterBenchMarksAvailable, }); }}
                                      >
                                        Employment type Across all Schools
                                        {!employmentType.IsFilterBenchMarksAvailable ? (
                                          <em className='nodata'>
                                            No data is available to display
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    ) : (
                                      <span></span>
                                    )}
                                    {this.state.collapseEmploymentType &&
                                    employmentType.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span className='grid-role-heading'>
                                          <ul>
                                            {employmentType.HeatMapsModel.map(
                                              (item) => {
                                                return (
                                                  <>
                                                    {item.IsBenchMarkAvailable ? (
                                                      <li>
                                                        {item.StaffType}{' '}
                                                        <span>
                                                          {item.ClassRoom}
                                                        </span>
                                                      </li>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </span>
                                        <span className='grid-data'>
                                          {employmentType.HeatMapsModel.map(
                                            (items, index) => {
                                              return (
                                                <>
                                                  {items.IsBenchMarkAvailable ? (
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          items.DemandColor
                                                        }
                                                        Value={items.Demand}
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ControlColor
                                                        }
                                                        Value={items.Control}
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ManagerSupportColor
                                                        }
                                                        Value={
                                                          items.ManagerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.PeerSupportColor
                                                        }
                                                        Value={
                                                          items.PeerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.RelationshipsColor
                                                        }
                                                        Value={
                                                          items.Relationships
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.RoleColor}
                                                        Value={items.Role}
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ChangeColor
                                                        }
                                                        Value={items.Change}
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseSupportStaffClassroomBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseSupportStaffClassroomBySchoolName &&
                                            this.state.allSchools
                                              .IsSupportStaffClassRoomAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseSupportStaffClassroomBySchoolName: !this.state.collapseSupportStaffClassroomBySchoolName && this.state.allSchools.IsSupportStaffClassRoomAvailable, }); }}
                                        >
                                          Support Staff Classroom{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          by School
                                          {!this.state.allSchools
                                            .IsSupportStaffClassRoomAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSupportStaffClassroomBySchoolName &&
                                      jobRole.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 0 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={false}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={false}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={false}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={false}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={false}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      !this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSupportStaffClassroomBySchoolName &&
                                      this.state.allSchools
                                        .IsSupportStaffClassRoomAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapSupportStafClasroom
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .Control
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafClasroom
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSupportStaffClassroomBySchoolName &&
                                      this.state.allSchools
                                        .IsSupportStaffClassRoomAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapSupportStafClasroom.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseSupportStaffNonClassroomBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseSupportStaffNonClassroomBySchoolName &&
                                            this.state.allSchools
                                              .IsSupportStaffNonClassRoomAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseSupportStaffNonClassroomBySchoolName: !this.state.collapseSupportStaffNonClassroomBySchoolName && this.state.allSchools.IsSupportStaffNonClassRoomAvailable, }); }}
                                        >
                                          Support Staff Non-Classroom{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          by School
                                          {!this.state.allSchools
                                            .IsSupportStaffNonClassRoomAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSupportStaffNonClassroomBySchoolName &&
                                      jobRole.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 1 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSupportStaffNonClassroomBySchoolName &&
                                      this.state.allSchools
                                        .IsSupportStaffNonClassRoomAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item
                                                .HeatMapSupportStafNonClasroom
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .Control
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSupportStafNonClasroom
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSupportStaffNonClassroomBySchoolName &&
                                      this.state.allSchools
                                        .IsSupportStaffNonClassRoomAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapSupportStafNonClasroom.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseTeachingStaffClassroomBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseTeachingStaffClassroomBySchoolName &&
                                            this.state.allSchools
                                              .IsTeachingStaffClassRoomAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseTeachingStaffClassroomBySchoolName: !this.state.collapseTeachingStaffClassroomBySchoolName && this.state.allSchools.IsTeachingStaffClassRoomAvailable, }); }}
                                        >
                                          Teaching Staff Classroom{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          by School
                                          {!this.state.allSchools
                                            .IsTeachingStaffClassRoomAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseTeachingStaffClassroomBySchoolName &&
                                      jobRole.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 2 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseTeachingStaffClassroomBySchoolName &&
                                      this.state.allSchools
                                        .IsTeachingStaffClassRoomAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapTeachingStafClasroom
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .Control
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .Role
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafClasroom
                                                            .Change
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseTeachingStaffClassroomBySchoolName &&
                                      this.state.allSchools
                                        .IsTeachingStaffClassRoomAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapteachingStafClasroom.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={true}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={true}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={true}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={true}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={true}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={true}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={true}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseTeachingStaffNonClassroomBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseTeachingStaffNonClassroomBySchoolName &&
                                            this.state.allSchools
                                              .IsTeachingStaffNonClassRoomAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseTeachingStaffNonClassroomBySchoolName: !this.state.collapseTeachingStaffNonClassroomBySchoolName && this.state.allSchools.IsTeachingStaffNonClassRoomAvailable, }); }}
                                        >
                                          Teaching Staff Non-Classroom{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          by School
                                          {!this.state.allSchools
                                            .IsTeachingStaffNonClassRoomAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseTeachingStaffNonClassroomBySchoolName &&
                                      jobRole.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 3 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      !this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseTeachingStaffNonClassroomBySchoolName &&
                                      this.state.allSchools
                                        .IsTeachingStaffNonClassRoomAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item
                                                .HeatMapTeachingStafNonClasroom
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapTeachingStafNonClasroom
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseTeachingStaffNonClassroomBySchoolName &&
                                      this.state.allSchools
                                        .IsTeachingStaffNonClassRoomAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapteachingStafNonClasroom.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseMiddleLeaderScoreBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseMiddleLeaderScoreBySchoolName &&
                                            this.state.allSchools
                                              .IsMiddleLeaderScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseMiddleLeaderScoreBySchoolName: !this.state.collapseMiddleLeaderScoreBySchoolName && this.state.allSchools.IsMiddleLeaderScoreAvailable, }); }}
                                        >
                                          {this.state.region === 'us'
                                            ? 'Building Administrator'
                                            : 'Middle Leader'}{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          by School
                                          {!this.state.allSchools
                                            .IsMiddleLeaderScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseMiddleLeaderScoreBySchoolName &&
                                      jobRole.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 4 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseMiddleLeaderScoreBySchoolName &&
                                      this.state.allSchools
                                        .IsMiddleLeaderScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapMiddleLeader
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapMiddleLeader
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseMiddleLeaderScoreBySchoolName &&
                                      this.state.allSchools
                                        .IsMiddleLeaderScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapMiddleLeader.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseSeniorLeaderScoresBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseSeniorLeaderScoresBySchoolName &&
                                            this.state.allSchools
                                              .IsSeniorLeaderScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseSeniorLeaderScoresBySchoolName: !this.state.collapseSeniorLeaderScoresBySchoolName && this.state.allSchools.IsSeniorLeaderScoreAvailable, }); }}
                                        >
                                          {this.state.region === 'us'
                                            ? 'District Administrator'
                                            : 'Senior Leader'}{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          by School
                                          {!this.state.allSchools
                                            .IsSeniorLeaderScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSeniorLeaderScoresBySchoolName &&
                                      jobRole.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 5 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSeniorLeaderScoresBySchoolName &&
                                      this.state.allSchools
                                        .IsSeniorLeaderScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapSeniorLeader
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapSeniorLeader
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseSeniorLeaderScoresBySchoolName &&
                                      this.state.allSchools
                                        .IsSeniorLeaderScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapSeniorLeader.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseLessThan1YearServiceBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseLessThan1YearServiceBySchoolName &&
                                            this.state.allSchools
                                              .IsStaffWithLessThan1YearServiceScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseLessThan1YearServiceBySchoolName: !this.state.collapseLessThan1YearServiceBySchoolName && this.state.allSchools.IsStaffWithLessThan1YearServiceScoreAvailable, }); }}
                                        >
                                          STAFF WITH Less Than 1 YR SERVICE{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          BY SCHOOL
                                          {!this.state.allSchools
                                            .IsStaffWithLessThan1YearServiceScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseLessThan1YearServiceBySchoolName &&
                                      timeAtSchool.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 0 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseLessThan1YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWithLessThan1YearServiceScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapLessThan1Year
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapLessThan1Year
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseLessThan1YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWithLessThan1YearServiceScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapLessThan1Year.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseBetween1to3YearServiceBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseBetween1to3YearServiceBySchoolName &&
                                            this.state.allSchools
                                              .IsStaffWith1To3YearServiceScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseBetween1to3YearServiceBySchoolName: !this.state.collapseBetween1to3YearServiceBySchoolName && this.state.allSchools.IsStaffWith1To3YearServiceScoreAvailable, }); }}
                                        >
                                          {this.state.isAnnonymised
                                            ? 'STAFF WITH 1-3 YRS SERVICE RAMKING BY SCHOOL'
                                            : 'STAFF WITH 1-3 YRS SERVICE SCORES BY SCHOOL'}
                                          {!this.state.allSchools
                                            .IsStaffWith1To3YearServiceScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween1to3YearServiceBySchoolName &&
                                      timeAtSchool.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 1 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween1to3YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith1To3YearServiceScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapWithin1to3Years
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin1to3Years
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween1to3YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith1To3YearServiceScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapWithin1to3Years.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseBetween4to5YearServiceBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseBetween4to5YearServiceBySchoolName &&
                                            this.state.allSchools
                                              .IsStaffWith4To5YearServiceScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseBetween4to5YearServiceBySchoolName: !this.state.collapseBetween4to5YearServiceBySchoolName && this.state.allSchools.IsStaffWith4To5YearServiceScoreAvailable, }); }}
                                        >
                                          STAFF WITH 4-5 YRS SERVICE{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          BY SCHOOL
                                          {!this.state.allSchools
                                            .IsStaffWith4To5YearServiceScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween4to5YearServiceBySchoolName &&
                                      timeAtSchool.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 2 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween4to5YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith4To5YearServiceScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapWithin4to5Years
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin4to5Years
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween4to5YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith4To5YearServiceScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapteachingStafNonClasroom.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseBetween6to10YearServiceBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseBetween6to10YearServiceBySchoolName &&
                                            this.state.allSchools
                                              .IsStaffWith6To10YearServiceScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseBetween6to10YearServiceBySchoolName: !this.state.collapseBetween6to10YearServiceBySchoolName && this.state.allSchools.IsStaffWith6To10YearServiceScoreAvailable, }); }}
                                        >
                                          STAFF WITH 6-10 YRS SERVICE{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          BY SCHOOL
                                          {!this.state.allSchools
                                            .IsStaffWith6To10YearServiceScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween6to10YearServiceBySchoolName &&
                                      timeAtSchool.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 3 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      !this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween6to10YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith6To10YearServiceScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapWithin6to10Years
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin6to10Years
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween6to10YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith6To10YearServiceScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapWithin6to10Years.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseBetween11to15YearServiceBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseBetween11to15YearServiceBySchoolName &&
                                            this.state.allSchools
                                              .IsStaffWith11To15YearServiceScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseBetween11to15YearServiceBySchoolName: !this.state.collapseBetween11to15YearServiceBySchoolName && this.state.allSchools.IsStaffWith11To15YearServiceScoreAvailable, }); }}
                                        >
                                          STAFF WITH 11-15 YRS SERVICE{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          BY SCHOOL
                                          {!this.state.allSchools
                                            .IsStaffWith11To15YearServiceScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween11to15YearServiceBySchoolName &&
                                      timeAtSchool.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 4 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      !this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween11to15YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith11To15YearServiceScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapWithin11to15Years
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin11to15Years
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween11to15YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith11To15YearServiceScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapWithin11to15Years.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseBetween16to20YearServiceBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseBetween16to20YearServiceBySchoolName &&
                                            this.state.allSchools
                                              .IsStaffWith16To20YearServiceScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseBetween16to20YearServiceBySchoolName: !this.state.collapseBetween16to20YearServiceBySchoolName && this.state.allSchools.IsStaffWith16To20YearServiceScoreAvailable, }); }}
                                        >
                                          STAFF WITH 16-20 YRS SERVICE{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          BY SCHOOL
                                          {!this.state.allSchools
                                            .IsStaffWith16To20YearServiceScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween16to20YearServiceBySchoolName &&
                                      timeAtSchool.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 5 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween16to20YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith16To20YearServiceScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapWithin16to20Years
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapWithin16to20Years
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseBetween16to20YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWith16To20YearServiceScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapWithin16to20Years.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseGretterThan20YearServiceBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseGretterThan20YearServiceBySchoolName &&
                                            this.state.allSchools
                                              .IsStaffWithMoreThan20YearServiceScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseGretterThan20YearServiceBySchoolName: !this.state.collapseGretterThan20YearServiceBySchoolName && this.state.allSchools.IsStaffWithMoreThan20YearServiceScoreAvailable, }); }}
                                        >
                                          STAFF WITH GREATER THAN 20 YRS SERVICE{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          BY SCHOOL
                                          {!this.state.allSchools
                                            .IsStaffWithMoreThan20YearServiceScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseGretterThan20YearServiceBySchoolName &&
                                      timeAtSchool.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 6 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseGretterThan20YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWithMoreThan20YearServiceScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapGreaterThan20Years
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapGreaterThan20Years
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseGretterThan20YearServiceBySchoolName &&
                                      this.state.allSchools
                                        .IsStaffWithMoreThan20YearServiceScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapGreaterThan20Years.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapseFullTimeStaffScoresBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapseFullTimeStaffScoresBySchoolName &&
                                            this.state.allSchools
                                              .IsFullTimeStaffScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapseFullTimeStaffScoresBySchoolName: !this.state.collapseFullTimeStaffScoresBySchoolName && this.state.allSchools.IsFullTimeStaffScoreAvailable, }); }}
                                        >
                                          FULL TIME STAFF{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          BY SCHOOL
                                          {!this.state.allSchools
                                            .IsFullTimeStaffScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseFullTimeStaffScoresBySchoolName &&
                                      employmentType.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 0 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      !this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseFullTimeStaffScoresBySchoolName &&
                                      this.state.allSchools
                                        .IsFullTimeStaffScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapFullTimeStaff
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapFullTimeStaff
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state.isAnnonymised &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapseFullTimeStaffScoresBySchoolName &&
                                      this.state.allSchools
                                        .IsFullTimeStaffScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapFullTimeStaff.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      this.state
                                        .collapsePartlTimeStaffScoresBySchoolName &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null && (
                                        <span
                                          className={`job-role-heading ${
                                            !this.state
                                              .collapsePartlTimeStaffScoresBySchoolName &&
                                            this.state.allSchools
                                              .IsPartTimeStaffScoreAvailable
                                              ? ''
                                              : 'greyStrep'
                                          }`}
                                          //onClick={() => { this.setState({ collapsePartlTimeStaffScoresBySchoolName: !this.state.collapsePartlTimeStaffScoresBySchoolName && this.state.allSchools.IsPartTimeStaffScoreAvailable, }); }}
                                        >
                                          PART TIME STAFF{' '}
                                          {this.state.isAnnonymised
                                            ? 'RANKING'
                                            : 'SCORES'}{' '}
                                          BY SCHOOL
                                          {!this.state.allSchools
                                            .IsPartTimeStaffScoreAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      loggedRole !== Roles.HeadTeacher &&
                                      getQueryStringVal(
                                        QueryStringTokens.schoolToken
                                      ) === null &&
                                      this.state
                                        .collapsePartlTimeStaffScoresBySchoolName &&
                                      employmentType.HeatMapsModel.map(
                                        (items, index) => {
                                          if (
                                            index === 1 &&
                                            items.IsBenchMarkAvailable
                                          )
                                            return (
                                              <div className='staff-type-grid-data staff-type-grid'>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    <li>All Schools</li>
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  <ul>
                                                    <HeatmapCell
                                                      Decile={items.DemandColor}
                                                      Value={items.Demand}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ControlColor
                                                      }
                                                      Value={items.Control}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.ManagerSupportColor
                                                      }
                                                      Value={
                                                        items.ManagerSupport
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.PeerSupportColor
                                                      }
                                                      Value={items.PeerSupport}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={
                                                        items.RelationshipsColor
                                                      }
                                                      Value={
                                                        items.Relationships
                                                      }
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.RoleColor}
                                                      Value={items.Role}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                    <HeatmapCell
                                                      Decile={items.ChangeColor}
                                                      Value={items.Change}
                                                      Allow={true}
                                                      AllStaff={true}
                                                    />
                                                  </ul>
                                                </span>
                                              </div>
                                            );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      this.state
                                        .collapsePartlTimeStaffScoresBySchoolName &&
                                      this.state.allSchools
                                        .IsPartTimeStaffScoreAvailable &&
                                      this.state.allSchools.SchoolHeatMaps.map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.HeatMapPartTimeStaff
                                                .IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>{item.Name}</li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .DemandColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .Demand
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .ControlColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .Control
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .ManagerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .ManagerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .PeerSupportColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .PeerSupport
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .RelationshipsColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .Relationships
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .RoleColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .Role
                                                        }
                                                        Allow={true}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .ChangeColor
                                                        }
                                                        Value={
                                                          item
                                                            .HeatMapPartTimeStaff
                                                            .Change
                                                        }
                                                        Allow={true}
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {this.state.isSchoolNameShow &&
                                      this.state.isAnnonymised &&
                                      this.state
                                        .collapsePartlTimeStaffScoresBySchoolName &&
                                      this.state.allSchools
                                        .IsPartTimeStaffScoreAvailable &&
                                      this.state.annonymisedSchoolScores.HeatMapPartTimeStaff.map(
                                        (item, key) => {
                                          return (
                                            <>
                                              {item.IsBenchMarkAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span className='grid-role-heading'>
                                                    <ul>
                                                      <li>
                                                        {key + 1}
                                                        {key + 1 === 1
                                                          ? 'st'
                                                          : key + 1 === 2
                                                          ? 'nd'
                                                          : key + 1 === 3
                                                          ? 'rd'
                                                          : 'th'}
                                                      </li>
                                                    </ul>
                                                  </span>
                                                  <span className='grid-data'>
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Demand.Color
                                                        }
                                                        Value={
                                                          item.Demand.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Demand.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Control.Color
                                                        }
                                                        Value={
                                                          item.Control.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Control.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.ManagerSupport
                                                            .Color
                                                        }
                                                        Value={
                                                          item.ManagerSupport
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.ManagerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.PeerSupport.Color
                                                        }
                                                        Value={
                                                          item.PeerSupport.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.PeerSupport.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Relationship
                                                            .Color
                                                        }
                                                        Value={
                                                          item.Relationship
                                                            .Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Relationship.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={item.Role.Color}
                                                        Value={item.Role.Value}
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Role.Id
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          item.Change.Color
                                                        }
                                                        Value={
                                                          item.Change.Value
                                                        }
                                                        Allow={true}
                                                        AllStaff={false}
                                                        IsAnonymised={
                                                          this.state
                                                            .schoolId ===
                                                          item.Change.Id
                                                        }
                                                      />
                                                    </ul>
                                                  </span>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    {/* Additional Filters taff type data */}
                                    {this.state.isSchoolNameShow &&
                                      !this.state.isAnnonymised &&
                                      this.state.additionalFilters.map(
                                        (item) => {
                                          return (
                                            <>
                                              {this.state
                                                .collapseAdditionalFilters[
                                                item.FilterName
                                              ] &&
                                              item.IsFilterBenchMarksAvailable ? (
                                                <div className='staff-type-grid-data staff-type-grid'>
                                                  <span
                                                    className={`job-role-heading ${
                                                      !this.state
                                                        .collapseAdditionalFilters[
                                                        item.FilterName
                                                      ] &&
                                                      item.IsFilterBenchMarksAvailable
                                                        ? ''
                                                        : 'greyStrep'
                                                    }`}
                                                  >
                                                    {item.FilterName}{' '}
                                                    {!item.IsFilterBenchMarksAvailable ? (
                                                      <em className='nodata'>
                                                        No data is available to
                                                        display
                                                      </em>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </span>
                                                  {this.state
                                                    .collapseAdditionalFilters[
                                                    item.FilterName
                                                  ] &&
                                                  item.IsFilterBenchMarksAvailable ? (
                                                    <>
                                                      <span className='grid-role-heading'>
                                                        <ul>
                                                          {item.HeatMapModel.map(
                                                            (filter, index) => {
                                                              return (
                                                                <>
                                                                  {filter.IsBenchMarkAvailable ? (
                                                                    <li>
                                                                      {
                                                                        filter.StaffType
                                                                      }
                                                                    </li>
                                                                  ) : (
                                                                    <></>
                                                                  )}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </span>
                                                      <span className='grid-data'>
                                                        {item.HeatMapModel.map(
                                                          (item, index) => {
                                                            return (
                                                              <>
                                                                {item.IsBenchMarkAvailable ? (
                                                                  <ul>
                                                                    <HeatmapCell
                                                                      Decile={
                                                                        item.DemandColor
                                                                      }
                                                                      Value={
                                                                        item.Demand
                                                                      }
                                                                      Allow={
                                                                        true
                                                                      }
                                                                      AllStaff={
                                                                        false
                                                                      }
                                                                      IsAnonymised={
                                                                        this
                                                                          .state
                                                                          .schoolId ===
                                                                        item.Id
                                                                      }
                                                                    />
                                                                    <HeatmapCell
                                                                      Decile={
                                                                        item.ControlColor
                                                                      }
                                                                      Value={
                                                                        item.Control
                                                                      }
                                                                      Allow={
                                                                        true
                                                                      }
                                                                      AllStaff={
                                                                        false
                                                                      }
                                                                      IsAnonymised={
                                                                        this
                                                                          .state
                                                                          .schoolId ===
                                                                        item.Id
                                                                      }
                                                                    />
                                                                    <HeatmapCell
                                                                      Decile={
                                                                        item.ManagerSupportColor
                                                                      }
                                                                      Value={
                                                                        item.ManagerSupport
                                                                      }
                                                                      Allow={
                                                                        true
                                                                      }
                                                                      AllStaff={
                                                                        false
                                                                      }
                                                                      IsAnonymised={
                                                                        this
                                                                          .state
                                                                          .schoolId ===
                                                                        item.Id
                                                                      }
                                                                    />
                                                                    <HeatmapCell
                                                                      Decile={
                                                                        item.PeerSupportColor
                                                                      }
                                                                      Value={
                                                                        item.PeerSupport
                                                                      }
                                                                      Allow={
                                                                        true
                                                                      }
                                                                      AllStaff={
                                                                        false
                                                                      }
                                                                      IsAnonymised={
                                                                        this
                                                                          .state
                                                                          .schoolId ===
                                                                        item.Id
                                                                      }
                                                                    />
                                                                    <HeatmapCell
                                                                      Decile={
                                                                        item.RelationshipsColor
                                                                      }
                                                                      Value={
                                                                        item.Relationships
                                                                      }
                                                                      Allow={
                                                                        true
                                                                      }
                                                                      AllStaff={
                                                                        false
                                                                      }
                                                                      IsAnonymised={
                                                                        this
                                                                          .state
                                                                          .schoolId ===
                                                                        item.Id
                                                                      }
                                                                    />
                                                                    <HeatmapCell
                                                                      Decile={
                                                                        item.RoleColor
                                                                      }
                                                                      Value={
                                                                        item.Role
                                                                      }
                                                                      Allow={
                                                                        true
                                                                      }
                                                                      AllStaff={
                                                                        false
                                                                      }
                                                                      IsAnonymised={
                                                                        this
                                                                          .state
                                                                          .schoolId ===
                                                                        item.Id
                                                                      }
                                                                    />
                                                                    <HeatmapCell
                                                                      Decile={
                                                                        item.ChangeColor
                                                                      }
                                                                      Value={
                                                                        item.Change
                                                                      }
                                                                      Allow={
                                                                        true
                                                                      }
                                                                      AllStaff={
                                                                        false
                                                                      }
                                                                      IsAnonymised={
                                                                        this
                                                                          .state
                                                                          .schoolId ===
                                                                        item.Id
                                                                      }
                                                                    />
                                                                  </ul>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                  </Scrollbars>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <IndividualHeatmapSelectionForm
                          title={'Download Heatmaps'}
                          submitButtonText={'Download'}
                          isOpen={this.state.isOpen}
                          downloadList={this.state.downloadList}
                          onClose={() => {
                            this.setState({ isOpen: false });
                          }}
                          onSelectionChange={this.onSelectionChange}
                          isLoading={this.state.isLoading}
                          allSelected={this.state.selectAll}
                          onSelectionAll={this.onSelectionAll}
                          onSubmit={this.downloadPdf}
                        />
                      </SuperExtendExtendedSubContainer>
                    </Grid>
                  </Grid>
                </Fragment>
              )
            )}
          </div>
        </div>
      </CommentsMainWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loaderAction: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(MatHeatMap));
