import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { borderIcon, downloadIcon } from '../../resources/images';
import { AssistanceWrapper } from '../../resources/styling/appStyle';

const {
    pictonBlue, teal, lightGrey, never,
    always, blackColor, purple,
    yellowColor, whiteColor, outrageousOrange, bordergrey
} = colors;

/*** Compound Slider ***/
export const SliderStyle = styled.div`
  width: 90%;
  padding: 5% 4%;

  ${media.lg`
    padding: 0% 4%;
  `}

  ${media.xl`
    padding: 5% 4%;
  `}

  .handle {
    background-color: ${colors.whiteColor};
    border: 1px solid ${colors.blackColor};

    ${media.lg`
      width: 75px;
      height: 75px;
      font-size: 12px;
      margin-top: 4px;
      margin-left: -75px;
    `};

    ${media.xl`
      font-size: 15px;
      width: 100px;
      height: 100px;
      margin-top: -10px;
      margin-left: -100px;
    `};

    .value {
      color: ${colors.blackColor};
      ${media.lg`
        font-size: 20px;
        margin-top: 24px;
      `};

      ${media.xl`
        font-size: 20px;
        margin-top: 38px;
      `};
    }

    .title {
      ${media.lg`
        font-size: 14px;
      `};

      ${media.xl`
        font-size: 20px;
      `};
    }
  }

  .handle:nth-child(1){
    border: 1px solid ${colors.blackColor};
    ${media.lg`
      width: 75px;
      height: 75px;
      margin-left: -75px;
    `};

    ${media.xl`
      width: 100px;
      height: 100px;
      margin-left: -100px;
    `};
  }

  .handle:nth-child(2){
    background-color: ${colors.purple};
    border: 1px solid ${colors.purple};
    margin-left: -15px;

    ${media.lg`
      width: 100px;
      height: 100px;
      font-size: 20px;
      margin-top: -12px;
      .title {
        margin-top: -152px;
      }
    `};

    ${media.xl`
      font-size: 30px;
      width: 150px;
      height: 150px;
      margin-top: -35px;
      .title {
        margin-top: -230px;
      }
    `};

    .value {
      color: ${colors.whiteColor};
      ${media.lg`
        font-size: 30px;
        margin-top: 34px;
      `};

      ${media.xl`
        font-size: 50px;
        margin-top: 45px;
      `};
    }
  }

  .handle:nth-child(3){
    border: 1px solid ${colors.blackColor};
    ${media.lg`
      margin-left: 100px;
    `};

    ${media.xl`
      margin-left: 150px;
    `};
  }

}`;
export const sliderStyle = {
  position: 'relative',
  height: 80,
  borderRight: `1px solid ${colors.blackColor}`,
  borderLeft: `1px solid ${colors.blackColor}`
};
export const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 2,
  marginTop: 39,
  backgroundColor: colors.blackColor
};
export const sliderBottomText = {
  0: 'School Benchmark Average',
  1: 'Our score',
  2: 'HSE Average'
};
export const handleStyle = {
  0: {
    fontSize: 15,
    width: 100,
    height: 100,
    marginTop: -10,
    backgroundColor: colors.whiteColor,
    border: `1px solid ${colors.blackColor}`,
    marginLeft: -100
  },
  1: {
    fontSize: 30,
    width: 150,
    height: 150,
    marginTop: -35,
    backgroundColor: colors.purple,
    border: `1px solid ${colors.purple}`,
    marginLeft: -15
  },
  2: {
    fontSize: 15,
    width: 100,
    height: 100,
    marginTop: -10,
    backgroundColor: colors.whiteColor,
    border: `1px solid ${colors.blackColor}`,
    marginLeft: 150
  }
};
export const handleValueStyle = {
  0: {
    color: colors.blackColor,
    fontSize: 20,
    marginTop: 38
  },
  1: {
    color: colors.whiteColor,
    fontSize: 50,
    marginTop: 45
  },
  2: {
    color: colors.blackColor,
    fontSize: 20,
    marginTop: 38
  }
};
export const bottomTextStyle = {
  0: {},
  1: { marginTop: '-240px' },
  2: {}
};

/*** Mat Presentation ***/
export const PageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0.7;
  z-index: 2;
  cursor: pointer;
`;
export const PresentationWrapperStyle = styled.div`
  .error-message {
    background: ${whiteColor};
    padding: 30px;
    margin-top: 30px;
    text-align: center;
    border: 1px solid ${bordergrey};
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    float: left;
    width: 100%;
  }
  .presentation-wrapper {
    margin-left: 50px;

    ${media.lg`
    margin-left: 199px;
  `}
    ${media.xl`
    margin-left: 80px;
    `}
    
    ${media.xs`
    margin-left: 0px;
    `}

    ${media.md`
    margin-left: 0px;
    `}
  }
  .overlayBody {
    position: relative;
    z-index: 3;
    padding: 0;
  }
  .survey-greeting {
    width: 100%;    
  }
  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }
    .carousel .slider-wrapper {
    ${media.xs`
    overflow-y: auto !important;
    `}
  }
  .carousel.carousel-slider {
    padding: 0 24px;
    overflow: initial !important;
  }

  .carousel .slide img {
    width: 30%;
  }
  .carousel .slide {
    background: transparent;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 0.7;
    width: 45px;
    height: 45px;
    line-height: 45px !important;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: ${pictonBlue};    
    :hover{
      opacity: 1;
    }
  }

  .carousel .control-next.control-arrow {
    right: -22px;    
    padding: 5px 16px;
  }
  .carousel .control-prev.control-arrow {
    left: -21px;
    padding: 5px 0px;
  }
  @media only screen and (max-width: 768px) {
    .presentation-slide {
      margin-left: 0px;
    }
  }

  .presentation-slide{
    margin-top: 0px;
  }
`;
export const PresentationInnerWrapperStyle = styled.div`

  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.1);
  background: ${whiteColor};
  float: left;
  height: 90vh;
  margin: 50px 8px 20px 8px;
  width: 99%;
  overflow: hidden; 

  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1){
    height: 70vh;
  }
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1){
    height: 95vh;
  }

  @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    height: 53vh
  }
  @media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    height: 70vh
  } 
 
  @media screen and (min-width: 1920px) {
    height: 70vh;
  }
  ${media.lg`
    margin: 50px 8px 20px 8px;
  `}
  ${media.xl`  
    margin: 30px 8px 20px 8px;
  `}
  span {
    text-align: left;
    padding: 0 40px;
    display: block;
    color: ${teal};
    font-size: 20px;
  }
  h2 {
    text-align: left;
    padding: 0 40px;
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 10px;
    color: ${teal};
  }
  div:nth-child(1){
    height: 100%;    
  }
  p{
    padding: 0 40px 0 40px;
    font-size: 14px;
    text-align: left;
    line-height: 18px;
    margin-bottom: 0;
  } 
`;
export const PageFooterStyle = styled.div`  
  font-size: 16px;
  text-align: left;
  width: 100%;  
  float: left;
  margin: 0 0 0 0;
  line-height: 26px;
 
  span:nth-child(1) {
    width: 49%;
    float: left;
    text-align: left;
    padding-left: 30px;
    color: ${teal};
    img {
      width: 34%;
    }
  }
  span:nth-child(2) {
    width: 49%;
    float: left;
    text-align: right;
  }  
`;
export const ExtendedAssistanceWrapper = styled(AssistanceWrapper)`
  width: 100%;
  position: relative;  
  
`;

/*** Question Category Result ***/
export const QuestionCategoryResultsStyle = styled.div`
  background-color: ${whiteColor};
  box-shadow: 0px 0px 5px 1px rgba(199, 199, 199, 1);
  float: left;
  position: relative;
  width: 100%;
  h1 {
    margin: 0 !important;
    padding: 0 !important;
    text-align: left;
    color: ${colors.purple};
  }
  .small-title {
    color: ${({ categoryColor = 'black' }) => categoryColor};

    font-size: 29px;
    margin: 0px;
    color: ${colors.purple};
    padding: 0 !important;
  }

  .big-title {
    color: ${colors.purple};
    font-size: 60px;
    margin: 10px 0;
  }

  .content {
    padding: 50px 50px 10px 50px;
    font-size: 25px;
    padding-top: 0px;
    line-height: 38px;
    width: 86%;
    float: left;
  }

  .app-logo {
    bottom: 10px;
    position: absolute;
    width: 100px;
    margin-left: 20px;
  }

  .question-category-results-header {
    padding: 40px 55px;
    height: 158px !important;
    background-color: ${yellowColor};
  }
  .question-category-results-body {
    float: left;
    width: 100%;
    background-color: ${colors.whiteColor};
    min-height: 67vh;
    ${media.md`
    min-height: 58.2vh;
    `}
    ${media.lg`
    min-height: 53vh;
    `}
    ${media.xl`
    min-height: 66.5vh;
    `}
  }

  table {
    border-collapse: collapse;
    width: 89%;
    margin-left: 50px;
  }

  tr:first-child,
  tr > td:first-child {
    font-weight: bold;
  }

  td {
    border: 1px solid black;
    text-align: center;
    padding: 8px;
  }

  ${media.md`
  .question-category-results-header {
    padding: 40px 55px;
  }
    .big-title {
      font-size: 60px;
      padding-top: 10px;
    }
    .content {
      font-size: 18px;
      padding-top: 0px;
      line-height: 28px;
      width: 83%;

    }
  `}

  ${media.lg`
  .question-category-results-header {
    padding: 40px 55px;
  }
    .big-title {
      font-size: 60px;
      padding-top: 10px;
    }
    .content {
      font-size: 18px;
      padding-top: 0px;
      line-height: 28px;
      width: 83%;

    }
  `}

  ul {
    padding: 50px;
    margin: 0 0 9px 0;
    list-style: none;
    line-height: 1.6;
    ${media.lg`
      padding:10px 50px;
    `};
    ${media.xl`
    margin: 0 0 125px 0;
    `}
    li {
      color: ${colors.mineShaft};
      font-size: 16px;
      padding: 0;
      text-align: left;
      margin: 0;
      width: 100%;
      font-weight: 400;
      line-height: 17px;
      margin-top: 11px;
      ${media.xl`
      line-height: 26px;
    `}
    }
    li::before {
      content: '•';
      color: ${colors.yellowColor};
      font-size: 15px;
      margin-right: 10px;
    }
  }
`;
export const CircleStyle = styled.span`
  background-color: ${({ backgroundColor = 'black' }) => backgroundColor};
  height: 180px;
  width: 180px;
  display: inline-block !important;
  border-radius: 50%;
  padding: 40px 0 !important;
  text-align: center !important;
  .all-organisations {
    font-size: 14px !important;
    margin-top: 4px;
  }
  .circle-text {
    float: left;
    width: 100%;    
    span {
      color: ${colors.whiteColor};
      float: left;
      text-align: center;
      width: 100%;
      font-size: 21px;
      line-height: 21px;
      padding: 0;
    }    
    .decile-type {
      font-size: 33px;
      font-weight: 900;         
      padding-top: 10px;
    }
    .circle-scrore{
      padding-top: 14px;
    }
  }

  ${media.lg`
    height: 150px;
    width: 150px;
    .circle-text {
      margin: 0;
    }
  `}

  ${media.xl`
    height: 160px;
    width: 160px;
    .circle-text {
      margin: 0;
    }
  `}
`;
export const SliderMainWrapper = styled.div`
  width: 100%;
  min-height: 50vh;
  float: left;
  padding: 0 55px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  > span:nth-child(1) {
    background: ${never};
  }
  > span:nth-child(2) {
    background: ${pictonBlue};
  }
  > span:nth-child(3) {
    background: ${teal};
  }
  > span:nth-child(4) {
    background: ${always};
  }
  .slider-main {
    width: 100%;
  }
  ${media.xl`
  min-height: 60vh;
  `}

  .inner-wrapper-right {
    border-right: 1px solid black;
    border-left: 1px solid black;
    height: 156px;
    width: 70%;
    position: relative;
    float: left;
    ${media.xl`
    height: 225px;
    `}
    span {
      width: 100%;
      height: 2px;
      background: ${blackColor};
      position: relative;
      i {
        font-style: normal;
        position: absolute;
        width: 50px;
        height: 50px;
        border: 1px solid ${blackColor};
        border-radius: 50%;
        top: -25px;
        left: 0;
        display: flex;
        align-items: center;
        background: ${whiteColor};
        color: ${blackColor};
        font-size: 12px;
        font-weight: 900;
        justify-content: center;
        ${media.xl`
        width: 70px;
        height: 70px;
        top: -35px;
        `}
      }
      .selected-value {
        background: ${purple} !important;
        color: ${whiteColor} !important;
        width: 70px;
        height: 70px;
        top: -38px;
        border: 1px solid ${purple} !important;
        font-size: 19px;
        ${media.xl`
        width: 100px;
        height: 100px;
        top: -50px;
        `}
      }
    }
    span:nth-child(1) {
      margin: 17px 0 30px 0;
    }
    span:nth-child(2) {
      margin: 60px 0 62px 0;
      ${media.xl`
      margin: 90px 0 94px 0;
      `}
    }
    span:nth-child(3) {
      margin: 17px 0 30px 0;
    }
    .left-number {
      float: left;
      font-weight: 900;
      font-size: 14px;
      position: absolute;
      left: -7px;
    }
    .right-number {
      float: right;
      font-weight: 900;
      font-size: 14px;
      position: absolute;
      right: -7px;
    }
  }
  .inner-wrapper-left {
    height: 180px !important;
    width: 20%;
    float: left;
    margin-left: 69px;
    ${media.xl`
    height: 223px;
    `}
    span {
      display: inline-block;
      padding-left: 0;
      font-size: 16px;
    }

    span:nth-child(1) {
      margin: 17px 0 8px 0;
    }
    span:nth-child(2) {
      margin: 29px 0 43px 0;
      ${media.xl`
      margin: 67px 0 74px 0;
      `}
    }
    span:nth-child(3) {
      margin: 17px 0 30px 0;
    }
  }
`;

/*** Survey Greeting Presentation ***/
export const SurveyGreetingPresentationStyle = styled.div`
  .left-section {
    float: left;
    width: 70%;
    .content {
      width: 86%;
      font-size: 30px;
    }
  }
  .red {
    color: ${colors.redColor};
    display: contents;
    font-weight: bold;
  }
  .teal-color {
    color: ${colors.teal};
    display: contents;
    font-weight: bold;
  }
  .right-section {
    float: left;
    width: 30%;
    background-color: ${colors.whiteColor};
    display: table;
    height: 100%;
  }
  .right-section > div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .legend-section{
    list-style-type: none;
    align-items: center;    
    display: flex;
    gap: 18px;
  }
  .circle-legend {
    width: 14px;
    height: 14px !important;
    border-radius: 50%;    
    margin-right: 5px;
  }
  .legend-text{
    text-transform: capitalize;
    font-size: 14px;
  }
  .legends{
    display: flex;
    align-items: center;
  }
`;

const SurveyReportPresentationStyle = {
    SliderStyle,
    sliderStyle,
    railStyle,
    sliderBottomText,
    handleStyle,
    handleValueStyle,
    bottomTextStyle,
    PageOverlay,
    PresentationWrapperStyle,
    PresentationInnerWrapperStyle,
    PageFooterStyle,
    ExtendedAssistanceWrapper,
    QuestionCategoryResultsStyle,
    CircleStyle,
    SliderMainWrapper,
    SurveyGreetingPresentationStyle
}
export default SurveyReportPresentationStyle;