import React from 'react';

const Legends = ({ category }) => {
  const survey1Colors = {
    1: { name: 'Demands', color: '#45338c' },
    2: { name: 'Control', color: '#e84f6b' },
    3: { name: 'Manager_Support', color: '#007a7a' },
    7: { name: 'Peer_Support', color: '#ff5939' },
    4: { name: 'Relationships', color: '#53b5e0' },
    5: { name: 'Role', color: '#fd92c2' },
    6: { name: 'Change', color: '#ffb205' },
  };

  const renderLegends = () => {
    if (category === 0) {
      // Display all legends in the specified order
      const orderedLegends = [1, 2, 3, 7, 4, 5, 6];
      return orderedLegends.map((key) => {
        const { name, color } = survey1Colors[key];
        return (
          <li key={key} className='legends'>
            <div className="circle-legend" style={{ backgroundColor: color }}></div>
            <div className='legend-text' style={{ color: color }}>{name.replace('_', ' ')}</div>
          </li>
        );
      });
    } else if (survey1Colors[category]) {
      // Display legend for the specified category
      const selectedCategory = survey1Colors[category];
      return (
        <li className='legends'>          
          <div className="circle-legend" style={{ backgroundColor: selectedCategory.color }} ></div>
          <div className='legend-text' style={{ color: selectedCategory.color }}>{selectedCategory.name.replace('_', ' ')}</div>
        </li>
      );
    }
    return null;
  };

  return (
    <ul className='legend-section'>
      {renderLegends()}
    </ul>
  );
};

export default Legends;
