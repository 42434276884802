import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderAction } from '../../../store/loaderStore';
import { navigationActions } from '../../../store/headerStore';
import { DirectorLeftMenu, MainMATHeader } from '../../../components';
import {
  AssistanceWrapper,
  DownloadButtonStyle,
} from '../../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { GroupDashboardMainWrapper } from '../../dashboard/DashboardStyle';
import { iconDownload } from '../../../resources/images/index';
import MatCycleDropDown from '../../../components/common/MatCycleDropDown';
import { MatReportWrapper } from '../MatStyle';
import { matGroupService, storageService } from '../../../services';
import { WebStorageNames, QueryStringTokens } from '../../../utils/Constants';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { withTranslation } from 'react-i18next';

class MatReport extends Component {
  constructor(props) {
    super(props);
    //const token = storageService.getItem(WebStorageNames.Token);
    //const userInfo = parseJwt(token);

    let groupType = storageService.getItem('groupType');

    this.state = {
      reportdata: {},
      matName: '',
      matType: '',
      loaded: false,
      matAvg: {},
      heatmapByRole: [],
      lowMstd: '',
      highMstd: '',
      heatmapBySchool: [],
      highestScoringSchools: [],
      lowestScoringSchools: [],
      highestScoringStandards: [],
      lowestScoringStandards: [],
      highScoreQuestions: [],
      lowScoreQuestions: [],
      selectedCycle: 0,
      errorMessage: false,
      groupType: groupType || this.props.t('MAT'),
    };
  }

  handleCycleChange = (selectedCycle) => {
    this.setState({ selectedCycle: selectedCycle.value });
    this.loadData(selectedCycle.value);
  };

  UsRegion = () => {
    const usRegion = storageService.getItem(WebStorageNames.UsRegion);
    return usRegion;
  };

  loadData = (cycleId) => {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    matGroupService
      .getMatGroupReport(
        storageService.getItem(WebStorageNames.MatGroupId),
        cycleId
      )
      .then((response) => {
        if (response && response.success) {
          this.setState({
            reportdata: response.data,
            loaded: true,
            matType: response.data.MatGroup?.GroupName,
            matName: response.data.MatGroup?.Name,
            matAvg: response.data.AverageModel,
            heatmapByRole: response.data.HeatmapByRole,
            heatmapBySchool: response.data.HeatmapBySchool,
            lowMstd: response.data.LowManagementStandards,
            highMstd: response.data.TopManagementStandards,
            highestScoringSchools: response.data.HighestScoringSchools,
            lowestScoringSchools: response.data.LowestScoringSchools,
            lowestScoringStandards: response.data.LowestScoringStandards,
            highestScoringStandards: response.data.HighestScoringStandards,
            highScoreQuestions: response.data.HighScoreQuestions,
            lowScoreQuestions: response.data.LowScoreQuestions,
          });
          loaderActions.dataLoading(false);
        }
      })
      .catch((err) => {
        loaderActions.dataLoading(false);
        this.setState({ loaded: true, errorMessage: true });
      });
  };
  componentDidMount() {
    this.loadData(0);
  }
  getHeatmapCell = (level, value, isDecile, preVal) => {
    return (
      <div
        className={
          level <= 3
            ? 'width-20-green'
            : level >= 7
            ? 'width-20-red'
            : 'width-10'
        }
      >
        {isDecile ? (
          <>
            {level}
            <sup>
              {level == 1 ? 'st' : level == 2 ? 'nd' : level == 3 ? 'rd' : 'th'}
            </sup>
          </>
        ) : preVal ? (
          <>
            {value.toFixed(2)}
            <span style={{ fontSize: '10px' }}> ({preVal.toFixed(2)}) </span>
          </>
        ) : (
          <>{value.toFixed(2) + ' '}</>
        )}
      </div>
    );
  };
  getHeatmapCellMd = (level, value, isDecile) => {
    return (
      <div
        className={
          level <= 3
            ? 'width-20-green'
            : level >= 7
            ? 'width-20-red'
            : 'width-10'
        }
      >
        {value >= 1 ? (
          <>
            {value.toFixed(2)}{' '}
            <span style={{ fontSize: 9 }}>
              ({level}
              <sup>
                {level == 1
                  ? 'st'
                  : level == 2
                  ? 'nd'
                  : level == 3
                  ? 'rd'
                  : 'th'}
              </sup>
              )
            </span>
          </>
        ) : (
          '-'
        )}
      </div>
    );
  };

  getAdditionalQuestionsDataForJobRole(AdditionalQuestionsScores) {
    var data = Object.entries(AdditionalQuestionsScores);
    if (data.length > 0)
      return (
        <div className='stats-table'>
          <div className='job-role-container'>
            <div className='stats-title-cell'> JOB ROLE</div>
            {this.state.reportdata.AdditionQuestionIds.map((item) => (
              <div className='stats-role-cell'>Q: {item.Order}</div>
            ))}
          </div>

          {data.map((item) => {
            let score = item[1];
            return (
              <div className='job-role-container'>
                <div className='job-role-title-cell'>{item[0]}</div>

                {score.map((score) => {
                  return (
                    <div className='job-role-cell'>{score.toFixed(2)}</div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
  }
  getAdditionalQuestionsDataForSchool(AdditionalQuestionsScores, allScore) {
    var data = Object.entries(AdditionalQuestionsScores);
    var allScoreRow = Object.entries(allScore)[0];
    if (data.length > 0)
      return (
        <div className='stats-table'>
          <div className='job-role-container'>
            <div className='stats-title-cell'>SCHOOLS</div>
            {this.state.reportdata.AdditionQuestionIds.map((item) => (
              <div className='stats-role-cell'>Q: {item.Order}</div>
            ))}
          </div>

          <div className='job-role-container'>
            <div className='job-role-title-cell'>{allScoreRow[0]}</div>

            {allScoreRow[1].map((score) => {
              return <div className='job-role-cell'>{score.toFixed(2)}</div>;
            })}
          </div>
          {data.map((item) => {
            let score = item[1];
            return (
              <div className='job-role-container'>
                <div className='job-role-title-cell'>{item[0]}</div>

                {score.map((score) => {
                  return (
                    <div className='job-role-cell'>{score.toFixed(2)}</div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
  }
  getAdditionalQuestionsTextData(AdditionalQuestions) {
    return (
      <div className='section'>
        <div className='job-role-container'>
          <div className='stats-title-cell' style={{ width: '14%' }}>
            Question Number
          </div>
          <div
            className='stats-role-cell'
            style={{
              width: '86%',
              justifyContent: 'start',
              paddingLeft: '12px',
            }}
          >
            Question Text
          </div>
        </div>
        {this.state.reportdata.AdditionQuestionIds.map((item) => (
          <div className='job-role-container'>
            <div className='job-role-title-cell' style={{ width: '14%' }}>
              Q: {item.Order}
            </div>
            <div
              className='job-role-cell'
              style={{
                width: '86%',
                justifyContent: 'start',
                paddingLeft: '12px',
              }}
            >
              {item.Text}
            </div>
          </div>
        ))}
      </div>
    );
  }
  render() {
    const { t } = this.props;

    return (
      <div>
        <GroupDashboardMainWrapper>
          <div className='containerClass'>
            <div className='left-container'>
              <DirectorLeftMenu />
            </div>
            <div className='right-container'>
              <MainMATHeader />

              {this.state.loaded ? (
                <Grid container>
                  <Grid item lg={8} xs={12} md={12}>
                    <h2 style={{ margin: '0' }}>{this.state.matType} Report</h2>
                  </Grid>

                  <Grid item lg={4} xs={12} md={12}>
                    <AssistanceWrapper style={{ zIndex: '999' }}>
                      <div
                        className='footer-buttons'
                        style={{ marginLeft: '8px' }}
                      >
                        <Tooltip title='Download' followCursor>
                          <button
                            onClick={() => {
                              if (this.state.reportdata.HasReport) {
                                loaderAction.dataLoading(true);
                                matGroupService
                                  .GetMatReportPdf(
                                    storageService.getItem(
                                      WebStorageNames.MatGroupId
                                    ),
                                    this.state.selectedCycle
                                  )
                                  .then((response) => {
                                    const url = window.URL.createObjectURL(
                                      new Blob([response], {
                                        type: 'application/pdf',
                                      })
                                    );
                                    const link = document.createElement('a');
                                    link.href = url;

                                    if (
                                      storageService.getItem(
                                        WebStorageNames.UsRegion
                                      ) === 'us'
                                    ) {
                                      link.setAttribute(
                                        'download',
                                        'group-report.pdf'
                                      );
                                    } else {
                                      link.setAttribute(
                                        'download',
                                        'mat-report.pdf'
                                      );
                                    }
                                    document.body.appendChild(link);
                                    loaderAction.dataLoading(false);
                                    link.click();
                                  })
                                  .catch(() => {
                                    loaderAction.dataLoading(false);
                                  });
                              }
                            }}
                          >
                            <img src={iconDownload} alt='' /> Download
                          </button>
                        </Tooltip>
                      </div>

                      {/* <div className='btn-request-assistance'>
                                                <RequestAssistanceButton />
                                            </div> */}
                      <div className='dd-survey'>
                        <MatCycleDropDown
                          handleOnChange={this.handleCycleChange}
                        />
                      </div>
                    </AssistanceWrapper>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    {this.state.errorMessage ||
                    !this.state.reportdata?.HasReport ? (
                      <div
                        className='error-message'
                        style={{ marginTop: '50px' }}
                      >
                        Your report will be available as soon as you run and
                        close your survey.
                      </div>
                    ) : (
                      <MatReportWrapper>
                        <div className='report-section'>
                          <div className='center-text'>
                            {this.state.matName}
                          </div>
                          <div className='center-text'>
                            {this.state.reportdata.CycleName}
                          </div>
                          <div className='table-of-content'>
                            <h2>Table of Contents</h2>
                            <ol>
                              <li>
                                <a href='#management-standard'>
                                  The Management Standards
                                </a>
                              </li>
                              <li>
                                <a href='#summery-score'>
                                  Summary of {this.state.matType} Scores
                                </a>
                              </li>
                              <li>
                                <a href='#score-job'>Scores by Job Role</a>
                              </li>
                              <li>
                                <a href='#score-school'>Scores by Site</a>
                              </li>
                              <li>
                                <a href='#score-questions'>
                                  Highest and Lowest Scoring Questions
                                </a>
                              </li>
                              {this.state.reportdata.HasAdditionalQuestion ? (
                                <li>
                                  <a href='#add-questions'>
                                    Additional Questions
                                  </a>
                                </li>
                              ) : null}
                              <li>
                                <a href='#next-step'>Next Steps</a>
                              </li>
                            </ol>
                          </div>
                        </div>

                        {/* The Management Standards */}
                        <div
                          className='report-section'
                          id='management-standard'
                        >
                          <h1 className='report-heading'>
                            1. The Management Standards
                          </h1>
                          <p className='section'>
                            The survey used is the Health and Safety Executive's
                            (HSE) Indicator Tool. This is evidence built and DfE
                            Staff Wellbeing Charter {t('recognised')} and
                            measures how staff view their working environment
                            against the Health and Safety Executive's (HSE)
                            Management Standards.
                          </p>
                          <p className='section'>
                            These Standards can be used to define the
                            characteristics and culture of an{' '}
                            {t('organisation')} and schools, where the risks
                            from work related stress and poor staff wellbeing
                            are being effectively managed and controlled. They
                            cover the six key areas of work design, that if not
                            properly managed, are associated with poor health
                            and wellbeing, lower productivity, increased
                            sickness absence and lower staff retention.
                          </p>
                          <h3>The Six Management Standards Are:</h3>
                          <div className='section'>
                            <ul>
                              <li>
                                <strong>Demands</strong> - workload, work
                                patterns, working hours, deadlines, time given
                                to complete tasks, the {t('behviour')} of
                                students and anything else that adds demands for
                                staff.
                              </li>
                              <li>
                                <strong>Control</strong> - the level of autonomy
                                a staff member feels they have, including the
                                say they have over what they do at work, how
                                they work and the level of flexibility
                                available.
                              </li>
                              <li>
                                <strong>Support</strong> (Manager and Peer) -
                                the encouragement, sponsorship and support they
                                receive from {t('managers')} and peers.
                              </li>
                              <li>
                                <strong>Relationships</strong> - the extent to
                                which staff {t('behviour')} is appropriate,
                                positive working is promoted to avoid conflict
                                and unacceptable {t('behviour')} is effectively
                                dealt with.
                              </li>
                              <li>
                                <strong>Role</strong> - how well staff members
                                understand their role, what is expected of them,
                                how it fits with the role of their department or
                                function and with the overall aim of the school
                              </li>
                              <li>
                                <strong>Change</strong> - how changes at school
                                (large or small) are managed and communicated to
                                staff, including effective consultation and
                                whether opportunities are given for staff to
                                question leaders.
                              </li>
                            </ul>
                          </div>
                        </div>

                        {/* Summery of MAT Score */}
                        <div className='report-section' id='summery-score'>
                          <h1 className='report-heading'>
                            2. Summary of {this.state.matType} Scores
                          </h1>
                          {storageService.getItem(WebStorageNames.UsRegion) !==
                            'us' && (
                            <p className='section'>
                              You can see further information on interpreting
                              your results in the{' '}
                              <strong>
                                <a
                                  href='https://knowledge.welbee.co.uk/group-and-mat-dashboard'
                                  target='_blank'
                                >
                                  user guide
                                </a>
                              </strong>
                              .
                            </p>
                          )}
                          <h2>2.1 Participation</h2>
                          <p className='section'>
                            {this.state.reportdata.NoOfStaffParticipated}{' '}
                            members of staff from across your{' '}
                            {this.state.matType} participated in the survey,
                            which is{' '}
                            {(
                              (this.state.reportdata.NoOfStaffParticipated /
                                this.state.reportdata.TotalMatStaff) *
                              100
                            ).toFixed(2)}
                            % of all staff. The breakdown of this across your
                            schools and core filters is shown in the table
                            below. Where no number is shown, this is because
                            less than 5 participants took part for the filter
                            shown.The participation numbers for each filter may
                            not add to the total if people have multiple job
                            titles and because participation below 5 is not
                            shown.
                          </p>
                          <p className='table-title'>
                            Table 1: Staff Participation Summary of{' '}
                            {this.state.groupType} Scores
                          </p>
                          <div className='stats-table'>
                            <div className='job-role-container'>
                              <div className='stats-title-cell'>
                                {t('Organisation_Pascal')}
                              </div>
                              <div className='stats-role-cell'>
                                Support Staff (Classroom)
                              </div>
                              <div className='stats-role-cell'>
                                Support Staff (Non-Classroom)
                              </div>
                              <div className='stats-role-cell'>
                                Teaching Staff (Classroom)
                              </div>
                              <div className='stats-role-cell'>
                                Teaching Staff (Non-Classroom)
                              </div>
                              <div className='stats-role-cell'>
                                {/* Middle Leaders */}
                                {t('MiddleLeaders')}
                              </div>
                              <div className='stats-role-cell'>
                                {/* Senior Leaders */}
                                {t('SeniorLeaders')}
                              </div>
                              <div className='stats-role-cell'>Total</div>
                            </div>
                            {this.state.reportdata.MatSchoolStaffCount.map(
                              (row) => {
                                return (
                                  <div className='job-role-container'>
                                    <div className='job-role-title-cell'>
                                      {row.SchoolName}
                                    </div>
                                    <div className='job-role-cell'>
                                      {row.ResponseCountByJob
                                        .SupportStaffWorkingInside > 4
                                        ? row.ResponseCountByJob
                                            .SupportStaffWorkingInside +
                                          (' (' +
                                            (
                                              (row.ResponseCountByJob
                                                .SupportStaffWorkingInside /
                                                row.ResponseCountByJob
                                                  .totalSupportStaffClassRoom) *
                                              100
                                            ).toFixed(2) +
                                            '%)')
                                        : '-'}
                                    </div>
                                    <div className='job-role-cell'>
                                      {row.ResponseCountByJob
                                        .SupportStaffWorkingOutside > 4
                                        ? row.ResponseCountByJob
                                            .SupportStaffWorkingOutside +
                                          (' (' +
                                            (
                                              (row.ResponseCountByJob
                                                .SupportStaffWorkingOutside /
                                                row.ResponseCountByJob
                                                  .totalSupportStaffNonClassRoom) *
                                              100
                                            ).toFixed(2) +
                                            '%)')
                                        : '-'}
                                    </div>
                                    <div className='job-role-cell'>
                                      {row.ResponseCountByJob
                                        .TeacherWorkingInside > 4
                                        ? row.ResponseCountByJob
                                            .TeacherWorkingInside +
                                          (' (' +
                                            (
                                              (row.ResponseCountByJob
                                                .TeacherWorkingInside /
                                                row.ResponseCountByJob
                                                  .totalTeachingStaffClassRoom) *
                                              100
                                            ).toFixed(2) +
                                            '%)')
                                        : '-'}
                                    </div>
                                    <div className='job-role-cell'>
                                      {row.ResponseCountByJob
                                        .TeacherWorkingOutside > 4
                                        ? row.ResponseCountByJob
                                            .TeacherWorkingOutside +
                                          (' (' +
                                            (
                                              (row.ResponseCountByJob
                                                .TeacherWorkingOutside /
                                                row.ResponseCountByJob
                                                  .totalTeachingStaffNonClassRoom) *
                                              100
                                            ).toFixed(2) +
                                            '%)')
                                        : '-'}
                                    </div>
                                    <div className='job-role-cell'>
                                      {row.ResponseCountByJob.MiddleLeader > 4
                                        ? row.ResponseCountByJob.MiddleLeader +
                                          (' (' +
                                            (
                                              (row.ResponseCountByJob
                                                .MiddleLeader /
                                                row.ResponseCountByJob
                                                  .totalMiddlerLeader) *
                                              100
                                            ).toFixed(2) +
                                            '%)')
                                        : '-'}
                                    </div>
                                    <div className='job-role-cell'>
                                      {row.ResponseCountByJob.SeniorLeader > 4
                                        ? row.ResponseCountByJob.SeniorLeader +
                                          (' (' +
                                            (
                                              (row.ResponseCountByJob
                                                .SeniorLeader /
                                                row.ResponseCountByJob
                                                  .totalLeader) *
                                              100
                                            ).toFixed(2) +
                                            '%)')
                                        : '-'}
                                    </div>
                                    <div className='job-role-cell'>
                                      {row.NoOfStaffParticipated > 0
                                        ? row.NoOfStaffParticipated +
                                          (' (' +
                                            (
                                              (row.NoOfStaffParticipated /
                                                row.TotalSchoolStaffSurveySent) *
                                              100
                                            ).toFixed(2) +
                                            '%)')
                                        : '-'}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                            <div className='job-role-container'>
                              <div className='job-role-title-cell'>Total</div>
                              <div className='job-role-cell'>
                                {this.state.reportdata.SupportStaffInsideCount >
                                4
                                  ? this.state.reportdata
                                      .SupportStaffInsideCount +
                                    ' (' +
                                    (
                                      (this.state.reportdata
                                        .SupportStaffInsideCount /
                                        this.state.reportdata
                                          .TotalSupportStaffInside) *
                                      100
                                    ).toFixed(2) +
                                    '%)'
                                  : '-'}
                              </div>
                              <div className='job-role-cell'>
                                {this.state.reportdata
                                  .SupportStaffOutsideCount > 4
                                  ? this.state.reportdata
                                      .SupportStaffOutsideCount +
                                    ' (' +
                                    (
                                      (this.state.reportdata
                                        .SupportStaffOutsideCount /
                                        this.state.reportdata
                                          .TotalSupportStaffOutside) *
                                      100
                                    ).toFixed(2) +
                                    '%)'
                                  : '-'}
                              </div>
                              <div className='job-role-cell'>
                                {this.state.reportdata
                                  .TeachingStaffInsideCount > 4
                                  ? this.state.reportdata
                                      .TeachingStaffInsideCount +
                                    ' (' +
                                    (
                                      (this.state.reportdata
                                        .TeachingStaffInsideCount /
                                        this.state.reportdata
                                          .TotalTeachingStaffInside) *
                                      100
                                    ).toFixed(2) +
                                    '%)'
                                  : '-'}
                              </div>
                              <div className='job-role-cell'>
                                {this.state.reportdata
                                  .TeachingStaffOutsideCount > 4
                                  ? this.state.reportdata
                                      .TeachingStaffOutsideCount +
                                    ' (' +
                                    (
                                      (this.state.reportdata
                                        .TeachingStaffOutsideCount /
                                        this.state.reportdata
                                          .TotalTeachingStaffOutside) *
                                      100
                                    ).toFixed(2) +
                                    '%)'
                                  : '-'}
                              </div>
                              <div className='job-role-cell'>
                                {this.state.reportdata.MiddleLeaderCount > 4
                                  ? this.state.reportdata.MiddleLeaderCount +
                                    ' (' +
                                    (
                                      (this.state.reportdata.MiddleLeaderCount /
                                        this.state.reportdata
                                          .TotalMiddleLeader) *
                                      100
                                    ).toFixed(2) +
                                    '%)'
                                  : '-'}
                              </div>
                              <div className='job-role-cell'>
                                {this.state.reportdata.SeniorLearderCount > 4
                                  ? this.state.reportdata.SeniorLearderCount +
                                    ' (' +
                                    (
                                      (this.state.reportdata
                                        .SeniorLearderCount /
                                        this.state.reportdata
                                          .TotalSeniorLearder) *
                                      100
                                    ).toFixed(2) +
                                    '%)'
                                  : '-'}
                              </div>
                              <div className='job-role-cell'>
                                {this.state.reportdata.NoOfStaffParticipated > 4
                                  ? this.state.reportdata
                                      .NoOfStaffParticipated +
                                    ' (' +
                                    (
                                      (this.state.reportdata
                                        .NoOfStaffParticipated /
                                        this.state.reportdata.TotalMatStaff) *
                                      100
                                    ).toFixed(2) +
                                    '%)'
                                  : '-'}
                              </div>
                            </div>
                          </div>
                          <p className='section'>
                            The average scores for all survey participants
                            across the {this.state.matType} for each of the
                            Management Standards are shown in heatmap 1 below.
                            Question scores are out of 5, with 5 being the
                            highest and 1 the lowest, and using the Likert scale
                            (Never, Seldom, Sometimes, Often, Always).
                          </p>
                          <p className='section'>
                            They are ranked from the highest to the lowest
                            comparative score - that is how your scores compare
                            to the Welbee school benchmark. Staff score
                            differently in each of the Management Standards and
                            the benchmarks for each are not the same.
                          </p>
                          <p className='section'>
                            This is the best indicator of your current position,
                            rather than the total score, as it takes account of
                            how staff across the schools sector score in each of
                            the Standards. The Health and Safety Executive's
                            (HSE) benchmark is also shown and this was
                            established across a wide number of business sectors
                            and not simply education.
                          </p>
                          <p className='section'>
                            Comparatively high scores are shaded green, in the
                            top three deciles (top 30%) when compared to the
                            school benchmark, while scores in red are in the 7th
                            decile and below (bottom 40%). Those without shading
                            are close to average.
                          </p>
                          <p className='table-title'>
                            Heatmap 1: Average Score for All Survey Participants
                          </p>
                          <div className='stats-table'>
                            <div className='job-role-container'>
                              <div className='stats-title-cell'>
                                Management Standards
                              </div>
                              <div className='stats-role-cell'>Score</div>
                              <div className='stats-role-cell'>
                                School Decile
                              </div>
                              <div className='stats-role-cell'>HSE Decile</div>
                            </div>
                            {this.state.matAvg.map((row) => {
                              return (
                                <div className='job-role-container'>
                                  <div className='job-role-title-cell'>
                                    {row.CategoryName}
                                  </div>
                                  {this.getHeatmapCell(
                                    row.SchoolDecileType,
                                    row.OverallCategoryAverage,
                                    false,
                                    row.PreviousAverage
                                  )}
                                  {this.getHeatmapCell(
                                    row.SchoolDecileType,
                                    row.OverallCategoryAverage,
                                    true
                                  )}
                                  {this.getHeatmapCell(
                                    row.HseDecileType,
                                    row.HseDecileType,
                                    true
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          <p className='section'>
                            The average scores for your {this.state.matType} for{' '}
                            {this.state.highMstd} in the top 30% (deciles 1 - 3){' '}
                            {this.state.highMstd != null &&
                            this.state.lowMstd != null
                              ? ' and '
                              : ''}
                            {this.state.lowMstd != null
                              ? this.state.lowMstd +
                                'also above the average for all schools'
                              : ''}
                            .
                          </p>
                          <p className='section'>
                            {this.state.reportdata.HighestScoreText == '' ? (
                              ''
                            ) : (
                              <>{this.state.reportdata.HighestScoreText}</>
                            )}
                          </p>
                          <p className='section'>
                            {this.state.reportdata.LowestScoreText == '' ? (
                              ''
                            ) : (
                              <>{this.state.reportdata.LowestScoreText}</>
                            )}
                          </p>
                          <p className='section'>
                            A report and heatmaps are provided for each school,
                            including suggested actions, to allow them to
                            quickly see where to celebrate and focus attention.
                            These can be accessed directly by each school
                            through their dashboards and from your{' '}
                            {this.state.matType} dashboard.
                          </p>
                          <p className='section'>
                            Scores may vary for different job roles and you can
                            see those for the core filters in Heatmap 2 below.
                            Further filters, heatmaps and analytics charts are
                            available to view and download from your{' '}
                            {this.state.matType} dashboard.
                          </p>
                          <p className='section'>
                            Where open questions were included in your survey,
                            the majority of staff members will have provided
                            comments. These are captured at a school level and
                            can also be read via your {this.state.matType}{' '}
                            dashboard. These will provide further insights into
                            the scores received, as well as identify trends and
                            support school leaders in taking the most
                            appropriate action.
                          </p>
                        </div>

                        {/* Scores by Job Role */}
                        <div className='report-section' id='score-job'>
                          <h1 className='report-heading'>
                            3. Scores by Job Role
                          </h1>
                          <p className='section'>
                            The average scores for participants in each of the
                            core job role filters are shown in heatmap 2 below.
                            Again comparatively high scores are shaded green and
                            comparatively lower ones red. Those without shading
                            are close to average.
                          </p>
                          <p className='table-title'>
                            Heatmap 2: Scores by Job Role
                          </p>
                          <div className='stats-table'>
                            <div className='job-role-container'>
                              <div
                                className='empty-cell'
                                style={{ width: '25%', float: 'left' }}
                              >
                                &nbsp;
                              </div>
                              <div className='width-10'>Demands</div>
                              <div className='width-10'>Control</div>
                              <div className='width-10 title-txt'>
                                {/* {t('managerCW')} Support (fyi-in future if we req leader in us here we need to add new key bc for uk we req manager and somepaces leader*/}
                                Manager Support
                              </div>
                              <div className='width-10 title-txt'>
                                Peer Support
                              </div>
                              <div className='width-10'>Relationship</div>
                              <div className='width-10'>Role</div>
                              <div className='width-10'>Change</div>
                            </div>
                            <div style={{ width: '100%' }}>
                              {this.state.heatmapByRole.map((row) => {
                                return (
                                  <div className='job-role-container'>
                                    <div
                                      style={{
                                        width: '25%',
                                        marginLeft: '0px',
                                      }}
                                      className='job-role-title-cell'
                                    >
                                      {this.UsRegion() === 'us' &&
                                      row.Name === 'Senior Leader'
                                        ? 'District Administrator'
                                        : this.UsRegion() === 'us' &&
                                          row.Name === 'Middle Leader'
                                        ? 'Building Administrator'
                                        : row.Name}
                                    </div>
                                    {this.getHeatmapCellMd(
                                      row.DemandDecile,
                                      row.Demand,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.ControlDecile,
                                      row.Control,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.ManagerSupportDecile,
                                      row.ManagerSupport,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.PeerSupportDecile,
                                      row.PeerSupport,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.RelationshipsDecile,
                                      row.Relationships,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.RoleDecile,
                                      row.Role,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.ChangeDecile,
                                      row.Change,
                                      false
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <p className='section' style={{ marginTop: '20px' }}>
                            {t('MiddleLeaders')} and Teaching Staff Classroom
                            tend to give lower scores when compared to other
                            staff across the surveys we run.
                          </p>
                          {this.state.reportdata.JobRoleInManagementStandardsTop
                            .length > 0 ? (
                            <p className='section'>
                              The three highest comparative scores are:
                              <ul>
                                {this.state.reportdata.JobRoleInManagementStandardsTop.map(
                                  (item) => {
                                    return (
                                      <li>
                                        {this.UsRegion() === 'us' &&
                                        item.JobRole === 'Senior Leader'
                                          ? 'District Administrator'
                                          : this.UsRegion() === 'us' &&
                                            item.JobRole === 'Middle Leader'
                                          ? 'Building Administrator'
                                          : item.JobRole}{' '}
                                        for {item.ManagementStandard}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </p>
                          ) : (
                            ''
                          )}
                          {this.state.reportdata
                            .JobRoleInManagementStandardsBottom.length > 0 ? (
                            <p className='section'>
                              The three lowest comparative scores are:
                              <ul>
                                {this.state.reportdata.JobRoleInManagementStandardsBottom.map(
                                  (item) => {
                                    return (
                                      <li>
                                        {this.UsRegion() === 'us' &&
                                        item.JobRole === 'Senior Leader'
                                          ? 'District Administrator'
                                          : this.UsRegion() === 'us' &&
                                            item.JobRole === 'Middle Leader'
                                          ? 'Building Administrator'
                                          : item.JobRole}{' '}
                                        for {item.ManagementStandard}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </p>
                          ) : (
                            ''
                          )}

                          <p className='section'>
                            Using heatmaps in your {this.state.matType}{' '}
                            Dashboard you can apply further filters to identify
                            where scores may be higher and lower. Your schools
                            will be able to do the same for their own staff,
                            from within their dashboard, to help identify areas
                            to celebrate and to target action quickly and
                            appropriately.
                          </p>
                        </div>

                        {/* Scores by School */}
                        <div className='report-section' id='score-school'>
                          <h1 className='report-heading'>4. Scores by Site</h1>
                          <p className='section'>
                            Heatmap 3 below shows the average scores for all
                            staff for each school within the{' '}
                            {this.state.matType}. Again comparatively high
                            scores are shaded green and comparatively lower ones
                            red. Those without shading are close to average.
                          </p>
                          <p className='table-title'>
                            Heatmap 3: Scores by Site
                          </p>
                          <div className='stats-table'>
                            <div className='job-role-container'>
                              <div
                                className='empty-cell'
                                style={{ width: '25%', float: 'left' }}
                              >
                                &nbsp;
                              </div>
                              <div className='width-10'>Demands</div>
                              <div className='width-10'>Control</div>
                              <div className='width-10 title-txt'>
                                {/* {t('managerCW')} Support (same new key method comment here for us read above comment)*/}
                                Manager Support
                              </div>
                              <div className='width-10 title-txt'>
                                Peer Support
                              </div>
                              <div className='width-10'>Relationship</div>
                              <div className='width-10'>Role</div>
                              <div className='width-10'>Change</div>
                            </div>
                            <div style={{ width: '100%' }}>
                              {this.state.heatmapBySchool.map((row) => {
                                return (
                                  <div className='job-role-container'>
                                    <div
                                      style={{
                                        width: '25%',
                                        marginLeft: '0px',
                                      }}
                                      className='job-role-title-cell'
                                    >
                                      {row.Name}
                                    </div>
                                    {this.getHeatmapCellMd(
                                      row.DemandDecile,
                                      row.Demand,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.ControlDecile,
                                      row.Control,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.ManagerSupportDecile,
                                      row.ManagerSupport,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.PeerSupportDecile,
                                      row.PeerSupport,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.RelationshipsDecile,
                                      row.Relationships,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.RoleDecile,
                                      row.Role,
                                      false
                                    )}
                                    {this.getHeatmapCellMd(
                                      row.ChangeDecile,
                                      row.Change,
                                      false
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <p className='section' style={{ marginTop: '20px' }}>
                            You can use the heatmap to quickly see where staff
                            appear to be better supported and where focus and
                            effort may be most needed.
                          </p>
                          {this.state.highestScoringSchools.length > 0 ? (
                            <p className='section'>
                              Those scoring comparatively higher in four or more
                              of the Management Standards are:
                              <ul>
                                {this.state.highestScoringSchools.map(
                                  (item) => {
                                    return <li>{item}</li>;
                                  }
                                )}
                              </ul>
                            </p>
                          ) : null}
                          {this.state.lowestScoringSchools.length > 0 ? (
                            <p className='section'>
                              Those scoring comparatively lower in three or more
                              Management Standards are:
                              <ul>
                                {this.state.lowestScoringSchools.map((item) => {
                                  return <li>{item}</li>;
                                })}
                              </ul>
                            </p>
                          ) : null}
                          {/* {this.state.highestScoringStandards.length > 0 ? (
                            <p className='section'>
                              Those Management Standards where scores across
                              schools are generally higher (top three deciles),
                              and therefore may be a {this.state.matType}{' '}
                              strength are:
                              <ul>
                                {this.state.highestScoringStandards.map(
                                  (item) =>
                                  {
                                    return <li>{item}</li>;
                                  }
                                )}
                              </ul>
                            </p>
                          ) : null}
                          {this.state.lowestScoringStandards.length > 0 ? (
                            <p className='section'>
                              Those Management Standards where scores across
                              schools are generally lower (deciles seven and
                              below) and therefore may be a {this.state.matType}{' '}
                              opportunity are:
                              <ul>
                                {this.state.lowestScoringStandards.map(
                                  (item) =>
                                  {
                                    return <li>{item}</li>;
                                  }
                                )}
                              </ul>
                            </p>
                          ) : null} */}
                          <p className='section'>
                            The above provides an overview and you can use the
                            heatmaps and analytics within your{' '}
                            {this.state.matType} dashboard to filter further to
                            see how these scores are broken down by school, job
                            role and other filters.
                          </p>
                        </div>

                        {/* Highest and Lowest Scoring Questions */}
                        <div className='report-section' id='score-questions'>
                          <h1 className='report-heading'>
                            5. The Five Highest and Lowest Scoring Questions
                          </h1>
                          <p className='section'>
                            In table 2 below you can see the scores and how
                            these compare to the school benchmark for the five
                            highest and lowest scoring questions. The benchmark
                            differs for each of the questions - overall staff
                            will score higher or lower and it is why
                            comparative, rather than absolute scores are
                            important in considering actions.
                          </p>
                          <p className='table-title'>
                            Table 2: Highest and Lowest Question Scores
                          </p>
                          <div className='stats-table'>
                            <div className='job-role-container'>
                              <div className='heatmap-title questions'>
                                The Five Highest Scoring Questions
                              </div>
                              <div
                                className='heatmap-title deciles'
                                style={{ width: '17.4%' }}
                              >
                                School Decile
                              </div>
                            </div>
                            {this.state.highScoreQuestions.map((item) => {
                              return (
                                <div className='job-role-container'>
                                  <div className='width-20-grey'>
                                    {item.Category}
                                  </div>
                                  <div className='width-60-grey'>
                                    Q{item.Order}: {item.Text}
                                  </div>
                                  <div className='width-18-grey'>
                                    {item.Decile}
                                    <sup>
                                      {item.Decile === 1
                                        ? 'st'
                                        : item.Decile == 2
                                        ? 'nd'
                                        : item.Decile == 3
                                        ? 'rd'
                                        : 'th'}
                                    </sup>
                                  </div>
                                </div>
                              );
                            })}
                            {this.state.lowScoreQuestions.length > 0 ? (
                              <>
                                {' '}
                                <div className='job-role-container'>
                                  <div className='heatmap-title questions'>
                                    The Five Lowest Scoring Questions
                                  </div>
                                  <div
                                    className='heatmap-title deciles'
                                    style={{ width: '17.4%' }}
                                  >
                                    School Decile
                                  </div>
                                </div>
                                {this.state.lowScoreQuestions.map((item) => {
                                  return (
                                    <div className='job-role-container'>
                                      <div className='width-20-grey'>
                                        {item.Category}
                                      </div>
                                      <div className='width-60-grey'>
                                        Q{item.Order}: {item.Text}
                                      </div>
                                      <div className='width-18-grey'>
                                        {item.Decile}
                                        <sup>
                                          {item.Decile === 1
                                            ? 'st'
                                            : item.Decile == 2
                                            ? 'nd'
                                            : item.Decile == 3
                                            ? 'rd'
                                            : 'th'}
                                        </sup>
                                      </div>
                                    </div>
                                  );
                                })}{' '}
                              </>
                            ) : (
                              <p className='table-title width-60-grey'>
                                There are no question scoring low.
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Additional Questions */}
                        {this.state.reportdata.HasAdditionalQuestion ? (
                          <div className='report-section' id='add-questions'>
                            <h1 className='report-heading'>
                              6. Your Questions
                            </h1>
                            <p className='table-title'>
                              Table 3: Your Questions
                            </p>
                            {this.getAdditionalQuestionsTextData(
                              this.state.reportdata.AdditionQuestionIds
                            )}
                            <p className='section'>
                              Table 3 shows the scores for all staff and job
                              role core filters for the additional questions you
                              chose to use in your survey. While they are not
                              benchmarked, you can see how different job roles
                              have scored, and you will need to use the Likert
                              scale and your judgement in assessing these. In
                              addition, staff comments are also likely to
                              provide further insights about these scores. These
                              can be seen in each school dashboard.
                            </p>
                            <p className='table-title'>
                              Table 4: Your Question Scores by Job Role
                            </p>
                            {this.getAdditionalQuestionsDataForJobRole(
                              this.state.reportdata.AdditionalQuestionsScores
                            )}
                            <p className='table-title'>
                              Table 5: Your Question Scores by School or Site
                            </p>
                            {this.getAdditionalQuestionsDataForSchool(
                              this.state.reportdata
                                .AdditionalQuestionsScoresforSchools,
                              this.state.reportdata.AdditionalQuestionsScores
                            )}
                          </div>
                        ) : null}
                        {/* Next Steps */}
                        <div className='report-section' id='next-step'>
                          <h1 className='report-heading'>
                            {this.state.reportdata.HasAdditionalQuestion
                              ? '7'
                              : '6'}
                            . Next Steps
                          </h1>
                          <p className='section'>
                            The information in this summary report provides you
                            with a snapshot of the full results available in
                            your {this.state.matType} dashboard. You can use the
                            heatmaps and analytics to filter further and view
                            information about different schools and staff
                            groups.
                          </p>
                          <p className='section'>
                            Heatmaps will support you in quickly identifying
                            likely areas for celebration, as well as areas where
                            you might want to focus and provide support.
                          </p>
                          <p className='section'>
                            Specific actions are provided at the school level,
                            to enable leaders to share their results with staff,
                            to quickly move to say well done and to find
                            solutions, where needed.
                          </p>
                          <p className='section'>
                            While you may want to co-ordinate{' '}
                            {this.state.matType} action, where there are similar
                            school results and provide coaching and support, as
                            appropriate, school leaders are not waiting for
                            information to be discussed and passed out to them.
                            This reduces workload and the time it takes to move
                            to action.
                          </p>
                          <p className='section'>
                            The Welbee Toolkit has resources and training
                            available for leaders and staff to support them in
                            building staff wellbeing into the culture, as well
                            as managing their own wellbeing. All staff uploaded
                            to Welbee have access using their email address and
                            you just need to provide the following link to them.
                          </p>
                          <p className='section'>
                            <a
                              href='https://toolkit.welbee.co.uk/'
                              target='_blank'
                              rel='noreferrer'
                            >
                              https//toolkit.welbee.co.uk.
                            </a>
                          </p>
                          <p className='section'>
                            Please contact support (or use the email icon top
                            right in your dashboard) to get in touch, ask
                            questions or to book a results review.
                          </p>
                        </div>
                      </MatReportWrapper>
                    )}
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </div>
          </div>
        </GroupDashboardMainWrapper>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(withTranslation()(MatReport));
