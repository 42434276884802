import styled, { css } from 'styled-components';
import { colors } from '../theme/colors';
import { media } from 'styled-bootstrap-grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { gridClasses } from '@mui/x-data-grid';

import {
  legendsLine,
  filterIcon,
  actioncheckbox,
  arrowDown,
  flagactive,
  replyactive,
  replyinactive,
  replyunread,
  flaginactive,
  radiodot,
  staffIcon,
  fillarrowprev,
  iconCheck,
} from '../images/index';
import { Select } from '@mui/material';
import { borderColor } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

const {
  pictonBlue,
  whiteColor,
  blackColor,
  mineShaft,
  ginFizz,
  keppel,
  gorse,
  hummingBird,
  lightGray,
  teal,
  orient,
  mandy,
  easternBlue,
  illusion,
  lighterGrey,
  twilightBlue,
  outrageousOrange,
  cornflowerBlue,
  parrotGreen,
  darkYellow,
  blizzBlue,
  alto,
  purple,
  barleyWhite,
  lightBlue,
  lightGreen,
  lightTeal,
  softGrey,
  midSoftGrey,
  viking,
  bittersweet,
  minttulip,
  yourpink,
  grainsboro,
  bordergrey,
} = colors;

export const SchoolListMainWrapper = styled.div`
  margin-bottom: 30px;

  .super-admin-header {
    min-height: 60px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .mat-group-btn {
    position: absolute;
    top: 6px;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 700px) {
    .mat-group-btn {
      position: relative;
      top: 0px;
    }
  }
  .create-school-dropdown {
    width: 239px;
    float: right;
    position: relative;
    margin-top: -15px;
    margin-right: -5px;
    .category-inner-wrapper {
      margin: 16px 0 4px 0 !important;
      .options {
        position: absolute;
        border-radius: 5px;
        background: ${whiteColor};
        padding: 10px 0;
        box-shadow: 3px 3px 10px rgba(0, 122, 122, 0.12);
        z-index: 9999;
        span {
          font-size: 12px;
          padding: 7px 42px 7px 12px;
          &:hover {
            background: ${pictonBlue};
            color: ${whiteColor};
          }
        }
      }
      label {
        color: #ffffff !important;
      }
    }
  }
  .conversation-button {
    margin: 0 0 0 2px;
    padding: 11px 10px;
    border-radius: 20px;
    box-shadow: none;
    font-size: 11px;
    color: ${whiteColor};
    background: ${mandy};
    text-decoration: none;
    font-weight: 700;
  }
  background: ${whiteColor};
  box-shadow: 4px 5px 16px -10px ${orient};
  h1 {
    background: ${teal};
    color: ${whiteColor};
    font-size: 17px;
    padding: 20px;
    font-weight: 900;
    position: relative;

    .create-school {
      float: right;
      font-size: 15px;
      padding: 21px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .filter-header {
    background: ${teal};
    color: ${whiteColor};
    font-size: 17px;
    padding: 10px;
    font-weight: 900;
    float: left;
    width: 100%;
    color: ${whiteColor};
    label {
      color: ${whiteColor} !important;
    }
    h1 {
      background: transparent;
      display: inline-block;
      padding: 0;
      margin: 0;
      line-height: 40px;
    }
    .staff-search-txtfield input {
      color: ${whiteColor} !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .create-school {
      position: relative !important;
      padding: 0 !important;
      width: 100%;
      text-align: right;
    }
  }

  .list-heading {
    font-size: 14px;
  }
  .list-heading:nth-child(4) {
    text-align: left;
  }
  ul {
    padding: 20px;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${lighterGrey};
    ul:nth-child(1) {
      padding-top: 0;
    }
    li {
      flex: 1;
      color: ${mineShaft};
      font-size: 13px;
      button {
        margin: 0 0 0 2px;
        padding: 11px 10px;
        border-radius: 20px;
        box-shadow: none;
        font-size: 11px;
      }
    }

    li:last-child {
      text-align: right;
    }
  }
  .selected-options label {
    color: ${whiteColor} !important;
  }

  @media only screen and (max-width: 700px) {
    .comments-search-filters {
      position: relative !important;
      width: 100% !important;
      left: 0;
      right: 0 !important;
      .search-filed {
        width: 65% !important;
        img {
          margin: 15px 0 0 11px !important;
        }
      }
      .search-filters {
        width: 35% !important;
        .selected-option {
          padding: 19px 20.5px !important;
        }
      }
    }

    .super-admin-header {
      min-height: 150px !important;
    }
    .create-school-dropdown {
      width: 100% !important;
      .category-inner-wrapper {
        background: transparent !important;
        margin: 16px 0 20px 0 !important;
      }
    }
  }

  .comments-search-filters {
    width: 62%;
    float: right;
    background: transparent;
    position: absolute;
    right: 276px;
    top: 0;
    padding: 0;
    z-index: 1000;

    .test-checkbox {
      display: inline-block;
      width: 20%;
    }
    .school-list-header {
      width: 100%;
    }
    .school-list-header:first-child {
      margin-right: 8px;
    }
    .search-filed {
      width: 70%;
      float: right;
      border-left: 0;
      padding: 5px 0;
      background: ${lightTeal};
      img {
        margin: 19px 0 0 19px;
        float: left;
        cursor: pointer;
      }
      .cross {
        display: none;
        float: right;
        margin: 10px 19px 0 0;
        color: ${whiteColor};
        font-size: 16px;
        cursor: pointer;
      }
      input:focus .cross {
        display: block;
      }
      input {
        width: 82%;
        padding: 17px 12px;
        background: transparent;
        border: 0;
        color: ${whiteColor};
        letter-spacing: 0.5px;
        ::-webkit-input-placeholder {
          color: ${whiteColor} !important;
        }
        ::-moz-placeholder {
          color: ${whiteColor} !important;
        }
        :-ms-input-placeholder {
          color: ${whiteColor} !important;
        }
        :-moz-placeholder {
          color: ${whiteColor} !important;
        }
      }
    }

    .sent-email-search {
      width: 100%;
    }

    .sent-emails-dd {
      width: 100%;
      float: right;
      border-left: 0;
      padding: 18px 12px;
      background: ${lightTeal};
      z-index: 999;
      label {
        color: ${whiteColor} !important;
        font-size: 14px;
      }
      .category-inner-wrapper {
        margin: 0;
        border-radius: 0;
        .options {
          padding-top: 15px;
          span {
            padding: 8px;
            color: ${whiteColor};
            font-size: 14px;
            :hover {
              background: ${pictonBlue};
            }
          }
        }
      }
      .search-sorting {
        width: 23%;
        float: left;
        position: relative;
        > div {
          background: transparent;
          border: 0;
          font-size: 14px;
          margin: 0;
        }
      }
    }
    .btn-send-invoice {
      margin-bottom: 30px;
      margin-top: 30px;
      padding-bottom: 30px;
      text-align: center;
    }
    button {
      border-radius: 50px;
    }
    .search-filters {
      width: 25%;
      float: right;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      border-right: 1px solid rgba(83, 181, 224, 0.5);
      position: relative;
      > div {
        background: transparent;
        border: 0;
        font-size: 14px;
        margin: 0;
        border-radius: 0;
        .selected-option {
          padding: 18px 20px;
          background: ${lightTeal};
          border: 0;
          color: ${whiteColor};

          label {
            color: ${whiteColor} !important;
          }
        }
      }

      .options {
        position: absolute;
        border-radius: 5px;
        background: ${whiteColor};
        padding: 10px 0;
        margin: -10px 0 0 24px;
        box-shadow: 3px 3px 10px rgba(0, 122, 122, 0.12);
        z-index: 9999;
        span {
          font-size: 12px;
          padding: 7px 42px 7px 12px;
          &:hover {
            background: ${pictonBlue};
            color: ${whiteColor};
          }
        }
      }
    }
  }
`;

export const UpcomingTasksWrapper = styled.div`
  h2 {
    font-size: 20px;
    margin-top: 0;
  }
  .task-list-wrapper {
    width: 100%;
    font-size: 14px;
    .task-list {
      float: left;
      width: 100%;
      padding: 9px 0;
      span:nth-child(1) {
        max-width: 70%;
        float: left;
        color: ${lightGray};
      }
      span:nth-child(2) {
        max-width: 28%;
        float: right;
      }
      .laps {
        color: ${outrageousOrange};
        font-weight: 900;
      }
      .days {
        color: ${darkYellow};
      }
      .dates {
        color: ${parrotGreen};
        font-weight: 400;
      }
    }

    ${media.md`
    padding: 5px 0 2px 0;
    max-height: 210px;
    overflow-y: auto;
    display: inline-block;
    `}
    ${media.lg`
    padding: 5px 0 2px 0;
    max-height: 137px;
    overflow-y: auto;
    `}
    ${media.xl`
    padding: 9px 0;
    max-height: 170px;
    overflow-y: auto;
    `}
  }
`;

export const PopupWrapper = styled.div`
  .graph-container {
    position: relative;
    width: 90%;
    height: 95vh;    
    justify-content: top;
    align-items: top;
    color: ${blackColor};
    padding: 20px;
    border-radius: 20px;
    background: ${whiteColor};
  }
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.8);
  .close {
    position: absolute;
    top: -40px;
    right: -40px;
    font-size: 25px;
    font-weight: 400;
    color: red;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
  .crossButton {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    background: transparent;
    border: 0;
    cursor: pointer;
    z-index: 9999;
  }
  .redCrossButtom {
    top: -15px;
    right: -15px;
    position: absolute;
  }
  .closeIcon {
    fill: red;
    font-size: 40px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

export const ConversationBody = styled.div`
  width: 100%;
  ul {
    padding: 0 0 25px 0;
    border-bottom: 1px solid ${softGrey};
    margin-bottom: 25px;
    list-style: none;
    li:nth-child(1) {
      display: inline-block;
      color: ${mandy};
      font-weight: 700;
      margin-bottom: 20px;
    }
    li:nth-child(2) {
      font-size: 15px;
    }
  }
  ${media.xs`
    padding: 0;
  `}
`;

export const VoiceNextPreviousButtons = styled.div`
  .Buttons-wrapper {
    margin: 0px auto -24px auto;
    .Previous-btn {
      top: 13px;
      text-decoration: none;
      color: ${pictonBlue};
      font-size: 15px;
      font-weight: 900;
      z-index: 10;
      span {
        font-size: 20px;
      }
      top: -46px;
      padding-right: 70px;
    }
    .next-btn {
      text-decoration: none;
      color: ${pictonBlue};
      font-size: 15px;
      font-weight: 900;
      z-index: 10;
      padding-left: 70px;
      span {
        font-size: 20px;
      }
    }
  }
`;

export const VoiceDashboardWrapper = styled.div`
  .content-wrapepr {
    display: flex;
    margin-left: 189px;
    padding: 20px 0;
    flex-direction: column;
    h2 {
      margin: 0;
    }
  }

  @media only screen and (max-width: 768px){
    .content-wrapepr{
      margin-left: 0;
      padding: 0;
    }
  }

  .voice-body-inner-wrapper {
    padding: 20px;
    border-radius: 8px;
    background: ${whiteColor};
    border: 1px solid ${bordergrey};
	float:left;
	width: 100%;
  }

  .content-body-inner-wrapper {	  

	li{
		margin-bottom: 0;
		:nth-child(1){
		  margin-bottom: 0;
		}
	  }

	.voice-list {
	  li {
		width: 100%;
		span:first-child {
		  float: left;
		}
		span {
		  float: right;
		  button {
			margin: 0;
			padding: 7px 10px;
			font-size: 12px;
			:first-child {
			  margin-right: 8px;
			}
		  }
		}
	  }
	}
	.back-btn {
	  position: relative;	  
	  text-decoration: none;
	  color: ${mandy};
	  font-size: 15px;	  
	  font-weight: 900;
	  z-index: 10;
	  span {
		font-size: 20px;
	  }
	  
	}
	position: relative;	
	margin: auto;
	float:left;
	width: 100%;
	// background: ${whiteColor};
	// border: 1px solid ${bordergrey};
	// border-radius: 8px;
	.voice-conv-title {
	  padding: 40px 200px 0 40px;
	  font-size: 16px;
	  font-weight: 400;
	  color: ${lightGray};	  
	}

	.voice-subject{
		background: ${whiteColor};
		padding: 20px;
		border: 1px solid ${bordergrey};
		border-radius: 8px;
		margin-bottom: 20px;
	}

	.voice-conv-desc {
	  padding: 0px 0px 10px 0px;
	  font-size: 15px;
	  font-weight: bold;
	  color: ${blackColor};	  
	}
	.new-message-label {
	  position: absolute;
	  left: 0;
	  padding: 0 !important;
	  top: 0;
	  height: 100%;
	  -webkit-align-items: center;
	  -webkit-box-align: center;
	  -ms-flex-align: center;
	  align-items: center;
	  width: 23px;
	  background: ${illusion};
	  display: flex !important;
	  align-items: center;
	  justify-content: center;
	}
	.searched-data-wrapper {
	  width: 100%;
	  position: relative;
	  z-index: 1;
	  margin-bottom: 0;
	  padding-bottom: 15px;
	  padding-top: 15px;
	  border-top: 1px solid ${lighterGrey};
	  background: ${whiteColor};
	  display: flex;
	  flex-direction: column;
	  justify-content: center;

	  .no-message-text {
		padding: 0 40px;
	  }
	  .search-date {
		padding: 0 0 0 40px;
	  }
	  .comment-data {
		padding: 0 0px 0 40px;
		color: ${mineShaft};
	  }
	  span {
		padding: 0 0 0 40px;
		display: block;
	  }

	  .unread-reply-active {
		width: 24px;
		height: 24px;
		border: none;
		margin-right: 12px;
		background: url(${replyinactive});
		background-repeat: no-repeat;
		border-radius: 50%;
		:hover {
		  background: url(${replyunread});
		  background-repeat: no-repeat;
		  background-size: 100%;		 
		}
	  }

	  .flag-icon {
		width: 24px;
		height: 24px;
		border: none;
		margin-right: 8px;
		background: url(${flaginactive});
		background-repeat: no-repeat;
		:hover {
		  background: url(${flagactive});
		}
	  }
	  
	  .active-flag {
		width: 24px;
		height: 24px;
		cursor: pointer;		
		border: none;
		display: inline-block;
		background-repeat: no-repeat;		
		margin-right: 8px;
		background: url(${flagactive});			
	  }

	.reply-icon {
		width: 24px;
		height: 24px;
		border: none;
		background: url(${replyinactive});
		background-repeat: no-repeat;
		:hover {
			background: url(${replyactive});
		}
	}
	  .search-heighlight {
		padding: 0 40px 10px 40px;
		line-height: 22px;
		mark {
		  padding: 0 6px;
		}
		span {
		  display: contents;
		  padding: 0;
		}
        pre{
          overflow: hidden;
          white-space: normal;   
		  margin-bottom: 8px;
		  margin-top: 8px      
        }
        pre code{
			font-family: 'Avenir LT Pro' !important;
			font-size: 14px;
			font-weight: 500;       
        }
	  }
	}    
  }
}
  .voice-staff-reply {
	color: ${pictonBlue};
	border: 1px solid ${bordergrey};
	background: ${whiteColor};
	border-radius: 8px;
	font-size: 16px;
	font-weight: 500;
	width: 100%;
	margin: 30px auto 0 auto;
	padding: 6px;
	text-align: center;
	float:left;
	span {
	  font-weight: 700;
	}
  }
  .content-body-pagination {
	color: ${pictonBlue};
	font-size: 14px;
	width: 100%;
	float:left;
	margin: 30px auto 0 auto;
	text-align: center;
	.active-pages {
	  font-weight: 700;
	  display: inline-block;
	}
	.prev {
	  float: left;
	  display: inline-block;
	  img {
		width: 20px;
	  }
	}
	.next {
	  float: right;
	  display: inline-block;
	  img {
		color: ${pictonBlue};
		width: 20px;
	  }
	}
  }
`;

export const CommonRepplyWrapper = styled.div`
  .search-inner-container {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .search-inner-sub-container {
    hr.horizontal-ruler {
      color: ${lighterGrey};
      margin-bottom: 20px;
    }
    .reply-section {
      padding: 0 20px;
    }
    textarea {
      font-size: 16px;
    }
    padding: 0;
    float: left;
    width: 100%;
    border-radius: 8px;
    background: ${whiteColor};
    border: 1px solid ${bordergrey};

    h3 {
      font-size: 15px;
      color: ${blackColor};
      margin: 20px 0;
      font-weight: 600 !important;
      line-height: 22px;
    }

    span {
      font-size: 12px;
      color: ${lightGray};
    }
    .comments-body {
      font-size: 14px;
      margin-top: 10px;
      font-weight: 500;
      line-height: 24px;
      padding-right: 20px;
      margin-bottom: 25px;
      color: ${blackColor};
    }
    .send-button {
      button {
        float: right;
        margin-bottom: 15px;
      }
    }

    .your-reply {
      .comments-body {
        color: ${mandy};
        word-break: break-word;
      }
    }
    .your-reply,
    .support-reply {
      .comments-body {
        font-size: 14px;
      }

      color: ${mandy};
      font-weight: 600;
      h3 {
        margin-bottom: 0;
        color: ${mandy};
        font-weight: 400;
      }
    }
    .support-reply {
      color: ${blackColor};
      font-weight: 600;
      h3 {
        color: ${teal};
      }
    }
  }
`;

export const MediaQuery = css`
  ${media.xs`
	position: relative;
	min-height: calc(100vh - 72px);
  `}
  ${media.md`
	position: relative;
	min-height: calc(100vh - 72px);
  `}
  ${media.lg`
  position:relative;
  min-height: calc(100vh - 72px);
  `}
  ${media.xl`
  position: relative;
  min-height: calc(100vh - 72px);
  `}
  ${media.sm`
  position: relative;
  min-height: calc(100vh - 72px);
  `}
`;

export const RegisterWrapper = styled.div`
  .styled-checkbox + label {
    align-items: end !important;
  }
  .register-checkbox-wrapper {
    left: 2px;
    &:after {
      top: 11px !important;
    }
  }
  float: left;
  width: 100%;

  .js .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .box {
    margin-bottom: 15px;
    height: 88px;
  }

  .inputfile {
    width: 100%;
    padding: 20px 0 0 0;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    height: 71px;
    opacity: 0;
    cursor: pointer;
  }

  .inputfile + label {
    max-width: 100%;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
  }

  .no-js .inputfile + label {
    display: none;
  }

  .inputfile:focus + label,
  .inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  .inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
  }

  .inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0;
    /* 4px */
  }

  /* style 6 */

  .inputfile-6 + label {
    color: #d3394c;
  }
  .inputfile-6-disabled + label {
    background: ${grainsboro};
    border: 1px solid ${blizzBlue};
    padding: 12px 0;
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 4px;
  }
  .inputfile-6 + label {
    background: ${twilightBlue};
    border: 1px solid ${blizzBlue};
    padding: 12px 0;
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 4px;
  }

  .inputfile-6:focus + label,
.inputfile-6.has-focus + label,
/* .inputfile-6 + label:hover*/ {
  }

  .inputfile-6 + label span,
  .inputfile-6 + label strong {
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
  }

  .inputfile-6 + label span {
    width: 100%;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    font-size: 16px;
  }

  .inputfile-6 + label strong {
    height: 100%;
    color: ${whiteColor};
    background-color: ${teal};
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 400;
    padding-top: 21px;
  }

  .inputfile-6:focus + label strong,
  .inputfile-6.has-focus + label strong,
  .inputfile-6 + label:hover strong {
    background-color: ${teal};
  }

  .no-file-upload {
    color: ${pictonBlue};
    font-weight: 400;
  }
  margin-top: 71px !important;
  @media screen and (max-width: 50em) {
    .inputfile-6 + label strong {
      display: block;
    }
  }

  ${media.xs`
  	float: none;
  `}
  ${media.sm`
  	float: none;
  `}
`;

export const MainContainer = styled.div`
  float: left;
  margin: 20px;
  min-height: 75vh;
  margin-top: 35px !important;
  border-radius: 5px;
  position: relative;
  ${media.xs`
	width: 90%;
	overflow: hidden;
	margin: 0 20px;
  `}
  ${media.md`
	width: 93%;
	overflow: hidden;
	margin: 0 30px;
  `}
  ${media.lg`
	width: 96%;
	overflow: hidden;
	margin: 0 30px;
  `}
  ${media.xl`
  width: 97%;
  `}
`;

export const GridMainHeading = styled.div`
  h2 {
    color: ${mineShaft};
    margin-bottom: 35px;
    margin-top: 15px;
    ${media.xs`
	margin-bottom: 13px;
	margin-top: 20px;
	font-size: 20px;
	`}

    ${media.md`
	margin-bottom: 13px;
	margin-top: 20px;
	font-size: 20px;
	`}
  }
`;

export const SurveyResultTables = styled.div`
  table {
    ${media.xs`
	width: 650px;
	`}
    ${media.md`
	width: 100%;
	`}
	${media.lg`
	width: 68% !important;
	`}
	${media.xl`
	width: 50% !important;
	`}
  }
`;

export const DropdownStyle = styled.div`
  width: 100%;
  float: left;
  text-align: left;

  input {
    ::-webkit-input-placeholder {
      color: transparent;
    }
    ::-moz-placeholder {
      color: transparent;
    }
    :-ms-input-placeholder {
      color: transparent;
    }
    :-moz-placeholder {
      color: transparent;
    }
  }

  cursor: pointer;
  /* border: 2px solid ${blizzBlue}; */
  margin-top: 0;
  font-weight: 400;
  margin: 0 0 27px 0;
  border-radius: 5px;
  color: ${pictonBlue};
  overflow: hidden;
  ${media.xs`
  margin: 0;
  background: ${whiteColor}
  `}
  ${media.xl`
  margin: 0 0 27px 0;
  `}
  
  img {
    float: right;
    margin-top: 8px;
  }
  span {
    display: block;
    padding: 7px 0 7px 17px;
    transition: all 0.5s ease;
    color: ${pictonBlue};
    font-weight: 400;
    font-size: 20px;
    button {
      background: transparent;
      border: none;
      padding: 0;
      font-size: 12px !important;
      font-weight: 500 !important;
      position: relative;
      color: #000000;
      font-family: 'Avenir LT Pro' !important;
      display: block;
      width: 100%;
      text-align: left;
      svg {
        position: absolute;
        top: -3px;
        right: 0%;
      }
    }
    .multilevel-dd {
      top: 0;
      right: -63%;
      span {
        padding: 7px 17px !important;
      }
    }
    .multilevel-dd-1 {
      top: 0;
      right: -45%;
      span {
        padding: 7px 17px !important;
      }
    }
  }
  span:last-child {
    border-bottom: 0;
  }
  span:hover {
    cursor: pointer;
  }

  span:hover button {
    color: ${whiteColor};
    cursor: pointer;
  }

  .options {
    display: block;
    max-height: 190px;
    // background: ${twilightBlue};
    overflow-y: auto;
  }
  .options span {
    color: ${blackColor};
    font-size: 16px;
    font-weight: 500;
  }
  .floating-input {
    background: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 97% center;
  }

  .selected-option {
    display: block;
    font-size: 20px;
    font-weight: 400;
    ${media.xs`
	  padding: 0px;
	  font-size: 18px;
	`}
  }
  .action-plan-options {
    overflow: initial;
  }
  .toplevel-dd {
    width: 150px;
    span {
      padding: 7px 0 7px 17px !important;
    }
  }
`;

export const CommentsDropdown = styled(DropdownStyle)``;

export const ReadActionPlan = styled.div`
  position: absolute;
  bottom: 8px;
  width: 93%;
  padding-top: 8px;
  background: ${whiteColor};
  border-top: 1px solid ${bordergrey};
  button {
    background: transparent;
    border: 0;
    font-size: 14px !important;
    cursor: pointer;
    font-weight: 600;
    color: ${pictonBlue};
    padding: 0;
  }
`;

export const SubContainer = styled.div`
  float: left;
  margin: 10px 10px 10px 5px;
  color: ${mineShaft};
  font-weight: 200;
  font-size: 20px;
  line-height: 20px;
  width: 100%;
  word-wrap: break-word;
  ${media.xs`   
	margin: 0;
	padding: 0;
  `}
  ${media.md`    
	margin: 0px;    
  `}  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    word-break: break-all;
  }

  table {
    margin-bottom: 30px;
    border: 1px solid ${alto};
  }
  thead {
    td {
      background: ${pictonBlue};
      padding: 15px 20px;
      color: ${whiteColor};
      border-right: 1px solid ${lightBlue};
      font-weight: 600;
      font-size: 16px;
      width: 11.1%;
    }
  }

  tbody {
    transition: all 0.5s ease;
    tbody:nth-child(even) {
      background: ${lighterGrey};
    }
    td {
      padding: 15px 20px;
      font-size: 16px;
      color: ${mineShaft};
      border-top: 1px solid ${alto};
    }
  }
`;

export const ExtendedSubContainer = styled(SubContainer)`
  .subcontainer-inner {
    width: 86%;
    margin: auto;
    .search-sorting {
      border-left: 1px solid rgba(83, 181, 224, 0.5);
      width: 32%;
      float: left;
      .selected-option {
        background-image: url(${staffIcon});
      }
    }
  }
  padding: 40px 0px 0 0;
  .search-data-container {
    width: 100%;
    float: left;
    padding-bottom: 20px;
  }
  .staff-result-innercontainer {
    width: 86%;
    margin-bottom: 20px;
    font-size: 14px;

    .back-button {
      display: block;
      padding: 10px 0;
      margin-bottom: 15px;
      a {
        color: ${pictonBlue};
        text-decoration: none;
        font-weight: 700;
        background: url(${fillarrowprev});
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 4%;
        padding-left: 15px;
      }
    }
  }
  .search-inner-sub-container {
    padding: 0;
    float: left;
    width: 100%;
    .searched-data-wrapper {
      width: 100%;
      position: relative;
      z-index: 1;
      margin-bottom: 0;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid ${lighterGrey};

      .no-message-text {
        padding: 0 40px;
      }
      .search-date {
        padding: 0 0 0 40px;
      }
      .comment-data {
        padding: 0 0 0 40px;
        color: ${mineShaft};
      }
      span {
        padding: 0 0 0 40px;
        display: block;
      }
    }
  }
  .staff-type-grid {
    float: left;
    width: 100%;
    .heatmap-error-msg {
      width: 100%;
      text-align: center;
      font-weight: 600;
      background: ${whiteColor};
      border: 1px solid ${bordergrey};
      border-radius: 8px;
      padding: 30px;
    }
    .heat-map-heading {
      float: left;
      width: 20%;
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
      padding-left: 12px;
      background: #ffffff;
      border: 1px solid ${bordergrey};
      height: 50px;
      line-height: 50px;
      margin-bottom: 4px;
      margin-top: 54px;
    }

    @media only screen and (max-width: 1000px) {
      .heat-map-heading {
        line-height: 20px;
        padding-top: 4px;
      }
    }

    .heat-map-grid-heading {
      float: left;
      width: 80%;
      ul {
        flex-direction: row;
      }
      li {
        text-align: center;
        border: 1px solid ${bordergrey};
        margin: 0px 0px 4px 4px;
      }
    }
    ul {
      display: flex;
      width: 100%;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        flex: 1;
        font-size: 13px;
        height: 50px;
        //line-height: 50px;
        font-weight: bold;
        color: ${blackColor};
        background: ${whiteColor};
        margin: 0px 0px 0px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }    
  }

  .staff-type-grid-data {
    .grid-role-heading {
      width: 20%;
      float: left;
      ul {
        flex-direction: column;
        align-items: baseline;
        li {
          height: 50px;
          float: left;
          flex: none;
          align-items: baseline;
          background: ${whiteColor};
          width: 100%;
          margin: 0px 0px 4px 0px;
          padding-left: 12px;
          font-weight: 600;
          border: 1px solid ${bordergrey};
          display: flex;
          //line-height: 50px;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
    }

    .grid-data-heading {
      ul {
        li {
          font-weight: 900;
        }
      }
    }

    .blank-entry {
      font-size: 20px;
      font-weight: 700;
    }
    .grid-data {
      width: 80%;
      float: left;
      ul {
        width: 100%;
        li {
          align-items: center;
          height: 50px;
          float: left;
          flex: auto;
          width: 100%;
          font-weight: 900;
          margin: 0px 0px 4px 4px;
        }
        li:first-child {
          align-items: center;
        }
      }
    }
    .role-heading {
      border-left: 5px solid ${midSoftGrey};
    }
    .greyStrep {
      background: ${softGrey};
    }

    .job-role-heading {
      border: 1px solid rgba(83, 181, 224, 0.32);
      padding: 12px;
      width: 100%;
      float: left;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 900;
      background-color: rgba(83, 181, 224, 0.25);
      background-image: url(${arrowDown});
      background-position-x: 99.5%;
      background-position-y: center;
      background-repeat: no-repeat;
      margin-bottom: 4px;
      .nodata {
        display: inline-block;
        margin-right: 40%;
        float: right;
        text-transform: initial;
        font-size: 12px;
        font-weight: 600;
      }
    }

    ul {
      padding: 0;
      li {
        color: ${mineShaft};
        height: 50px;
        border-bottom: 1px solid ${whiteColor};
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        font-weight: 900;
        .span {
          font-size: 9px;
          color: black;
          float: none;
          font-weight: 600;
        }
      }
      .viking {
        background: ${viking};
      }
      .bittersweet {
        background: ${bittersweet};
      }
      .minttulip {
        background: ${minttulip};
      }
      .yourpink {
        background: ${yourpink};
      }
    }
  }
  ul.upper-heading {
    width: 100%;
    float: left;
    text-align: center;
    li {
      color: ${blackColor};
      font-weight: bold;
      font-size: 14px;
    }
  }
  .staff-result-heading {
    background: ${softGrey};
    float: left;
    width: 100%;
    margin-bottom: 12px;
    padding: 20px;
    span:nth-child(1) {
      font-weight: 600;
      float: left;
    }
    span:nth-child(2) {
      font-weight: 400;
      float: right;
      font-size: 12px;
      color: ${lightGray};
    }
  }

  pre {
    white-space: normal;
    font-family: 'Avenir LT Pro' !important;
  }

  .custom-heatmap {
    .custom-heatmap-dropdown {
      width: 23vw;
      float: left;
      font-size: 14px;
      margin-bottom: 20px;
      font-weight: 500;
      .css-1s2u09g-control,
      .css-1pahdxg-control {
        border: 1px solid ${pictonBlue};
        font-size: 14px;
        color: ${blackColor};
      }
    }
    .custom-filter-heatmap-dropdown {    
      width: 40vw;
      float: left;
      font-size: 14px;
      margin-bottom: 20px;
      font-weight: 500;
      .css-1s2u09g-control,
      .css-1pahdxg-control {
        border: 1px solid ${pictonBlue};
        font-size: 14px;
        color: ${blackColor};
      }
    }  
    .flex-custom-heatmap {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      column-gap: 10px;
      form {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
      }
    }
    .heatmap-button {
      border: 1px solid ${pictonBlue};
      width: 100%;
      height: auto;
      line-height: 36px;
      text-align: center;
      color: ${pictonBlue};
      cursor: pointer;
      border-radius: 4px;
      padding: 0 10px;
      font-family: inherit;
      font-size: 14px !important;
      font-weight: 500;
      background-color: transparent;
      margin-right: 8px;
      transition: all 0.5s ease 0s;
      :hover {
        background-color: ${pictonBlue};
        color: ${whiteColor};
      }
    }
  }

  .heatmap-filters {
    border: 1px solid ${bordergrey};
    width: 100%;
    margin-right: 10px;
    background: ${whiteColor};
    max-height: 350px;
    overflow-y: auto;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        border-bottom: 1px solid ${bordergrey};
        color: ${blackColor};
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
        display: block;
        text-transform: capitalize;
        padding: 7px 10px;
        cursor: pointer;
        :hover {
          color: ${whiteColor};
          background: ${purple};
        }
        :last-child {
          border-bottom: 0px solid ${bordergrey};
        }
      }
      li.active {
        color: ${whiteColor};
        background: ${purple};
      }
    }
  }

  .filter-dropdown {
    padding: 12px;
    background: transparent;
    border: 1px solid #45b1e8;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    height: 50px;
    text-align: left;
    font-weight: 500;
    width: 20%;
    span {
      float: right;
    }
  }

  .save-heatmap-icon{
    cursor: pointer;
  }

  .heatmap-option > div{
    display: flex !important;        
    width: 48%;
    float: left;
  }

  .heatmap-option input[type="checkbox"]{
    margin-right: 8px !important;
  }

  .custom-filter-heatmap-dropdown > div > div:nth-child(1) > div > div:nth-child(1) {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #000;    
    font-weight: 900;
    margin: 10px 0 0 0;
  }
  .option-checkbox{
    padding: 0 8px 0 0;
  }
  
`;

export const SearchFilterWrapper = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 30px;
  background: rgba(83,181,224, 0.30);
  border: 1px solid rgba(83,181,224, 0.44);
  border-radius: 8px; 
  padding: 0;

  .search-filed{
	width: 44%;
	float: left;    
	img{
	  margin: 10px 0 0 12px;
	  float: left;
	  cursor: pointer;
	  height: 19px;
	}
	.cross{
		display: block;
		float: right;
		margin: 10px 19px 0 0;
		color: ${blackColor};
		font-size: 16px;
		cursor: pointer;
		font-size: 500;
	  }
	  input:focus .cross{
		  display: block;
	  }
	input{
	  width: 78%;
	  padding: 10px 12px;
	  background: transparent;
	  border: 0;
	  color: ${blackColor};  
	  font-weight: 500;
	  ::-webkit-input-placeholder {
		color: ${blackColor};
	  }
	  ::-moz-placeholder {
		color: ${blackColor};
	  }
	  :-ms-input-placeholder {
		color: ${blackColor};
	  }
	  :-moz-placeholder {
		color: ${blackColor};
	  }
	}
	
  }
  .search-filters, .search-sorting{
	width: 21%;
	float: left;
	border-left: 1px solid rgba(83,181,224, .5);
	border-right: 1px solid rgba(83,181,224, .5);
	position: relative;
	:last-child{
	  border-right: 0px solid rgba(83,181,224, .5);
	}
	> div{
	  background: transparent;
	  border: 0;
	  font-size: 14px;      	  
	  margin: 0;
	}
	.selected-option, .selected-option{
	  font-size: 14px;
	  font-weight: 500;
	  padding: 10px 20px 10px 45px;
	  border-bottom: 0;
	  background-image: url(${iconCheck});
	  background-repeat: no-repeat;
	  background-position: 17px 10px;   
	  img{
		width: 10px;
	  }
	  label{
		color: ${blackColor} !important;
		font-weight: 500;
	  }
	}
	.options, .options{
	  position: absolute;
	  border-radius: 5px;
	  background: ${whiteColor};
	  padding: 10px 0;
	  margin: -10px 0 0 24px;
	  box-shadow: 3px 3px 10px rgba(0, 122, 122, 0.12);
	  z-index: 9999;
	  span{
		font-size: 12px;
		padding: 7px 42px 7px 12px;		
		&:hover{
			background: ${pictonBlue};
			color: ${whiteColor};
		}
	  }
	}
  }

  


  .search-sorting{
	width: 20%;
	float: left;
	border-left: 0;
	}
	.selected-option{
	  background-image: url(${filterIcon});
	  background-repeat: no-repeat;
	  background-position: 19px 18px;
	}
	.options{
	  margin: -10px 0 0 22px;
	  box-shadow: 3px 3px 10px rgba(0, 122, 122, 0.12);
	  z-index: 9999;
	  span{
		font-size: 12px;
		padding: 7px 42px 7px 12px;		
		&:hover{
			background: ${pictonBlue};
			color: ${whiteColor};
		}
	  }
	}
  }

  @media only screen and (max-width: 700px){
	.search-filters{
	  width: 53%;
	}
	.search-sorting{
	  width: 47%;
	}    
  }
`;

export const DashboardCommentsWrapper = styled.div`
  position: relative;
  height: 100%;
  .comment-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 1;
    #loading {
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 3px solid rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      border-top-color: ${pictonBlue};
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
  .see-all-comments {
    position: absolute;
    bottom: 13px;
    padding-top: 5px;
    background: ${whiteColor};
    width: 88%;
    button {
      background: transparent;
      border: 0;
      font-size: 12px;
      cursor: pointer;
      font-weight: 900;
      color: ${pictonBlue};
      padding: 0;
    }
  }
  .thumb-vertical {
    background: ${illusion};
    width: 3px !important;
    margin-left: 5px;
    margin-top: 15px;
    cursor: pointer;
  }
  .scrollbar-view {
    height: 90% !important;
    // ${media.md`
    //   height: 65vh !important;
    // `}
    // ${media.xs`
    //   height: 30vh !important;
    // `}
  }
  .comments-wrapper {
    position: relative;
    height: 100%;
    .signle-dashboard-newmsg {
      left: 0;
      top: 55px;
    }

    .new-dashboard-comments {
      background: ${pictonBlue};
      color: ${whiteColor};
      padding: 6px 21px 6px 21px;
      font-size: 12px;
      width: 100%;
      z-index: 2;
    }
  }
  h2 {
    font-size: 20px;
    margin-top: 0;
    span {
      font-size: 12px;
      color: ${lightGray};
      float: right;
      margin-top: 4px;
      font-weight: 400;
    }
  }
  .comments-outer {
    width: 100%;
    float: left;
    font-size: 14px;
    max-height: 60vh;
    .day {
      display: block;
      width: 100%;
      margin-top: 10px;
      color: ${teal};
      font-weight: 600;
      font-size: 13px;
      float: left;
    }
    .comment-description {
      width: 100%;
      float: left;
      padding: 7px 0;
      font-size: 13px;
      word-wrap: break-word;
      color: ${blackColor};
      border-bottom: 1px solid ${bordergrey};
      span {
        display: block;
        margin-top: 16px;
        font-size: 12px;
        line-height: 18px;
      }
    }
    span {
      font-size: 12px;
    }
  }
`;

export const FieldWrapper = styled.div`
  position: relative;
  padding-bottom: 0;
  float: left;
  width: 100%;
  button {
    margin-top: 15px;
    ${media.xs`
  text-align: center;
  width: 100%;
  `}
  }
`;

export const AssistanceWrapperHeading = styled.div`
  font-weight: 900;
  float: left;
  .comment-heading {
    display: block;
    font-weight: 900;
    font-size: 24px;
    color: ${blackColor};
    margin-bottom: 5px;
  }
  span {
    color: ${blackColor};
    font-size: 16px;
    font-weight: 500;
    // margin-top: 5px;
    display: block;
  }
  h2 {
    margin: 0 0 10px 0;
    ${media.xs`
		font-size: 20px;
	`}
  }
  ${media.xs`
    width: 100%;
    margin-bottom: 15px;        
  `}
`;

export const RegisterInnerMain = styled.div`
  display: table;
  width: 100%;
  min-height: calc(100vh - 112px);
  h1 {
    color: ${mineShaft};
    font-size: 48px;
    margin-bottom: 4px;
    ${media.xs`
	   font-size: 36px;
	   font-weight: 900;
	 `}
  }
  h2 {
    color: ${mineShaft};
    font-size: 30px;
    font-weight: 900;
    width: 55%;
    margin-top: 0;
    padding: 10px;
    ${media.xs`
	   width: 100%;
	   font-size: 24px;
	   text-align: center;
	 `}
  }
  p {
    color: ${mineShaft};
    width: 55%;
    font-weight: 400;
    font-size: 20px;
    margin: 31px 0 55px 0;
    ${media.xs`
	   width: 90%;
	   line-height: 31px;
	   font-size:18px;
	 `}
  }
  span {
    color: ${outrageousOrange};
  }
`;

export const NewSurveyInputWrapper = styled.form`
  margin-top: 30px;
  .new-survey-placeholder {
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px ${barleyWhite} inset !important;
    }
    input {
      color: ${mineShaft};
      font-size: 16px;
      ::-webkit-input-placeholder {
        color: ${purple};
      }
      ::-moz-placeholder {
        color: ${purple};
      }
      :-ms-input-placeholder {
        color: ${purple};
      }
      :-moz-placeholder {
        color: ${purple};
      }
      :focus {
        /* border: transparent; */
      }
    }
  }
`;

export const ChartWrapper = styled.div`
  .main {
    display: flex;
    position: relative;
    margin-left: 111px;
  }

  .main span {
    text-align: center;
    color: #fff;
    font-weight: 900;
  }

  .first {
    width: 14.5%;
    display: inline-block;
    background: red;
    text-align: center;
    padding: 18px 0;
  }
  .second {
    width: 25.5%;
    display: inline-block;
    background: green;
    text-align: center;
    padding: 18px 0;
  }
  .third {
    width: 20%;
    display: inline-block;
    background: blue;
    text-align: center;
    padding: 18px 0;
  }
  .fourth {
    width: 20%;
    display: inline-block;
    background: teal;
    text-align: center;
    padding: 18px 0;
  }
  .fifth {
    width: 20%;
    display: inline-block;
    background: purple;
    text-align: center;
    padding: 18px 0;
  }

  .percent-value {
    width: 10%;
    display: inline-block;
    background: url(${legendsLine});
    background-repeat: no-repeat;
    background-position: top right;
    text-align: right;
    padding-right: 0;
    height: 6px;
  }

  .percent-value span {
    width: 63%;
    display: inherit;
    padding: 12px 0 0 0;
    margin-left: 10px;
    text-align: right;
    margin: 0;
    float: right;
    font-size: 13px;
    font-weight: 600;
  }

  .percent-value span:nth-child(10) {
    padding: 3px 20px 0 0;
  }

  .percent-legend {
    display: flex;
    position: relative;
    margin-top: 8px;
    border-top: 1px solid #000;
    margin-left: 110px;
  }

  .main-wrapper {
    width: 100%;
    float: left;
    position: relative;
    font-style: normal;
    font-style: normal;
    margin: 50px 0;
  }
  .main-wrapper > span {
    border-right: 1px solid #000;
    padding-left: 6px;
    position: absolute;
    left: 20px;
    padding-right: 20px;
    height: 100%;
    top: -6px;
  }
  .main-wrapper > span i {
    padding-top: 30px;
    font-style: normal;
    display: inherit;
  }
`;

export const CategoryPresentationBaseStyle = styled.div`
  background-color: ${colors.yellowColor};
  position: relative;
  padding: 40px 55px 50px;
  float: left;
  width: 100%;

  span {
    padding: 0 !important;
  }
  div:nth-child(1) {
    display: table;
    width: 100%;
    height: 100%;

    > div {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .big-title {
    color: ${colors.purple};
    font-size: 80px;
    margin: 0px;
  }

  .number-counter {
    width: 100% !important;
  }

  .number-counter:before {
    float: left;
  }

  .content {
    padding: 10px 50px 10px 0px;
    color: ${colors.mineShaft};
  }

  ${media.md`
	.big-title {
	  font-size: 40px;
	  text-align: left;
	}
	.content {
	  font-size: 30px;
	  line-height: 36px;
	  font-weight: 400;
	  width: 70%;
	  margin-top: 10px;
	}
  `}

  ${media.lg`
	.big-title {
	  font-size: 40px;
	  text-align: left;
	}
	.content {
	  font-size: 30px;
	  line-height: 36px;
	  font-weight: 400;
	  width: 70%;
	  margin-top: 10px;
	}
  `}

  ${media.xl`
	.big-title {
	  font-size: 50px;
	  text-align: left;
	}
	.content {
	  font-size: 30px;
	  line-height: 36px;
	  font-weight: 400;
	  width: 70%;
	  margin-top: 10px;
	}
  `}
`;

export const QuestionCategoryPresentationStyle = styled(
  CategoryPresentationBaseStyle
)``;

export const LeftSectionStyle = styled(CategoryPresentationBaseStyle)`
  background-color: ${colors.whiteColor};
  padding: 40px 55px;
  box-shadow: none;
  .big-title {
    /* padding: 200px 50px 0px 0px; */
    width: 100%;
  }

  .content {
    color: ${colors.purple};
    font-size: 40px;
    width: 80%;
  }

  .app-logo {
    bottom: 10px;
    position: absolute;
    width: auto;
    padding: 0;
  }
`;

export const QuestionCategoryEvaluationStyle = styled(
  CategoryPresentationBaseStyle
)`
  background-color: ${({ subType }) =>
    subType === 'strength' ? colors.redColor : colors.teal};
  padding: 40px 55px;
  .small-title {
    color: ${({ subType }) =>
      subType === 'strength' ? colors.yellowColor : colors.illusion};
    font-size: 40px;
    margin: 0px;
    padding: 0 !important;
  }
  .small-percentag {
    font-size: 15px;
    color: ${whiteColor};
    margin-left: 47px;
    line-height: 14px;
  }
  .silde-content {
    margin-left: 0px;
    color: #fff;
    font-size: 27px;
    position: relative;
    padding-left: 42px !important;

    i {
      font-style: normal;
      margin-right: 15px;
      position: absolute;
      left: 0;
    }
  }

  .big-title {
    color: ${({ subType }) =>
      subType === 'strength' ? colors.yellowColor : colors.illusion};
    font-size: 40px;
    margin: 10px 0 20px 0;
    ${media.xl`
	font-size: 50px;
	`}
  }
  .content {
    padding: 0;
    color: ${colors.whiteColor};
  }

  .app-logo {
    bottom: 10px;
    position: absolute;
    width: auto;
    padding: 0;
  }
  ${media.md`

	.content {
	  // padding-left: 10%;
	}
  `}
  ${media.lg`
	.content {
	  // padding-left: 10%;
	}
  `}
`;

export const QuestionCategoryStyle = styled(CategoryPresentationBaseStyle)`
  background-color: ${({ subType }) =>
    subType === 'strength' ? '#84C1C3' : '#F7B2A5'};
  padding: 20px 20px 20px 20px;
  width: ${({ dynamicWidth }) => dynamicWidth};
  float: left;
  margin-top: 15px;
  height: 100vh !important;
  .small-title {
    color: ${({ subType }) =>
      subType === 'strength' ? colors.blackColor : colors.blackColor};
    font-size: 40px;
    margin: 0px;
    padding: 0 !important;
  }
  .small-percentag {
    font-size: 14px;
    color: ${blackColor};
    margin-left: 20px;
    line-height: 14px;
    font-weight: 500;
  }
  .silde-content {
    margin-left: 0px;
    color: ${blackColor};
    font-size: 17px;
    font-weight: 600;
    position: relative;
    line-height: normal !important;
    padding: 6px 0 6px 20px !important;
    i {
      font-style: normal;
      margin-right: 15px;
      position: absolute;
      left: 0;
    }
  }

  @media only screen and (max-width: 1200px){
    .silde-content{
      font-size: 14px;      
    }
    .small-percentag{
      font-size: 12px;
      font-weight: 500;
    }
    .big-title{
      font-size: 24px !important;
    }
  }

  .big-title {
    color: ${({ subType }) =>
      subType === 'strength' ? colors.blackColor : colors.blackColor};
    font-size: 28px;
    margin: 0px 0 10px 0;
  }
  .content {
    padding: 0;
    color: ${colors.whiteColor};
  }

  .app-logo {
    bottom: 10px;
    position: absolute;
    width: auto;
    padding: 0;
  }
`;

export const AssistanceWrapper = styled.div`
  .footer-buttons {
    float: right;
    img {
      height: auto;
      position: relative;
      top: 6px;
    }
    button {
      background: ${outrageousOrange};
      width: auto;
      height: auto;
      line-height: 45px;
      text-align: center;
      color: ${whiteColor};
      cursor: pointer;
      border-radius: 8px;
      padding: 0 18px;
      font-family: inherit;
      font-size: 15px !important;
      font-weight: 500;
    }
  }

  .add-voice-button {
    float: right;
    margin-right: 8px;
    position: relative;
    .add-tool-tip {
      display: none;
      position: absolute;
      font-size: 13px;
      font-weight: 400;
      color: ${mineShaft};
      left: -89px;
      margin-top: 49px;
      background: ${lighterGrey};
      padding: 6px 12px;
      border-radius: 7px;
    }
    :hover .add-tool-tip {
      display: block;
    }
    button {
      border: 0;
      background: ${mandy};
      color: #ffffff;
      font-size: 14px !important;
      border-radius: 8px;
      float: right;
      cursor: pointer;
      width: auto;
      height: auto;
      padding: 0 18px;
      line-height: 43px;
      font-weight: 500;
      ${media.xs`
				float:none;
				margin-top: 10px;				
			`}
    }
  }

  .upgrade-account-button {
    float: right;
    margin-right: 8px;
    position: relative;
    .add-tool-tip {
      display: none;
      position: absolute;
      font-size: 13px;
      font-weight: 400;
      color: ${mineShaft};
      left: -89px;
      margin-top: 49px;
      background: ${lighterGrey};
      padding: 6px 12px;
      border-radius: 7px;
    }
    :hover .add-tool-tip {
      display: block;
    }
    button {
      border: 0;
      background: #007a7a;
      color: #ffffff;
      font-size: 14px !important;
      border-radius: 8px;
      float: right;
      cursor: pointer;
      width: auto;
      height: auto;
      padding: 0 18px;
      line-height: 43px;
      font-weight: 500;
      ${media.xs`
				float:none;
				margin-top: 10px;				
			`}
    }
  }

  @media only screen and (max-width: 700px) {
    .add-voice-button {
      float: right;
      width: 100%;
    }
    .add-voice-button button {
      width: 100%;
    }
  }
  .create-survey-button {
    background: ${mandy};
    margin-right: 10px;
  }
  //z-index: 20;
  position: relative;
  button {
    border: 0;
    background: ${teal};
    color: ${whiteColor};
    font-size: 14px;
    border-radius: 8px;
    float: right;
    cursor: pointer;
    width: 45px;
    height: 45px;
    line-height: 49px;
    img {
      height: 14px;
    }
    ${media.xs`
			float:none;
			margin-top: 0px;
			padding: 9px 18px;		       
		`}
  }
  @media only screen and (max-width: 700px) {
    .btn-request-assistance {
      display: flex;
      button {
        width: 100%;
        display: block;
        margin-bottom: 10px;
        padding: 0;
      }
    }
    .dd-survey {
      width: 100%;
    }
    .dd-survey .latest-survey {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    .dashboard-school-name {
      margin-top: 15px;
    }
  }
  .btn-download {
    float: right;
  }
  .main-app-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .stickyheader {
    margin-left: 230px;
    width: 100%;
    width: calc(100% - 230px);
    float: left;
    background: transparent;
    color: #000000;
    padding-top: 20px;
    z-index: 999;
    box-shadow: none;
    position: fixed;
    top: 0;
    transition: 0.3s ease-out 0s;
    padding-left: 30px;
    padding-right: 30px;
  }

  .stickyheader.sticky {
    background: #fff;
    //border-bottom: 1px solid ${pictonBlue};
    transition: 0.3s ease-out 0s;
    box-shadow: 4px 4px 6px rgb(0 0 0 / 10%);
  }

  @media only screen and (max-width: 1000px) {
    .stickyheader {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .stickyheader.sticky {
      background: transparent;
      box-shadow: none;
    }
  }

  .main-app-user {
    display: flex;
    flex-direction: row-reverse;
  }

  .dashboard-school-name {
    margin-bottom: 15px;
    h1 {
      font-size: 26px;
      font-weight: 600;
      display: inline-block;
      margin: 0 0 8px 0;
    }
    .subscription {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
      background: ${purple};
      padding: 8px 15px;
      color: ${whiteColor};
      border-radius: 20px;
      position: relative;
      top: -4px;
    }
    .subtitle {
      font-size: 16px;
      display: block;
    }
  }
  .user-account {
    font-size: 15px;
    float: right;
    margin-left: 15px;
    .username {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .useremail {
      display: block;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .dashboard-school-name {
      margin-bottom: 15px;

      h1 {
        font-size: 20px;
      }
      .subscription {
        font-size: 12px;
      }
      .subtitle {
        font-size: 14px;
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    .main-app-header,
    .main-app-user {
      display: block;
    }
    .user-account,
    .btn-request-assistance {
      display: none;
    }
  }
  @media only screen and (max-width: 700px) {
    .header-bottom {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      .btn-download,
      .footer-buttons {
        margin-left: 8px;
        span {
          display: none;
        }
      }
    }

    .mat-header-bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      button,
      .btn-download {
        span {
          display: none;
        }
      }
    }
  }
`;

export const DashboardWelcomePopupWrapper = styled.div`
  .dashboard-welcome-wrapper {
    float: left;

    background: yellow;
  }
  .dashboard-welcome-image {
    width: 450px !important;
    margin: auto;
  }
`;

export const DashboardMainWrapper = styled.div`
  .btn-logout a {
    background: #45338c;
    border: #45338c 1px solid;
    color: ${whiteColor} !important;
    text-align: center;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    -o-border-radius: 8px;
    :hover {
      background: #e84f6b !important;
      border: #e84f6b 1px solid;
    }
  }

  .btn-bottom {
    margin-top: 35px;
    display: inline-block;
  }
  @media only screen and (min-width: 1920px) {
    .btn-bottom {
      position: absolute;
      bottom: 21px;
      left: 15px;
      width: 200px;
    }
  }

  .dashboard-ui-blocked {
    position: absolute;
    left: 1px;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 21;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    .survey-in-progress-message {
      color: ${mandy};
      width: 50%;
      font-size: 18px;
      font-weight: 900;
    }

    #loading {
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 3px solid rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      border-top-color: ${pictonBlue};
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }

  .survey-button-wrapper {
    float: left;
    width: 100%;
    text-align: center;
    position: relative;
    top: 20px;
    button {
      background: ${mandy};
      border: 0;
      color: ${whiteColor};
      border-radius: 50px;
      padding: 14px 20px;
      font-weight: 600;
      font-size: 14px !important;
      cursor: pointer;
      width: 100%;
      :hover {
        background: ${teal};
      }
    }
  }

  .create-survey-button-wrapper {
    display: inherit;
  }

  .super-admin-dashboard-wrapper {
    width: 100%;
    text-align: center;
    ul {
      list-style: none;
      width: 100%;
      padding-left: 0;
      margin: 0;
      li {
        display: inline-block;
        width: 100%;
        padding: 65px 0;
        border: 1px solid ${lighterGrey};
        background: ${whiteColor};
        font-weight: 900;
        margin: 10px 0;
        box-shadow: 3px 0px 9px -5px ${lightGray};
        span {
          font-size: 50px;
          font-weight: 900;
          width: 100%;
          text-align: center;
          line-height: 75px;
          color: ${mandy};
          ${media.md`
					font-size: 40px;
		  		`}
          ${media.xl`
					font-size: 50px;
		  		`}
        }
      }
      li:nth-child(1),
      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5) {
        cursor: pointer;
      }
      .surveyPercentage {
        width: 23%;
      }
    }
  }

  width: 100%;
  float: left;
  ${media.xs`
	float: none;
`}
  ${media.sm`
	float: none;
`}
.containerClass {
    padding-top: 0;
    width: 100%;
    float: left;
  }

  .right-container {
    .comment-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.9);
      top: 0;
      left: 0;
      z-index: 1;
      #loading {
        display: inline-block;
        width: 50px;
        height: 50px;
        border: 3px solid rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        border-top-color: ${pictonBlue};
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
      }

      @keyframes spin {
        to {
          -webkit-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        to {
          -webkit-transform: rotate(360deg);
        }
      }
    }
  }

  .inner-right-container {
    border: 1px solid ${bordergrey};
    background: ${whiteColor};
    border-radius: 10px;
    padding: 20px 20px;
    height: 100%;
  }

  .inner-left-container {
    .survey-tiles-outer {
      margin-top: 15px;
    }
  }

  .active-survey-outer {
    float: left;
    width: 50%;
    vertical-align: top;
    height: 100%;
  }

  @media only screen and (max-width: 700px) {
    .active-survey-outer {
      width: 100%;
    }
  }

  .tiles-wrapper {
    float: left;
    width: 100%;
  }

  .strongest-tiles {
    border-top: 1px solid ${cornflowerBlue};
    border-bottom: 1px solid ${cornflowerBlue};
    width: 96%;
    float: left;
    margin: 0 0 22px 0px;
    position: absolute;
    left: -37px;

    ${media.md`
		margin: 0 0 22px 0px;
		height: 127px;
		top: 22px;
	`}
    ${media.lg`
		margin: 0 0 22px 0px;
		height: 110px;
		top: 22px;
	`}
	${media.xl`
		height: 154px;
		top: 29px;
		width: 97%;
	  margin: 0 0 22px 0px;
	`}
  }

  .weakest-tiles {
    border-top: 1px solid ${outrageousOrange};
    border-bottom: 1px solid ${outrageousOrange};
    width: 96%;
    float: left;
    margin: 0 0 0 0;
    position: absolute;
    z-index: -1;
    left: -37px;

    ${media.md`
		margin: 0 0 22px 0px;
		height: 127px;
		top: 22px;
	`}

    ${media.lg`
		margin: 0 0 0 0;
		height: 108px;
		top: 22px;
	`}

	${media.xl`
		margin: 0 0 0 0;
		height: 157px;
		top: 28px;
		width: 97%;
	`}

	.tiles-box .header {
      background: ${darkYellow};
      width: 100%;
      padding: 15px 8px;
      text-align: center;
      color: #fff;
      font-weight: 900;
      font-size: 12px;

      ${media.md`
			padding: 10px 8px;
		`}

      ${media.lg`
			padding: 10px 8px;
		`}

		${media.xl`
			padding: 15px 8px;
		`}
    }
    .tiles-box:nth-child(1) .header {
      background: ${parrotGreen};
    }
    .tiles-box:nth-child(2) .header {
      background: ${outrageousOrange};
    }
    .tiles-box:nth-child(3) .header {
      background: ${pictonBlue};
    }
  }

  .weakest-label {
    display: inline-block;
    transform: rotate(-90deg);
    position: absolute;
    color: ${outrageousOrange};
    left: -60px;
    top: 58px;
    background: transparent;
    padding: 10px 0;
    width: 151px;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;

    ${media.md`
		top: 70px;
		width: 103px;
		left: -59px;
	`}

    ${media.lg`
		top: 59px;
		width: 103px;
		left: -68px;
	`}

	${media.xl`
		top: 97px;
		width: 151px;
		left: -92px;
	`}
  }

  .strongest-label {
    display: inline-block;
    transform: rotate(-90deg);
    position: absolute;
    color: ${cornflowerBlue};
    left: -60px;
    top: 58px;
    background: transparent;
    padding: 10px 0;
    width: 151px;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;

    ${media.md`
		top: 70px;
		width: 103px;
		left: -59px;
	`}

    ${media.lg`
		top: 63px;
		width: 103px;
		left: -68px;
	`}

	${media.xl`
		top: 97px;
		width: 151px;
		left: -92px;
	`}
  }

  .first-row {
    margin-top: 0 !important;
  }

  .tiles-main {
    margin-top: 8px;
    display: flex;
    position: relative;
    width: 100%;

    ${media.xs`
		margin-left: 0;
		padding-right: 0;
	`}
  }

  .inner-main {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .active-survey-wrapper {
    border-radius: 10px;
    background: ${whiteColor};
    padding: 20px 20px;
    border: 1px solid ${bordergrey};
    position: relative;
    height: 100%;
  }

  .upcoming-task-wrapper {
    border-radius: 10px;
    background: ${whiteColor};
    padding: 20px 20px;
    position: relative;
    border: 1px solid ${bordergrey};
  }

  .dashboard-school-name {
    max-width: 100%;
    font-size: 24px;
    font-weight: 900;
    float: left;
    span {
      display: block;
      color: ${blackColor};
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

export const AssitanceForm = styled.div`
  width: 100%;
  margin: auto;
  .create-survey-direction {
    color: ${blackColor};
    display: block;
    margin-bottom: 20px;
  }
  h2 {
    color: ${blackColor}!important;
    padding: 0;
  }
`;
export const InputWrapper = styled.form`
  padding: 0px;
`;
export const NewSurveyInner = styled.div`
  h2 {
    font-size: 24px;
  }
  vertical-align: middle;
  input {
    color: ${blackColor};
  }
`;

export const NewSurveyInnerMain = styled(RegisterInnerMain)`
  width: 100%;
  min-height: initial;
  h2 {
    width: 100%;
  }
`;

export const ExtendedAssitanceForm = styled(AssitanceForm)`
  .optional-label {
    font-size: 13px;
    float: left;
    position: absolute;
    bottom: -7px;
    left: 0;
  }
  width: 65%;
  ${media.lg`
  width: 55%;
  `}
  h2 {
    width: 100%;
    text-align: center;
    color: ${mineShaft} !important;
  }
  p {
    font-size: 18px;
    text-align: center;
    width: 91%;
    margin-bottom: 0;
    margin: auto;
  }
  .form-heading {
    border-top: 1px solid ${pictonBlue};
    text-align: center;
    margin: 50px 0 40px 0;
    float: left;
    width: 100%;
    span {
      position: relative;
      background: ${whiteColor};
      margin: -10px 0 0 0;
      color: ${pictonBlue};
      font-size: 12px;
      font-weight: 900;
      padding: 0 12px;
      top: -12px;
    }
  }
`;

export const WelbeeOption = styled(ExtendedAssitanceForm)`
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    margin-left: 5px;
    margin-top: 5px;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 37px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: url(${radiodot}) no-repeat center center;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  width: 65%;
  ${media.lg`
  width: 72%;
  `}

  .welbee-Options-Inner {
    width: 88%;
    margin: auto;

    .voice-box {
      border: 1px solid ${pictonBlue};
      border-radius: 6px;
      margin-top: 15px;
      padding: 12px;
      width: 100%;
      span {
        color: ${pictonBlue};
      }
    }
  }
  .voice-box-selected {
    background: ${pictonBlue};
    span {
      color: ${whiteColor} !important;
    }
  }
`;

export const ModalInnerWrapper = styled.div`
  width: 100%;

  .form-inner-wrapper {
    input {
      font-size: 14px;
      ::-webkit-input-placeholder {
        color: ${purple};
      }
      ::-moz-placeholder {
        color: ${purple};
      }
      :-ms-input-placeholder {
        color: ${purple};
      }
      :-moz-placeholder {
        color: ${purple};
      }
    }
  }
  ${media.md`
	width: 100%;
  `}
  ${media.xs`
	width: 100%;
  `}
`;

export const FooterButtonsWrapper = styled.div`
  float: left;
  width: 100%;
  button {
    font-size: 14px;
    padding: 12px 30px;
    margin-bottom: 0 !important;
  }
  button:nth-child(2) {
    float: right;
  }
`;

export const ExtendedFooterButtonsWrapper = styled(FooterButtonsWrapper)`
  text-align: center;
  padding: 0 28px 20px;
  button {
    padding: 16px 40px;
    font-size: 15px;
  }
`;

export const customStyles = {
  overlay: {
    background: 'rgba(255, 255,  255, 0.8)',
    //width: '100%'
  },
  modal: {
    background: `${barleyWhite}`,
    color: `${purple}`,
    borderRadius: '10px',
    boxShadow: '0 6px 15px 0 rgba(0, 0, 0, 0.1)',
    //width: '100%',
  },
  closeIcon: {
    fill: `${purple}`,
  },
  closeIconSize: {
    width: 15,
    height: 15,
  },
};

export const ErrorWrapper = styled.div`
  position: relative;
  float: left;
  top: 0px;
  left: 5px;
  color: ${mandy};
  font-size: 14px;
  text-align: left;
  width: 100%;
  a {
    font-size: 12px;
    font-weight: 900;
    color: ${pictonBlue};
    text-decoration: none;
  }
  .error-school {
    margin-top: 12px !important;
  }
`;

export const NewSurveyWrapper = styled.div`
  float: left;
  width: 100%;
  input {
    background: transparent;
  }
  ${media.xs`
  	position: relative;   
  `}
  ${media.md`
  	position: relative;   
  `}
  ${media.lg`
  	position: relative;  
  `}
  ${media.xl`
  	position: relative;  
  `};
`;

export const ThankYouMainWrapper = styled.div`
  float: left;
  width: 100%;
  padding-left: 30px;
  .welcome-right-panel {
    border-right: 2px solid ${hummingBird};
    border-left: 2px solid ${hummingBird};
  }
  .mobile-wrapper {
    min-height: calc(100vh - 125px);
    ${media.xs`
  min-height: auto;
  width: 100%;
  margin: auto;
  padding-bottom: 0;
  `}
    > div {
      ${media.xs`
  min-height: auto;
  width: 100%;
  margin: 30px auto 5px auto;
  `}
    }
  }
  margin-top: 63px !important;
  ${media.xs`
margin-top: 61px !important;
position: relative;
  min-height: calc(100vh - 72px);
`}
  ${media.sm`
position: relative;
  min-height: calc(100vh - 72px);
`}
${media.md`
position: relative;
  min-height: calc(100vh - 72px)
`}
${media.lg`
position: relative;
min-height: calc(100vh - 65px);
`}
${media.xl`
position: relative;
min-height: calc(100vh - 65px);
`}
`;

export const HeadTeacherReplyWrapper = styled(ThankYouMainWrapper)`
  .reply-guide {
    width: 188px;
    border: 1px solid ${lightGreen};
    color: ${teal};
    font-size: 14px;
    font-weight: 900;
    margin-top: 30px;
    border-radius: 4px;
    padding: 20px;
    line-height: 30px;

    ${media.md`
	 width: 160px;
	   padding: 16px;
	`}
  }
`;

export const ThankYouWrapper = styled.div`
  display: table;
  height: 100%;
  width: 100%;
  h1 {
    color: ${mineShaft};
    font-size: 48px;
    margin-bottom: 4px;
    ${media.xs`
	 font-size: 36px;
	 font-weight: 900;
   `}
  }
  h2 {
    color: ${mineShaft};
    font-size: 36px;
    font-weight: 400;
    width: 55%;

    margin-top: 0;
    ${media.xs`
  line-height: 31px;
	 width: 90%;
	 font-size: 24px;
   `}
  }
  p {
    color: ${mineShaft};
    width: 55%;
    font-weight: 400;
    font-size: 20px;
    margin: 31px 0 55px 0;
    ${media.xs`
	 width: 90%;
	 line-height: 31px;
	 font-size:18px;
	 margin: 31px 0 0 0;
   `}
  }
  span {
    color: ${outrageousOrange};
  }
`;

export const CommentReplyWrapper = styled(ThankYouWrapper)`
  margin-top: 11px;
`;

export const ThankYouInnerWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const SurveySubmitCardWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;
  border: 1px soid ${teal};
  border-radius: 4px;
`;

export const BaseSurveyStandardStyle = styled.div`
  width: 100%;
  float: left;
  .header-text {
    font-size: 14px;
    display: inline-block;
    width: 100%;
    font-weight: 500;
  }

  .survey-progress-action-wrapper {
    width: 100%;
    float: left;
    margin: 0 0 30px 0;
    display: table;
    //min-height: 320px;
    position: relative;
    h3 {
      font-weight: 900;
      font-size: 16px;
    }
  }

  h3 {
    font-weight: 900;
    font-size: 16px;
    margin: 30px 0 15px;
  }

  .progress-chart {
    float: left;
    width: 39%;
    background: ${whiteColor};
    color: ${mineShaft};
    padding: 0;
    border: 1px solid ${bordergrey};
    border-radius: 6px;
    min-height: 50vh;
    position: relative;
    ${media.xl`
	min-height: 34vh;
	`}
    h3 {
      padding: 20px 20px 20px 20px;
      margin-top: 0;
      background: ${({ value }) => value};
      color: ${whiteColor};
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      font-size: 24px;
    }
    span {
      padding: 0 20px 0 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .progress-chart,
    .multi-survey-action {
      width: 49% !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .progress-chart,
    .multi-survey-action {
      width: 100% !important;
    }
    .progress-chart {
      margin-bottom: 20px;
    }
  }

  .multi-survey-action {
    float: right;
    width: 59.2%;
    padding: 20px;
    background: ${whiteColor};
    color: ${mineShaft};
    padding: 0 20px 0;
    border: 1px solid ${bordergrey};
    border-radius: 6px;
    min-height: 50vh;
    position: relative;
    ${media.xl`
	    min-height: 34vh;
	  `}
    ${media.lg`
	    min-height: 43vh;
	  `}
    h3 {
      .add-btn {
        float: right;
        border-radius: 50%;
        border: 0;
        color: ${whiteColor};
        font-size: 25px !important;
        background: ${({ value }) => value};
        width: 35px;
        height: 35px;
        cursor: pointer;
        margin: -12px -2px 0 0;
      }
    }

    .action-footer {
      position: absolute;
      border-top: 1px solid ${lighterGrey};
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 15px 20px;
      a {
        font-size: 12px;
        font-weight: 900;
        color: ${pictonBlue};
        text-decoration: none;
      }
    }
    .plus-icon {
      font-size: 14px;
      text-align: left;
      line-height: 16px;
      margin-top: 20px !important;
      font-weight: 500;
    }
    .action-plan-body {
      padding: 0;
      width: 100%;
      float: left;
      margin: 0;
      max-height: 198px;
      overflow: auto;
      .action-plan-date {
        float: left;
      }
      .alert-date {
        color: ${outrageousOrange};
        font-weight: 900;
      }

      ul {
        position: relative;
        list-style: none;
        padding: 5px 0;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        li {
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          padding-left: 3px;
        }
        :hover {
          background: ${twilightBlue};
          border-radius: 5px;
        }
        :hover .circle-parent-wrapper {
          visibility: visible;
        }

        :hover .action-category {
          color: ${teal};
          font-weight: 900;
        }
      }

      .circle-parent-wrapper {
        visibility: hidden;
        float: right;
        text-align: center;
        padding: 0 5px;
        border-radius: 4px;
        cursor: pointer;
      }

      .circle-tick {
        display: inline-block;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: ${pictonBlue};
        color: ${whiteColor};
        font-weight: 900;
        font-size: 11px;
        text-align: left;
        padding-left: 3px;
      }
    }
  }

  .inner-sub-container {
    padding: 15px 0;
    float: left;
    width: 100%;
    border-radius: 6px;
    background: ${whiteColor};
    box-shadow: 3px 3px 24px rgba(0, 122, 122, 0.12);
    h3 {
      padding: 0 40px;
      margin: 14px 0 6px 0;
      font-size: 16px;
      font-weight: 900;
    }
    .add-btn {
      float: right;
      border-radius: 50%;
      border: 0;
      color: ${whiteColor};
      font-size: 25px;
      background: ${({ value }) => value};
      box-shadow: 2px 1px 7px rgba(0, 122, 122, 0.12);
      padding: 3px 10px;
      cursor: pointer;
      margin: -7px -12px 0 0;
    }
    .action-plan-header {
      border-top: 1px solid ${lighterGrey};
      border-bottom: 1px solid ${lighterGrey};
      padding: 10.5px 40px;
      width: 100%;
      float: left;
      margin: 15px 0 8px 0;
      ul {
        list-style: none;
        padding: 0;
        width: 100%;
        display: flex;
        margin: 0;
        justify-content: space-between;

        li {
          display: inline-block;
          font-size: 12px;
          font-weight: 900;
          flex: 1;
          text-align: left;
          color: ${lightGray};
        }
        li:nth-child(1) {
          flex: 3;
          text-align: left;
        }
      }
    }
  }
`;

export const SingleSurveyStandard = styled(BaseSurveyStandardStyle)``;

export const MultiSurveyStandard = styled(BaseSurveyStandardStyle)`
  .progress-chart {
    float: left;
    width: 59.2%;
    padding: 0;

    > div {
      height: 235px !important;
    }
  }

  .multi-survey-action {
    width: 39%;
  }

  .inner-sub-container {
    .add-btn {
      background: ${mandy};
    }
  }
`;

export const PageWrapper = styled.div`
  .btn-bottom {
    margin-top: 35px;
    display: inline-block;
    width: 100%;
  }
  @media only screen and (min-width: 1920px) {
    .btn-bottom {
      position: absolute;
      bottom: 21px;
      left: 15px;
      width: 200px;
    }
  }

  .right-container {
    position: relative;
    margin-left: 230px;
    width: 100%;
    width: calc(100% - 230px);
    padding: 30px;
    float: left;
    margin-top: 80px;
	.dashboard-school-name {
		max-width: 100%;
		font-size: 24px;
		font-weight: 900;    
		float: left;
		span {
			display: block;      
			  color: ${blackColor};
			  font-size: 16px;
			  font-weight: 500;	  
		}
	}
  }

  .group-right-container{
    margin-top: 50px !important;
  }
  @media only screen and (max-width: 1023px) {
    .right-container {
      width: 100%;
      margin-left: 0;
      padding: 0 15px 0 15px;
      z-index: -1;
      margin-top: 0px;
    }
  }

  .left-container {
    width: 100%;
    float: left;
    z-index: 999;
  }

  .main-header {
    padding: 15px 15px 0 15px;
    .btn-request-assistance {
      display: flex;
      button {
        border: 0;
        background: #007a7a;
        color: #ffffff;
        border-radius: 8px;
        float: right;
        cursor: pointer;
        width: 38px;
        height: 38px;
        line-height: 40px;
        img {
          height: 13px;
        }
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .main-header {
      display: none;
    }
  }
@media only screen and (max-width: 1000px){
    .main-header .main-header-content {
      display: flex;
    }
  }

  @media only screen and (min-width: 992px) {
    mobile-logo {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .mobile-logo {
      display: block;
    }
  }

  .mobile-logo {
    width: 88%;
    text-align: center;
  }

  .mobile-logo img {
    height: 55px;
  }

  .leftsidebar > div > div:first-child,
  div[role='navigation'] > div:first-child {
    width: 230px;
    background: #ffffff;
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1) !important;
    height: 100%;
    overflow-x: hidden;
    z-index: 999 !important;
    position: fixed !important;
  }

  @media only screen and (min-width: 1024px) {
    .leftsidebar > div > div:first-child,
    div[role='navigation'] > div:first-child {
      transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -o-transform: none !important;
    }
  }

  div[role='navigation'] {
    position: static !important;
  }

  div[role='navigation'] > div:nth-child(3) {
    position: relative !important;
    overflow-y: hidden !important;
    width: 100%;
  }

  div[role='navigation'] > div:nth-child(2) {
    z-index: 101 !important;
  }

  .leftsidemenu .voice-sruvey-options {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    margin: 7px 0 22px 0;
    background: ${whiteColor};
    border-top: 1px solid ${pictonBlue};
    border-bottom: 1px solid ${pictonBlue};
    padding-bottom: 0;

    span {
      padding: 10px 27px;
      text-align: center;
      color: ${pictonBlue};
      font-weight: 400;
      cursor: pointer;
      :hover {
        background: ${pictonBlue};
        color: ${whiteColor};
      }
    }
    .active-toggle {
      background: ${pictonBlue};
      color: ${whiteColor};
    }
    .survey-disabled {
      pointer-events: none;
      color: ${lighterGrey};
    }
  }
  .leftsidemenu .voice-nav {
    border-left: 0 !important;
    a {
      /* :hover {
	  border-left: 4px solid ${darkYellow} !important;
	} */
    }
  }
  .leftsidemenu .show-account {
    bottom: 0 !important;
    height: 345px !important;
  }

  .leftsidemenu .account-upgrade {
    background: ${barleyWhite};
    padding: 35px 10px 10px 10px;
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -11px;
    overflow: hidden;
    min-height: 30px;
    height: 0;
    width: 100%;
    transition: all 0.5s ease-in-out;

    .close-box {
      display: none;
      float: right;
      font-size: 18px;
      margin: -2px 10px 0 0;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 1px;
    }
    .premium-alert {
      font-size: 14px;
      a {
        float: left;
        font-weight: 900;
        color: ${mineShaft};
      }
    }
    .box {
      position: relative;
      width: 170px;
      min-height: 300px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      background: transparent;
      transition: all 0.4s ease-in;
    }
    .box .percent {
      position: relative;
      width: 150px;
      height: 150px;
    }
    .box .percent svg {
      position: relative;
      width: 150px;
      height: 150px;
    }
    .box .percent svg circle {
      width: 150px;
      height: 150px;
      fill: none;
      stroke: #000;
      transform: translate(5px, 5px);
      stroke-dasharray: 440;
      stroke-dashoffset: 440;
    }
    .box .percent svg circle:nth-child(1) {
      stroke-dashoffset: 0;
      stroke: #e3bda3;
      stroke-width: 1px;
    }
    .box .percent svg circle:nth-child(2) {
      stroke-dashoffset: calc(440 - (440 * 15) / 30);
      stroke: #e84f6b;
      stroke-width: 4px;
      transform: rotate(90deg);
      transform-origin: 47% 50%;
    }
    .box .percent .number {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .box .percent .number h2 {
      font-size: 24px;
      margin: 10px 0;
      font-weight: 900;
    }
    .box .percent .number h2 span {
      font-size: 24px;
    }
    &:hover {
      bottom: 0;
      height: 345px;
      padding: 35px 10px 10px 10px;

      .close-box {
        display: block;
      }
    }
  }

  .leftsidemenu .welbee-url {
    display: none;
    position: fixed;
    bottom: 40px;
    background: ${whiteColor};
    width: 173px;
    a {
      padding: 0 0 0 28px;
      border-left: none !important;
      :hover {
        border-left: 4px solid ${twilightBlue} !important;
        font-weight: 400;
      }
    }
  }
  .scrollbar-view {
    @supports (-moz-appearance: none) {
      ul {
        overflow-x: hidden;
      }
    }
  }
  .leftsidemenu .account-main {
    .log-out {
      display: none;
      li {
        margin: 0;
        a {
          padding-top: 15px;
          margin-left: 0;
          padding-left: 10px;
          font-size: 12px;
          :hover {
            padding-left: 10px;
            border-left: 1px solid transparent;
          }
        }
      }
    }
  }
  .leftsidemenu .account-main:hover {
    .log-out {
      display: block;
      float: left;
    }
  }
  .leftsidemenu .sub-list { 
    padding: 10px 0 0 0;
    float: left;
    width: -webkit-fill-available;
    a {
      margin-left: 15px;
      margin-right: 15px;
      font-size: 13px;
      border-left: 0;
      padding: 10px 15px;
      cursor: pointer;
    }
  }

  .leftsidemenu ul {
    padding: 20px 0 0 0;
    margin: 0 15px;
    list-style: none;
    li {
      display: block;
      width: 100%;
      float: left;
      position: relative;
      padding-bottom: 5px;
      .active {
        border: none !important;
      }
      .school-logo {
        height: 100%;
        width: 45%;
      }
      .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: ${pictonBlue};
        color: ${whiteColor};
        font-weight: 400;
        font-size: 13px;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 90%;
        left: 35%;
        opacity: 0;
        transition: opacity 0.7s;
        :after {
          content: '';
          position: absolute;
          top: 100%;
          left: 20%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: ${pictonBlue} transparent transparent transparent;
        }
      }
      :hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
      .tooltiptext:hover {
        visibility: hidden;
      }
      .comment-badge {
        background: #ff5939;
        color: ${whiteColor};
        border-radius: 50px;
        width: 24px;
        height: 24px;
        font-size: 12px;
        position: absolute;
        right: 10px;
        top: 8px;
        text-align: center;
        line-height: 24px;
      }

      a {
        display: block;
        padding: 11px 15px;
        text-decoration: none;
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        float: left;
        width: 100%;
        transition: all 0.5s ease;
        :hover {
          background: #ede7f6;
          border-radius: 8px;
          webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -o-border-radius: 8px;
        }
        img {
          margin-right: 10px;
          position: relative;
          vertical-align: middle;
          float: left;
        }
        span {
          float: left;
          padding-top: 0px;
        }
        .icon-expand{
          float:right;
          position: absolute;
          right: 10px;
        }
      }

      .disable {
        background-color: ${lighterGrey};
        color: ${whiteColor} !important;
        pointer-events: none;
      }
    }

    li a.active {
      background: #ede7f6;
      border-radius: 8px;
      webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -o-border-radius: 8px;
      border: none !important;
      float: left;
      width: 100%;
    }
    /*li:nth-child(3) {
	border-left: 0px solid ${whiteColor};    
	a {
	  :hover {
		border-left: 0px solid ${whiteColor};
	  }
	}
  }
  li:nth-child(1) {
	margin: 39px 0 36px 38px;
	img {
	  width: 105px;
	}
  }*/

    .btn-upgrade a {
      border: #ff5939 1px solid;
      background: transparent;
      color: #ff5939;
      text-align: center;
      border-radius: 8px;
      -moz-border-radius: 8px;
      -webkit-border-radius: 8px;
      -o-border-radius: 8px;
      margin-bottom: 8px;
      :hover {
        background: #ff5939;
        color: ${whiteColor};
      }
    }
  }

  .btn-logout a {
    background: #45338c;
    border: #45338c 1px solid;
    color: ${whiteColor} !important;
    text-align: center;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    -o-border-radius: 8px;
    :hover {
      background: #e84f6b !important;
      border: #e84f6b 1px solid;
    }
  }

  .btn-tour a{
	border: #FF5939 1px solid;
	background: transparent;
	color: #FF5939 !important;
	text-align: center;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	-o-border-radius: 8px;
	margin-bottom: 8px;
	:hover{
	  background: #FF5939 !important;
	  color: ${whiteColor} !important;
	}
  }
}

  .action-wrapper-inner {
    max-width: 100%;
    margin-left: 120px;
    ${media.md`
	max-width: 100%;
	margin-left: 160px;
	`}
    ${media.sm`
	max-width: 100% !important;    
	
	`}
	${media.xs`
	max-width: 100%;    
	`}
	${media.xl`
	max-width: 89.7%;   
	`}
  }

  @media only screen and (max-width: 1000px) {
    .action-wrapper-inner {
      margin-left: 0;
    }
  }

  .carousel.carousel-slider .control-arrow {
    top: 34px;
    transition: none;
  }

  .zero-percent {
    text-align: left !important;
    float: left !important;
    padding-left: 0 !important;
    background: url(${legendsLine});
    background-repeat: no-repeat;
    background-position: -1px -24px;
    width: 35% !important;
  }

  .login-mobile-wrapper {
    ${media.xs`
	padding: 0 36px;
	`}
    ${media.md`
	padding: 0 60px;
	`}
	${media.lg`
	padding: 0 15px;
	`}
  }

  width: 100%;
  float: left;
  ${media.xs`	
	float: none;
  `}
  ${media.sm`	
	float: none;
  `}
  form {
    height: 100%;
  }
  .owl-carousel .owl-item {
    border-left: none !important;
    font-weight: normal;
    font-size: 14px;
  }
  .report-active {
    font-weight: 900 !important;
    border-left: 0px solid ${whiteColor} !important;
  }
 
`;
export const SelectWrapper = styled.div`
  float: left;
  width: 64%;
  margin: 30px 0 20px 66px;

  z-index: 999;
  ${media.xs`
	width: 84%;
	margin: 30px 20px 20px 20px;
  `}
  ${media.md`
  width: 84%;
  float: none;
	margin: 30px auto 20px auto;
  `}
  ${media.lg`
  width: 84%;
	margin: 10px 0 20px 49px;
  `}
  ${media.xl`
	width: 90%;
	margin: 10px 0 20px 49px;
  `}
`;

export const HeaderWrapper = styled.div`
  display: none;
  background: ${pictonBlue};
  width: 100%;
  float: left;
  min-height: 64px;
  position: fixed;
  z-index: 1;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;

    li {
      float: left;
      color: ${whiteColor};
      font-weight: 900;
      font-size: 14px;
      padding: 24px 24px 20px 24px;
      border-bottom: 2px solid ${pictonBlue};
      cursor: pointer;
      :hover {
        border-bottom: 3px solid ${gorse};
      }
    }
    li:last-child {
      background: ${teal};
      border-bottom: 0;
      border-left: 2px solid ${whiteColor};
      padding: 24px 30px;
      border-bottom: 0;
      :hover {
        background: ${easternBlue};
      }
    }
  }
  img {
    float: left;
    margin-left: 30px;
    margin-top: 20px;

    cursor: pointer;
    ${media.xs`
	  margin-left: 24px;
	  width: 22%;
	`}
    ${media.sm`
	  margin-left: 24px;
	`}
	${media.md`
	margin-left: 30px ;
	margin-top: 20px;
	`}
	${media.lg`
	margin-left: 30px;
	margin-top: 20px;
	`}

	${media.xs`   
	margin: 20px;
	`}
  }
`;

//should be in form style

export const ReportDropDown = styled.ul`
  display: none;
  height: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  float: right;
  transition: all 0.5s ease;
  background: ${whiteColor};
  border-bottom: 1px solid ${pictonBlue};
  border-right: 1px solid ${pictonBlue};
  border-left: 1px solid ${pictonBlue};

  li {
	border: 0 !important;
	background: transparent;
	color: ${pictonBlue} !important;
	font-size: 12px !important;
	font-weight: 900 !important;
	;
	padding: 10px 15px !important;
  }
  }
  li:last-child {
	border: none !important;
	background: transparent !important;
	color: ${pictonBlue};
	font-size: 12px;
	font-weight: 900;
  }
  :nth-child(1){
	padding-top: 9px;
	padding-bottom: 8px;
  }
  div{
	width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  top: -10px;
  border-bottom: 15px solid ${whiteColor};
  right: 38%;
  }
`;

export const MobileLogoWrapper = styled.div`
  display: none;

  ${media.xs`
  padding: 15px 0 15px 0;
	  display: block;
	  float: right;
	  width:90%;
	  text-align: right;
  `}
  img {
    ${media.xs`
	  width: 20%;
	  margin-left: 24px;
	`}
  }

  ${media.sm`
  padding: 15px 0 15px 0;
	  display: block;
	  float: right;
	  width:90%;
	  text-align: right;
  `}
  img {
    ${media.sm`
	  width: 20%;
	  margin-left: 24px;
	`}
  }

  ${media.md`
  padding: 35px 0 25px 0;
	  display: none;
	  float: right;
	  width:90%;
	  text-align: right;
  `}
  img {
    ${media.md`
	  width: 8%;
	  margin-left: 24px;
	`}
  }

  ${media.lg`
  display: none;
  width: auto;
  img {
	  width: auto;

  `};
`;

export const DesktopLogoWrapper = styled.div`
  display: block;

  img {
    ${media.xs`
	  width: 20%;
	  margin-left: 24px;
	  margin-top: 15px;
	`}
  }

  ${media.sm`
	  padding: 35px 0 25px 0;
	  display: none;
	  float: right;
	  width:90%;
	  text-align: right;
  `}
  ${media.lg`
	  padding: 35px 0 25px 0;
	  display: block;
	  float: right;
	  width:90%;
	  text-align: right;
  `}
  img {
    ${media.sm`
	  width: 20%;
	  margin-left: 24px;
	  margin-top: 15px;
	`}
  }

  img {
    ${media.md`
	  width: 20%;
	  margin-left: 24px;

	`}
    ${media.lg`
	  width: 20%;
	  margin-left: 0;
	`}
  }

  ${media.md`
  display: block;
  width: auto;
  float: none;
  text-align: center;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  img {
	  width: 150px;
	  margin-top: -50px;
  }
  `};
  ${media.lg`
  display: block;
  width: auto;
  float: none;
  text-align: center;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  img {
	  width: 150px;
	  margin-top: -50px;
  }
  `};
  ${media.xl`
  height: calc(91.5vh - 50px);
  `}
`;

export const CheckBox = styled.div`
  margin-bottom: 20px;
  position: relative;
  .checkbox-wrapper {
    text-indent: -1px;
    display: inline-block;
    width: 75%;
    color: ${pictonBlue};
    font-size: 13px;
    ${media.lg`
	width: 90%;
	`}
  }
  text-align: left;
  .styled-checkbox {
    position: absolute;
    opacity: 0;
    top: 6px;
    left: 6px;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      text-indent: -35px;
    }

    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 25px;
      height: 25px;
      background: white;
      border: 1px solid ${pictonBlue};
      border-radius: 5px;
    }

    &:hover + label:before {
      background: transparent;
    }
    &:focus + label:before {
    }
    &:checked + label:before {
      background: transparent;
    }

    // Disabled state label.
    &:disabled + label {
      color: ${pictonBlue};
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 7px;
      top: 12px;
      background: ${mandy};
      width: 3px;
      height: 3px;
      box-shadow: 2px 0 0 ${mandy}, 4px 0 0 ${mandy}, 4px -2px 0 ${mandy},
        4px -4px 0 ${mandy}, 4px -6px 0 ${mandy}, 4px -8px 0 ${mandy};
      transform: rotate(45deg);
    }
  }
`;
export const LoginFormWrapper = styled.div`
  .fee-detail {
    margin: 10px 36px;
    line-height: 25px;
    text-align: center;
  }
  .agree-button-wrapper {
    margin-top: 15px;
    button {
      float: none;
      width: 100%;
    }
    text-align: center;
  }

  display: block;
  ${media.xs`
  padding: 35px 0 25px 0;
	  display: none;
	  float: right;
	  width:90%;
	  text-align: right;
  `}
  ${media.sm`
  padding: 35px 0 25px 0;
	  display: none;
	  float: right;
	  width:90%;
	  text-align: right;
  `}


  ${media.md`
  padding: 35px 0 25px 0;
	  display: flex;
	  height: 100%;
	  float: left;
	  width:100%;
	  text-align: right;
  `}


  ${media.lg`
  display: block;
  width: auto;
  float: none;
  text-align: center;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  img {

	  width: auto;

	  margin-top: -50px;
  `};
  ${media.xl`
  min-height: 100vh;
  `}
`;
//may be we can use meaning full name
export const SchoolName = styled.div`
  float: right;
  padding: 24px 30px;
  font-size: 14px;
  font-weight: 900;
  color: ${whiteColor};
  ${media.xs`
  padding: 24px;
  `}
`;

export const ReportNavigation = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  float: right;
  ${media.xs`
  display: none;
  `}
  ${media.md`
  display: block;
  `}
  ${media.lg`
  display: block;
  `}
  li:nth-child(5) {
    padding: 24px 18px 20px 24px !important;
  }
`;

//should be in survey style

export const QuestionNavigationWrapper = styled.div`
  width: 100%;
  left: 0;
`;

//should be in survey style

export const QuestionNavigation = styled.div`
  display: block;
  margin: 0 45px;
  position: relative;
  z-index: 100;

  ${media.md`
  margin: 30px 60px;
  `}
  ${media.xs`
  margin: 0 20px;
  `}
  ${media.lg`
	margin: 0 45px;
  `}
`;

//should be in survey style

export const PreviousButton = styled.button`
  color: ${pictonBlue};
  float: left;
  font-weight: 900;
  font-size: 16px;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  ${media.xs`
  width: 82px;
	white-space: nowrap;
	overflow: hidden;
	padding: 0;
  `}
`;
//should be in survey style

export const NextButton = styled.button`
  color: ${pictonBlue};
  float: right;
  font-weight: 900;
  font-size: 16px;
  border: 0;
  margin-bottom: 10px;
  background: transparent;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
	opacity: 0.5;
	cursor: not-allowed;
  `}
  ${media.xs`
  width: 46px;
	white-space: nowrap;
	overflow: hidden;
	padding: 0;
  `}
`;
//should be in survey style

export const QuestionWrapper = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  text-align: left;
  width: 90%;
  margin: 20px;
  min-height: 75vh;
  transition: all 0.2s ease-in-out;
  background: #fdfdfd;
  box-shadow: 4px 5px 16px -10px ${orient};
  border-radius: 5px;
  ${({ border }) => border && `border: 2px solid ${teal}`}
  opacity: ${({ fade }) => fade};
  pointer-events: ${({ enabledCurrentSlide }) => enabledCurrentSlide};
  position: relative;
  ${media.xs`
  padding:  0 0 20px 0;
  margin: 20px 20px 20px 13px;
  width: 92%;
  `}
  ${media.sm`
  padding:  0 0 20px 0;
  margin: 20px 20px 20px 13px;
  width: 92%;
  `}
  ${media.md`
  padding: 0 0 20px 0;

  //min-height: 83vh;
  width: 93%;
  `}
  ${media.lg`
  width: 97%;
  padding:15px;
  margin-top: 20px;
  //min-height: 78vh;
  `}
  ${media.xl`
  //min-height: 83vh;
  `}
`;
//should be in survey style

export const QuestionTitle = styled.div`
  float: left;
  margin: 15px 45px 20px 45px;
  color: ${mineShaft};
  text-align: left;
  font-weight: 900;
  font-size: ${({ length }) => (length <= 72 ? '68px' : '40px')};
  line-height: ${({ length }) => (length <= 72 ? '73px' : '45px')};
  width: 80%;
  word-wrap: break-word;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    word-break: break-all;
    -ms-word-break: normal;
  }
  ${media.xs`
  font-size: ${({ length }) =>
    length <= 85 ? '19px' : length === 86 ? '20px' : '16px'};
  line-height: ${({ length }) =>
    length <= 85 ? '28px' : length === 86 ? '25px' : '20px'};
	margin: 20px;
  `}
  ${media.sm`
  font-size: 16px;
   line-height: 20px;
	margin: 20px;
  `}
  ${media.md`
  margin: 30px 45px;
  font-size: ${({ length }) => (length <= 86 ? '57px' : '40px')};
  line-height: ${({ length }) => (length <= 86 ? '73px' : '45px')};
  width: 84%;
  `}
  ${media.lg`
  width: 80%;
  line-height: 50px;
  margin: 15px 45px 20px 45px;
  font-size: ${({ length }) => (length <= 86 ? '50px' : '40px')};
  line-height: ${({ length }) =>
    length <= 85 ? '60px' : length === 86 ? '55px' : '45px'};
  `}

  ${media.xl`
  width: 70%;
  font-size: ${({ length }) => (length <= 86 ? '56px' : '40px')};
  line-height: ${({ length }) => (length <= 86 ? '73px' : '50px')};
  `}
`;
//should be in survey style

export const CircleButtonWrapper = styled.div`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.xs`
  display: block;
  padding-bottom: 16px;
  `}
`;
//should be in survey style

export const CircleButton = styled.button`
  background: transparent;
  width: 135px;
  border: none;
  height: 135px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  font-weight: ${({ fontweight, selected }) => (selected ? fontweight : '400')};
  background: ${({ color, selected }) => (selected ? color : 'transparent')};
  color: ${({ selected }) => (selected ? whiteColor : blackColor)};
  box-shadow: ${({ color, selected }) => (selected ? color : 'transparent')};
  -moz-box-shadow: ${({ color, selected }) =>
    selected ? `${'3px 2px 21px -3px'}${' ' + color}` : 'transparent'};
  -webkit-box-shadow: ${({ color, selected }) =>
    selected ? `${'3px 2px 21px -3px'}${' ' + color}` : 'transparent'};
  box-shadow: ${({ color, selected }) =>
    selected ? `${'3px 2px 21px -3px'}${' ' + color}` : 'transparent'};
  :hover {
    border: ${({ color, selected }) => !selected && `2px solid ${color}`};
  }

  span {
    ${media.xs`
	position: absolute;
	margin-top: -9px;
	display: block;
	margin-left: 38px;
	color: ${mineShaft};

	`}
  }
  ${media.xs`
  border:  ${({ color }) => `2px solid ${color}`};
  width: 20px;
  height: 20px;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  margin: 2px;
  display: block;
  margin-left: 20px;
  margin-bottom: 9px;
  -webkit-border-radius: 50%;
  `}
  ${media.sm`
  width: 95px;
  height: 95px;
  font-size: 12px;
  margin: 2px;
  border: none;
  display: flex;
  `}


  ${media.md`
  width: 100px;
  height: 100px;
  font-size: 14px;
  margin: 2px;
  border: none;
  display: flex;
  `}

  ${media.lg`
  width: 135px;
  height: 135px;
  font-size: 20px;
  margin: 10px;
  border: none;
  display: flex;
  `}
  ${media.xl`
  width: 135px;
  height: 135px;
  font-size: 20px;
  margin: 15px;
  border: none;
  display: flex;
  `}
`;

//should be in survey style
export const PasswordViewIcon = styled.button`
  position: absolute;
  width: 43px;
  height: 28px;
  right: 13px;
  top: 0.8rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  img {
    margin: 0;
  }
`;

export const ProgressBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 12px;
  background: ${ginFizz};
  ${media.xs`
  height: 24px;
  `}
  ${media.md`
  height: 24px;
  `}
  ${media.lg`
  height: 12px;
  `}
`;
//should be in survey style

export const QuestionProgress = styled.div`
  width: ${({ value }) => value};
  height: 12px;
  background: ${gorse};
  ${media.xs`
  height: 24px;
  `}
  ${media.md`
  height: 24px;
  `}
  ${media.lg`
  height: 12px;
  `}
`;

export const AppButton = styled.button`
  background: ${({ color }) => color};
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  color: ${whiteColor};
  padding: 12px 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: ${(props) => (props.isDisable ? 'auto' : 'pointer')};
  ${media.xs`
	font-size: 18px;	
	width: 100%;
  `};
`;

export const LinkButton = styled.button`
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  border: none;
  color: ${teal};
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: ${(props) => (props.isDisable ? 'auto' : 'pointer')};
  ${media.xs`
  	font-size: 18px;  
  	width: 100%;
  `};
`;

//we can use App prefix in main styled componenets

export const MainFooter = styled.div`
  width: 100%;
  float: left;
  text-align: right;
  border-top: 2px solid ${hummingBird};
  padding: 24px 33px 5px;
  color: ${keppel};
  font-size: 12px;
  bottom: 10px;

  span {
    float: right;
    color: ${pictonBlue};
    img {
      padding-top: 0;
      margin-left: 3px;
      width: 51px;
      ${media.xs`
		  padding-top: 0;
		  margin-top: -1px;
	  `}
    }
  }

  ${media.xs`
  font-size: 12px;
  padding: 24px 33px 15px;
  `}
  ${media.sm`
  font-size: 12px;
  padding: 24px 33px 15px;
  `}
  ${media.md`
  padding: 24px 33px 15px;
  `}
  ${media.lg`
  padding: 24px 33px 15px;
  `}
`;

//should be in form style

export const TextareaWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 45px;

  span {
    font-size: 12px;
    color: ${lightGray};
    font-weight: 400;
    width: 100%;
    float: left;
    margin-top: 4px;
  }
  ${media.xs`
 padding: 5px 20px;
 `}
  ${media.sm`
 padding: 5px 20px;
 `}
 ${media.md`
 padding: 30px 40px;
 `}
 ${media.lg`
 padding: 10px 45px;;
 `}
`;

export const DownloadButtonStyle = styled.div`
  width: auto;
  height: auto;
  line-height: 45px;
  text-align: center;
  color: ${whiteColor};
  cursor: pointer;
  background: ${outrageousOrange};
  border-radius: 8px;
  padding: 0 18px;
  font-size: 15px;
  img {
    position: relative;
    top: 6px;
  }

  ${media.xs`    
    margin-bottom: 10px;
  `}
`;

export const LatestSurveyDropdown = styled.div`
  .latest-survey {
    .selected-option {
      border: 0;
      font-size: 14px;
      font-weight: 500;
      img {
        margin-left: 17px;
        width: 12px;
        margin-top: 6px;
      }
    }
    .options {
      border-radius: 5px;
      background: #45338c;
      color: ${whiteColor};
      padding: 10px 0;
      margin: -10px 0 0 0;
      //box-shadow: 3px 3px 10px rgba(0,122,122,0.12);
      z-index: 9999;
      max-height: inherit;
      position: absolute;
      width: 100%;
      span {
        color: ${whiteColor};
        font-size: 12px;
        padding: 7px 11px 7px 26px;
      }
    }
    .active-option {
      background: #45338c;
      color: ${whiteColor};
    }
    float: right;
    margin-right: 8px;
    > div {
      margin: 0;
      border-radius: 8px;
      min-height: 45px;
      min-width: 123px;
      background: #45338c;
      color: ${whiteColor} !important;
      .selected-option {
        min-height: 45px;
        padding: 13px 20px;
        label {
          color: ${whiteColor} !important;
        }
      }
    }
    position: relative;
  }
`;

export const LoaderOverlay = styled.div`
  .comment-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    top: 0;
    left: 0;
    z-index: 1;
    #loading {
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 3px solid rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      border-top-color: ${pictonBlue};
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
`;

export const ChartWrapperError = styled.div`
  background: #fff;
  border: 1px solid ${bordergrey};
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  padding: 25px 25px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;
export const AnalyticsChartWrapper = styled.div`
  background: #fff;    
  border: 1px solid ${bordergrey};  
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  padding: 25px 25px;  
  //min-height: 600px;
  position: relative; 
  
  // ${media.sm`
  // 	min-height: 100%;
  // `}
  // ${media.xs`
  // 	min-height: 100%;
  // `}

   canvas{
     font-family: 'Avenir LT Pro' !important;  
     width: 100% !important;
     height: auto !important; 
      ${media.xs`
        height: 250px !important;
      `}   
   }
 
  .large-btn{    
	display: inline-block;
	float: right;
	button{
		padding: 0;		
	}
  }

  .large-download-btn{
    position: absolute;
    right: 57px;
    top: 25px;
	display: inline-block;
	float: right;
	margin-right: 8px;
  }
  .large-graph-btn {
    user-select: none;
    width: 34px;
    height: 34px;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;    
    padding: 5px;
    border-radius: 50px; 
    background: ${pictonBlue}; 
    color: ${whiteColor} !important;
  }
  .large-graph-btn2 {
    user-select: none;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;    
    padding: 5px;
    border-radius: 50px; 
    background: ${pictonBlue}; 
    color: ${whiteColor} !important;
    position: absolute;
    top: 10px;
    right: 50px;
  }
  

  .MuiBox-root {
    border: none;
  }
  .MuiTab-root{
    background: #efefef;
    text-transform: capitalize;
    margin-right: 5px;
    min-height: 40px;
    border-radius: 5px;    
  }
  .MuiTabs-root{
    min-height: 40px;
  }
  .MuiTab-root.Mui-selected{
    background: #53B5E0;
    color: #ffffff;
  }
  .nodata{
    font-family: 'Avenir LT Pro' !important;
    display: inline-block;
    text-transform: initial;
    font-weight: initial;
    padding: 0% 25% 0% 25%;
	font-size: 14px;
	font-weight: 600;
  }

  .compare-bars{
    display: flex !important;
    flex-direction: row;
    //flex-wrap: nowrap;
    //width: 2302px !important;    
  }
  .sort-btn, .north-sort-btn, .south-sort-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 4px;
    padding: 2px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;        
  }
  .south-sort-btn, .north-sort-btn, .sort-btn:hover{
    background-color: ${pictonBlue};
  } 
  .analytics-legends{
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: center;    
  }
  // .compare-bars > div:first-child > .analytics-legends{
  //   margin-left: 125px;
  // }
  
  .horizontal-chart{
    width: 100% !important;    
    max-height: 1000px !important;    
  }
  .analytics-container{
    margin-bottom: 30px;
    .recharts-legend-item{
      display: inline-flex !important;
      align-items: center !important;
    }
  }
  .circle-list {
    list-style-type: none;
    display: flex;
    padding: 0;
  }

  .circle-list-item {
    margin-right: 20px;
  }

  .legend-section{
    list-style-type: none;
    align-items: center;    
    display: flex;
    gap: 18px;
  }
  .circle-legend {
    width: 14px;
    height: 14px;
    border-radius: 50%;    
    margin-right: 5px;
  }
  .legend-text{
    text-transform: capitalize;
    font-size: 14px;
  }
  .legends{
    display: flex;
    align-items: center;
  }


  @media only screen and (max-width: 1366px){
    .responsive-analytics{
      width: 1280px;
    }
  }  
}
`;


export const AnalyticsChartPopupWrapper = styled.div`
  background: #fff;
  color: #000;  
  height: 600px;
  max-height: 85vh;  
  overflow: overlay;  
  border: 0.1px solid transparent;  
  scrollbar-width: thin;

  width: 100%;
  position: relative;
  font-family: 'Avenir LT Pro' !important;
  canvas {
    font-family: 'Avenir LT Pro' !important;
    width: 100% !important;
    height: auto !important;
  }
  .large-btn {
    display: inline-block;
    float: right;
    button {
      padding: 0;
    }
  }

  .large-download-btn {
    display: inline-block;
    float: right;
    margin-right: 8px;    
  }
  .large-graph-btn {
    user-select: none;
    width: 34px;
    height: 34px;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    padding: 5px;
    border-radius: 50px;
    background: ${pictonBlue};
    color: ${whiteColor} !important;
  }
  .large-graph-btn2 {
    user-select: none;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    padding: 5px;
    border-radius: 50px;
    background: ${pictonBlue};
    color: ${whiteColor} !important;
    position: absolute;
    top: 10px;
    right: 50px;
  }  
  .compare-bars{
    display: flex !important;
    flex-direction: row;
    //flex-wrap: nowrap;
    //width: 2302px !important;    
  }
  .sort-btn, .north-sort-btn, .south-sort-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 4px;
    padding: 2px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;        
  }
  .south-sort-btn, .north-sort-btn, .sort-btn:hover{
    background-color: ${pictonBlue};
  } 
  .analytics-legends{
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  .horizontal-chart{
    width: 100% !important;    
    max-height: 1000px !important;    
  }
  .analytics-container{
    margin-bottom: 30px;
  }

  .compare-bars > div:first-child > .analytics-legends{
    margin-left: 125px;
  }
  
  ::-webkit-scrollbar {
    background: rgba(222, 222, 222, .75);    
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {      
      border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,0.5);
      border-radius: 10px;      
  }

  ::-moz-scrollbar {
    background: rgba(222, 222, 222, .75);
    width: 8px;
    height: 8px;
  }
  ::-moz-scrollbar-track {    
    border-radius: 10px;
  }
  ::-moz-scrollbar-thumb {
    background: rgba(0,0,0,0.5);
    border-radius: 10px;    
  }

  .popup-scroll::-webkit-scrollbar {    
    width: 8px;
    height: 8px;
  }
  .popup-scroll::-webkit-scrollbar-track {      
      border-radius: 10px;
      background-color: rgba(0,0,0,0.1);
  }
  .popup-scroll::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0,0,0,0.5);
  }
  .popup-scroll::-moz-scrollbar {    
    width: 8px;
    height: 8px;
  }
  .popup-scroll::-moz-scrollbar-track {      
      border-radius: 10px;
      background-color: rgba(0,0,0,0.1);
  }
  .popup-scroll::-moz-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0,0,0,0.5);
  }
  .popup-scroll {
      max-height: 1000px;      
      overflow: overlay;
      scrollbar-width: thin;      
      scrollbar-height: thin;      
  } 
  .circle-list {
    list-style-type: none;
    display: flex;
    padding: 0;
  }

  .circle-list-item {
    margin-right: 20px;
  }

  .legend-section{
    list-style-type: none;
    align-items: center;    
    display: flex;
    gap: 18px;
  }
  .circle-legend {
    width: 14px;
    height: 14px;
    border-radius: 50%;    
    margin-right: 5px;
  }
  .legend-text{
    text-transform: capitalize;
    font-size: 14px;
  }
  .legends{
    display: flex;
    align-items: center;
  }
`;
export const WelbeeTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#53B5E0',
    },
    '&:hover fieldset': {
      borderColor: '#53B5E0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#53B5E0',
    },
  },
});

export const WelbeeTextArea = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#53B5E0',
    },
    '&:hover fieldset': {
      borderColor: '#53B5E0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#53B5E0',
    },
  },
});

export const SelectStyles = styled(Select)({
  fontSize: '14px',
  fontWeight: '500',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#53B5E0',
    fontSize: '14px !important',
    fontWeight: '500',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#53B5E0',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#53B5E0',
  },
});

export const HeaderDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-cell:hover': {
    color: 'primary.main',
  },
  '.MuiDataGrid-cell': {
    fontWeight: '500',
    color: '#000000',
  },
  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#45338C',
    color: '#ffffff',
    minHeight: '40px !important',
    maxHeight: '40px !important',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  '.MuiDataGrid-menuIconButton,.MuiDataGrid-sortIcon': {
    color: '#ffffff',
  },

  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      '&:hover, &.Mui-hovered': {
        backgroundColor: 'rgba(0,0,0,0.1)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'rgba(0,0,0,0.1)',
        },
      },
    },
  },

  '.MuiDataGrid-iconButtonContainer': {
    visibility: 'visible !important',
    '.MuiDataGrid-sortIcon': {
      opacity: '1 !important',
      fontSize: '15px',
      visibility: 'visible !important',
    },
  },
});

export const Autocompletemui = styled(Autocomplete)({
  fontSize: '14px',
  fontWeight: '500',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#53B5E0',
    fontSize: '14px !important',
    fontWeight: '500',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#53B5E0',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#53B5E0',
  },
});

export const FooterWrapper = styled.div`
  border-top: 1px solid rgba(83, 181, 224, 0.4);
  font-size: 13px;
  padding: 20px 0 20px 260px;
  margin-top: 15px;
  float: left;
  width: 100%;
  ${media.xs`
		padding-left: 15px;
  	`}
`;

export const ModalCustomStyles = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // bgcolor: '#ffffff',
  borderRadius: '8px',
  // boxShadow: 24,
  padding: '10px 15px 30px 15px',
};

/*** Table Style ***/
export const TableStyle = styled.div`
  table {
    font-size: 14px !important;
    border-collapse: collapse;
    width: 100%;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
  }

  td,
  th {
    padding: 8px !important;
    font-size: 14px !important;
    text-align: left;
  }

  tr:nth-of-type(odd) {
    background-color: #f2f2f2;
  }

  th {
    background-color: ${pictonBlue};
    color: ${whiteColor};
    padding: 15px 8px !important;
  }
  td > button {
    margin-right: 5px;
    padding: 10px 20px;
    border-radius: 50px !important;
  }
`;
export const MultiSelectDropDownWrapper = styled.div`
  #multiselectContainerReact {
    > div {
      min-height: 47px;
      border: 1px solid ${pictonBlue};
    }
    .optionContainer {
      max-height: 150px;
    }
    span {
      float: none !important;
      margin-top: 6px;
    }
    li:first-child {
      margin-bottom: 0;
    }
    .searchBox {
      margin-top: 9px;
    }
    .highlight {
      position: static;
      height: '112px';
      width: 100%;
      top: 0;
      left: 0;
      pointer-events: all;
      opacity: 1;
      color: ${pictonBlue};
      background: transparent;
    }
    .option {
      color: ${pictonBlue};
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.6px;
      display: flex;
    }
    li:hover {
      color: ${whiteColor};
      background: ${teal};
    }
  }
  .multi-select-wrapper {
    width: 100%;
    float: left;
  }

  width: 100%;
  float: right;
  span:first-child {
    line-height: normal !important;
    width: auto !important;
  }
  /* display: flex;
  align-items: flex-start; 
  button {
    padding: 15px 21px;
    margin: 0 0 0 15px;
  }*/
  .category-inner-wrapper {
    margin: 0;
    border: 2px solid;
    padding: 10px;
    width: 100%;
    position: relative;
    overflow: initial;
  }
  label {
    font-size: 16px;
  }
  .options {
    background: ${whiteColor};
    border: 2px solid;
    position: absolute;
    max-height: initial;
    width: 101.5%;
    left: -2px;
    z-index: 10;
    padding: 0px;
    margin-top: 9px;

    span {
      padding: 7px 17px 7px 0;
    }
  }
`;

export const MatAnalyticsVerticalTabs = styled.div`
  button {
    text-align: left !important;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    color: ${blackColor};
    align-items: start;
    min-height: 40px;
    border-bottom: 1px solid ${midSoftGrey};
    font-family: 'Avenir' !important;
    padding: 8px 16px;
  }
  .Mui-selected {
    color: ${whiteColor};
    background: ${purple};
  }
  .MuiTabs-vertical {
    border: 1px solid ${midSoftGrey};
    border-radius: 8px;
    min-width: 260px;
  }
`;

export const AppTable = styled.div`
  table {
    font-size: 14px !important;
    border-collapse: collapse;
    width: 100%;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
  }

  td,
  th {
    padding: 8px !important;
    font-size: 14px !important;
    text-align: left;
  }

  tr:nth-of-type(odd) {
    background-color: #f2f2f2;
  }

  th {
    background-color: ${purple};
    color: ${whiteColor};
    padding: 8px !important;
  }
  ${media.xs`
		overflow: auto;
	`}
`;
export const EnlargePopup = styled.div`
  > div {
    height: 83vh !important;
    width: 100% !important;
  }
  padding-top: 10px;
  canvas {
    width: auto !important;
    height: 100% !important;
  }
`;

export const WelbeeTour = styled.div`
  text-align: center;
  h2 {
    margin-top: 15px !important;
  }
  p {
    font-size: 17px;
    line-height: 29px;
  }
  button {
    font-size: 16px !important;
    width: 210px;
    font-weight: 500 !important;
  }
`;

export const StaffWrapper = styled.div`
  width: 100%;
  margin: 10px 0;
  float: left;
`;
