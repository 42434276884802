import request from '../request';
import { additionalSurveyQuestionsAPI } from './urls';
import { map } from 'lodash';
import { getLikertOptions } from '../../utils/CommonFunctions';

export const getQuestionBank = (token) => {
  return request({
    url: additionalSurveyQuestionsAPI.getQuestionBank(token),
    method: 'GET'
  })
    .then(response => response)
    .catch(error => error);
};

export const getSurveyQuestions = (token) => {
  return request({
    url: additionalSurveyQuestionsAPI.getSurveyQuestions(token),
    method: 'GET'
  })
    .then(response => response)
    .catch(error => error);
};

export const setSurveyQuestions = (question) => {
  return request({
    url: additionalSurveyQuestionsAPI.api,
    method: 'POST',
    data: question,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const deleteSurveyQuestions = (question) => {
  return request({
    url: additionalSurveyQuestionsAPI.api,
    method: 'DELETE',
    data: question,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const checkIsSurveyEditable = (token) => {
  return request({
    url: additionalSurveyQuestionsAPI.checkIsSurveyEditable(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getAdditionalQuestions = (token) => {
  return request({
    url: additionalSurveyQuestionsAPI.loadSurveyQuestions(token),
    method: 'GET',
  }).then((response) => {
    let questions = response.data;
    questions = map(questions, (question) => {
      
      question.Id = question.Order;
      question.Text = `${question.QuestionText}`;
      question.NoteText = `${question.NoteText}`;
      question.CategoryType = question.Category;
      question.HSEDecile = null;
      question.SchoolDecile = null;

      if(question.Type == 0) {
        question.answer = getAnswer({
          type: 'radio',
          questionId: question.Order,
          likertScale: question.LikertFormat,
          questionNumber: question.QuestionNumber
        });
        question.isAnswered = false;
      } else {
        question.answer = getAnswer({
          type: 'comment',
          questionId: question.Order,
          likertScale: question.LikertFormat,
          questionNumber: question.QuestionNumber
        });
        question.isAnswered = false;
      }
      return question;
    });

    const slides = map(questions, (question) => {
      return {
        type: 'question',
        question,
      };
    });

    return slides;
  })
  .catch((response) => response);
};

const getAnswer = ({ type = 'radio', questionId, likertScale, questionNumber }) => {
  const answer = {
    type,
    questionId,
  };
  if (type === 'radio') {
    answer.options = getLikertOptions(likertScale);
  } else if (type === 'comment') {
    answer.value = '';
    if (questionNumber == 77)
      answer.name = 'staffOpinion';
    else if (questionNumber == 78)
      answer.name = 'biggestImpact';
    else 
      answer.name = questionId;
  }

  return answer;
};

const additionalSurveyQuestionService = {
  getQuestionBank,
  getSurveyQuestions,
  setSurveyQuestions,
  deleteSurveyQuestions,
  getAdditionalQuestions,
  checkIsSurveyEditable,
};

export default additionalSurveyQuestionService;
