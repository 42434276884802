import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { amplitudeLog, getQueryStringVal } from '../../utils/CommonFunctions';
import {
  AppButton,
  LoginFormWrapper,
  ErrorWrapper,
} from '../../resources/styling/appStyle';
import { loginNormalEye, loginShowEye } from '../../resources/images/index';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import { AppInputField } from '../../components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { accountService, storageService } from '../../services';
import { routesObj } from '../../routes';
import { loaderAction } from '../../store/loaderStore';
import {
  WebStorageNames,
  Roles,
  WelbeeType,
  toggleOption,
  HeadTeacherAccess,
} from '../../utils/Constants';
import { parseJwt } from '../../utils/CommonFunctions';
import { PasswordReg } from '../../utils';
import { Redirect } from 'react-router-dom';
import LoginWrapper from '../../components/common/LoginWrapper';
import { WrapperMain } from '../../resources/styling/formStyle';
import {
  LoginFormInner,
  InputWrapper,
  PasswordViewIcon,
} from '../../components/common/loginStyle';

const { mandy } = colors;

function ResetPasswordForm(props) {
  let [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  let [isPasswordShow, setIsPasswordShow] = useState(true);
  let [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState('');
  let [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(true);
  let [isButtonDisabled, setIsButtonDisabled] = useState(false);
  useEffect(() => {
    amplitudeLog('PageView', 'ResetPassword', '');
    storageService.clear();
  }, {});
  return (
    <Formik
      initialValues={{
        Password: '',
        ConfirmPassword: '',
        Email: '',
        ResetCode: '',
      }}
      onSubmit={(values, actions) => {
        setPasswordErrorMessage('');
        setConfirmPasswordErrorMessage('');
        values.NewPassword = values.Password;

        values.Email = getQueryStringVal('email');
        values.ResetCode = getQueryStringVal('resetCode');
        setIsButtonDisabled(true);
        props.loaderActions.dataLoading(true);

        setTimeout(() => {
          actions.setSubmitting(false);
          accountService
            .resetPassword(values)
            .then((response) => {
              const { success, message } = response;
              props.loaderActions.dataLoading(false);

              if (success) {
                storageService.setToken({
                  token: response.data.access_token,
                });
                storageService.setItem(
                  WebStorageNames.UserInfo,
                  response.data.userInfo
                );
                storageService.setItem(
                  WebStorageNames.SchoolId,
                  response.data.schoolId
                );
                storageService.setItem(
                  WebStorageNames.IsStaff,
                  response.data.IsStaff
                );
                storageService.setItem(
                  WebStorageNames.IsFirstLogin,
                  response.data.IsFirstLogin
                );
                storageService.setItem(
                  WebStorageNames.IsVoiceFirstLogin,
                  response.data.IsVoiceFirstLogin
                );
                storageService.setItem(
                  WebStorageNames.IsUpgradeDisplay,
                  response.data.IsUpgradeDisplay
                );
                storageService.setItem(
                  WebStorageNames.WelbeeVoiceAccountType,
                  response.data.WelbeeVoiceAccountType
                );
                storageService.setItem(
                  WebStorageNames.AccessLevel,
                  response.data.AccessLevel
                );
                storageService.setItem(
                  WebStorageNames.SelectedTab,
                  response.data.WelbeeType === WelbeeType.WelbeeVoice
                    ? toggleOption.WelbeeVoice
                    : toggleOption.WelbeeSurvey
                );

                const token = storageService.getItem(WebStorageNames.Token);
                if (!token) {
                  return <Redirect to={routesObj.Login.path} />;
                }
                const userInfo = parseJwt(token);

                if (userInfo.role === Roles.SuperAdmin) {
                  props.history.push(routesObj.Schools.path);
                }
                if (userInfo.role === Roles.GroupDirector) {
                  storageService.setItem(WebStorageNames.IsMat, true);
                  storageService.setItem(
                    WebStorageNames.Role,
                    Roles.GroupDirector
                  );
                  props.history.push(routesObj.GroupDashboard.path);
                }
                if (userInfo.role === Roles.HeadTeacher) {
                  amplitudeLog(
                    'HeadTeacherLogin',
                    response.data.schoolName + ' HeadTeacherLogin',
                    {
                      UserName: values.Email,
                      SchoolName: response.data.schoolName,
                    }
                  );
                  storageService.setItem(
                    WebStorageNames.WelbeeType,
                    response.data.WelbeeType
                  );
                  storageService.setItem(WebStorageNames.IsMat, false);
                  if (response.data.AccessLevel === HeadTeacherAccess.Admin) {
                    if (response.data.WelbeeType === WelbeeType.WelbeeVoice) {
                      props.history.push(routesObj.Account.path);
                    } else {
                      props.history.push(routesObj.AdminDashboard.path);
                    }
                  } else {
                    if (response.data.WelbeeType === WelbeeType.WelbeeVoice)
                      props.history.push(routesObj.WelbeeVoice.path);
                    else props.history.push(routesObj.Dashboard.path);
                  }
                }
                amplitudeLog('Login', 'Login button success', {
                  UserName: values.Email,
                });
              } else {
                setPasswordErrorMessage(message);
                setIsButtonDisabled(false);
                amplitudeLog('ResetPassword', 'Reset Password failure', {
                  UserName: values.Email,
                });
              }
            })
            .catch((error) => {
              setPasswordErrorMessage(error.data.message);
              setIsButtonDisabled(false);
            });
        }, 1000);
      }}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        Password: Yup.string()
          .trim()
          .min(12, 'Password must be at least 12 characters.')
          .matches(
            PasswordReg,
            'Your password must contain at least 1 uppercase, lowercase, number and special character from @£!#$%^&+="\',-.'
          )
          .required('Password is required.'),
        ConfirmPassword: Yup.string()
          .trim()
          .oneOf([Yup.ref('Password'), null], 'Passwords must match.')
          .required('Confirm password is required.'),
      })}
      render={({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => (
        <LoginWrapper>
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.keyCode === 13) handleSubmit();
            }}
          >
            <LoginFormWrapper>
              <LoginFormInner>
                <h2>Set a new password</h2>
                <InputWrapper>
                  <WrapperMain>
                    <AppInputField
                      placeholder='New password'
                      name='Password'
                      value={values.Password}
                      onChange={(e) => {
                        setPasswordErrorMessage('');
                        setConfirmPasswordErrorMessage('');
                        setFieldValue('Password', e.target.value);
                      }}
                      onBlur={handleBlur}
                      onKeyDown={(key) => console.log(key)}
                      type={isPasswordShow ? 'password' : 'text'}
                    />
                    <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                      <img
                        src={isPasswordShow ? loginNormalEye : loginShowEye}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsPasswordShow(!isPasswordShow);
                        }}
                        alt=''
                      />
                    </PasswordViewIcon>
                    <ErrorWrapper>
                      {(touched.Password && errors.Password) ||
                        passwordErrorMessage}
                    </ErrorWrapper>
                  </WrapperMain>
                  <WrapperMain>
                    <AppInputField
                      placeholder='Confirm new password'
                      name='ConfirmPassword'
                      value={values.ConfirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={(key) => console.log(key)}
                      type={isConfirmPasswordShow ? 'password' : 'text'}
                    />
                    <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                      <img
                        src={
                          isConfirmPasswordShow ? loginNormalEye : loginShowEye
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setIsConfirmPasswordShow(!isConfirmPasswordShow);
                        }}
                        alt=''
                      />
                    </PasswordViewIcon>
                    <ErrorWrapper>
                      {(touched.ConfirmPassword && errors.ConfirmPassword) ||
                        confirmPasswordErrorMessage}
                    </ErrorWrapper>
                  </WrapperMain>

                  <WrapperMain>
                    <Grid container>
                      <Grid item lg={12} sm={12} xs={12} md={12}>
                        <AppButton
                          color={mandy}
                          type='submit'
                          onClick={handleSubmit}
                          disabled={isButtonDisabled}
                          isDisable={isButtonDisabled}
                        >
                          Submit
                        </AppButton>
                      </Grid>
                    </Grid>
                  </WrapperMain>
                </InputWrapper>
              </LoginFormInner>
            </LoginFormWrapper>
          </form>
        </LoginWrapper>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(ResetPasswordForm));
