
import React, { useState, useEffect } from "react";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    borderColor: '#45b1e8 !important;',
    color:'#000 !important'
  },
  select: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Avenir LT Pro',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
    '& .css-1jggpfo-MuiTypography-root': {
 
      color: '#000 !important',
  }
  },
  menuitem: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Avenir LT Pro',
    color:'#000 !important',
  },
  menuPaper: {
    maxHeight: 400,
    color:'#000 !important'
  },
  
}));

const MenuProps = {
  variant: "menu",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const MatCycleMultiSelect = (props) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const SelectedLatestSurvey = (data) => {
    setOptions(data);
    const selectedOptionId = Math.max(...data.map(x => x.Id));
    const selectedOptions = data.filter(
      (option) => option.Id === selectedOptionId
    );
    setSelected(selectedOptions);
  };
  useEffect(() => {
    SelectedLatestSurvey(props.data);
  }, [props.data]);

  useEffect(() => {
    setSelected(props.selectedValues);
  }, [props.cycleReload, props.selectedValues]);

  useEffect(() => {
    SelectedLatestSurvey(props.data);
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      props.onSelect(value);
      return;
    }
    else if (value.length === 1 || value.length === 2) {
      props.onSelect(value);
      setSelected(value);
      return;
    }
  };

 
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel className={classes.menuitem} id="demo-multiple-checkbox-label">{props.isSurveys ? 'Survey Comparison' : 'Cycle Comparison'}</InputLabel>
      <Select
        className={classes.select}
        variant="outlined"
        label={props.isSurveys ? 'Survey Comparison' : 'Cycle Comparison'}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.map(d => props.isSurveys ? d.SurveyName : d.CycleName).join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
        >
        </MenuItem>
        <ListSubheader style={{ fontSize: '14px !important', fontWeight: '500 !important', color: '#000 !important', fontFamily: 'Avenir LT Pro' }}>Compare any two surveys.</ListSubheader>
        {options.map((option) => (
          <MenuItem value={option} disabled={option.IsScore === false ? true : false} style={{ fontSize: '14px !important', color:'#000 !important', fontWeight: '500 !important' }}>
            <Tooltip disableHoverListener={option.IsScore === false ? false : true} arrow={true} title={`${props.isSurveys ? option.SurveyName : option.CycleName} record not available for comparison`}>
              <ListItemIcon>
                <Checkbox  checked={selected.indexOf(option) > -1} style={{ marginRight: 8, padding: 0 }} >              
                </Checkbox>
                <ListItemText primary={props.isSurveys ? option.SurveyName : option.CycleName} style={{ fontSize: '14px !important', color:'#000 !important', fontWeight: '500 !important' }} />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>

  );
};
export default MatCycleMultiSelect;
