import React, { Component } from 'react';
import { Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { AppButton } from '../../resources/styling';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import 'react-day-picker/lib/style.css';
import { AppModel } from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { actionPlanService } from '../../services';
import { actionPlanActions } from '../../store/actionPlanStore';
import { NewSurveyWrapper, NewSurveyInnerMain } from '../../resources/styling/appStyle';
import { 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  FormLabel, 
} from '@mui/material';

import {  AdditionalQuestionsFormWrapper,
  InputWrapper,
  NewSurveyInner,
  ModalInnerWrapper,
  FooterButtonsWrapper,
  RadioGroupWrapper } from './AdditionalQuestionStyle';

const {
  mandy
} = colors;

class AdditionalQuestionLikertForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isListOpen: false,
      isSignListOpen: true,
      isEdit: props.isEdit,
      isEmpty: true,
      isLikert: true,
      type: props.editRecord.Type,
      signType: props.editRecord.SignType,
      likertFormat: props.isEdit ? props.editRecord.LikertFormat : 0
    };
  }
  
  render() {
    const {
      isOpen,
      onClose,
      handleSubmit,
      editRecord,
      surveyId,
      schoolId
    } = this.props;
    const { 
      isEdit,
      likertFormat = isEdit ? editRecord.LikertFormat : 0
    } = this.state;
    const classes = this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          Order: editRecord.Order,
          QuestionNumber: editRecord.QuestionNumber,
          QuestionText: editRecord.QuestionText,
          Type: editRecord.Type,
          Category: editRecord.Category,
          IsIncluded: editRecord.IsIncluded,
          IsArchive: false,
          SurveyId: surveyId,
          SchoolId: schoolId,
          SignType: editRecord.SignType,
          LikertFormat: editRecord.LikertFormat
        }}
        onSubmit={(values, actions) => {
          const { isEdit } = this.state;
          if (!isEdit) values.Id = 0;
          actions.setSubmitting(true);
          handleSubmit(values);
        }}
        validateOnBlur={false}
        validateOnChange={true}
        render={({
          handleSubmit,
          setFieldValue,
        }) => (
          <div className='styles_modal__gNwvD'>
            <AppModel              
              open={isOpen}
              onClose={onClose}              
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container className='form-inner-wrapper'>
                      
                      <Grid item
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                      >
                        <NewSurveyInnerMain>
                          <NewSurveyInner>
                            <AdditionalQuestionsFormWrapper>
                              <h2>Choose Likert Format</h2>
                              <div className='action-plan-instructions'>
                                Pick up the relevent likert format for the question.
                              </div>
                              <InputWrapper>
                                  <Grid item
                                    lg={12}
                                    sm={12}
                                    md={12}
                                    xs={12}
                                    className='form-column-wrapper radio-group'
                                  >
                                    <RadioGroupWrapper>
                                      <FormControl component="fieldset">
                                        <FormLabel component="legend">Answer Format</FormLabel>
                                        <RadioGroup 
                                          aria-label="likertScale" 
                                          name="likertScale" 
                                          value={likertFormat} 
                                          onChange = {(event) => {
                                            var questionFormat = Number(event.target.value);
                                            setFieldValue('LikertFormat', questionFormat);
                                            this.setState({
                                              likertFormat: questionFormat
                                            });
                                          }}>
                                          <FormControlLabel value={0} control={<Radio />} label="Never, Seldom, Sometimes, Often, Always" />
                                          <FormControlLabel value={1} control={<Radio />} label="Strongly disagree, Disagree, Neutral, Agree, Strongly agree" />
                                          <FormControlLabel value={2} control={<Radio />} label="Very dissatisfied, Dissatisfied, Neutral, Satisfied, Very satisfied" />
                                          <FormControlLabel value={3} control={<Radio />} label="Not important, Slightly Important, Fairly Important, Important, Very important" />
                                          <FormControlLabel value={4} control={<Radio />} label="For next term, For at least a year, For at least 3 years, For at least 5 years, I’ll retire here" />
                                        </RadioGroup>
                                      </FormControl>
                                    </RadioGroupWrapper>

                                  </Grid>
                                
                              </InputWrapper>
                            </AdditionalQuestionsFormWrapper>
                          </NewSurveyInner>
                        </NewSurveyInnerMain>
                      </Grid>
                      
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
              footer={
                <FooterButtonsWrapper>                 
                  <AppButton color={mandy} type='button' onClick={handleSubmit}>
                    Save
                  </AppButton>
                </FooterButtonsWrapper>
              }
            />
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionPlanService, dispatch),
  actionPlanActions: bindActionCreators(actionPlanActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(AdditionalQuestionLikertForm);
