import React from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Tooltip } from '@mui/material';
import { storageService } from '../../services';
import { WebStorageNames } from '../../utils';
import { padding } from '@mui/system';
Chart.register(ChartDataLabels);

const GetTitlesForBarChart = (data) => {
  const value = data
    .filter((d) => d)
    .map((x) => x.label)
    .join(', ');
  return value;
};
export default function AnalyticsBarChart(props) {
  const region = storageService.getItem(WebStorageNames.UsRegion);
  var jobRoleText =
    region === 'us' && props.Chartlabel === 'Senior Leader'
      ? 'District Administrator'
      : region === 'us' && props.Chartlabel === 'Middle Leader'
      ? 'Building Administrator'
      : props.Chartlabel;

  const [isLoader, setIsLoader] = React.useState(false);
  const downloadChart = (id) => {
    setIsLoader(true);
    let chartId = 'barChart' + id;
    const imageLink = document.createElement('a');
    const canvas = document.getElementById(chartId);
    imageLink.download = 'BarChart.png';
    imageLink.href = canvas.toDataURL('image/png', 1);

    imageLink.click();
    setIsLoader(false);
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        suggestedMin: 1,
        suggestedMax: 5,
        ticks: {
          color: '#000',
          font: {
            family: 'Avenir LT Pro',
            weight: 'bold',
          },
        },
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          color: '#000',
          weight: 'bold',
          font: {
            family: 'Avenir LT Pro',
            weight: 'bold',
          },
        },
      },
    },
    plugins: {
      title: {
        font: {
          size: 14,
          family: 'Avenir LT Pro',
        },
        color: '#000',
        display: true,
        text: props.mainTitle + '  ' + jobRoleText,
        padding: { bottom: 30 },
        
      },
      datalabels: {
        responsive: true,
        color: '#000',
        labels: {
          title: {
            align: 'top',
            anchor: 'end',
            font: {
              family: 'Avenir LT Pro',
              weight: 'bold',
              size: '10%',
            },
          },
          value: {
            font: {
              family: 'Avenir LT Pro',
              size: '10%',
             
              weight: 'bold',
            },
            align: 'top',
            anchor: 'top',
            color: '#fff',
            formatter: function (value, context) {
              return context.chart.data.datasets[context.datasetIndex]
                .decileData[context.dataIndex];
            },
          },
        },
      },
      legend: {
        display: false,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label(context) {
            return (
              context.chart.data.datasets[context.datasetIndex].label +
              ': ' +
              context.formattedValue +
              ' Decile (' +
              context.chart.data.datasets[context.datasetIndex].decileData[
                context.dataIndex
              ] +
              ')'
            );
          },
        },
      },
    },
  };
  let data = {
    labels: props.datalables,
    datasets: props.datasets,
  };
  return (
    <>
      {props.children}
      <div className='large-download-btn'>
        <Tooltip title='Download Chart' arrow>
          {isLoader ? (
            <CircularProgress />
          ) : (
            <ArrowCircleDownIcon
              className='large-graph-btn'
              fontSize='inherit'
              onClick={() => downloadChart(props?.id)}
            />
          )}
        </Tooltip>
      </div>
      {props.datasets !== 0 && props.datasets !== 'undefined' ? (
        <Bar
          type='bar'
          id={'barChart' + props.id}
          responsive={true}
          min={0}
          max={5}
          height={props.height}
          download={true}
          data={data}
          options={options}
        />
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
