import React from 'react';
import {
  WrapperStyle,
  InnerWrapperStyle,
  ManagementStandardStyle,
  RiskFactorsStyle,
} from '../../resources/styling/otherSuggestionStyle';
import { useTranslation } from 'react-i18next';
const DemandSuggestion = () => {
  const { t } = useTranslation();
  return (
    <div>
      <WrapperStyle>
        <InnerWrapperStyle>
          <h2>Further actions to address risks to your management standards</h2>
          <h3>1. Demands</h3>

          <ManagementStandardStyle>
            <li>
              Scores from one or more questions indicate that high or perceived
              high workload may be an issue. This could be based on the
              comparative performance against other schools, all{' '}
              {t('organisations')} or due to the scores of one particular job
              role. As a result it may or may not be a priority.
              <p>
                If you decide to act on this, start by assessing the impact of
                workload, agree what is needed to deliver your required
                outcomes, and make sure staff do stick to what has been agreed.
              </p>
              <p>
                Staff often continue to undertake too much because of their own
                perceptions, beliefs or fears and as a result put additional
                pressure on themselves.
              </p>
              <p>
                You might, for example, review lesson planning, marking and data
                collection for assessment. You may already have reviewed the
                Government’s workload reduction toolkit (
                <a href='https://www.gov.uk/government/collections/workload-reduction-toolkit'>
                  https://www.gov.uk/government/collections/workload-reduction-toolkit
                </a>
                ) and other resources, and if not then it is a good place to
                start.
              </p>
            </li>

            <li>
              Leaders have to act as role models, e.g. working sensible hours,
              taking breaks, meeting deadlines and demonstrating good planning
              and practice (including making sure things are good enough rather
              than always having to be perfect). This includes making sure line
              {t('managers')} are effective in delegation and consider workload
              and removing tasks when distributing new ones.
              <p>
                Given how busy {t('seniorandmiddle')} are, this is often very
                hard for them to deliver but is important for their own and
                their team members benefit. Openly discuss this as a management
                team and identify small steps to start and then build on them as
                a group. It will be much easier to deliver with leaders
                supporting each other than individually.
              </p>
              <p>
                In addition you can make sure leaders are aware of and
                demonstrate those competencies proven to prevent and reduce
                staff stress (these are available for you to use and further
                details are included in next steps at the end of this report).
                It is often how line {t('managers')} approach workload, for
                example how deadlines are set, the empathy they show, and how
                regularly they check in with team members that makes a big
                difference.
              </p>
            </li>
            <li>
              Ensure breaks are long enough for staff to be able to walk to the
              staff room and back and have the opportunity for refreshments and
              the time to switch tasks. It is very easy for staff to continue to
              work through breaks or to fit in extra work activity and research
              has shown this is a false economy leading to lower productivity
              and performance, as well as health issues.
              <p>
                Create the right staff room environment to encourage more people
                to visit it during breaks. One way of doing this is to find a
                way to have drinks ready and prepared for staff, as well as
                making sure the clearing up is done for them.
              </p>
              <p>
                While finances and resources are tight, schools that have found
                a way of doing this have seen a return to a fuller and more
                vibrant staff room and reported significant benefits to morale
                and wellbeing, with all its associated benefits.
              </p>
            </li>
            <li>
              Make sure meetings that are held are necessary and effective and
              as a result add value and don’t impinge on staff time that could
              be used in other ways. Review the meetings that are held across
              your schools and Trust.
              <p className='sub-heading'>
                Below are some good practices you can use:
              </p>
              <div class='secondaryList'>
                <RiskFactorsStyle>
                  <li>
                    Is this the best way to communicate information or could
                    this be distributed in other ways?
                  </li>
                  <li>
                    Does everyone who is attending a meeting need to be there?
                  </li>
                  <li>
                    Is there a clear agenda and what outcome do you want from
                    the meeting?
                  </li>
                  <li>
                    Is the agenda realistic and can it be covered effectively in
                    the time available? Because specific meetings may happen
                    infrequently there is often a tendency to overfill the
                    agenda, forgetting that more is often less!
                  </li>
                  <li>
                    Were you able to stick to the agenda and deliver the
                    intended outcome? Asking this is a good way to finish and to
                    take learning into the next meeting.
                  </li>
                  <li>
                    Did the meeting start and finish on time? As well as being
                    good manners, this is important for people who have planned
                    their workload or have other plans.
                  </li>

                  <li>
                    Is everyone prepared for the meeting? This means that any
                    important information needs to be distributed in advance and
                    in a succinct but effective way and staff need the time to
                    review it.
                  </li>
                </RiskFactorsStyle>
              </div>
            </li>

            <li>
              Consider how effectively e-mail is used within your Trust. Are
              there clear protocols in place to make sure that e-mail is a
              supportive and enhancing tool, rather than one that overwhelms and
              where it is difficult to pick out those that are important.
              <p className='sub-heading'>
                Make sure there is clear guidance on:
              </p>
              <div class='secondaryList'>
                <RiskFactorsStyle>
                  <li>
                    When to use e-mail and when it isn’t appropriate for the
                    task.
                  </li>
                  <li>
                    How to use it, for example who to copy in. Copying an e-mail
                    to other parties is usually used to cover a position, to
                    show that something has been done, to put pressure on
                    someone, or for reassurance. Ideally recipients should
                    always be in the ‘To’ rather than ‘Cc’ box and the e-mail
                    marked with any action needed, e.g. for reading, for
                    feedback, or for action.
                  </li>
                  <li>
                    Agree at what times it is not OK to send an e-mail. Should
                    there be a cut off for evenings and weekends? While staff
                    may like to work in different ways, and those sending
                    e-mails out of hours may say they don’t expect them to be
                    opened, some staff find it difficult to not open them. When
                    they do it may put pressure on them. Better to time the
                    e-mail send or leave in drafts and send the next working
                    day.
                  </li>
                </RiskFactorsStyle>
              </div>
            </li>

            <li>
              Staff often have to manage multiple deadlines and requested by
              more than one leader. Depending on their role these can cover
              those they set themselves, from their department, to do with
              curriculum, data and assessment and those covering pastoral needs,
              among others.
              <p>
                Having a Trust and school calendar and plan that highlights
                deadlines and key workload activities is a good way of ensuring
                these are spread across each term and take account of likely
                activities that will be taking place.
              </p>
              <p>
                If you already do this then you might review and consider if
                there are opportunities for further improvements.
              </p>
            </li>
            <li>
              Although difficult for many staff within their job role, and
              particularly for teaching staff, provide as much personal and
              flexible time as possible and where they are able to prioritise
              workload and activities. {t('linemanagers')} can provide 1 to 1
              support and coaching to help with this and staff managing their
              workload.
            </li>
            <li>
              Provide specific professional development that includes training
              on personal effectiveness, including prioritising, focus, chunking
              work and when to do e-mails. Many staff in schools are
              perfectionists and focusing only on the most important tasks that
              deliver impact is important.
            </li>
            <p>
              We can provide further guidance as well as specific training where
              needed across this and other areas.
            </p>
          </ManagementStandardStyle>
        </InnerWrapperStyle>
      </WrapperStyle>
    </div>
  );
};

export default DemandSuggestion;
