import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
} from 'recharts';
import { ImportExport, North, South } from '@mui/icons-material';
import { height } from '@mui/system';

const sortOrder = {
  none: 0,
  ascending: 1,
  descending: 2,
};

const ManagementStandardChart = (props) => {
  const [sortingOrder, setSortingOrder] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [transformedData, setTransformedData] = useState(props?.datasets);
  const [currentSortedCatetgory, setCurrentSortedCategory] = useState('');

  useEffect(() => {
    setTransformedData(props.datasets);
  }, [props.datasets]);

  function sortByCategory(sortBy, direction, category) {
    if (direction === sortOrder.ascending) {
      props.onSort(sortBy, sortOrder.ascending,category);
    } else if (direction === sortOrder.descending) {
      props.onSort(sortBy, sortOrder.descending,category);
    }
    setCurrentSortedCategory(category);
    setSortingOrder(direction);
    setSortBy(sortBy);
  }

  var barHeight = 30;
  var barGap = props?.surveyCount > 1 ? 3 : 1.8;
  const totalHeight = props?.datasets.length * barHeight * barGap;
  const categoryWidth = 1170;

  return transformedData.length > 0 ? (
    <div className='analytics-container'>
      <div className='analytics-legends'>
        {props.hideBars === false ? (
          <>
            {' '}
            {sortBy === 'value1' && sortingOrder === sortOrder.ascending && props.currentSortedCatetgory === currentSortedCatetgory  ? (
              <North
                style={{ backgroundColor: transformedData[0].backgroundColor1 }}
                className='north-sort-btn'
                onClick={(e) => sortByCategory('value1', sortOrder.descending,transformedData[0]?.title)}
              ></North>
            ) : sortBy === 'value1' && sortingOrder === sortOrder.descending  && props.currentSortedCatetgory === currentSortedCatetgory ? (
              <South
                style={{ backgroundColor: transformedData[0].backgroundColor1 }}
                className='south-sort-btn'
                onClick={(e) => sortByCategory('value1', sortOrder.ascending,transformedData[0]?.title)}
              ></South>
            ) : (
              <ImportExport
                style={{ backgroundColor: transformedData[0].backgroundColor1 }}
                className='sort-btn'
                onClick={(e) => sortByCategory('value1', sortOrder.ascending,transformedData[0]?.title)}
              ></ImportExport>
            )}
            <text
              x={props.width / 2}
              textAnchor='middle'
              fill='black'
              style={{
                paddingLeft: '5px',
                fontSize: ' 14px',
              }}
            >
              {transformedData[0]?.title}
            </text>
          </>
        ) : (
          <div style={{ height: '24px' }}></div>
        )}
      </div>

      <div className='horizontal-analytics'>
        <BarChart
          height={totalHeight}
          data={transformedData}
          layout='vertical'
          margin={{ left: props.margin }}
          barSize={30}
          width={props.isCategory ? categoryWidth : props.width}
        >
          <CartesianGrid vertical={false} strokeDasharray='3 3' />
          <XAxis type='number' hide={true} domain={[0, 5]} />
          <YAxis
            dataKey='label'
            hide={props.TicksHidden}
            type='category'
            width={170}
            fontWeight='bold'
            fontFamily='Avenir LT Pro'
            tick={{ fill: '#000', fontSize: 12 }}
            tickLine={{ stroke: 'none' }}
          />

          {props.hideBars === false && (
            <>
              <Bar
                dataKey='value1'
                background={'gray'}
                fill={transformedData[0].backgroundColor1}
                name='Value 1'
              >
                <LabelList
                  dataKey={`value1`}
                  position='insideLeft'
                  fill='#fff'
                  fontSize={14}
                  className='value-label'
                  offset={50}
                  formatter={(value) => value?.toFixed(2)}
                />
                <LabelList
                  dataKey={`decileValue1`}
                  position='insideLeft'
                  fill='#fff'
                  fontSize={12}
                  className='decile-label'
                  offset={80}
                />
              </Bar>

              {props?.surveyCount > 1 && (
                <Bar
                  dataKey='value2'
                  background={'gray'}
                  fill={transformedData[0].backgroundColor2}
                  name='Value 2'
                >
                  <LabelList
                    dataKey={`value2`}
                    position='insideLeft'
                    fill='#fff'
                    fontSize={14}
                    className='value-label'
                    offset={50}
                    formatter={(value) => value?.toFixed(2)}
                  />
                  <LabelList
                    dataKey={`decileValue2`}
                    position='insideLeft'
                    fill='#fff'
                    fontSize={12}
                    className='decile-label'
                    offset={80}
                  />
                </Bar>
              )}
            </>
          )}
        </BarChart>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default ManagementStandardChart;
