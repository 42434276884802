import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import {
  AppButton,
  customStyles,
  ErrorWrapper,
  StaffWrapper,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import { schoolStaffService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { DateFormats } from '../../utils/Constants';
const { purple } = colors;

const AddStaffMember = (props) => {
  const [matSchool, setMatSchool] = useState('');
  const [jobRole] = useState('');
  const [type, setType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [filterValues, setFilterValues] = useState([]);
  const [schoolFilters, setSchoolFilters] = useState([]);
  const [filterLibrary, setFilterLibrary] = useState([]);
  const { loaderActions } = props;
  const [jobTitles, setJobTitles] = useState([]);
  const [jobTitleInputValue, setJobTitleInputValue] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [queryfilters, setQueryFilter] = useState([]);
  const [filterDropDownMaxSize] = useState(500);

  useEffect(() => {
    if (!props.isMat) {
      getSchoolFilters(props.schoolId);
      getFilterValues(props.schoolId);
      getFilterLibrary();
    }
  }, [props.isMat]);

  useEffect(() => {
    if (props.isMat) {
      getFilterLibrary();
    }
  }, [props.isMat]);

  useEffect(() => {
    if (!props.isMat) {
      initialValues(props.schoolId);
    }
  }, []);

  useEffect(() => {
    let filters =
      [...filterLibrary] !== null
        ? [...filterLibrary]
          .filter((x) => x.KeyWord !== null && x.KeyWord !== '')
          .filter((x) =>
            x.KeyWord.toLowerCase().includes(jobTitleInputValue.toLowerCase())
          )
        : null;
    setQueryFilter([...filters].slice(0, filterDropDownMaxSize));
  }, [jobTitleInputValue]);

  const initialValues = (schoolId) => {
    setMatSchool(schoolId);
  };

  const getFilterValues = (schoolId) => {
    loaderActions.dataLoading(true);
    schoolStaffService
      .getFilterValuesBySchoolId(schoolId)
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          setFilterValues(data);
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  const getFilterLibrary = () => {
    loaderActions.dataLoading(true);
    schoolStaffService
      .getFilterLibrary()
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          setFilterLibrary(data);
          setQueryFilter([...data].slice(0, filterDropDownMaxSize));
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  const getSchoolFilters = (schoolId) => {
    loaderActions.dataLoading(true);
    schoolStaffService
      .getFiltersBySchoolId(schoolId)
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          setSchoolFilters(data);
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  const handleSchoolChange = (event) => {
    setMatSchool(event.target.value);
    let schoolId = event.target.value;
    getSchoolFilters(schoolId);
    getFilterValues(schoolId);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleJobTitleChange = (event, values) => {
    setJobTitles(values);
  };

  function getFiltersOnSubmit(values) {
    let filterValues = [];
    schoolFilters.map((item) => {
      let opt = item.Attribute;
      let filterValue = values[opt];
      if (
        filterValue !== undefined &&
        (filterValue !== null || filterValue !== '')
      ) {
        let filterValueModel = {
          FilterId: filterValue.key,
          Value: filterValue.value,
          SchoolId: values.matSchool,
        };
        filterValues.push(filterValueModel);
      }
    });
    return filterValues;
  }

  function getCoreFiltersOnSubmit() {
    let coreFilters = [];
    for (let i = 0; i < jobTitles.length; i++) {
      let coreFilter = {
        JobTitle: jobTitles[i].title,
      };
      coreFilters.push(coreFilter);
    }
    return coreFilters;
  }

  const filterValueSuggestions =
    filterValues !== null
      ? filterValues
        .sort((a, b) => (a.Value > b.Value ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.Value,
            key: item.FilterId,
          };
        })
      : null;

  const jobTitleSuggestions =
    [...queryfilters] !== null
      ? [...queryfilters]
        .filter((x) => x.KeyWord !== null && x.KeyWord !== '')
        .sort((a, b) => (a.KeyWord > b.KeyWord ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.KeyWord,
            key: item.Id,
          };
        })
      : null;

  const schoolItems =
    props.matSchools !== null
      ? props.matSchools.map((item) => (
        <MenuItem key={item.Id} value={item.Id}>
          {item.Name}
        </MenuItem>
      ))
      : null;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: firstName !== null ? firstName : '',
        lastName: lastName !== null ? lastName : '',
        email: email !== null ? email : '',
        matSchool: matSchool !== null ? matSchool : '',
        jobRole: jobRole === null || jobRole == '' ? 0 : jobRole,
        type: type === null || type == '' ? 0 : type,
        startDate: startDate !== null ? startDate : new Date(),
        jobTitles: jobTitles !== null ? jobTitles : [],
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().trim().required('First name is required.'),
        lastName: Yup.string().trim().required('Last name is required.'),
        email: Yup.string()
          .trim()
          .email('Provide email address like username@gmail.com')
          .required('Email is required.'),
        matSchool: Yup.string().trim().required('School is required.'),
      })}
      onSubmit={(values, formProps) => {
        setBtnDisabled(true);

        //Core Filter
        let coreFilters = getCoreFiltersOnSubmit();

        //Additional Filter
        let filterValues = getFiltersOnSubmit(values);

        let model = {
          FirstName: values.firstName,
          LastName: values.lastName,
          Email: values.email?.toLowerCase(),
          SchoolId: values.matSchool,
          StartDate: values.startDate,
          EmploymentType: values.type,
          CoreFilters: coreFilters,
          FilterValues: filterValues,
        };

        schoolStaffService
          .saveSchoolStaff(model)
          .then((response) => {
            setBtnDisabled(false);
            if (response.success) {
              toast.success('Record added successfully.');
              if (!props.isMat) props.getStaff();
              else props.getMatStaff();
              props.handleClose();
            } else if (
              response.message === 'email already exist' ||
              response.message === 'email should be example@domain.com'
            ) {
              formProps.setErrors({ email: response.message });
            } else {
              toast.error('Record not added. Please try again later.');
            }
          })
          .catch((error) => {
            setBtnDisabled(false);
          });
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        return (
          <Grid item xs={12}>
            <AppModel
              onClose={props.handleClose}
              styles={customStyles}
              open={true}
              titleText={'Add Staff Member'}
              bodyText={
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '25px' }}>
                    Add your new staff member.
                  </p>
                  <Box
                    component='form'
                    sx={{
                      '& .MuiTextField-root': { width: '100%' },
                    }}
                    autoComplete='off'
                  >
                    <StaffWrapper>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='firstName'
                            id='first-name'
                            label='First Name'
                            onChange={(e) => {
                              setFieldValue('firstName', e.target.value);
                              setFirstName(e.target.value);
                            }}
                          />

                          <ErrorWrapper>
                            {touched.firstName && errors.firstName}
                          </ErrorWrapper>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='lastName'
                            id='last-name'
                            label='Last Name'
                            onChange={(e) => {
                              setFieldValue('lastName', e.target.value);
                              setLastName(e.target.value);
                            }}
                          />

                          <ErrorWrapper>
                            {touched.lastName && errors.lastName}
                          </ErrorWrapper>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='email'
                            id='staff-email'
                            label='Email'
                            type='email'
                            onChange={(e) => {
                              setFieldValue('email', e.target.value);
                              setEmail(e.target.value);
                            }}
                          />

                          <ErrorWrapper>
                            {touched.email && errors.email}
                          </ErrorWrapper>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat={DateFormats.format_31_12_2019}
                              label='Start Date'
                              name='startDate'
                              openTo='day'
                              value={startDate}
                              onChange={(date) => {
                                setFieldValue('startDate', date);
                                handleDateChange(date);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        {props.isMat ? (
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id='mat-schools'>School</InputLabel>
                              <Select
                                name='matSchool'
                                labelId='mat-schools'
                                id='schools'
                                value={matSchool}
                                label='School'
                                onChange={(e) => {
                                  setFieldValue('matSchool', e.target.value);
                                  handleSchoolChange(e);
                                }}
                              >
                                {schoolItems}
                              </Select>
                            </FormControl>

                            <ErrorWrapper>
                              {touched.matSchool && errors.matSchool}
                            </ErrorWrapper>
                          </Grid>
                        ) : null}

                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id='staff-type'>Type</InputLabel>
                            <Select
                              fullWidth
                              name='type'
                              labelId='staff-type'
                              id='type'
                              value={type}
                              label='Type'
                              onChange={(e) => {
                                setFieldValue('type', e.target.value);
                                handleTypeChange(e);
                              }}
                            >
                              <MenuItem value={0}>None</MenuItem>
                              <MenuItem value={1}>Full Time</MenuItem>
                              <MenuItem value={2}>Part Time</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* Staff Core Filters */}
                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              fullWidth
                              multiple
                              freeSolo={false}
                              name='jobTitles'
                              inputValue={jobTitleInputValue}
                              options={[
                                ...new Set(
                                  jobTitleSuggestions.map((opt) => opt)
                                ),
                              ]}
                              onChange={handleJobTitleChange}
                              onInputChange={(e, value) => {
                                setJobTitleInputValue(value);
                              }}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={'Job Titles'}
                                  margin='normal'
                                />
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.title, inputValue);
                                const parts = parse(option.title, matches);

                                return (
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                );
                              }}
                              style={{
                                top: '-15px',
                                position: 'absolute',
                              }}
                            />
                          </FormControl>
                        </Grid>

                        {/* Staff Core Filter End */}

                        {/* Staff Additional Filters Start */}

                        {schoolFilters.map((obj, index) => (
                          <Grid item md={6} sm={6} xs={12}>
                            <Autocomplete
                              fullWidth
                              freeSolo={true}
                              name={obj.Attribute}
                              options={[
                                ...new Set(
                                  filterValueSuggestions.filter(
                                    (x) => x.key == obj.Id
                                  )
                                ),
                              ]}
                              onInputChange={(e, v) => {
                                setFieldValue(obj.Attribute, {
                                  value: v,
                                  key: obj.Id,
                                });
                              }}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField {...params} label={obj.Attribute} />
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.title, inputValue);
                                const parts = parse(option.title, matches);

                                return (
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                );
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>

                      <AppButton
                        color={purple}
                        type='button'
                        onClick={handleSubmit}
                        style={{ marginTop: '20px' }}
                      >
                        {btnDisabled ? 'Saving...' : 'Save'}
                      </AppButton>
                    </StaffWrapper>
                    {/* School Filters End */}
                  </Box>
                </div>
              }
            />
          </Grid>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(AddStaffMember);
